export function splitIntoEqualParts(num: number, parts: number) {
    // If we cannot split the
    // number into exactly 'N' parts
    if (num < parts) {
        return undefined
    }

    const res: number[] = []
    // If x % n == 0 then the minimum
    // difference is 0 and all
    // numbers are x / n
    if (num % parts == 0) {
        for (let i = 0; i < parts; i++) {
            res.push(num / parts)
        }
    } else {
        // upto n-(x % n) the values
        // will be x / n
        // after that the values
        // will be x / n + 1
        const zp = parts - (num % parts)
        const pp = Math.floor(num / parts)
        for (let i = 0; i < parts; i++) {
            if (i >= zp) {
                res.push(pp + 1)
            } else {
                res.push(pp)
            }
        }
    }
    return res
}

export function padZero(num: number, width: number) {
    return pad(num + "", width, "0")
}

export function pad(n: string, width: number, padChar: string) {
    padChar = padChar || "0"
    n = n + ""
    return n.length >= width ? n : new Array(width - n.length + 1).join(padChar) + n
}
