import React from "react"
import { useTranslation } from "react-i18next"
import "./FormHeaderPartner.scss"

const FormHeaderPartner = () => {
    const { t } = useTranslation()
    return (
        <div className="form-head">
            <h2 id="partner-heading" className="title text-center form-head-heading">
                <span>{t("partnersignup.header1")} </span>
                <span>{t("partnersignup.header2")} </span>
            </h2>
        </div>
    )
}

export default FormHeaderPartner
