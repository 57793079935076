import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { ISignupDetails, prepareFormData, requestThunk, userReducerActions, userSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { IUserSignupDataFormModel } from "../../type/IUserSignupDataFormModel"
import { UserSignupFormModel } from "../../type/userSignupFormModel"
import { StoreState } from "../StoreState"

export const userSignupThunk =
    (form: UserSignupFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const userRegisterData = await dispatch(
            requestThunk<ISignupDetails>(endpoints.Core.register, {
                data: {
                    ...prepareFormData(form, []),
                },
            })
        )

        if (userRegisterData.token) {
            dispatch(userReducerActions.setUserRegisterData(userRegisterData))
        }
    }

export const removeUserRegisterDataThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch) => {
    dispatch(userReducerActions.removeUserRegisterData())
}

export const addDataWithToken =
    (form: IUserSignupDataFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const signupDetails = userSelectors.userRegisterData(getState())

        if (!signupDetails) {
            return
        }

        await dispatch(
            requestThunk<void>(endpoints.Core.addDataWithToken, {
                data: {
                    ...prepareFormData(form, ["isBillingAddress"]),
                    token: signupDetails.token,
                },
            })
        )
    }

export const checkUserExistsThunk =
    (inputEmail: string): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            const email = inputEmail.trim().toLowerCase()
            await dispatch(
                requestThunk<void>(
                    endpoints.Core.userExists,
                    {
                        params: { UserName: email },
                    },
                    { noErrorAlert: true }
                )
            )
            return true
        } catch (err) {
            return false
        }
    }

export const isUserConfirmedThunk =
    (inputEmail: string): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        try {
            const email = inputEmail.trim().toLowerCase()
            const res = await dispatch(
                requestThunk<{ emailConfirmed: boolean; userExists: boolean }>(
                    endpoints.Core.userExists,
                    {
                        params: { UserName: email },
                    },
                    { noErrorAlert: true }
                )
            )
            return res.emailConfirmed
        } catch (err) {
            return false
        }
    }
