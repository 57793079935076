import { TCountryCode } from "swiipe.portal.shared"
import TrygLogo from "../images/swPaymentTypes/account-to-account-insurance-tryg-texted-logo.png"
import { IGatewayDto } from "../store/reducers/gatewayReducer"
import { IUserRelationFlattened } from "../type/IUserRelation"
import { TUserRole } from "../type/TUserRole"
import { IA2aGatewayConfig } from "../type/gatewayConfigs/IA2aGatewayConfig"
import { IA2aUserAccount } from "./../type/a2a/IA2aUserAccount"

interface IA2aAccountsModel {
    mainAccounts: IA2aUserAccount[]
    otherAccounts: IA2aUserAccount[]
    unsupportedAccounts: IA2aUserAccount[]
    selectedAccountForWebshop?: IA2aUserAccount
}

type TA2aInsuranceTerms = "termsAndConditionsTryg"

type TA2aInsurance = {
    termsType: TA2aInsuranceTerms
    logoPath?: string
}

type TA2aInsuranceByCountry = {
    [countryCode in Extract<TCountryCode, "DK" | "SE">]: TA2aInsurance
}

const a2aInsuranceByCountry: TA2aInsuranceByCountry = {
    DK: { termsType: "termsAndConditionsTryg", logoPath: TrygLogo },
    SE: { termsType: "termsAndConditionsTryg" },
}

export const getA2aAccountsModel = (data: {
    userId: string
    webshopId?: string | undefined
    expectedCountryCode: string
    accounts: IA2aUserAccount[]
}): IA2aAccountsModel => {
    const { userId, webshopId, expectedCountryCode, accounts } = data

    const allSupportedAccounts = accounts?.filter((account) => account.providerCountryCode == expectedCountryCode)

    return {
        mainAccounts: allSupportedAccounts?.filter((account) => account.userId === userId),
        otherAccounts: allSupportedAccounts?.filter((account) => account.userId !== userId),
        unsupportedAccounts: accounts?.filter((account) => account.providerCountryCode != expectedCountryCode),
        selectedAccountForWebshop: webshopId
            ? accounts?.find((account) => account.selectedForWebshopIds.includes(webshopId ?? ""))
            : undefined,
    }
}

export const tryGetResolvedCountryCode = (gateway?: IGatewayDto, overwriteCountryCode?: string): string | undefined => {
    const gatewayDetails = gateway ? (gateway.configuration as IA2aGatewayConfig) : undefined
    const gatewayCountry = gatewayDetails?.supportedCountries[0] // TODO support multi countries
    const resolvedCountryCode = gatewayCountry ?? overwriteCountryCode ?? undefined
    return resolvedCountryCode
}

export function tryGetA2aInsuranceLogo(gateway?: IGatewayDto): string | undefined {
    if (!gateway) {
        return
    }

    const gatewayCountry = tryGetResolvedCountryCode(gateway)

    if (!gatewayCountry) {
        return
    }

    const insurance: TA2aInsurance | undefined = a2aInsuranceByCountry[gatewayCountry]

    if (!insurance?.logoPath) {
        return
    }

    return insurance.logoPath
}

export function isWebshopPaymentsAccountDeletable(
    userRelation: IUserRelationFlattened,
    userAccount: IA2aUserAccount,
    selectedWebshopId: string
) {
    const isAccountUsedForOtherWebshops =
        userAccount.selectedForWebshopIds.filter((webshopId) => webshopId != selectedWebshopId).length > 0

    const isNonOwnerRoleDeletingNonOwnedAccount = userAccount.userId != userRelation.id && userRelation.userRole < TUserRole.Owner

    if (isAccountUsedForOtherWebshops || isNonOwnerRoleDeletingNonOwnedAccount) {
        return false
    }

    return true
}
