import cn from "classnames"
import React from "react"
import { Input } from "reactstrap"
import { IStandardButtonBaseProps } from "swiipe.portal.shared"
import CopyToClipBoardButton from "../buttons/CopyToClipboardButton"
import "./TextAreaWithCopyBtn.scss"

export type TBtnPlacement = "below" | "right"

interface ITextAreaWithCopyBtnProps {
    name: string
    textToCopy: string
    label?: string
    labelClassName?: string
    btnClassName?: string
    textAreaClassName?: string
    rows?: number
    readOnly?: boolean
    btnProps?: IStandardButtonBaseProps
    btnPlacement?: TBtnPlacement
}

const TextAreaWithCopyBtn = (props: ITextAreaWithCopyBtnProps) => {
    const btnPlacement = props.btnPlacement || "below"
    return (
        <div className={cn("textarea-with-copybtn")}>
            {!!props.label && <span className={cn("fixed-label", "blue", props.labelClassName)}>{props.label}</span>}
            <div className={cn("inputs", btnPlacement)}>
                <Input
                    name={props.name}
                    type="textarea"
                    value={props.textToCopy}
                    rows={props.rows ?? 9}
                    readOnly={props.readOnly ?? true}
                    className={props.textAreaClassName}
                ></Input>
                <CopyToClipBoardButton
                    id="copy-to-clipboard-btn"
                    className={props.btnClassName}
                    {...props.btnProps}
                    dark
                    noBorder
                    textToCopy={props.textToCopy}
                />
            </div>
        </div>
    )
}
export default TextAreaWithCopyBtn
