import { route } from "navi"
import React from "react"
import { getQueryValueFromCurrentUrl } from "swiipe.portal.shared"
import TermsTextPage from "../pages/TermsTextPage"
import { disableForEnvSandbox, enableOnlyForEnvSandbox, getRouteIncludingEnv } from "../services/routesService"
import { ETermsType } from "../store/reducers/legalReducer"
import { TUserRelation } from "../type/TUserRelation"

export const legalRoutes = {
    "/terms": getRouteIncludingEnv(
        <TermsTextPage
            userRelationType={getQueryValueFromCurrentUrl("relationType") as TUserRelation}
            userRelationId={getQueryValueFromCurrentUrl("relationId") ?? undefined}
            termsType={getQueryValueFromCurrentUrl("termsType") as ETermsType}
        />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_end_user": getRouteIncludingEnv(
        <TermsTextPage userRelationType="User" termsType="TermsUser" />,
        disableForEnvSandbox
    ),
    "/privacy_policy_end_user": getRouteIncludingEnv(
        <TermsTextPage userRelationType="User" termsType="PrivacyPolicyShared" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_merchant": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsMerchant" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_merchant_sandbox": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsMerchant" />,
        enableOnlyForEnvSandbox
    ),
    "/privacy_policy_merchant": route({
        view: <TermsTextPage userRelationType="Merchant" termsType="PrivacyPolicyShared" />,
    }),
    "/privacy_policy": route({
        view: <TermsTextPage userRelationType="User" termsType="PrivacyPolicyShared" />,
    }),
    "/data_processing_terms": route({
        view: <TermsTextPage userRelationType="User" termsType="DataProcessingAgreement" />,
    }),
    "/merchant_partner_agreement": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="MerchantPartnerAgreement" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_partner": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Partner" termsType="TermsPartner" />,
        disableForEnvSandbox
    ),
    "/privacy_policy_partner": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Partner" termsType="PrivacyPolicyShared" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_psp": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsPSP" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_a2a": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsA2a" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_acquiring": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsAcquiringClearhaus" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_mobilepay_online": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType={"TermsMobilePayOnline"} />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_dankort": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsDankort" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_forbrugsforeningen": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsForbrugsforeningen" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_winback": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsWinBack" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_plus_sell": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsPlusSell" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_anywhere_mobile": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsAnywhereMobile" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_return_sell": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsReturnSell" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_viabill": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsViaBill" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_applepay": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsApplePay" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_vipps": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsVipps" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_klarna": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsKlarna" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_swish": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsSwish" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_resurs": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsResurs" />,
        disableForEnvSandbox
    ),
    "/terms_and_conditions_external_analytics": getRouteIncludingEnv(
        <TermsTextPage userRelationType="Merchant" termsType="TermsExternalAnalytics" />,
        disableForEnvSandbox
    ),
}
