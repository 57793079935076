import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector, userSelectors } from "swiipe.portal.shared"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"

interface IUserEmailFormProps extends IEditFormBase {}

const UserEmailForm = ({ isInEditMode, onSwitchEditMode }: IUserEmailFormProps) => {
    const { t } = useTranslation()
    const userData = useSelector(userSelectors.userData)
    if (!userData || !userData.user) {
        return null
    }
    const email = userData.user.email

    return (
        <InfoBoxContainer isInEditMode={isInEditMode} iconClass="icon-email" title={t("common.email.label")}>
            <InfoBoxEditor
                state={InfoBoxState.NOEDIT}
                iconClass="icon-email"
                title={email ? t("personal.editphone") : ""}
                isInEditMode={false}
                editBtnTextType="editing"
                renderPreview={() => <div>{email}</div>}
                onSwitchEditMode={onSwitchEditMode}
            />
        </InfoBoxContainer>
    )
}

export default UserEmailForm
