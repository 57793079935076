import React, { useEffect, useRef, useState } from "react"
import { Layouts, Responsive as ResponsiveGridLayout, WidthProvider } from "react-grid-layout"
import { PageContainer, PageWrapper, SpinnerContainer, useSelector } from "swiipe.portal.shared"
import WebshopSelect from "../../components/merchant/WebshopSelect"
import MerchantDashboardCellContainer from "../../components/merchantDashboard/MerchantDashboardCellContainer"
import DashboardConversionDisplay from "../../components/merchantDashboard/dataDisplay/analytics/DashboardConversionDisplay"
import DashboardCustomersDisplay from "../../components/merchantDashboard/dataDisplay/customers/DashboardCustomersDisplay"
import DashboardOrdersDisplay from "../../components/merchantDashboard/dataDisplay/orders/DashboardOrdersDisplay"
import DashboardPaymentDisplay from "../../components/merchantDashboard/dataDisplay/paymentMethods/DashboardPaymentDisplay"
import DashboardPlusSellDisplay from "../../components/merchantDashboard/dataDisplay/plusSell/DashboardPlusSellDisplay"
import DashboardRefundsDisplay from "../../components/merchantDashboard/dataDisplay/refunds/DashboardRefundsDisplay"
import DashboardRevenueDisplay from "../../components/merchantDashboard/dataDisplay/revenue/DashboardRevenueDisplay"
import DashboardWinBackDisplay from "../../components/merchantDashboard/dataDisplay/winback/DashboardWinBackDisplay"
import MerchantDashboardFooter from "../../components/merchantDashboard/footer/MerchantDashboardFooter"
import MerchantDashboardDateFilters, {
    IMerchantDashboardSelectedDates,
} from "../../components/merchantDashboard/header/dateFilters/MerchantDashboardDateFilters"
import MerchantDashboardGreeting from "../../components/merchantDashboard/header/greeting/MerchantDashboardGreeting"
import MerchantDashboardLatestReportDate from "../../components/merchantDashboard/header/latestReportDate/MerchantDashboardLatestReportDate"
import { TMerchantDashboardServicesInfo, useMerchantDashboardServicesInfo } from "../../services/merchantDashboardService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantDashboardSelectors } from "../../store/reducers/merchantDashboardReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { getDashboardDataThunk } from "../../store/thunks/merchantDashboardThunks"
import { getOnboardingStatusThunk } from "../../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IMerchantDashboardServerData } from "../../type/merchantDashboard/IMerchantDashboardServerData"
import "./MerchantDashboardPage.scss"
import "./ReactGridLayoutStyles.css"
import "./ReactResizebleStyles.css"

const ReactGridLayout = WidthProvider(ResponsiveGridLayout)

const MerchantDashboardPage = () => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const dispatch = useReduxDispatch()

    const dashboardPageRef = useRef<HTMLElement | null | undefined>()

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    const merchantDashboardServicesInfo = useMerchantDashboardServicesInfo(merchantDetails, selectedWebshopId)

    const [selectedDates, setSelectedDates] = useState<IMerchantDashboardSelectedDates>({
        dates: { fromDateUTC: undefined, toDateUTC: undefined },
        comparePeriod: { fromDateUTC: undefined, toDateUTC: undefined },
    })

    const dashboardData = useSelector((state: StoreState) =>
        merchantDashboardSelectors.dashboardData(state, selectedWebshopId ?? "", selectedDates)
    )

    useEffect(() => {
        if (!merchantDetails || !selectedWebshopId) {
            return
        }

        dispatch(getDashboardDataThunk(false, merchantDetails.swMerchant.swMerchantId, selectedWebshopId, selectedDates))
        dispatch(getOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, selectedWebshopId, false))
    }, [selectedWebshopId, selectedDates])

    if (!merchantDetails) {
        return null
    }

    return (
        <PageWrapper className="merchant-dashboard-page" useRef={(ref) => (dashboardPageRef.current = ref)}>
            <PageContainer transparent>
                <MerchantDashboardCellContainer className="merchant-dashboard-webshop-select">
                    <WebshopSelect merchantDetails={merchantDetails} />
                </MerchantDashboardCellContainer>
                <MerchantDashboardCellContainer className="merchant-dashboard-header">
                    <MerchantDashboardGreeting />
                    <MerchantDashboardDateFilters onDatesUpdated={(selectedDates) => setSelectedDates(selectedDates)} />
                    <MerchantDashboardLatestReportDate
                        selectedWebshopId={selectedWebshopId}
                        paymentsEnabled={merchantDashboardServicesInfo.payments.isEnabled}
                    />
                </MerchantDashboardCellContainer>
                <SpinnerContainer showSpinner={!dashboardData}>
                    <div className="grid-container">
                        {dashboardData && (
                            <>
                                <MerchantDashboardPageGrid
                                    dashboardData={dashboardData.serverData}
                                    merchantDashboardServicesInfo={merchantDashboardServicesInfo}
                                />
                                <MerchantDashboardFooter
                                    merchantDetails={merchantDetails}
                                    selectedDates={selectedDates}
                                    dashboardPageRef={dashboardPageRef}
                                />
                            </>
                        )}
                    </div>
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

interface IMerchantDashboardPageGridProps {
    dashboardData: IMerchantDashboardServerData
    merchantDashboardServicesInfo: TMerchantDashboardServicesInfo
}

const MerchantDashboardPageGrid = ({ dashboardData, merchantDashboardServicesInfo }: IMerchantDashboardPageGridProps) => {
    const paymentsEnabled = merchantDashboardServicesInfo.payments.isEnabled || dashboardData.mainData.revenue.amountTotal > 0

    const layouts: Layouts = {
        lg: [
            { w: 3, h: 3, x: 0, y: 0, i: "revenue", static: true },
            { w: 3, h: 3, x: 3, y: 0, i: "refunds", static: true },
            { w: 6, h: 2, x: 0, y: 3, i: "externalAnalytics", static: true },
            { w: 6, h: 2, x: 0, y: 5, i: "orders", static: true },
            { w: 6, h: 3, x: 0, y: 7, i: "customers", static: true },
            { w: 6, h: 2, x: 6, y: 0, i: "winBack", static: true },
            { w: 6, h: 2, x: 6, y: 2, i: "plusSell", static: true },
            { w: 6, h: 5, x: 6, y: 4, i: "paymentMethods", static: true },
        ],
        md: [
            { w: 3, h: 3, x: 0, y: 0, i: "revenue", static: true },
            { w: 3, h: 3, x: 3, y: 0, i: "refunds", static: true },
            { w: 6, h: 2, x: 0, y: 3, i: "externalAnalytics", static: true },
            { w: 6, h: 2, x: 0, y: 5, i: "orders", static: true },
            { w: 3, h: 5, x: 0, y: 11, i: "customers", static: true },
            { w: 6, h: 2, x: 0, y: 7, i: "winBack", static: true },
            { w: 6, h: 2, x: 0, y: 9, i: "plusSell", static: true },
            { w: 3, h: 5, x: 3, y: 11, i: "paymentMethods", static: true },
        ],
        s: [
            { w: 3, h: 3, x: 0, y: 0, i: "revenue", static: true },
            { w: 3, h: 1, x: 2, y: 3, i: "refunds", static: true },
            { w: 3, h: 3, x: 0, y: 10, i: "externalAnalytics", static: true },
            { w: 3, h: 2, x: 0, y: 4, i: "orders", static: true },
            { w: 3, h: 4, x: 0, y: 17, i: "customers", static: true },
            { w: 3, h: 2, x: 0, y: 6, i: "winBack", static: true },
            { w: 3, h: 2, x: 0, y: 8, i: "plusSell", static: true },
            { w: 3, h: 4, x: 0, y: 13, i: "paymentMethods", static: true },
        ],
    }

    return (
        <ReactGridLayout
            breakpoints={{ lg: 1200, md: 650, s: 0 }}
            className="layout"
            onLayoutChange={(e) => {
                // console.log(e, "123")
            }}
            margin={[20, 20]}
            layouts={layouts}
            rowHeight={60}
            cols={{ lg: 12, md: 6, s: 3 }}
        >
            <div key="revenue">
                <DashboardRevenueDisplay dashboardData={dashboardData} paymentsEnabled={paymentsEnabled} />
            </div>
            <div key="refunds">
                <DashboardRefundsDisplay dashboardData={dashboardData} paymentsEnabled={paymentsEnabled} />
            </div>
            <div key="externalAnalytics">
                <DashboardConversionDisplay
                    dashboardData={dashboardData}
                    analyticsEnabled={merchantDashboardServicesInfo.externalAnalytics.isEnabled}
                    analyticsEnabledDate={merchantDashboardServicesInfo.externalAnalytics.enabledDate}
                />
            </div>
            <div key="orders">
                <DashboardOrdersDisplay dashboardData={dashboardData} paymentsEnabled={paymentsEnabled} />
            </div>
            <div key="winBack">
                <DashboardWinBackDisplay
                    dashboardData={dashboardData}
                    paymentsEnabled={paymentsEnabled}
                    winBackEnabled={merchantDashboardServicesInfo.winback.isEnabled}
                />
            </div>
            <div key="plusSell">
                <DashboardPlusSellDisplay
                    dashboardData={dashboardData}
                    paymentsEnabled={paymentsEnabled}
                    plusSellEnabled={merchantDashboardServicesInfo.plusSell.isEnabled}
                />
            </div>
            <div key="paymentMethods">
                <DashboardPaymentDisplay dashboardData={dashboardData} paymentsEnabled={paymentsEnabled} />
            </div>
            <div key="customers">
                <DashboardCustomersDisplay dashboardData={dashboardData} paymentsEnabled={paymentsEnabled} />
            </div>
        </ReactGridLayout>
    )
}

export default MerchantDashboardPage
