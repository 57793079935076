import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import { Form } from "reactstrap"
import {
    FieldsetFormGroup,
    ShowErrorMessages,
    isValidEmail,
    useSelector,
    userReducerActions,
    userSelectors,
} from "swiipe.portal.shared"
import { forgotPasswordThunk } from "../../../store/thunks/loginThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import { ForgotPasswordFormModel } from "./../../../type/ForgotPasswordFormModel"
import LinkButton from "./../../buttons/LinkButton"
import SubmitButton from "./../../buttons/SubmitButton"

interface IForgotPasswordFormProps {
    onMailSent: (email: string) => void
}

const ForgotPasswordForm = ({ onMailSent }: IForgotPasswordFormProps) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState, getValues, setValue } = useForm<ForgotPasswordFormModel>()
    const dispatch = useReduxDispatch()
    const emailToPrefill = useSelector(userSelectors.userEmailLoginFormPrefill)
    const currentRoute = useCurrentRoute()
    const returnUrl = currentRoute.url.query.returnUrl

    useEffect(() => {
        if (emailToPrefill) {
            setValue("email", emailToPrefill)
        }
    }, [])

    const onSubmit: SubmitHandler<ForgotPasswordFormModel> = async (data, e) => {
        try {
            await dispatch(forgotPasswordThunk(data))
            onMailSent(data.email)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <p>{t("forgotpw.label")}</p>
            <FieldsetFormGroup field>
                <EmailInputComp
                    inputName="email"
                    register={register}
                    onChange={() => {
                        if (isValidEmail(getValues().email)) {
                            dispatch(userReducerActions.setUserEmailLoginFormPrefill(getValues().email))
                        } else {
                            dispatch(userReducerActions.setUserEmailLoginFormPrefill())
                        }
                    }}
                />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-6 order-3 order-sm-1">
                    <FieldsetFormGroup>
                        <LinkButton href={returnUrl || "/login"} title={t("forgotpw.gotologin")} />
                    </FieldsetFormGroup>
                </div>

                <div className="col-lg-4 offset-lg-4 offset-md-2 col-md-5 col-sm-6 order-2">
                    <FieldsetFormGroup className="center-btn">
                        <SubmitButton formState={formState} title={t("forgotpw.sendreset")} />
                    </FieldsetFormGroup>
                </div>
            </div>
        </Form>
    )
}

export default ForgotPasswordForm
