import Parser from "html-react-parser"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { ShowErrorMessages, TextButton, valFuncRequired } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FixedLabelInput from "../../components/form/input/FixedLabelInput"
import "./SwishIdStep.scss"

interface ISwishIdStepProps {
    onNextStep: () => void
    onBackStep: () => Promise<void>
    swishId: string
    setSwishId: (swishId: string) => void
}

interface IFormModel {
    swishId: string
}

const SwishIdStep = ({ onNextStep, onBackStep, swishId, setSwishId }: ISwishIdStepProps) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<IFormModel>()
    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        setSwishId(data.swishId)
        onNextStep()
    }

    return (
        <section className="swish-id-step">
            <div className="swish-grey-textbox">{t("swishonboarding.toactivate")}</div>
            <div className="swish-sub-text">{Parser(t("swishonboarding.donthaveone"))}</div>

            <Form onSubmit={handleSubmit(onSubmit)} className="swish-id-form">
                <FixedLabelInput
                    label={t("swishonboarding.swishid")}
                    name="swishId"
                    innerRef={register(valFuncRequired(t("swishonboarding.swishiderror")))}
                    defaultValue={swishId}
                    className="swish-id-input"
                />

                <ShowErrorMessages formState={formState} />

                <SubmitButton dark noBorder noBorderRadius containerClass="submit-btn" formState={formState}>
                    {t("swiipeservicesonboarding.continue")}
                </SubmitButton>
            </Form>

            <TextButton text={t("commononboardingtexts.goback")} className="mb-5 mt-4" onClick={onBackStep} />
        </section>
    )
}

export default SwishIdStep
