import { ActionsUnion, createAction, toDict } from "swiipe.portal.shared"
import { replaceOrderInList } from "../../services/orderService"
import { Order, OrdersFilter } from "../../type/Order"
import { OrderDetailsDto } from "../../type/orderDetailsDto"
import { PaymentOrderDetails } from "../../type/paymentOrderDetails"
import { StoreState } from "../StoreState"

export type OrderState = Readonly<{
    webshops: {
        [webshopId: string]: WebshopOrders | undefined
    }
    ordersForReview: {
        [orderId: string]: Order | undefined
    }
    paymentOrderDetails: {
        [orderId: string]: PaymentOrderDetails | undefined
    }
    orderDetails: {
        [orderId: string]: OrderDetailsDto | undefined
    }
    ordersFilter: OrdersFilter
}>

type WebshopOrders = Readonly<{
    continuationToken?: string
    orders: Order[]
}>

const initialState: OrderState = {
    webshops: {},
    ordersForReview: {},
    paymentOrderDetails: {},
    orderDetails: {},
    ordersFilter: {},
}

export const orderReducerActions = {
    setOrders: (webshopId: string, orders: Order[], replaceOrders: boolean, continuationToken?: string) =>
        createAction("APP/SET_ORDERS_FOR_WEBSHOP", { webshopId, orders, replaceOrders, continuationToken }),
    setOrder: (webshopId: string, order: Order) => createAction("APP/SET_ORDER_FOR_REVIEW", { webshopId, order }),
    setPaymentOrderDetails: (orderId: string, details: PaymentOrderDetails) =>
        createAction("APP/SET_DETAILS_FOR_ORDER", { orderId, details }),
    setOrderDetails: (orderId: string, details: OrderDetailsDto) => createAction("APP/SET_INFO_FOR_ORDER", { orderId, details }),
    setOrderFilter: (ordersFilter: OrdersFilter) => createAction("APP/SET_ORDER_FILTER", { ordersFilter }),
    clearOrders: (webshopId: string) => createAction("APP/CLEAR_ORDERS", { webshopId }),
}

export type OrderActions = ActionsUnion<typeof orderReducerActions>

export const orderReducer = (state: OrderState = initialState, action: OrderActions) => {
    switch (action.type) {
        case "APP/SET_ORDERS_FOR_WEBSHOP": {
            return {
                ...state,
                webshops: {
                    ...(state.webshops || {}),
                    [action.webshopId]: {
                        continuationToken: action.continuationToken,
                        orders: action.replaceOrders
                            ? action.orders
                            : [...(state.webshops[action.webshopId]?.orders || []), ...action.orders],
                    },
                },
                ordersForReview: {
                    ...state.ordersForReview,
                    ...toDict(
                        action.orders,
                        (o) => o.orderId,
                        (o) => o
                    ),
                },
            }
        }
        case "APP/SET_ORDER_FOR_REVIEW": {
            return {
                ...state,
                webshops: {
                    ...state.webshops,
                    [action.webshopId]: state.webshops[action.webshopId]
                        ? {
                              ...state.webshops[action.webshopId],
                              orders: replaceOrderInList(state.webshops[action.webshopId]?.orders ?? [], action.order),
                          }
                        : undefined,
                },
                ordersForReview: {
                    ...state.ordersForReview,
                    [action.order.orderId]: action.order,
                },
            }
        }
        case "APP/SET_ORDER_FILTER": {
            return {
                ...state,
                webshops: {},
                ordersFilter: action.ordersFilter,
            }
        }
        case "APP/CLEAR_ORDERS": {
            return {
                ...state,
                webshops: {
                    ...(state.webshops || {}),
                    [action.webshopId]: undefined,
                },
                ordersForReview: {},
            }
        }
        case "APP/SET_DETAILS_FOR_ORDER": {
            return {
                ...state,
                paymentOrderDetails: {
                    ...state.paymentOrderDetails,
                    [action.orderId]: action.details,
                },
            }
        }
        case "APP/SET_INFO_FOR_ORDER": {
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    [action.orderId]: action.details,
                },
            }
        }
    }

    return state
}

export const orderSelectors = {
    getOrdersForWebshop: (state: StoreState, webshopId: string) =>
        state.orders.webshops[webshopId] && state.orders.webshops[webshopId]?.orders
            ? state.orders.webshops[webshopId]?.orders
            : undefined,
    getOrder: (state: StoreState, orderId: string) => state.orders.ordersForReview[orderId],
    getPaymentOrderDetails: (state: StoreState, orderId: string) => state.orders.paymentOrderDetails[orderId],
    getOrderDetails: (state: StoreState, orderId: string) => state.orders.orderDetails[orderId],
    getContinuationTokenPerMerchant: (state: StoreState, webshopId: string) =>
        state.orders.webshops[webshopId] && state.orders.webshops[webshopId]?.continuationToken,
    getOrderFilter: (state: StoreState) => state.orders.ordersFilter,
}
