import cn from "classnames"
import React, { useEffect } from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    CompletedFieldsReview,
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    ICompanyFieldsBase,
    PhoneNumberInput,
    getCompanyNumberPlaceholder,
    getCountriesList,
    getCountryByISO,
    lookupCityByZipcodeThunk,
    organizationSelectors,
    useSelector,
    valFuncPattern,
    valFuncRequired,
    valFuncRequiredAndPattern,
    validationPatterns,
    validationPatternsWithLocale,
} from "swiipe.portal.shared"
import {
    findClearhausCompanyTypeOption,
    findClearhausOwnershipTypeOption,
    getClearhausCompanyTypeFromCvrApiCompanyCode,
    getClearhausCompanyTypesOptions,
    getClearhausOwnershipTypesOptions,
} from "../../../../services/clearhausService"
import { getAvailableCompanyInfo } from "../../../../services/companyService"
import { openContactUsWidget } from "../../../../services/contactUsService"
import { StoreState } from "../../../../store/StoreState"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"
import { IClearhausApplicationData } from "../../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm } from "../../../../type/clearhaus/IClearhausApplicationForm"
import { TClearhausOnboardingFormFields } from "../../../../type/clearhaus/TClearhausFormFieldsType"
import { TClearhausFormState } from "../../../../type/clearhaus/TClearhausFormState"
import { MixedMerchantDetails } from "../../../../type/mixedmerchantdetails"
import { ClearhausUpdateInfoButton } from "../../../clearhaus/ClearhausUpdateInfoButton"
import "./ClearhausCompanyFields.scss"

interface IClearhausCompanyFieldsProps {
    control: Control<IClearhausApplicationForm>
    companyDetails: ICompanyFieldsBase | undefined
    merchantDetails: MixedMerchantDetails | undefined
    clearhausFormState: TClearhausFormState
    applicationData?: IClearhausApplicationData
    viewMode?: boolean
}

const ClearhausCompanyFields = ({
    control,
    companyDetails,
    merchantDetails,
    clearhausFormState,
    applicationData,
    viewMode,
}: IClearhausCompanyFieldsProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const savedFields = applicationData?.applicationFields?.company
    const currentFields = (!viewMode && control.getValues().company) || undefined
    const isCompanyDK = (currentFields?.country || savedFields?.country) === "DK"

    const company = useSelector((state: StoreState) =>
        organizationSelectors.companyData(state, merchantDetails?.swMerchant.registrationNumber || "")
    )

    const defaultCompanyTypeOption = findClearhausCompanyTypeOption(
        (o) => o.text === getClearhausCompanyTypeFromCvrApiCompanyCode(companyDetails?.companyCode)
    )?.value as string

    useEffect(() => {
        // needs triggering because depends on another property (company.country)
        // and react-hook-form "onChange" not always triggered which results in
        // incorrect validation on load
        const companyTypeFieldName: TClearhausOnboardingFormFields = "company.type"
        control.trigger(companyTypeFieldName)
    }, [currentFields?.country])

    const availableCompanyInfo = getAvailableCompanyInfo(company, companyDetails, merchantDetails, {
        companyType: defaultCompanyTypeOption,
        isValid: currentFields?.country != "DK" || !!defaultCompanyTypeOption,
    })
    const firstPageFieldsHidden = availableCompanyInfo.allCompanyInfoAvailable || clearhausFormState !== "first"

    return (
        <div className="clearhaus-company-fields">
            {clearhausFormState !== "first" && (
                <CompletedCompanyFieldsReview
                    control={control}
                    applicationData={applicationData}
                    clearhausFormState={clearhausFormState}
                    viewMode={viewMode}
                />
            )}
            {clearhausFormState === "submitted" && <ClearhausUpdateInfoButton applicationData={applicationData} />}

            {clearhausFormState === "second" && (
                <FieldsetFormGroup className="mb-0">
                    <DropDownList
                        name="company.ownershipType"
                        placeholder={t("clearhausonboarding.ownershiptypes.placeholder")}
                        options={getClearhausOwnershipTypesOptions()}
                        innerRef={control.register(valFuncRequired(t("clearhausonboarding.inputfields.errormsg.ownershiptype")))}
                    ></DropDownList>
                </FieldsetFormGroup>
            )}

            {availableCompanyInfo.allCompanyInfoAvailable && clearhausFormState === "first" && (
                <div className="clearhaus-company-fields__company-review">
                    <CompletedCompanyFieldsReview
                        control={control}
                        applicationData={applicationData}
                        clearhausFormState={clearhausFormState}
                        viewMode={viewMode}
                    />
                    <p className="clearhaus-company-fields__contact-us">
                        {t("clearhausonboarding.messagetitles.incorrectInfo")}
                        <br />
                        <span onClick={() => openContactUsWidget()}>{t("clearhausonboarding.messagetitles.contactUs")}</span>
                    </p>
                </div>
            )}

            <div className={cn({ "d-none": clearhausFormState !== "first" })}>
                <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                    <FloatingLabelInput
                        placeholder={t("onboarding.companyname")}
                        name="company.name"
                        type="text"
                        defaultValue={availableCompanyInfo.defaultValues.name}
                        innerRef={control.register(valFuncRequired(t("onboarding.errmsg.companyname")))}
                    ></FloatingLabelInput>
                </FieldsetFormGroup>
                <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                    <FloatingLabelInput
                        placeholder={getCompanyNumberPlaceholder(isCompanyDK ? "DK" : "other")}
                        name="company.cvr"
                        type="text"
                        defaultValue={availableCompanyInfo.defaultValues.cvr}
                        innerRef={control.register(valFuncRequiredAndPattern(validationPatterns.cvr, t("onboarding.errmsg.cvr")))}
                    ></FloatingLabelInput>
                </FieldsetFormGroup>
                {isCompanyDK && (
                    <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                        <DropDownList
                            placeholder={t("clearhausonboarding.inputfields.companytype")}
                            name="company.type"
                            options={getClearhausCompanyTypesOptions()}
                            defaultValue={availableCompanyInfo.defaultValues.type}
                            innerRef={control.register(
                                valFuncRequired(t("clearhausonboarding.inputfields.errormsg.companytype"))
                            )}
                        />
                    </FieldsetFormGroup>
                )}
                <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                    <DropDownList
                        defaultValue={availableCompanyInfo.defaultValues.country}
                        placeholder={t("personal.country")}
                        name="company.country"
                        options={getCountriesList()}
                        innerRef={control.register(
                            valFuncRequiredAndPattern(validationPatterns.countryISO2Code, t("personal.validationerrors.country"))
                        )}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                    <FloatingLabelInput
                        name="company.address"
                        placeholder={t("onboarding.address")}
                        defaultValue={availableCompanyInfo.defaultValues.address}
                        innerRef={control.register(
                            valFuncRequiredAndPattern(validationPatterns.addressStreet, t("validation.wrongaddressformat"))
                        )}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                    <Row className="clearhaus-company-fields__row-city-postcode">
                        <div className="col-md-4">
                            <FloatingLabelInput
                                name="company.postCode"
                                placeholder={t("personal.postcode")}
                                defaultValue={availableCompanyInfo.defaultValues.postCode}
                                innerRef={control.register(
                                    valFuncRequiredAndPattern(
                                        validationPatternsWithLocale.addressPostalCode(currentFields?.country || "DK"),
                                        t("personal.validationerrors.postcode")
                                    )
                                )}
                                onChange={async () => {
                                    const inputNamePostcode = "company.postCode"
                                    const inputNameCity = "company.city"
                                    const postcode = control.getValues(inputNamePostcode) as string

                                    const city = await dispatch(lookupCityByZipcodeThunk(postcode))
                                    if (city) {
                                        control.setValue(inputNameCity, city, { shouldValidate: true })
                                    }
                                }}
                            />
                        </div>

                        <div className="col-md-8 pl-md-0">
                            <FloatingLabelInput
                                name="company.city"
                                placeholder={t("personal.city")}
                                defaultValue={availableCompanyInfo.defaultValues.city}
                                innerRef={control.register(
                                    valFuncRequiredAndPattern(validationPatterns.addressCity, t("personal.validationerrors.city"))
                                )}
                            />
                        </div>
                    </Row>
                </FieldsetFormGroup>
                <FieldsetFormGroup field hidden={firstPageFieldsHidden}>
                    <PhoneNumberInput
                        inputName="company.phone"
                        onValidPhoneTyped={() => control.trigger("company.phone")}
                        customErrorMsg={t("clearhausonboarding.inputfields.errormsg.companyphone")}
                        defaultValue={availableCompanyInfo.defaultValues.phone}
                        preferredCountry={(merchantDetails?.swMerchant.countryCode || "").toLocaleLowerCase()}
                        register={control.register}
                        setValue={control.setValue}
                        getValues={control.getValues}
                        watch={control.watchInternal}
                        required={true}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup className="mb-0" field hidden={firstPageFieldsHidden}>
                    <FloatingLabelInput
                        placeholder={t("clearhausonboarding.inputfields.companyemail")}
                        name="company.email"
                        type="email"
                        defaultValue={availableCompanyInfo.defaultValues.email}
                        innerRef={control.register(
                            valFuncPattern(validationPatterns.email, t("personal.validationerrors.email"))
                        )}
                    ></FloatingLabelInput>
                </FieldsetFormGroup>
            </div>
        </div>
    )
}

interface ICompletedCompanyFieldsReviewProps {
    control: Control<IClearhausApplicationForm>
    clearhausFormState: TClearhausFormState
    applicationData?: IClearhausApplicationData
    viewMode?: boolean
}
const CompletedCompanyFieldsReview = ({
    control,
    clearhausFormState,
    applicationData,
    viewMode,
}: ICompletedCompanyFieldsReviewProps) => {
    const { t } = useTranslation()
    const savedFields = applicationData?.applicationFields?.company
    const currentFields = (!viewMode && control.getValues().company) || undefined
    const isCompanyDK = (currentFields?.country || savedFields?.country) === "DK"

    const FieldReviewRow = (props: { name?: string; value?: string }) => {
        if (!props.name && !props.value) {
            return null
        }

        return (
            <>
                {props.name && <span>{props.name}: </span>}
                {props.value}
                <br />
            </>
        )
    }

    return (
        <CompletedFieldsReview>
            <FieldReviewRow value={currentFields?.name || savedFields?.name} />
            <FieldReviewRow
                name={getCompanyNumberPlaceholder(isCompanyDK ? "DK" : "other")}
                value={currentFields?.cvr || savedFields?.cvr}
            />
            <FieldReviewRow
                value={
                    findClearhausCompanyTypeOption((o) => o.value === currentFields?.type)?.text ||
                    findClearhausCompanyTypeOption((o) => o.value === savedFields?.type)?.text
                }
            />
            <br />
            <div>
                <FieldReviewRow value={currentFields?.address || savedFields?.address} />
                <FieldReviewRow
                    value={`${currentFields?.postCode || savedFields?.postCode}, ${currentFields?.city || savedFields?.city}`}
                />
                <FieldReviewRow value={getCountryByISO(currentFields?.country || savedFields?.country || "")} />
                <br />
            </div>
            <FieldReviewRow value={currentFields?.email || savedFields?.email} />
            <FieldReviewRow value={currentFields?.phone || savedFields?.phone} />
            {clearhausFormState === "submitted" && (
                <>
                    <br />
                    <FieldReviewRow
                        value={
                            findClearhausOwnershipTypeOption((o) => o.value === currentFields?.ownershipType)?.text ||
                            findClearhausOwnershipTypeOption((o) => o.value === savedFields?.ownershipType)?.text
                        }
                    />
                </>
            )}
        </CompletedFieldsReview>
    )
}

export default ClearhausCompanyFields
