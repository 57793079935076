import cn from "classnames"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    TextButton,
    getLanguage,
    openUrl,
    useSelector,
} from "swiipe.portal.shared"
import SubmitButton from "../../../components/buttons/SubmitButton"
import LegalTextLinkPopup from "../../../components/legal/LegalTextLinkPopup"
import CancelService from "../../../components/merchant/CancelService"
import GoBackContainer from "../../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../../components/servicesonboarding/SharedCancelContainer"
import AnywhereMobileLogo from "../../../images/swFeatureServices/anywhere-mobile-logo.svg"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { anywhereMobileOnboardingThunk } from "../../../store/thunks/anywhereMobileThunks"
import { sendServiceCancellationEmailThunk } from "../../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import "./AnywhereMobileOnboardingPage.scss"

interface IAnywhereMobileOnboardingPageProps {
    isEdit?: boolean
    webshopIds: string[]
    onGoBack: () => void
    onOnboarded: () => void
}

interface IAnywhereMobileOnboardingPageInnerProps extends IAnywhereMobileOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

export const AnywhereMobileOnboardingPage = (props: IAnywhereMobileOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return (
        <AnywhereMobileOnboardingPageInner merchantDetails={merchantDetails} selectedWebshopId={selectedWebshopId} {...props} />
    )
}

interface IForm {
    termsAccepted: boolean
}

const AnywhereMobileOnboardingPageInner = ({
    merchantDetails,
    selectedWebshopId,
    isEdit,
    webshopIds,
    onGoBack,
    onOnboarded,
}: IAnywhereMobileOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const serviceName = t("serviceNames.anywhereMobile")

    const [showCancelService, setShowCancelService] = useState(false)

    const { handleSubmit, formState } = useForm<IForm>()

    const onSubmit: SubmitHandler<IForm> = async () => {
        try {
            await dispatch(anywhereMobileOnboardingThunk(merchantDetails.swMerchant.swMerchantId, webshopIds, true))
            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={serviceName}
                    cancelText={t("cancelService.featureCanceledCommon.cancelText", { serviceName })}
                    customCancelledText={t("cancelService.featureCanceledCommon.cancelledText", {
                        serviceName,
                    })}
                    onCancel={async () => {
                        await dispatch(
                            anywhereMobileOnboardingThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], false)
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationInstantEmail",
                                serviceName
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper className={cn(showCancelService && "d-none")}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit} disableDefaultPaddingBottom>
                    <div className="anywhere-mobile-onboarding">
                        <div className="anywhere-mobile-onboarding__logo">
                            <img src={AnywhereMobileLogo} />
                        </div>

                        <div className="anywhere-mobile-onboarding__legend my-5">
                            <span className="d-inline-block mb-3">{t("anywhereMobileOnboarding.legend")}</span>
                            <span className="d-inline-block mb-3">{t("anywhereMobileOnboarding.legend2")}</span>
                            <TextButton
                                text={t("anywhereMobileOnboarding.seeOurGuide")}
                                onClick={async () => {
                                    const guideUrl = `https://swiipe-support.zendesk.com/hc/da/articles/${
                                        getLanguage() === "da"
                                            ? "16487043437201-S%C3%A5dan-s%C3%A6tter-du-Anywhere-Mobile-op"
                                            : "16486983746833-How-to-set-up-Anywhere-Mobile-"
                                    }`
                                    openUrl(guideUrl, { inNewTab: true })
                                }}
                                textDecoration="underline"
                                display="inline-block"
                            />
                        </div>

                        {isEdit && (
                            <LegalTextLinkPopup
                                legalTextBeforeLink={t("anywhereMobileOnboarding.legalLink.text")}
                                linkText={t("anywhereMobileOnboarding.legalLink.linkText")}
                                termsType={"TermsAnywhereMobile"}
                                className="mb-5"
                                centered
                            />
                        )}

                        {!isEdit && (
                            <Form onSubmit={handleSubmit(onSubmit)} className="anywhere-mobile-onboarding__form">
                                <ShowErrorMessages formState={formState} />
                                <SubmitButton
                                    dark
                                    noBorder
                                    containerClass="anywhere-mobile-onboarding__submit-btn"
                                    formState={formState}
                                >
                                    {t("commononboardingtexts.submitTheService")}
                                </SubmitButton>
                                <TextButton
                                    text={t("commononboardingtexts.back")}
                                    className="mt-4 mb-4"
                                    onClick={async () => onGoBack()}
                                />
                            </Form>
                        )}
                    </div>
                </PageContainer>
                {isEdit && (
                    <SharedCancelContainer
                        cancelText={t("anywhereMobileOnboarding.cancel")}
                        onCancel={() => setShowCancelService(true)}
                    />
                )}
            </PageWrapper>
        </>
    )
}
