import { unique } from "swiipe.portal.shared"

declare let window: {
    swQueues: IQueues
    swAggregationQueues: IAggregationQueues
}

interface IQueues {
    [queueKey: string]: IQueue<any>[]
}
interface IQueue<ReturnValue> {
    func: () => Promise<ReturnValue>
    finshedListener: (res: ReturnValue) => void
}

window.swQueues = {}

export async function runInQueueExecuteFirstOnly<ReturnValue>(
    queueKey: string,
    func: () => Promise<ReturnValue>
): Promise<{ result: ReturnValue; wasExecuted: boolean }> {
    const queue = (window.swQueues[queueKey] ?? []) as IQueue<ReturnValue>[]
    window.swQueues[queueKey] = queue

    if (queue.length === 0) {
        queue.push({ func: func, finshedListener: (res: ReturnValue) => {} })
        const res = await func()
        window.swQueues[queueKey] = []
        queue.forEach((entry) => {
            entry.finshedListener(res)
        })
        return { result: res, wasExecuted: true }
    }

    return new Promise((resolve) => {
        queue.push({
            func: func,
            finshedListener: (res: ReturnValue) => {
                resolve({ result: res, wasExecuted: false })
            },
        })
    })
}

interface IAggregationQueues {
    [queueName: string]: IAggregationQueueEntries<any, any>
}

window.swAggregationQueues = {}

interface IAggregationQueueEntries<T, R> {
    entries: {
        handler: (list: T[]) => Promise<R>
        resolve: (res: R) => void
        reject: (error: any) => void
        items: T[]
    }[]
    aggregatedItems: T[]
    timer?: NodeJS.Timeout
}

export async function executeInAggregationQueue<T, R>(
    queueKey: string,
    items: T[],
    handler: (list: T[]) => Promise<R>,
    timeout = 50
): Promise<R> {
    return new Promise((resolve, reject) => {
        if (!window.swAggregationQueues[queueKey]) {
            window.swAggregationQueues[queueKey] = {
                entries: [],
                aggregatedItems: [],
            }
        }

        const queue = window.swAggregationQueues[queueKey]

        queue.entries.push({
            handler,
            resolve,
            reject,
            items,
        })
        queue.aggregatedItems = unique([...queue.aggregatedItems, ...items])

        const clearQueue = () => {
            window.swAggregationQueues[queueKey].entries = []
            window.swAggregationQueues[queueKey].aggregatedItems = []
            window.swAggregationQueues[queueKey].timer = undefined
        }

        if (queue.timer) {
            clearTimeout(queue.timer)
            queue.timer = undefined
        }
        queue.timer = setTimeout(() => {
            const aggregatedItems = window.swAggregationQueues[queueKey].aggregatedItems
            const entries = window.swAggregationQueues[queueKey].entries
            clearQueue()
            entries[0]
                .handler(aggregatedItems)
                .then((res: R) => {
                    entries.forEach((entry) => {
                        entry.resolve(res)
                    })
                })
                .catch((error) => {
                    entries.forEach((entry) => {
                        entry.reject(error)
                    })
                })
        }, timeout)
    })
}
