import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, FormGroup, Row } from "reactstrap"
import {
    CompanyNameAndCVRInput,
    CountryInputComp,
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    FormGroupWithCheckbox,
    ICompanyNameAndCVRInputModel,
    PhoneNumberInput,
    ShowErrorMessages,
    TCountryCode,
    companyNumberLookupSupportedCountries,
    companySearchSelectors,
    euAndEaaCountryFilter,
    getAvailablePlatforms,
    getCompanyNumberRaw,
    getDomainValidationPattern,
    mapCountryToMarket,
    sharedConfigurationSelectors,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getPreferredLanguage } from "../../../services/languageService"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MerchantSignupFormModel } from "../../../type/merchantSignupFormModel"
import GeneralTermsAndPrivacyLinks from "../../legal/LegalTextWithLinks"
import UnorderedListSection from "../../sections/UnorderedListSection"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import { merchantSignupThunk } from "./../../../store/thunks/merchantThunks"
import LinkButton from "./../../buttons/LinkButton"
import SubmitButton from "./../../buttons/SubmitButton"
import "./MerchantSignupForm.scss"

interface IMerchantSignupFormProps {
    onSignedUp: (email: string) => void
}

const MerchantSignupForm = ({ onSignedUp }: IMerchantSignupFormProps) => {
    const { handleSubmit, register, errors, formState, getValues, setValue, setError, clearErrors, watch } = useForm<
        MerchantSignupFormModel & ICompanyNameAndCVRInputModel
    >()
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const selectedCompany = useSelector(companySearchSelectors.selected)
    const [selectedCountry, setSelectedCountry] = useState("DK")

    const onSubmit: SubmitHandler<MerchantSignupFormModel> = async (data) => {
        try {
            if (selectedCompany) {
                data.companyName = selectedCompany.companyName
                data.companyNumber = selectedCompany.companyNumber
            } else {
                // If manual company entry get raw company number
                data.companyNumber = getCompanyNumberRaw(data.companyNumber, data.country as TCountryCode)
            }

            data.language = getPreferredLanguage()

            await dispatch(merchantSignupThunk(data))
            onSignedUp(data.email)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    useEffect(() => {
        const country = getValues()["country"]

        if (!country) {
            return
        }

        setSelectedCountry(country)
    }, [watch("country")])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {env === "Sandbox" ? (
                <></>
            ) : (
                <FieldsetFormGroup>
                    <UnorderedListSection
                        listTitle={`${t("signup.merchantFormHeader.subtitle")}:`}
                        listItems={[
                            t("signup.merchantFormHeader.list.1"),
                            t("signup.merchantFormHeader.list.2"),
                            t("signup.merchantFormHeader.list.3"),
                        ]}
                    />
                </FieldsetFormGroup>
            )}
            <Row>
                <FieldsetFormGroup field col2>
                    <EmailInputComp inputName="email" register={register} />
                </FieldsetFormGroup>
                <FieldsetFormGroup field col2 col2pl0>
                    <PhoneNumberInput<MerchantSignupFormModel>
                        inputName="phone"
                        countryFilter={euAndEaaCountryFilter}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                    />
                </FieldsetFormGroup>
            </Row>
            <Row>
                <FieldsetFormGroup field col2>
                    <FloatingLabelInput
                        name="firstName"
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.firstName, t("common.firstName.errorMessage"))
                        )}
                        placeholder={t("common.firstName.label")}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field col2 col2pl0>
                    <FloatingLabelInput
                        name="lastName"
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.lastName, t("common.lastName.errorMessage"))
                        )}
                        placeholder={t("common.lastName.label")}
                    />
                </FieldsetFormGroup>
            </Row>
            <FieldsetFormGroup field>
                <CountryInputComp
                    defaultValue={"DK"}
                    readOnly={!!selectedCompany}
                    inputName="country"
                    register={register}
                    countryFilter={companyNumberLookupSupportedCountries}
                />
            </FieldsetFormGroup>
            <CompanyNameAndCVRInput
                register={register}
                getValues={getValues}
                setValue={setValue}
                clearError={clearErrors}
                setError={setError}
                watch={watch}
                errors={errors}
            />
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="hostName"
                    innerRef={register(
                        valFuncRequiredAndPattern(getDomainValidationPattern(env), t("common.hostname.errorMessage"))
                    )}
                    placeholder={t("common.hostname.label")}
                    bottomHint={t(
                        env === "Production" ? "common.hostname.domainCheckHintProd" : "common.hostname.domainCheckHintNonProd"
                    )}
                    disableHintAfter={5}
                    autoCompleteOff
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <DropDownList
                    innerRef={register({
                        required: true,
                    })}
                    name="platform"
                    options={getAvailablePlatforms()}
                />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            <FormGroupWithCheckbox
                innerRef={register}
                name="acceptTerms"
                required
                validationError={t("merchantsignup.errmsg.termsandconditions")}
            >
                <GeneralTermsAndPrivacyLinks
                    inForm
                    userRelationType="Merchant"
                    fallbackMarket={mapCountryToMarket(selectedCountry)}
                />
            </FormGroupWithCheckbox>
            <FieldsetFormGroup className="px-sm-5 mt-4">
                <SubmitButton formState={formState} id="merchant-signup-btn" isSignUpMerchant />
            </FieldsetFormGroup>
            {env === "Sandbox" ? (
                <LinkButton textOnly title={t("signup.login")} isSandbox href="/login" />
            ) : (
                <>
                    <FormGroup className={cn("mt-5 px-sm-5 text-center")}>
                        <p className="mb-1">{t("signup.alreadyhaveaccount")}</p>
                        <LinkButton href="/login" title={t("signup.login")} />
                    </FormGroup>
                    <FieldsetFormGroup>
                        <LinkButton textOnly href="/signup" title={t("merchantsignup.createpersonal")} />
                    </FieldsetFormGroup>
                </>
            )}
        </Form>
    )
}

export default MerchantSignupForm
