import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    PhoneNumberInput,
    getCompanyNumberPlaceholder,
    organizationSelectors,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import EditFormInput from "../../components/form/input/EditFormInput"
import InfoBoxContainer from "../../components/infobox/InfoBoxContainer"
import InfoBoxEditor from "../../components/infobox/InfoBoxEditor"
import { InfoBoxState } from "../../components/infobox/InfoBoxState"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { updateCompanyDetailsPartnerThunk, updatePartnerContactDataThunk } from "../../store/thunks/partnerThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IPartnerContactDataForm } from "../../type/IPartnerContactDataForm"
import { TUserRole } from "../../type/TUserRole"
import "./PartnerSettingsPage.scss"

interface IPartnerSettingsPageProps {}

const PartnerSettingsPage = ({}: IPartnerSettingsPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [inEditMode, setInEditMode] = useState("")
    useRequiredUserRelations([{ requiredType: "Partner", requiredRole: TUserRole.Read }])
    const partnerDetails = useSelector(partnerSelectors.partnerDetails)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)

    const company = useSelector((state: StoreState) => organizationSelectors.companyData(state, partnerDetails?.cvr || ""))

    if (!partnerDetails || !currentUserRelation) {
        return null
    }

    const isPartnerDK = partnerDetails.countryCode === "DK"

    const onSwitchEditMode = (switchType: string) => {
        return setInEditMode(inEditMode === switchType ? "" : switchType)
    }

    return (
        <PageWrapper>
            <PageContainer classNameBody="partnersettings" transparent title={t("settings.titlenodash")}>
                <InfoBoxContainer
                    isInEditMode={inEditMode === "companyInfo"}
                    iconClass="icon-company"
                    title={t("partnersettings.companyinfo")}
                >
                    <InfoBoxEditor
                        state={hasMasterPartner ? InfoBoxState.DEFAULT : InfoBoxState.NOEDIT}
                        title={t("partnersettings.companyinfo")}
                        iconClass="icon-email"
                        isInEditMode={inEditMode === "companyInfo"}
                        editBtnTextType="editing"
                        renderPreview={() => (
                            <div>
                                {partnerDetails?.name ?? company?.companyName}
                                <br />
                                {getCompanyNumberPlaceholder(isPartnerDK ? "DK" : "other")}: {partnerDetails?.cvr}
                            </div>
                        )}
                        onSwitchEditMode={() => onSwitchEditMode("companyInfo")}
                        renderFormFields={(register) => (
                            <div className="edit-form">
                                <Row>
                                    <div className="col-12">
                                        <EditFormInput
                                            defaultValue={partnerDetails?.name ?? company?.companyName}
                                            name="name"
                                            innerRef={register(
                                                valFuncRequiredAndPattern(
                                                    validationPatterns.companyName,
                                                    t("personal.validationerrors.companyname")
                                                )
                                            )}
                                            placeholder={t("merchantsignup.companyname")}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-12">
                                        <EditFormInput
                                            defaultValue={partnerDetails.cvr}
                                            name="cvr"
                                            innerRef={register(
                                                valFuncRequiredAndPattern(
                                                    validationPatterns.cvr,
                                                    t("personal.validationerrors.cvr")
                                                )
                                            )}
                                            placeholder={t("merchantsignup.cvrnumber")}
                                        />
                                    </div>
                                </Row>
                            </div>
                        )}
                        onSave={async (model) => {
                            await dispatch(updateCompanyDetailsPartnerThunk(currentUserRelation.id, model.name, model.cvr))
                        }}
                    />
                </InfoBoxContainer>
                <InfoBoxContainer
                    isInEditMode={inEditMode === "contactPerson"}
                    iconClass="icon-contact"
                    title={t("partnersettings.contactperson")}
                >
                    <InfoBoxEditor<IPartnerContactDataForm>
                        state={currentUserRelation.userRole >= TUserRole.Admin ? InfoBoxState.DEFAULT : InfoBoxState.NOEDIT}
                        title={t("partnersettings.contactperson")}
                        iconClass="icon-contact"
                        isInEditMode={inEditMode === "contactPerson"}
                        editBtnTextType={
                            partnerDetails.contactFirstName ||
                            partnerDetails.contactLastName ||
                            partnerDetails.contactPhone ||
                            partnerDetails.contactEmail
                                ? "editing"
                                : "adding"
                        }
                        onSwitchEditMode={() => onSwitchEditMode("contactPerson")}
                        renderPreview={() =>
                            partnerDetails.contactFirstName ||
                            partnerDetails.contactLastName ||
                            partnerDetails.contactPhone ||
                            partnerDetails.contactEmail ? (
                                <Row>
                                    <div className="inner-info-container col-md-7 col-sm-12 align-self-center">
                                        <div>{partnerDetails.contactFullName}</div>
                                        <div className="info-light">{partnerDetails.contactEmail}</div>
                                        <div className="info-light">{partnerDetails.contactPhone}</div>
                                    </div>
                                </Row>
                            ) : (
                                <div>
                                    <strong>{t("personal.addContactPerson")}</strong>
                                </div>
                            )
                        }
                        renderFormFields={(register, setValue, getValues, watch) => (
                            <div className="edit-form">
                                <Row>
                                    <div className="col-12 pl-3 col-md-6">
                                        <EditFormInput
                                            defaultValue={partnerDetails.contactFirstName ? partnerDetails.contactFirstName : ""}
                                            name="firstName"
                                            innerRef={register(
                                                valFuncRequiredAndPattern(
                                                    validationPatterns.firstName,
                                                    t("personal.validationerrors.firstName")
                                                )
                                            )}
                                            placeholder={t("personal.firstName")}
                                        />
                                    </div>
                                    <div className="col-12 pl-3 col-md-6 pl-md-0">
                                        <EditFormInput
                                            defaultValue={partnerDetails.contactLastName ? partnerDetails.contactLastName : ""}
                                            name="lastName"
                                            innerRef={register(
                                                valFuncRequiredAndPattern(
                                                    validationPatterns.lastName,
                                                    t("personal.validationerrors.lastName")
                                                )
                                            )}
                                            placeholder={t("personal.lastName")}
                                        />
                                    </div>
                                </Row>
                                <EditFormInput
                                    defaultValue={partnerDetails.contactEmail ? partnerDetails.contactEmail : ""}
                                    name="email"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(validationPatterns.email, t("personal.validationerrors.email"))
                                    )}
                                    placeholder={t("common.email.label")}
                                />
                                <PhoneNumberInput<IPartnerContactDataForm>
                                    inputName="phone"
                                    defaultValue={partnerDetails.contactPhone}
                                    register={register}
                                    setValue={setValue}
                                    getValues={getValues}
                                    watch={watch}
                                />
                            </div>
                        )}
                        onSave={async (model) => {
                            await dispatch(updatePartnerContactDataThunk(model, currentUserRelation.id))
                        }}
                    />
                </InfoBoxContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default PartnerSettingsPage
