import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getPagesCount, getRecordsForPageNumber, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { PaymentLinkDto } from "../../type/PaymentLink"
import { StoreState } from "../StoreState"
import { paymentLinksReducerActions, paymentLinksSelectors } from "../reducers/paymentLinkReducer"

type TPaymentLinkFromSource = "Webshop" | "Portal"

interface ILatestPaymentLinkDataResponse {
    platformOrderId?: string
    language?: string
    currency?: string
}

export const getLatestPaymentDataThunk =
    (
        swMerchantId: string,
        webshopId: string,
        fromSource: TPaymentLinkFromSource
    ): ThunkAction<Promise<ILatestPaymentLinkDataResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const latestPaymentLinkPlatformOrderId = await dispatch(
            requestThunk<ILatestPaymentLinkDataResponse>(endpoints.Payments.getLatestPaymentLinkData, {
                params: {
                    swMerchantId,
                    webshopId,
                    fromSource,
                },
            })
        )

        return latestPaymentLinkPlatformOrderId
    }

export interface ICreatePaymentLinkRequest {
    swMerchantId: string
    webshopId: string
    customerLanguage: string
    customerEmail: string
    customerFirstName: string
    paymentLinkOrderId: string
    amountInMinors: number
    currency: string
    enableAutoReceipt: boolean

    allowOrderOverride: boolean
}

export interface ICreatePaymentLinkResponse {
    success: boolean
    canBeOverridden: boolean
    paymentLinkDetails: {
        paymentLink: string
        orderId: string
        platformOrderId: string
        created: string
    }
}

export const createPaymentLinkAsMerchantThunk =
    (data: ICreatePaymentLinkRequest): ThunkAction<Promise<ICreatePaymentLinkResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<ICreatePaymentLinkResponse>(endpoints.Payments.createPaymentLinkAsMerchant, {
                data,
            })
        )
    }

interface ISendPaymentLinkNotificationRequest {
    swMerchantId: string
    webshopId: string
    orderId: string
    notificationType: "email" | "sms"
    language: string
    customerEmail: string
    customerFirstName: string
    customerPhone: string
}

export const sendPaymentLinkNotificationThunk =
    (request: ISendPaymentLinkNotificationRequest): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Payments.sendPaymentLinkNotification, {
                data: request,
            })
        )
    }

export const getPaymentLinksPerWebshopThunk =
    (webshopId: string, pageNumber: number, perPage: number, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentPaymentLinks = paymentLinksSelectors.getPaymentLinksPerMerchant(getState(), webshopId)
        const currentPagePaymentLinks = getRecordsForPageNumber(pageNumber, perPage, currentPaymentLinks)

        const continuationToken = paymentLinksSelectors.getContinuationTokenPerMerchant(getState(), webshopId)
        const pagesCount = getPagesCount(perPage, currentPaymentLinks)

        const fetchNewPaymentLinks = !!continuationToken && pagesCount === pageNumber

        if (currentPagePaymentLinks && !force && !fetchNewPaymentLinks) {
            return
        }

        const filters = paymentLinksSelectors.getPaymentLinksFilter(getState())

        const sorting = {
            sortByProperty: filters.sortByProperty,
            isOrderByDescending: typeof filters.isOrderByDescending === "undefined" ? true : filters.isOrderByDescending,
        }

        const data = {
            webshopId,
            perPage,
            // in case we want to fetch orders until certain page
            fetchPagesCount: pageNumber == 1 || pagesCount === pageNumber ? null : pageNumber,
            sorting: sorting,
            continuationToken: fetchNewPaymentLinks ? continuationToken : null,
        }

        const paymentLinks = await dispatch(
            requestThunk<{
                paymentLinks: PaymentLinkDto[]
                pagesCount: number
                continuationToken: string
            }>(
                endpoints.Payments.getPaymentLinksPerWebshop,
                {
                    data: data,
                },
                undefined
            )
        )

        dispatch(
            paymentLinksReducerActions.setPaymentLinks(
                webshopId,
                paymentLinks.paymentLinks,
                !fetchNewPaymentLinks,
                paymentLinks.continuationToken
            )
        )
    }
