import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    requestThunk,
    setDefaultFallbackErrorhandler,
    sharedConfigurationReducerActions,
    sharedConfigurationSelectors,
} from "swiipe.portal.shared"
import { openContactUsWidget } from "../../services/contactUsService"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { IUserRelationFlattened } from "../../type/IUserRelation"
import { StoreState } from "../StoreState"
import { configurationActions } from "../reducers/configurationSlice"
import { userRelationReducerActions } from "../reducers/userRelationReducer"
import { endpoints } from "./../../data/endpoints"
import { authService } from "./../../services/authService"
import { ServerConfiguration } from "./../../type/serverconfiguration"
import { getCountryRegionAssets } from "./countryRegionThunks"
import { checkCookieThunk } from "./loginThunks"
import { fetchMerchantDataThunk } from "./merchantThunks"
import { ensurePartnerDataThunk } from "./partnerThunks"
import { ensureLegalMessageShownThunk } from "./termsThunks"
import { fetchUserDataThunk } from "./userPersonalInfoThunks"
import {
    addLastUsedRelationThunk,
    ensureSwitchUserRelationsFromUrl,
    ensureUserRelationsThunk,
    fetchRelationSearchData,
} from "./userRelationThunks"

export const initializeThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
    setDefaultFallbackErrorhandler(async (err) => {
        const result = await dispatch(addModalThunk({ type: "contactUs", errorMessage: err.errorToAlert }))
        if (result.type === "accepted") {
            openContactUsWidget()
        }
    })

    const serverConfiguration = await dispatch(requestThunk<ServerConfiguration>(endpoints.Self.getConfiguration))

    dispatch(sharedConfigurationReducerActions.setServerConfiguration(serverConfiguration))
    dispatch(configurationActions.setSwiipeInvoicingPaymentIQMerchantID(serverConfiguration.swiipeInvoicingPaymentIQMerchantID))

    const authEndpoint = sharedConfigurationSelectors.endpointConfig(getState(), "Auth")

    authService.setup(serverConfiguration, authEndpoint)

    dispatch(getCountryRegionAssets())

    const authorized = await dispatch(checkCookieThunk())
    authService.setIsLoggedIn(authorized)
    if (authorized) {
        authService.setAuthChecked(false)
        const userDataPromise = dispatch(fetchUserDataThunk(false))
        const userRelationsPromise = dispatch(ensureUserRelationsThunk())
        await Promise.all([userDataPromise, userRelationsPromise])

        const didSwitchUser = await dispatch(ensureSwitchUserRelationsFromUrl())
        dispatch(sharedConfigurationReducerActions.setInitialized(true))
        if (!didSwitchUser) {
            dispatch(onUserSwitchThunk())
        }

        dispatch(fetchRelationSearchData(false))
    } else {
        dispatch(sharedConfigurationReducerActions.setInitialized(true))
    }
}

export const signOutThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const answer = await dispatch(addModalThunk({ type: "logOut" }))
    if (answer.type !== "accepted") {
        return
    }
    const authEndpoint = sharedConfigurationSelectors.endpointConfig(getState(), "Auth")
    const serverConfiguration = sharedConfigurationSelectors.serverConfiguration(getState())
    window.location.replace(authEndpoint + "/Account/Logout" + "?=" + serverConfiguration.clientUrl + "/login")
}

export const switchUserThunk =
    (userRelation: IUserRelationFlattened): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(userRelationReducerActions.setCurrentRelation(userRelation))
        dispatch(addLastUsedRelationThunk(userRelation))
        dispatch(onUserSwitchThunk())
    }

const onUserSwitchThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch) => {
    dispatch(ensureLegalMessageShownThunk())
    dispatch(fetchMerchantDataThunk(false))
    dispatch(ensurePartnerDataThunk(false))
}
