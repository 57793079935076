import { MixedMerchantDetails } from "../type/mixedmerchantdetails"

export function removeSchemeFromHostName(hostName: string) {
    if (!hostName) {
        return ""
    }
    return hostName.replace(/(^\w+:|^)\/\//, "")
}

export const getMerchantWillBeDeletedDate = (isExpedite: boolean) => {
    const now = new Date()

    return new Date(now.getFullYear(), now.getMonth(), 1).setMonth(now.getMonth() + (isExpedite ? 4 : 5))
}

export function getMerchantAllServicesStopped(merchantDetails: MixedMerchantDetails | undefined) {
    if (!merchantDetails?.deletionStatus?.allServicesStoppedDate) {
        return undefined
    }
    return new Date(merchantDetails.deletionStatus.allServicesStoppedDate).getTime() < new Date().getTime()
}

export const getMerchantWillBeDeactivatedDate = (isExpedite: boolean) => {
    const now = new Date()

    return new Date(now.getFullYear(), now.getMonth(), 1).setMonth(now.getMonth() + (isExpedite ? 1 : 2))
}

export function getMerchantContact(merchantDetails: MixedMerchantDetails | undefined) {
    if (!merchantDetails) {
        return
    }

    return merchantDetails?.swMerchantContacts?.length ? merchantDetails.swMerchantContacts[0] : undefined
}

export function getWebshopHostName(merchantDetails: MixedMerchantDetails | undefined, webshopId: string, withScheme?: boolean) {
    if (!merchantDetails) {
        return ""
    }

    const hostName = merchantDetails.webshops.find((w) => w.webshopId === webshopId)?.hostName ?? ""

    return withScheme ? hostName : removeSchemeFromHostName(hostName)
}
