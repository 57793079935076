import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    SpinnerContainer,
    useSelector,
    valFuncRequired,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { billingSelectors } from "../../../store/reducers/billingReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../../store/reducers/userRelationReducer"
import { fetchInvoicingOrganizationThunk, updateInvoicingEanThunk } from "../../../store/thunks/billingThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IInvoicingOrganizationEanForm } from "../../../type/IInvoicingOrganizationEanForm"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import EditFormInput from "../input/EditFormInput"

interface IMerchantInvoicingEanFormProps extends IEditFormBase {}

const MerchantInvoicingEanForm = ({ isInEditMode, onSwitchEditMode }: IMerchantInvoicingEanFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const invoicingOrganization = useSelector(billingSelectors.invoicingOrganization)
    const userRelation = useSelector(userRelationSelectors.currentUserRelation)

    useEffect(() => {
        dispatch(fetchInvoicingOrganizationThunk(false))
    }, [userRelation])

    const supportsEan = merchantDetails?.swMerchant.countryCode?.toLowerCase() === "dk"
    if (!supportsEan) {
        return null
    }

    if (!invoicingOrganization || !merchantDetails || !merchantDetails.swMerchant) {
        return (
            <SpinnerContainer showSpinner={true}>
                <div style={{ height: "100px" }}></div>
            </SpinnerContainer>
        )
    }

    return (
        <InfoBoxContainer
            isInEditMode={isInEditMode}
            className="mt-0"
            title={t("invoicing.eanSectionTitle")}
            transparentBg
            noIcon
        >
            <InfoBoxEditor<IInvoicingOrganizationEanForm>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-invoice"
                title={t("invoicing.eanSectionTitle")}
                isInEditMode={isInEditMode}
                editBtnTextType={invoicingOrganization.ean || invoicingOrganization.eInvoiceId ? "editing" : "adding"}
                allowDelete={!!invoicingOrganization.ean || !!invoicingOrganization.eInvoiceId}
                renderPreview={() => (
                    <>
                        {invoicingOrganization.ean || invoicingOrganization.eInvoiceId ? (
                            <>
                                <div>{invoicingOrganization.ean ?? ""}</div>
                                <div>{invoicingOrganization.eInvoiceId ?? ""}</div>
                            </>
                        ) : (
                            <div>
                                <div>
                                    <strong>{t("invoicing.addEan")}</strong>
                                </div>
                                <div>
                                    <strong>{t("invoicing.addEInvoiceId")}</strong>
                                </div>
                            </div>
                        )}
                    </>
                )}
                renderFormFields={(register) => (
                    <div className="edit-form">
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    defaultValue={invoicingOrganization.ean ?? ""}
                                    name="ean"
                                    innerRef={register(
                                        valFuncRequiredAndPattern(validationPatterns.ean, t("invoicing.validationerrors.ean"))
                                    )}
                                    showHintOnHover
                                    placeholder={t("invoicing.ean")}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    defaultValue={invoicingOrganization.eInvoiceId}
                                    name="eInvoiceId"
                                    innerRef={register(valFuncRequired(t("invoicing.validationerrors.eInvoiceId")))}
                                    placeholder={t("invoicing.eInvoiceId")}
                                />
                            </div>
                        </Row>
                    </div>
                )}
                onSave={async (model) => {
                    await dispatch(updateInvoicingEanThunk(model.ean, model.eInvoiceId))
                }}
                onDelete={async (model) => {
                    await dispatch(updateInvoicingEanThunk("", ""))
                }}
                onSwitchEditMode={onSwitchEditMode}
            />
        </InfoBoxContainer>
    )
}

export default MerchantInvoicingEanForm
