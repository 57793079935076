import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, TextButton } from "swiipe.portal.shared"
import FormSectionTitleWithHint from "../form/title/FormSetctionTitleWithHint"
import ServicesOnboardingPageWrapper from "../servicesonboarding/ServicesOnboardingPageWrapper"

interface IAlreadyClearhausExistingUserPromptProps {
    handleResponse: (hasClearhaus: boolean) => void
    onGoBack: () => Promise<void>
}

export const AlreadyClearhausExistingUserPrompt = ({ handleResponse, onGoBack }: IAlreadyClearhausExistingUserPromptProps) => {
    const { t } = useTranslation()

    return (
        <ServicesOnboardingPageWrapper>
            <FormSectionTitleWithHint
                title={t("clearhausOnboardingAsExistingUser.alreadyHasClearhausPrompt.doYouAlreadyUseClearhaus")}
                hintText={t("clearhausOnboardingAsExistingUser.alreadyHasClearhausPrompt.hint")}
                clickToExpand
                className="hint-bigger"
            />
            <StandardButton invertedBlue className="mt-5" onClick={() => handleResponse(true)}>
                {t("common.yes")}
            </StandardButton>
            <StandardButton dark noBorder noBorderRadius className="mt-3" onClick={() => handleResponse(false)}>
                {t("common.no")}
            </StandardButton>
            <TextButton className="mt-3 mb-2" text={t("commononboardingtexts.goback")} onClick={onGoBack} />
        </ServicesOnboardingPageWrapper>
    )
}
