import React, { useEffect, useMemo, useState } from "react"
import { Control, FormState } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert, Input, Row } from "reactstrap"
import {
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    TClearError,
    TRegister,
    TSetError,
    TitleWithAbsoluteHint,
    delay,
    getCountriesList,
    lookupCityByZipcodeThunk,
    valFuncRequiredAndPattern,
    validationPatterns,
    validationPatternsWithLocale,
} from "swiipe.portal.shared"
import { getClearhausPersonFormFields } from "../../../../services/clearhausService"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"
import { IClearhausApplicationForm } from "../../../../type/clearhaus/IClearhausApplicationForm"
import { TClearhausPersonType } from "../../../../type/clearhaus/TClearhausPersonType"
import { formatShortDateWithFormat } from "../../../../util/dateUtil"
import { UploadedFilesOverviewText } from "../../../overview/UploadedFilesOverview"
import CprInput from "../../input/CprInput"
import DateInput from "../../input/DateInput"
import FileInput, { megabyteInBytes } from "../../input/FileInput"
import FullNameInputComp from "../../input/FullNameInputComp/FullNameInputComp"
import "./ClearhausPersonFields.scss"

interface IClearhausPersonFieldsProps {
    control: Control<IClearhausApplicationForm>
    register: TRegister
    setError: TSetError<any>
    clearError: TClearError<any>
    formState: FormState<IClearhausApplicationForm>
    showErrors: boolean
    type: TClearhausPersonType
    index?: number
    alreadyUploadedFileNames?: {
        pictureLegitimation: string[]
        addressLegitimation: string[]
    }
    saveJustTriggered: boolean
}

const ClearhausPersonFields = ({
    control,
    register,
    setError,
    clearError,
    formState,
    showErrors,
    type,
    index,
    alreadyUploadedFileNames,
    saveJustTriggered,
}: IClearhausPersonFieldsProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [selectedCountry, setSelectedCountry] = useState("DK")

    const inputsPrefix = `${type}s[${index ?? 0}]`

    const dateOfBirthValueDateFormat = "yyyy-MM-dd"
    const dateOfBirthInputName = `${inputsPrefix}.dateOfBirth`

    const errorNamesToDisplay = useMemo(() => getClearhausPersonFormFields(type, index ?? 0), [type, index])

    const [showFilesWarning, setShowFilesWarning] = useState(false)
    useEffect(() => {
        if (!saveJustTriggered) {
            return
        }
        const values = control.getValues()
        const legitimationFiles = values[`${type}s`]![index ?? 0]["legitimationFiles"]
        const addressFiles = values[`${type}s`]![index ?? 0]["addressFiles"]
        if (!legitimationFiles.length && !addressFiles.length) {
            return
        }
        setShowFilesWarning(true)
        delay(15 * 1000).then(() => setShowFilesWarning(false))
    }, [saveJustTriggered])

    return (
        <div className="clearhaus-person-fields">
            <Input type="text" name={`${inputsPrefix}.id`} innerRef={register} hidden />
            <FieldsetFormGroup field>
                <FullNameInputComp inputName={`${inputsPrefix}.name`} register={register} />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <DropDownList
                    defaultValue="DK"
                    placeholder={t("personal.country")}
                    name={`${inputsPrefix}.country`}
                    options={getCountriesList()}
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.countryISO2Code, t("personal.validationerrors.country"))
                    )}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <CprInput
                    control={control}
                    cprInputName={`${inputsPrefix}.personalNumber`}
                    country={selectedCountry}
                    onDateOfBirthParsedCallback={(parsedDate: Date | undefined) =>
                        control.setValue(
                            dateOfBirthInputName,
                            parsedDate ? formatShortDateWithFormat(parsedDate, dateOfBirthValueDateFormat) : ""
                        )
                    }
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <DateInput
                    required
                    name={dateOfBirthInputName}
                    placeholder={t("clearhausonboarding.inputfields.dateOfBirth")}
                    errorMessage={t("clearhausonboarding.inputfields.errormsg.dateOfBirth")}
                    country={selectedCountry}
                    register={register}
                    setValue={control.setValue}
                    validationTrigger={control.trigger}
                    hiddenForCountries={["DK", "SE", "PL"]}
                    valueDateFormat={dateOfBirthValueDateFormat}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name={`${inputsPrefix}.address`}
                    placeholder={t("onboarding.address")}
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.addressStreet, t("validation.wrongaddressformat"))
                    )}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <Row className="clearhaus-person-fields__row-city-postcode">
                    <div className="col-md-4 mb-3">
                        <FloatingLabelInput
                            name={`${inputsPrefix}.postCode`}
                            placeholder={t("personal.postcode")}
                            innerRef={register(
                                valFuncRequiredAndPattern(
                                    validationPatternsWithLocale.addressPostalCode(
                                        control.getValues(`${inputsPrefix}.country`) || "DK"
                                    ),
                                    t("personal.validationerrors.postcode")
                                )
                            )}
                            onChange={async () => {
                                const inputNamePostcode = `${inputsPrefix}.postCode`
                                const inputNameCity = `${inputsPrefix}.city`
                                const postcode = control.getValues(inputNamePostcode) as string

                                //TODO: refactor so it is one single postCodeInput component and not same code everywhere
                                const city = await dispatch(lookupCityByZipcodeThunk(postcode))
                                if (city) {
                                    control.setValue(inputNameCity, city, { shouldValidate: true })
                                }
                            }}
                        />
                    </div>

                    <div className="col-md-8 pl-md-0">
                        <FloatingLabelInput
                            name={`${inputsPrefix}.city`}
                            placeholder={t("personal.city")}
                            innerRef={register(
                                valFuncRequiredAndPattern(validationPatterns.addressCity, t("personal.validationerrors.city"))
                            )}
                        />
                    </div>
                </Row>
            </FieldsetFormGroup>
            <div className="clearhaus-person-fields__file-upload mt-4 mb-2">
                <div className="clearhaus-person-fields__upload-fields">
                    <span className="clearhaus-person-fields__explanation-above-input">
                        1. {t("clearhausonboarding.fileuploadlabels.photolegitimation")}
                        <span>{t("clearhausonboarding.fileuploadlabels.photolegitimationexample")}</span>
                    </span>
                    <FileInput
                        name={`${inputsPrefix}.legitimationFiles`}
                        accept="image/*"
                        minFilesAmount={alreadyUploadedFileNames?.pictureLegitimation.length ? 0 : 1}
                        maxFilesAmount={1}
                        maxFileSizeBytes={megabyteInBytes * 10}
                        triggerValidation={control.trigger}
                        setError={setError}
                        clearError={clearError}
                        setValue={control.setValue}
                        getValues={control.getValues}
                        register={register}
                        requiredErrorMessage={
                            type === "director"
                                ? t("fileupload.errormessages.director.photoLegitimation")
                                : t("fileupload.errormessages.owner.photoLegitimation")
                        }
                        multiple
                    />
                    <UploadedFilesOverviewText fileNames={alreadyUploadedFileNames?.pictureLegitimation} />
                    <span className="clearhaus-person-fields__explanation-above-input">
                        2. {t("clearhausonboarding.fileuploadlabels.addresslegitimation")}
                        <span>{t("clearhausonboarding.fileuploadlabels.addresslegitimationexample")}</span>
                    </span>
                    <FileInput
                        name={`${inputsPrefix}.addressFiles`}
                        accept="image/*"
                        minFilesAmount={alreadyUploadedFileNames?.addressLegitimation.length ? 0 : 1}
                        maxFilesAmount={1}
                        maxFileSizeBytes={megabyteInBytes * 10}
                        triggerValidation={control.trigger}
                        setError={setError}
                        clearError={clearError}
                        setValue={control.setValue}
                        getValues={control.getValues}
                        register={register}
                        requiredErrorMessage={
                            type === "director"
                                ? t("fileupload.errormessages.director.addressLegitimation")
                                : t("fileupload.errormessages.owner.addressLegitimation")
                        }
                        multiple
                    />
                    <UploadedFilesOverviewText fileNames={alreadyUploadedFileNames?.addressLegitimation} />
                    {showFilesWarning && (
                        <Alert color="warning">{t("clearhausonboarding.warnings.documentsAreNotGettingSaved")}</Alert>
                    )}
                </div>
                <div className="clearhaus-person-fields__upload-hint">
                    <TitleWithAbsoluteHint
                        className="clearhaus-person-fields__hint"
                        titleClassName="clearhaus-person-fields__hint-title"
                        title={t("clearhausonboarding.fileuploadlabels.description")}
                        iconBold
                    />
                </div>
            </div>
            <ShowErrorMessages
                className="clearhaus-person-fields__error-messages"
                isHidden={!showErrors}
                formState={formState}
                onlyForFieldNames={errorNamesToDisplay}
            />
        </div>
    )
}

export default ClearhausPersonFields
