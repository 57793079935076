import cn from "classnames"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    getLanguage,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { setJustConfirmedRelationToLocalStorage } from "../../../services/userRelationService"
import { resetPasswordThunk } from "../../../store/thunks/loginThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { ELoginFlow } from "../../../type/ELoginFlow"
import { TUserRelation } from "../../../type/TUserRelation"
import SubmitButton from "../../buttons/SubmitButton"
import TitleSection from "../../sections/TitleSection"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import { ConfirmAccountFormModel } from "./../../../type/ConfirmAccountFormModel"
import FormPasswordRequirements from "./../FormPasswordRequirements"
import FormSectionTitle from "./../title/FormSectionTitle"
import "./ConfirmAccountForm.scss"

interface IConfirmAccountFormProps {
    email: string
    token: string
    termsTypes?: string
    relationType: TUserRelation
    relatesToId?: string
    relatesToSecondaryId?: string
    onMailSend: (email: string) => void
    flowType: ELoginFlow
    returnUrl?: string
}

const ConfirmAccountForm = ({
    email,
    token,
    termsTypes,
    relationType,
    relatesToId,
    relatesToSecondaryId,
    flowType,
    returnUrl,
}: IConfirmAccountFormProps) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<ConfirmAccountFormModel>({ defaultValues: { email } })
    const dispatch = useReduxDispatch()
    const [showRequirements, setShowRequirements] = useState(false)

    const onSubmit: SubmitHandler<ConfirmAccountFormModel> = async (data, e) => {
        try {
            if (relatesToId) {
                setJustConfirmedRelationToLocalStorage(relatesToId)
            }
            await dispatch(
                resetPasswordThunk(
                    {
                        ...data,
                        email,
                        token,
                        termsTypes,
                        relatesToId,
                        relatesToSecondaryId,
                        relationType,
                        language: getLanguage(),
                    },
                    returnUrl
                )
            )
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={cn("confirm-account-form")}>
            {!flowType && <FormSectionTitle title={t("confirmaccount.title")} />}
            {flowType === "merchantOffer" && (
                <>
                    <TitleSection className="pt-4 pb-4" center isSmaller title={t("confirmaccount.merchantOffer.title")} />
                    <div className="confirm-account-form__description mt-4 mb-4 pb-2">
                        {t("confirmaccount.merchantOffer.description")}
                    </div>
                </>
            )}
            <FieldsetFormGroup field>
                <EmailInputComp inputName="email" register={register} disabled />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="password"
                    onFocus={() => setShowRequirements(true)}
                    onBlur={() =>
                        setTimeout(() => {
                            setShowRequirements(false)
                        }, 100)
                    }
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.password, t("validation.resetpasswordform.wrongpassword"))
                    )}
                    type="password"
                    placeholder={t("signup.password")}
                />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            {showRequirements && <FormPasswordRequirements isBlueBubble />}
            <FieldsetFormGroup className="pt-4">
                <SubmitButton
                    formState={formState}
                    title={
                        flowType === "merchantOffer" ? t("confirmaccount.merchantOffer.createButton") : t("confirmaccount.send")
                    }
                />
            </FieldsetFormGroup>
        </Form>
    )
}

export default ConfirmAccountForm
