import React, { useState } from "react"
import cn from "classnames"
import "./ToggleButtonCheckbox.scss"

interface IToggleButtonCheckbox {
    id: string
    onClick: () => Promise<void> | void
    checked: boolean
    className?: string
}

const ToggleButtonCheckbox = ({ id, onClick, checked, className }: IToggleButtonCheckbox) => {
    const [isSwitching, setIsSwitching] = useState(false)

    return (
        <div className="toggle-btn-checkbox">
            <input
                type="checkbox"
                checked={checked}
                id={id}
                readOnly
                className={cn("switch", className, isSwitching && "switching")}
            />
            <label
                onClick={async () => {
                    setIsSwitching(true)
                    await onClick()
                    setIsSwitching(false)
                }}
                htmlFor={id}
            />
        </div>
    )
}

export default ToggleButtonCheckbox
