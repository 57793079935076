import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getLanguage, requestThunk, userSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { authService } from "../../services/authService"
import { mapUserRelationToLegalRelation } from "../../services/legalService"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { ETermsAcceptSource, ITermsAcceptanceStatusDto } from "../../type/ILegalData"
import { TUserRelation } from "../../type/TUserRelation"
import { TUserRole } from "../../type/TUserRole"
import { StoreState } from "../StoreState"
import { ETermsType, legalReducerActions, legalSelectors } from "../reducers/legalReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export type ETermsContentType = "text/html" | "application/pdf"

export const clearTermsThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    dispatch(legalReducerActions.clearTerms())
}

export const ensureTermsStatusThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())
        if (!force && legalSelectors.termsStatuses(getState())) {
            return
        }

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        const statusResp = await dispatch(
            requestThunk<{ statuses: ITermsAcceptanceStatusDto[] }>(endpoints.Legal.getTermsStatus, {
                params: {
                    relationId: currentUserRelation.id,
                    relationType: mapUserRelationToLegalRelation(currentUserRelation.relationType),
                },
            })
        )
        await dispatch(legalReducerActions.setTermsStatus(currentUserRelation.id, statusResp.statuses))
    }

export const markTermsAsAcceptedThunk =
    (termsAccepted: ETermsType[]): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const termsStatuses = legalSelectors.termsStatuses(getState()) || []
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        const notAcceptedTerms = termsStatuses.filter((d) => !d.isLatestAccepted && termsAccepted.includes(d.termsType))
        if (notAcceptedTerms.length === 0) {
            return
        }

        await dispatch(
            requestThunk(endpoints.Legal.acceptTerms, {
                data: {
                    termsList: notAcceptedTerms.map((t) => {
                        return {
                            termsType: t.termsType,
                            version: t.version,
                            revision: t.revision,
                            language: getLanguage(),
                            secondaryRelationId: undefined,
                        }
                    }),
                    relationId: currentUserRelation.id,
                    relationType: mapUserRelationToLegalRelation(currentUserRelation.relationType),
                    ipAddress: undefined,
                    acceptSourceType: "Portal" satisfies ETermsAcceptSource,
                },
            })
        )
        await dispatch(ensureTermsStatusThunk(true))
    }

const ignoreLegalModalForPaths = ["merchantOffer"]

export const ensureLegalMessageShownThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const firstPath = document.location.pathname.split("/").filter((p) => !!p)?.[0] ?? ""
        if (ignoreLegalModalForPaths.includes(firstPath)) {
            return
        }

        await dispatch(ensureTermsStatusThunk(false))
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!

        if (currentUserRelation.userRole !== TUserRole.Owner) {
            return
        }

        const alreadyShown = legalSelectors.legalMessageShown(getState())
        if (alreadyShown) {
            return
        }

        const termsStatuses = legalSelectors.termsStatuses(getState()) || []
        const missingAcceptances = termsStatuses.filter(
            (s) => s.isConsentRequired && !s.productType && (s.category === "GeneralTerms" || s.category === "PrivacyPolicy")
        )
        if (missingAcceptances.length === 0) {
            return
        }

        const isVersionUpgrade = !!missingAcceptances.find((s) => s.isVersionUpdate || s.isRevisionUpdate)

        dispatch(legalReducerActions.setLegalMessageShown(currentUserRelation.id, true))
        dispatch(
            addModalThunk({
                type: "acceptTerms",
                isVersionUpgrade: isVersionUpgrade,
                termsTypes: missingAcceptances.map((a) => a.termsType),
            })
        )
    }

export const ensureTermsThunk =
    (
        termsType: ETermsType,
        relationType: TUserRelation,
        relationId: string | undefined,
        token?: string,
        market?: string,
        forceLanguage?: string,
        force?: boolean
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && legalSelectors.terms(getState(), termsType, relationType, relationId)) {
            return
        }
        let isLoggedIn = authService.getIsLoggedIn()
        const resolvedRelationId = isLoggedIn || relationType !== "User" ? relationId : undefined
        if (isLoggedIn && resolvedRelationId) {
            if (relationType === "User") {
                const user = userSelectors.userData(getState())
                if (user?.user.id !== resolvedRelationId) {
                    isLoggedIn = false
                }
            } else {
                const userRelations = userRelationSelectors.userRelationsFlattened(getState()) || []
                if (!userRelations.find((ur) => ur.id === resolvedRelationId)) {
                    isLoggedIn = false
                }
            }
        }
        const termsContent = await dispatch(
            requestThunk<string>(isLoggedIn ? endpoints.Legal.getTermsAsLoggedIn : endpoints.Legal.getTerms, {
                params: {
                    termsType,
                    relationType: mapUserRelationToLegalRelation(relationType),
                    relationId: resolvedRelationId,
                    language: forceLanguage ?? getLanguage(),
                    token,
                    market,
                    shouldReturnString: true,
                },
            })
        )
        dispatch(legalReducerActions.setTerms(termsType, relationType, relationId, termsContent))
    }
