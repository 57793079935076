import React from "react"

interface IBoldTextFromatter {
    text: string
}

const BoldTextFromatter = ({ text }: IBoldTextFromatter) => {
    const separator = "**"
    const splittedText = text.split(separator)

    return (
        <>
            {splittedText.map((t, index) => {
                if ((index + 1) % 2 == 0) {
                    return (
                        <span key={index}>
                            <b>{t}</b>
                        </span>
                    )
                }

                return <span key={index}>{t}</span>
            })}
        </>
    )
}

export default BoldTextFromatter
