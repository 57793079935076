import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, Row } from "reactstrap"
import {
    CompanyNameAndCVRInput,
    CountryInputComp,
    FieldsetFormGroup,
    FloatingLabelInput,
    FormGroupWithCheckbox,
    ICompanyNameAndCVRInputModel,
    PhoneNumberInput,
    ShowErrorMessages,
    companySearchSelectors,
    euAndEaaCountryFilter,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getPreferredLanguage } from "../../../services/languageService"
import { partnerSignupThunk } from "../../../store/thunks/partnerThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import GeneralTermsAndPrivacyLinks from "../../legal/LegalTextWithLinks"
import { IPartnerSignupFormModel } from "./../../../type/IPartnerSignupFormModel"
import SubmitButton from "./../../buttons/SubmitButton"
import "./MerchantSignupForm.scss"
import "./PartnerSignupForm.scss"

interface IPartnerSignupFormProps {
    onSignedUp: (email: string) => void
}

const PartnerSignupForm = ({ onSignedUp }: IPartnerSignupFormProps) => {
    const { handleSubmit, register, errors, formState, getValues, setValue, setError, clearErrors, watch } = useForm<
        IPartnerSignupFormModel & ICompanyNameAndCVRInputModel
    >()
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const selectedCompany = useSelector(companySearchSelectors.selected)

    const onSubmit: SubmitHandler<IPartnerSignupFormModel> = async (data) => {
        try {
            if (selectedCompany) {
                data.companyName = selectedCompany.companyName
                data.companyNumber = selectedCompany.companyNumber
            } else {
                // If manual company entry remove country code from vat number
                data.companyNumber = data.companyNumber.replace(data.country, "")
            }

            data.language = getPreferredLanguage()

            await dispatch(partnerSignupThunk(data))
            onSignedUp(data.email)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <FieldsetFormGroup field col2>
                    <FloatingLabelInput
                        name="email"
                        innerRef={register(valFuncRequiredAndPattern(validationPatterns.email, t("onboarding.errmsg.email")))}
                        placeholder={t("onboarding.email")}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field col2 col2pl0>
                    <PhoneNumberInput<IPartnerSignupFormModel>
                        countryFilter={euAndEaaCountryFilter}
                        inputName="phone"
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        watch={watch}
                    />
                </FieldsetFormGroup>
            </Row>
            <Row>
                <FieldsetFormGroup field col2>
                    <FloatingLabelInput
                        name="firstName"
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.firstName, t("onboarding.errmsg.firstName"))
                        )}
                        placeholder={t("onboarding.firstName")}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup field col2 col2pl0>
                    <FloatingLabelInput
                        name="lastName"
                        innerRef={register(
                            valFuncRequiredAndPattern(validationPatterns.lastName, t("onboarding.errmsg.lastName"))
                        )}
                        placeholder={t("onboarding.lastName")}
                    />
                </FieldsetFormGroup>
            </Row>
            <FieldsetFormGroup field>
                <CountryInputComp
                    defaultValue={"DK"}
                    readOnly={!!selectedCompany}
                    inputName="country"
                    register={register}
                    countryFilter={euAndEaaCountryFilter}
                />
            </FieldsetFormGroup>
            <CompanyNameAndCVRInput
                register={register}
                getValues={getValues}
                setValue={setValue}
                clearError={clearErrors}
                setError={setError}
                watch={watch}
                errors={errors}
            />
            <ShowErrorMessages formState={formState} />
            <FormGroupWithCheckbox
                innerRef={register}
                name="acceptTerms"
                required
                validationError={t("merchantsignup.errmsg.termsandconditions")}
            >
                <GeneralTermsAndPrivacyLinks inForm userRelationType="Partner" />
            </FormGroupWithCheckbox>
            <FieldsetFormGroup>
                <SubmitButton formState={formState} id="partner-signup-btn" isSignUpPartner />
            </FieldsetFormGroup>
        </Form>
    )
}

export default PartnerSignupForm
