import React from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import UserSettingsForm from "../../components/form/password/UserSettingsForm"
import MessageBox from "../../components/layouts/MessageBox"
import { useRequiredUserRelations } from "../../services/userRelationService"
import "./SettingsPage.scss"

interface ISettingsPageProps {}

const SettingsPage = ({}: ISettingsPageProps) => {
    useRequiredUserRelations([{ requiredType: "User" }])
    const { t } = useTranslation()

    return (
        <PageWrapper>
            <PageContainer classNameBody="settingspage" transparent title={t("menu.partnerSettings")}>
                <UserSettingsForm />

                <MessageBox titleInBody smallTopBottomPadding title={t("settings.deleteaccount")}>
                    <p>{t("settings.deletemsg")}</p>
                </MessageBox>
            </PageContainer>
        </PageWrapper>
    )
}

export default SettingsPage
