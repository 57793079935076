import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    CompanyDetailsPreviewComp,
    FieldsetFormGroup,
    ICompanyFieldsBase,
    PhoneNumberInput,
    ShowErrorMessages,
    getFullVatNumber,
    getLanguage,
    organizationSelectors,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../../services/userRelationService"
import { StoreState } from "../../../store/StoreState"
import { viabillOnboardingRegistrationThunk } from "../../../store/thunks/viabillThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import SubmitButton from "../../buttons/SubmitButton"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import FullNameInputComp from "../input/FullNameInputComp/FullNameInputComp"

interface IViabillRegistrationFormProps {
    webshopId: string
    merchantDetails: MixedMerchantDetails
    onOnboarded: () => void
}

interface IViabillRegistrationFormModel {
    acceptTerms: boolean

    fullName: string
    email: string
    phone: string
    dialCode: string
}

const ViabillRegistrationForm = ({ merchantDetails, webshopId, onOnboarded }: IViabillRegistrationFormProps) => {
    const company = useSelector((state: StoreState) =>
        organizationSelectors.companyData(state, merchantDetails.swMerchant.registrationNumber)
    )

    if (!company) {
        return null
    }

    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const { t, i18n } = useTranslation()
    const dispatch = useReduxDispatch()

    const { handleSubmit, register, setError, formState, setValue, getValues, watch } = useForm<IViabillRegistrationFormModel>()

    const [phoneData, setPhoneData] = useState<{
        dialCode: string
        number: string
    }>({
        dialCode: "",
        number: "",
    })

    const companyDetails: ICompanyFieldsBase = {
        companyCountry: merchantDetails.swMerchant.countryCode,
        companyName: merchantDetails.swMerchant.name,
        companyAddress: company.companyAddress,
        companyCity: company.companyCity,
        companyPostcode: company.companyPostcode,
        companyNumber: merchantDetails.swMerchant.registrationNumber,
    }

    const onSubmit: SubmitHandler<IViabillRegistrationFormModel> = async (data) => {
        try {
            await dispatch(
                viabillOnboardingRegistrationThunk({
                    address: company.companyAddress,
                    city: company.companyCity,
                    companyName: company.companyName!,
                    contactName: data.fullName,
                    contactPhone: {
                        countryCode: phoneData.dialCode,
                        number: phoneData.number,
                    },
                    country: companyDetails.companyCountry,
                    cvrNumber: getFullVatNumber(companyDetails),
                    email: data.email,
                    locale: getLanguage(),
                    postalCode: company.companyPostcode,
                    swMerchantId: merchantDetails.swMerchant.swMerchantId,
                    webshopId,
                })
            )

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    const userData = useSelector(userSelectors.userData)

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className="viabill-form">
                <div className="viabill-onboarding-form mt-5 panel-body">
                    <FieldsetFormGroup field>
                        <FullNameInputComp inputName="fullName" defaultValue={userData?.user.fullName} register={register} />
                    </FieldsetFormGroup>

                    <FieldsetFormGroup field>
                        <EmailInputComp inputName="email" defaultValue={company.companyEmail} register={register} />
                    </FieldsetFormGroup>

                    <FieldsetFormGroup field>
                        <PhoneNumberInput<IViabillRegistrationFormModel>
                            inputName="phone"
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                            defaultValue={company.companyPhone}
                            onDataChange={(number: string, dialCode: string) => setPhoneData({ number, dialCode })}
                        />
                    </FieldsetFormGroup>

                    <ShowErrorMessages formState={formState} />

                    <CompanyDetailsPreviewComp details={companyDetails} />

                    <SubmitButton className="mt-4" dark noBorder containerClass="submit-btn" formState={formState}>
                        {t("commononboardingtexts.submit")}
                    </SubmitButton>
                </div>
            </Form>
        </>
    )
}

export default ViabillRegistrationForm
