import { getLanguage } from "swiipe.portal.shared"
import { TImage } from "../locale/images/TImage"
import { daImages } from "./../locale/images/imagesDa"
import { enImages } from "./../locale/images/imagesEn"

export function getImage(image: TImage): string {
    const lang = getLanguage()
    switch (lang) {
        case "da":
            return daImages.images[image]
        case "en":
            return enImages.images[image]
        default:
            return daImages.images[image]
    }
}
