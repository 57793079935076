import React from "react"
import MerchantDashboardCircleWithList from "../../tiles/MerchantDashboardTileCircleWithList"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import { useTranslation } from "react-i18next"
import MerchantDashboardValueInText from "../../MerchantDashboardValueInText"
import { getPaymentMethodsData } from "../../../../services/merchantDashboardPaymentMethodsService"

interface IDashboardPaymentDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
}

const DashboardPaymentDisplay = ({ dashboardData, paymentsEnabled }: IDashboardPaymentDisplayProps) => {
    const { t } = useTranslation()
    const paymentMethodsData = getPaymentMethodsData(dashboardData)

    return (
        <MerchantDashboardCircleWithList
            title={t("merchantDashboard.paymentMethodsTitle")}
            subtitle={t("merchantDashboard.viaSwiipe")}
            values={paymentMethodsData.mainPaymentMethods}
            compareValues={paymentMethodsData.comparePaymentMethods}
            notEnabled={!paymentsEnabled}
            otherContent={
                paymentMethodsData.mainOtherCreditCardPaymentMethods.length === 0 ? undefined : (
                    <MerchantDashboardValueInText
                        items={paymentMethodsData.mainOtherCreditCardPaymentMethods.map((m, i) => ({
                            placeholder: "[[value]]",
                            text: t("merchantDashboard.mobilePayText") + ` ${m.title}`,
                            value: {
                                amount: m.value,
                                isPercent: true,
                            },
                            compareValue: paymentMethodsData.compareOtherCreditCardPaymentMethods && {
                                amount: (paymentMethodsData.compareOtherCreditCardPaymentMethods ?? [])[i]?.value,
                                isPercent: true,
                            },
                        }))}
                    />
                )
            }
        />
    )
}

export default DashboardPaymentDisplay
