import cn from "classnames"
import { Route } from "navi"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute, useNavigation } from "react-navi"
import { TEnv, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { layoutReducerActions } from "../../store/reducers/layoutReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TUserRelation } from "../../type/TUserRelation"
import { TUserRole } from "../../type/TUserRole"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"
import "./NavMenu.scss"

interface INavItemProps extends IMenuLink {
    isActive: boolean
}
const NavItem = ({ isActive, href, iconClass, textId, noIcon }: INavItemProps) => {
    const { t } = useTranslation()
    const navi = useNavigation()
    const dispatch = useReduxDispatch()

    return (
        <li
            onClick={() => {
                navi.navigate(href)
                dispatch(layoutReducerActions.setNavBarOpen(false))
            }}
            className={cn(isActive && "router-link-exact-active router-link-active")}
        >
            <div className={cn("nav__item", noIcon && "no_icon")}>
                {!noIcon && <span className={`icon ${iconClass ?? ""}`}></span>}
                <p>{t(textId)}</p>
            </div>
        </li>
    )
}

interface IMenuLink {
    userRelationTypes?: TUserRelation[]
    href: string
    iconClass?: string
    noIcon?: boolean
    textId: string
    minimumRole?: TUserRole
    requiredMasterPartner?: boolean
    requiredMasterPartnerRelationSelected?: boolean
    disableForEnv?: TEnv[]
}

const MenuLinks: IMenuLink[] = [
    { userRelationTypes: ["User"], href: "/personalinfo", iconClass: "icon-personal-info", textId: "menu.personalinfo" },
    {
        userRelationTypes: ["Merchant"],
        href: "/account",
        iconClass: "icon-ecommerce-account-white",
        textId: "menu.account",
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/api_key",
        iconClass: "icon-apikey-white",
        textId: "apikey.title",
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/plugins",
        iconClass: "icon-plugins-white",
        textId: "plugins.panel",
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/merchantdashboard",
        iconClass: "icon-reports",
        textId: "menu.dashboard",
        disableForEnv: ["Sandbox"],
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/orders",
        iconClass: "icon-transactions",
        textId: "transactions.orders",
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/create_payment_link",
        iconClass: "icon-payment-link",
        textId: "menu.paymentLink",
        disableForEnv: ["Sandbox"],
    },
    {
        userRelationTypes: ["Partner"],
        href: "/partnermerchantrelations",
        iconClass: "icon-ecommerce-white",
        textId: "menu.partnerMerchantRelations",
    },
    {
        userRelationTypes: ["CallCenter"],
        href: "/sendoffer",
        iconClass: "icon-sendoffer-white",
        textId: "menu.sendoffer",
    },
    {
        userRelationTypes: ["CallCenter"],
        href: "/offerhistory",
        iconClass: "icon-offerhistory-white",
        textId: "menu.order_history",
    },
    {
        userRelationTypes: ["CallCenter"],
        href: "/offersettings",
        iconClass: "icon-offersettings-white",
        textId: "menu.offer_settings",
        minimumRole: TUserRole.Admin,
    },
    {
        userRelationTypes: ["CallCenter"],
        href: "/account",
        iconClass: "icon-business-white-menu",
        textId: "menu.account",
        minimumRole: TUserRole.Admin,
    },
    {
        userRelationTypes: ["Merchant", "CallCenter", "Partner"],
        href: "/manageusers",
        iconClass: "icon-people-white",
        textId: "menu.users",
        minimumRole: TUserRole.Admin,
    },
    {
        userRelationTypes: ["Partner"],
        href: "/partnersettings",
        iconClass: "icon-settings",
        textId: "menu.partnerSettings",
    },
    {
        userRelationTypes: ["Partner"],
        href: "/download_onboarding",
        noIcon: true,
        textId: "documentspage.documents",
        requiredMasterPartner: true,
        requiredMasterPartnerRelationSelected: true,
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/invoices",
        iconClass: "icon-invoice-menu",
        textId: "invoices.menuTitle",
        disableForEnv: ["Sandbox"],
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/bookkeeping",
        iconClass: "icon-bookkeeping",
        textId: "bookkeeping.menuTitle",
        disableForEnv: ["Sandbox"],
    },
    {
        userRelationTypes: ["Merchant"],
        href: "/switchservices",
        noIcon: true,
        textId: "switchservices.menuTitle",
        requiredMasterPartner: true,
    },
    //{ userRelationTypes: [], href: "/paymentinfo", iconClass: "icon-orders", textId: "menu.orders" },
    { userRelationTypes: ["User"], href: "/settings", iconClass: "icon-settings", textId: "menu.partnerSettings" },
    {
        userRelationTypes: ["Merchant", "Partner"],
        href: "/releasenotes",
        iconClass: "icon-releasenotes",
        textId: "releasenotes.title",
    },
]

const NavMenu = () => {
    const userRelations = useSelector(userRelationSelectors.userRelationsFlattened)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const masterPartnerId = useSelector(partnerSelectors.partnersMasterPartnerId)
    const env = useSelector(sharedConfigurationSelectors.environment)
    const masterPartnerRelation = masterPartnerId ? userRelations?.find((ur) => ur.id === masterPartnerId) : undefined
    const currentRoute = useCurrentRoute()
    const currentPath = currentRoute.url.pathname
    const [path, setPath] = useState(currentRoute.url.pathname)

    useEffect(() => {
        return navigationService.addNavigationListener((value: Route) => {
            // New route, trigger rerender
            if (path !== value.url.pathname) {
                setPath(value.url.pathname)
            }
        })
    }, [])

    const links = MenuLinks.filter((ml) => {
        if (!currentUserRelation) {
            return
        }

        if (ml.disableForEnv) {
            if (ml.disableForEnv.includes(env)) {
                return false
            }
        }

        if (ml.requiredMasterPartner) {
            return (
                masterPartnerRelation &&
                (masterPartnerRelation.id === currentUserRelation.id || !ml.requiredMasterPartnerRelationSelected) &&
                (!ml.userRelationTypes || ml.userRelationTypes.find((r) => r === currentUserRelation.relationType)) &&
                (!ml.minimumRole || ml.minimumRole <= masterPartnerRelation.userRole)
            )
        } else {
            return (
                (!ml.userRelationTypes || ml.userRelationTypes.find((r) => r === currentUserRelation.relationType)) &&
                (!ml.minimumRole || ml.minimumRole <= currentUserRelation.userRole)
            )
        }
    })
    return (
        <ul className={cn("sidebar-top")}>
            {links.map((link) => (
                <NavItem isActive={link.href === currentPath} key={link.href} {...link} />
            ))}
        </ul>
    )
}

export default NavMenu
