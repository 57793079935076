import { TSwiipeService, getDistinct, hasValue } from "swiipe.portal.shared"
import { ETermsType } from "../store/reducers/legalReducer"

const serviceTermsMapping: { [serviceType in TSwiipeService]: ETermsType[] } = {
    AcquiringClearhaus: ["TermsAcquiringClearhaus"],
    AccountToAccount: ["TermsA2a"],
    AnywhereMobile: ["TermsAnywhereMobile"],
    ApplePay: ["TermsApplePay"],
    Checkout: [],
    CreditCard: [],
    Dankort: ["TermsDankort"],
    ExternalAnalytics: ["TermsExternalAnalytics"],
    Forbrugsforeningen: ["TermsForbrugsforeningen"],
    KlarnaFinancing: ["TermsKlarna"],
    KlarnaPayLater: ["TermsKlarna"],
    MobilePayOnline: ["TermsMobilePayOnline"],
    PlusSell: ["TermsPlusSell"],
    ResursInvoice: ["TermsResurs"],
    ResursPartPayment: ["TermsResurs"],
    ResursRevolvingCredit: ["TermsResurs"],
    ReturnSell: ["TermsReturnSell"],
    Swish: ["TermsSwish"],
    Viabill: ["TermsViaBill"],
    Vipps: ["TermsVipps"],
    Winback: ["TermsWinBack"],
}

const servicesRequiringAcquiring: TSwiipeService[] = ["CreditCard", "MobilePayOnline", "ApplePay"]

export function getTermsForSwiipeService(service: TSwiipeService) {
    // TODO: Refactor in the future when splitting PSP, Acquiring, and Creditcards
    const acquiringTerms: ETermsType[] = servicesRequiringAcquiring.includes(service) ? ["TermsAcquiringClearhaus"] : []
    return getDistinct([...serviceTermsMapping[service], ...acquiringTerms])
}

export function getTermsForSwiipeServices(services: TSwiipeService[]) {
    return getDistinct(services.map((s) => serviceTermsMapping[s].filter(hasValue)).flat())
}
