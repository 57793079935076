import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, TextButton, TitleWithFoldableHint } from "swiipe.portal.shared"
import { getImage } from "../../../../services/imageLanguageService"
import { TApplePayOnboardingType } from "../../ApplePayOnboardingPage"
import "./ApplePayIsNoCertsStep.scss"

interface IApplePayIsNoCertsStep {
    onBackStep: () => Promise<void>
    onNextStep(onboardingType: TApplePayOnboardingType | undefined): void
}

const ApplePayIsNoCertsStep = ({ onNextStep, onBackStep }: IApplePayIsNoCertsStep) => {
    const { t } = useTranslation()
    const commonButtonsProps = {
        invertedBlueBorder: true,
        isSmall: true,
        smallBorderRadius: true,
    }
    return (
        <section className="applepay-id-step">
            <div className="applepay-form-head">
                <img className="applepay-logo" src={getImage("applePayLogo")} />
            </div>
            <div className="apple-pay-is-no-certs-step-dialog">
                <TitleWithFoldableHint
                    className="mt-5 mb-4"
                    title={t("applepayonboarding.noCertsOnboarding.isAlreadyAtApple")}
                    hint={t("applepayonboarding.noCertsOnboarding.alreadyAtAppleHint")}
                    titleFontSize="20"
                    titleTextAlign="center"
                    icon="lightGray"
                    theme="grey"
                />
                <StandardButton {...commonButtonsProps} className="mb-3" onClick={() => onNextStep("noCert")}>
                    {t("applepayonboarding.noCertsOnboarding.newToApple")}
                </StandardButton>
                <StandardButton {...commonButtonsProps} className="mb-4" onClick={() => onNextStep(undefined)}>
                    {t("applepayonboarding.noCertsOnboarding.alreadyAtApple")}
                </StandardButton>
            </div>
            <TextButton text={t("commononboardingtexts.goback")} className="mb-5 mt-4" onClick={onBackStep} />
        </section>
    )
}

export default ApplePayIsNoCertsStep
