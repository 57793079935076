import cn from "classnames"
import React, { ReactNode, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import DownloadTextButtonComp from "../../../../components/buttons/DownloadTextButtonComp/DownloadTextButtonComp"
import FileInput from "../../../../components/form/input/FileInput"
import { IApplePayOnboardingData, applePayOnboardingThunk, createApplePayCsrThunk } from "../../../../store/thunks/applePayThunks"
import { MixedMerchantDetails } from "../../../../type/mixedmerchantdetails"
//TODO: Get rid of "html-react-parser" lib, (50KB!!!zipped)
import Parser from "html-react-parser"
import { ShowErrorMessages, SpinnerContainer, TextButton } from "swiipe.portal.shared"
import SubmitButton from "../../../../components/buttons/SubmitButton"
import ApplePayOnboardingStepsContainer from "../../../../components/paymentsOnboarding/ApplePayOnboardingStepsContainer"
import { getImage } from "../../../../services/imageLanguageService"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"

interface ApplePayGenerateCertsStepProps {
    merchantDetails: MixedMerchantDetails
    webshopId: string
    applePayId: string
    onNextStep: () => void
    onBackStep: () => Promise<void>
}

interface IFormModel {
    uploadedCertificates: File[]
}

const ApplePayGenerateCertsStep = ({
    merchantDetails,
    webshopId,
    applePayId,
    onNextStep,
    onBackStep,
}: ApplePayGenerateCertsStepProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [merchantIdentityCsr, setMerchantIdentityCsr] = useState("")
    const [paymentProcessingCsr, setPaymentProcessingCsr] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    const swMerchantId = merchantDetails.swMerchant.swMerchantId

    const initCsrData = async () => {
        try {
            const createdCsrResponse = await dispatch(createApplePayCsrThunk(webshopId, swMerchantId, applePayId))
            setMerchantIdentityCsr(createdCsrResponse.merchantIdentityCsr)
            setPaymentProcessingCsr(createdCsrResponse.paymentProcessingCsr)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            onBackStep()
        }
    }

    useEffect(() => {
        if (webshopId && swMerchantId && applePayId) {
            initCsrData()
        }
    }, [])

    const { handleSubmit, register, setValue, formState, trigger, setError, clearErrors, getValues } = useForm<IFormModel>()
    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            const formData: IApplePayOnboardingData = {
                applePayButtonLocation: "WebshopDomain",
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: merchantDetails.swMerchantContacts[0]?.email,
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: merchantDetails.swMerchantContacts[0]?.name,
                merchantContactPhone: merchantDetails.swMerchantContacts[0]?.phone,
                webshopIds: [webshopId],
                applePayId: applePayId,
                merchantCertificateFile: data.uploadedCertificates[0],
            }

            await dispatch(applePayOnboardingThunk(formData, false))
            onNextStep()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <ApplePayOnboardingStepsContainer>
            <SpinnerContainer className="applepay-generate-cert-step" showSpinner={isLoading}>
                <StepComponent>
                    <div className="applepay-form-head">
                        <img className="applepay-logo" src={getImage("applePayLogo")} />
                    </div>
                    <div className="applepay-summary">{Parser(t("applepayonboarding.description"))}</div>
                </StepComponent>

                <StepComponent label="1.">
                    <div className="applepay-step-description">{Parser(t("applepayonboarding.paymentprocessingstep"))}</div>
                    <DownloadTextButtonComp
                        // tooltip="Certificate Signing Request (Payment Processing Csr)"
                        className="applepay-download-btn"
                        textToDownload={paymentProcessingCsr}
                        label={t("applepayonboarding.downloadPPCertificate")}
                        fileName="paymentProcessingCsr.csr"
                        invertedBlueBorder
                    />
                    <div className="applepay-guidelink">{Parser(t("applepayonboarding.seeguide"))}</div>
                </StepComponent>

                <StepComponent label="2.">
                    <div className="applepay-step-description">{Parser(t("applepayonboarding.merchantidentitystep"))}</div>
                    <DownloadTextButtonComp
                        // tooltip="Certificate Signing Request (Merchant Identity Csr)"
                        className="applepay-download-btn"
                        textToDownload={merchantIdentityCsr}
                        label={t("applepayonboarding.downloadMICertificate")}
                        fileName="merchantIdentityCsr.csr"
                        invertedBlueBorder
                    />
                    <div className="applepay-guidelink">{Parser(t("applepayonboarding.seeguide"))}</div>
                </StepComponent>

                <StepComponent label="3.">
                    <div className="applepay-grey-textbox">{Parser(t("applepayonboarding.uploadstep"))}</div>
                    <div className="applepay-upload-input">
                        <FileInput
                            name="uploadedCertificates"
                            accept=".cer"
                            minFilesAmount={1}
                            maxFilesAmount={1}
                            triggerValidation={trigger}
                            setError={setError}
                            clearError={clearErrors}
                            setValue={setValue}
                            getValues={getValues}
                            register={register}
                            requiredErrorMessage={t("applepayonboarding.errorMessages.missingCert")}
                            multiple
                            slimButton
                        />
                    </div>
                    <div className="applepay-guidelink">{Parser(t("applepayonboarding.seeguide"))}</div>
                </StepComponent>

                <StepComponent label="">
                    <Form onSubmit={handleSubmit(onSubmit)} className="applepay-submit-form">
                        <ShowErrorMessages formState={formState} />
                        <SubmitButton dark noBorder containerClass="submit-btn" formState={formState}>
                            {t("commononboardingtexts.submit")}
                        </SubmitButton>
                    </Form>
                    <TextButton text={t("commononboardingtexts.goback")} className="mt-4" onClick={onBackStep} />
                </StepComponent>
            </SpinnerContainer>
        </ApplePayOnboardingStepsContainer>
    )
}

const StepComponent = (props: { label?: string; children: ReactNode }) => {
    return (
        <section className={cn("step", { "step-centered": !props.label })}>
            {props.label && <div className="step-header">{props.label}</div>}
            <div className="step-content">{props.children}</div>
        </section>
    )
}

export default ApplePayGenerateCertsStep
