import React from "react"
import { useTranslation } from "react-i18next"
import { IStandardButtonProps, StandardButton } from "swiipe.portal.shared"

interface IDownloadTextButtonCompProps extends IStandardButtonProps {
    textToDownload: string
    fileName: string
    label?: string
}

const DownloadTextButtonComp = (props: IDownloadTextButtonCompProps) => {
    const { t } = useTranslation()

    const downloadText = (fileContents: string, fileName: string) => {
        const a = document.createElement("a")
        a.href = "data:text/plain;base64," + btoa(fileContents)
        a.download = fileName
        a.click()
        a.remove()
    }
    props = {
        ...props,
        onClick: () => downloadText(props.textToDownload, props.fileName),
    }
    return <StandardButton {...props}>{props.label ?? t("common.download")}</StandardButton>
}

export default DownloadTextButtonComp
