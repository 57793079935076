import { ActionsUnion, IInvoicingPrice, TCurrency, createAction } from "swiipe.portal.shared"
import { IInvoiceSimple } from "../../type/billing/IInvoice"
import { IInvoicingOrganization } from "../../type/billing/IInvoicingOrganization"
import { StoreState } from "../StoreState"

export type BillingState = Readonly<{
    simpleInvoices: { [organizationId: string]: IInvoiceSimple[] | undefined }
    invoicingOrganizations: { [organizationId: string]: IInvoicingOrganization | undefined }
    organizationCurrency: { [organizationId: string]: TCurrency | undefined }
    resolvedPrices: { [invoicingRelationId: string]: IInvoicingPrice[] }
}>

export type EInvoicingRelationType = "Merchant" | "Partner" | "User"

const initialState: BillingState = {
    simpleInvoices: {},
    invoicingOrganizations: {},
    organizationCurrency: {},
    resolvedPrices: {},
}

export const billingReducerActions = {
    setSimpleInvoices: (organizationId: string, invoices: IInvoiceSimple[]) =>
        createAction("APP/SET_SIMPLE_INVOICES", { organizationId, invoices }),
    setInvoicingOrganization: (organizationId: string, organization: IInvoicingOrganization) =>
        createAction("APP/SET_INVOICING_ORGANIZATION", { organizationId, organization }),
    setOrganizationCurrency: (currency: TCurrency, organizationId: string) =>
        createAction("APP/SET_ORGANIZATION_CURRENCY", { currency, organizationId }),
    setInvoicingResolvedPrices: (invoicingRelationId: string, prices: IInvoicingPrice[]) =>
        createAction("APP/SET_INVOICING_RESOLVED_PRICES", { invoicingRelationId, prices }),
}

export type BillingActions = ActionsUnion<typeof billingReducerActions>

export const billingReducer = (state: BillingState = initialState, action: BillingActions) => {
    switch (action.type) {
        case "APP/SET_SIMPLE_INVOICES": {
            return {
                ...state,
                simpleInvoices: {
                    ...state.simpleInvoices,
                    [action.organizationId]: action.invoices,
                },
            }
        }
        case "APP/SET_INVOICING_ORGANIZATION": {
            return {
                ...state,
                invoicingOrganizations: {
                    ...state.invoicingOrganizations,
                    [action.organizationId]: action.organization,
                },
            }
        }
        case "APP/SET_ORGANIZATION_CURRENCY": {
            return {
                ...state,
                organizationCurrency: {
                    ...state.organizationCurrency,
                    [action.organizationId]: action.currency,
                },
            }
        }
        case "APP/SET_INVOICING_RESOLVED_PRICES": {
            return {
                ...state,
                resolvedPrices: {
                    ...state.resolvedPrices,
                    [action.invoicingRelationId]: action.prices,
                },
            }
        }
    }

    return state
}

export const billingSelectors = {
    currency: (state: StoreState) =>
        state.userRelation.currentRelation
            ? state.billing.organizationCurrency[state.userRelation.currentRelation.id]
            : undefined,
    resolvedPrices: (state: StoreState) =>
        state.userRelation.currentRelation ? state.billing.resolvedPrices[state.userRelation.currentRelation.id] : undefined,
    simpleInvoices: (state: StoreState) =>
        state.userRelation.currentRelation ? state.billing.simpleInvoices[state.userRelation.currentRelation.id] : undefined,
    invoicingOrganization: (state: StoreState) =>
        state.userRelation.currentRelation
            ? state.billing.invoicingOrganizations[state.userRelation.currentRelation.id]
            : undefined,
}
