import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { FormFieldsSection, SpinnerContainer } from "swiipe.portal.shared"
import { IClearhausComment } from "../../type/clearhaus/IClearhausComment"
import { IClearhausCommentsData } from "../../type/clearhaus/IClearhausCommentsData"
import { TClearhausApplicationSectionType } from "../../type/clearhaus/TClearhausApplicationSectionType"
import "./ClearhausApplicationComments.scss"
import { ClearhausComment } from "./ClearhausComment"

interface IClearhausApplicationComments {
    commentsData?: IClearhausCommentsData
    forSection?: TClearhausApplicationSectionType
    insideForm?: boolean
    className?: string
}

export const ClearhausApplicationComments = ({
    commentsData,
    forSection,
    insideForm,
    className,
}: IClearhausApplicationComments) => {
    const { t } = useTranslation()

    if (!commentsData && insideForm) {
        return null
    }

    const commentsToShow = commentsData?.comments.filter((c) => !forSection || c.sectionType == forSection)

    if (insideForm && commentsToShow?.length === 0) {
        return null
    }

    if (insideForm) {
        return <CommentsSection className={className} comments={commentsToShow} isLoading={!commentsData} />
    }

    return (
        <FormFieldsSection header={t("clearhausonboarding.sectiontitles.applicationComments")} headerFont="smaller">
            <CommentsSection className={className} comments={commentsToShow} isLoading={!commentsData} />
        </FormFieldsSection>
    )
}

interface ICommentsSectionProps {
    comments?: IClearhausComment[]
    isLoading: boolean
    className?: string
}
const CommentsSection = ({ comments, isLoading, className }: ICommentsSectionProps) => {
    return (
        <div className={cn("clearhaus-comments", className)}>
            <SpinnerContainer className={cn("clearhaus-comments__spinner", isLoading && "pt-5")} showSpinner={isLoading}>
                {comments?.map((c, index) => (
                    <ClearhausComment key={index} comment={c} />
                ))}
            </SpinnerContainer>
        </div>
    )
}
