import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, Row } from "reactstrap"
import {
    CountryInputComp,
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    useSelector,
    userSelectors,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getLanguagesList } from "../../../services/languageService"
import { changePasswordThunk } from "../../../store/thunks/loginThunks"
import { updateUserDataThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import SubmitButton from "../../buttons/SubmitButton"
import MessageBox from "../../layouts/MessageBox"
import FormPasswordRequirements from "../FormPasswordRequirements"
import "./UserSettingsForm.scss"

interface IUserSettingsFormProps {}

interface IFormModel {
    countryCode: string
    language: string
    existing_password: string
    new_password: string
}

const UserSettingsForm = ({}: IUserSettingsFormProps) => {
    const userData = useSelector(userSelectors.userData)

    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<IFormModel>()
    const [showRequirements, setShowRequirements] = useState(false)

    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<IFormModel> = async (data, e) => {
        try {
            //Update password
            if (data.existing_password !== "" && data.new_password !== "") {
                await dispatch(changePasswordThunk({ ...data }))
            }

            await dispatch(
                updateUserDataThunk({
                    type: "languageCountryForm",
                    countryCode: data.countryCode,
                    language: data.language,
                })
            )
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <div id="user-settings-form">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <MessageBox titleInBody smallTopBottomPadding title={t("personal.country")}>
                    <FieldsetFormGroup field>
                        <CountryInputComp
                            inputName="countryCode"
                            defaultValue={userData?.user.countryCode || "DK"}
                            register={register}
                            onChange={() => {
                                handleSubmit(onSubmit)()
                            }}
                        />
                    </FieldsetFormGroup>
                </MessageBox>
                <MessageBox titleInBody smallTopBottomPadding title={t("personal.language")}>
                    <FieldsetFormGroup field>
                        <DropDownList
                            defaultValue={userData?.user.language || "en"}
                            name="language"
                            innerRef={register(
                                valFuncRequiredAndPattern(
                                    validationPatterns.userLanguage,
                                    t("personal.validationerrors.language")
                                )
                            )}
                            options={getLanguagesList(["en", "da"])}
                            onChange={() => {
                                handleSubmit(onSubmit)()
                            }}
                        />
                    </FieldsetFormGroup>
                </MessageBox>
                <MessageBox titleInBody smallTopBottomPadding title={t("settings.change_pw")}>
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="existing_password"
                            type="password"
                            innerRef={register(valFuncPattern(validationPatterns.password, t("validation.wrongoldpw")))}
                            placeholder={t("settings.existing_password")}
                        />
                    </FieldsetFormGroup>
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="new_password"
                            type="password"
                            onFocus={() => setShowRequirements(true)}
                            onBlur={() =>
                                setTimeout(() => {
                                    setShowRequirements(false)
                                }, 100)
                            }
                            innerRef={register(valFuncPattern(validationPatterns.password, t("validation.wrongnewpw")))}
                            placeholder={t("settings.new_password")}
                        />
                    </FieldsetFormGroup>
                    <ShowErrorMessages formState={formState} />
                    {showRequirements && <FormPasswordRequirements isBlueBubble />}
                    <Row>
                        <div className="col-sm-4 offset-8">
                            <FieldsetFormGroup>
                                <SubmitButton dark noBorder noBorderRadius formState={formState} title={t("common.save")} />
                            </FieldsetFormGroup>
                        </div>
                    </Row>
                </MessageBox>
            </Form>
        </div>
    )
}

export default UserSettingsForm
