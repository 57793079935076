import cn from "classnames"
import React from "react"
import { TitleWithFoldableHint } from "swiipe.portal.shared"
import "./UnorderedListWithHints.scss"

interface IUnorderedListWithHints {
    heading?: string
    listRows: {
        title: string
        hint: string | JSX.Element
    }[]
    className?: string
}

const UnorderedListWithHints = ({ heading, listRows, className }: IUnorderedListWithHints) => {
    return (
        <div className={cn("unordered-hints-list-comp", className)}>
            {heading && <p className="heading">{heading}</p>}
            <ul>
                {listRows.map((row, index) => {
                    return (
                        <li className="list-row" key={`${row.title}_${index}`}>
                            <TitleWithFoldableHint
                                title={row.title}
                                titleFontSize="16"
                                hint={row.hint}
                                hintFontSize="15"
                                hintFontWeight="300"
                                theme="grey"
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default UnorderedListWithHints
