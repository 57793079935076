import { getKeys, groupBy, hasValue, sortDescending } from "swiipe.portal.shared"
import { IReleaseNote, TReleaseNoteContext } from "../type/releaseNotes/releaseNote"
import { padZero } from "../util/numberUtil"

export const getReleaseNotes = (
    allNotes: IReleaseNote[] | undefined,
    options: {
        context?: TReleaseNoteContext
        language?: string
        fallbackLanguage?: string
    }
) => {
    const notesByContext = allNotes?.filter((rn) => rn.context === options.context) ?? []
    const groupedByLanguageId = groupBy(notesByContext, (rn) => rn.crossLanguageId ?? "")

    return sortDescending(
        getKeys(groupedByLanguageId)
            .map((crossLanguageId) => {
                const languageNotes = groupedByLanguageId[crossLanguageId]

                const requestedLanguageNote = languageNotes.find((rn) => rn.language === options.language)
                if (requestedLanguageNote) {
                    return requestedLanguageNote
                }

                const fallbackLangNote = languageNotes.find((rn) => rn.language === options.fallbackLanguage)
                if (fallbackLangNote) {
                    return fallbackLangNote
                }

                // If there is no note with language or fallbackLanguage
                // still return any available note
                return languageNotes.find((rn) => rn.releaseNoteId)
            })
            .filter(hasValue),
        (note) => toComparableVersionString(note.version)
    )
}

const toComparableVersionString = (versionStr: string) =>
    versionStr.replace(new RegExp("\\d+\\.\\d+(\\.\\d+)?(\\.\\d+)?", "g"), (match) => {
        return padZero(
            match.split(".").reduce((sum, numberStr, index) => sum + parseInt(numberStr) * Math.pow(100, 3 - index), 0),
            8
        ) // Create a big integer from the values to use as comparizon
    })
