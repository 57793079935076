import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormHeader, PageContainer, PageWrapper } from "swiipe.portal.shared"
import { AlreadyClearhausExistingUserPrompt } from "../../../components/clearhaus/AlreadyClearhausExistingUserPrompt"
import ClearhausOnboardingMessage from "../../../components/clearhaus/ClearhausOnboardingMessage"
import { getMerchantContact } from "../../../services/merchantService"
import { navigationService } from "../../../services/navigationService"
import { TOnboardingType } from "../../../services/onboardingService"
import { IMerchantPreOnboardingDetails } from "../../../store/reducers/merchantPreOnboardingSlice"
import {
    clearhausOnboardingAsExistingUserThunk,
    onboardClearhausForPaymentTypeThunk,
} from "../../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"

interface IClearhausOnboardingAsExistingUserPageProps {
    webshopIds: string[]
    merchantDetails: MixedMerchantDetails
    onboardingType: TOnboardingType
    onGoBack: () => Promise<void>
    onNotExistingUser: () => void
    onOnboarded: () => void
    flowType: "merchantOffer" | undefined
    preOnboardingDetails: IMerchantPreOnboardingDetails
}

type TClearhausOnboardingAsExistingUserDialog =
    | "alreadyHasClearhausPrompt"
    | "onboardWithoutData"
    | "onboardedMessage"
    | "continue"
export const ClearhausOnboardingAsExistingUserPage = ({
    webshopIds,
    merchantDetails,
    onboardingType,
    onGoBack,
    onNotExistingUser,
    onOnboarded,
    flowType,
    preOnboardingDetails,
}: IClearhausOnboardingAsExistingUserPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const initialDialog: TClearhausOnboardingAsExistingUserDialog =
        flowType === "merchantOffer" && preOnboardingDetails.exists
            ? preOnboardingDetails.services.alreadyHaveAcquiringClearhaus
                ? "onboardWithoutData"
                : "continue"
            : "alreadyHasClearhausPrompt"
    const [activeDialog, setActiveDialog] = useState<TClearhausOnboardingAsExistingUserDialog>(initialDialog)

    useEffect(() => {
        if (activeDialog === "continue") {
            onNotExistingUser()
            setActiveDialog("alreadyHasClearhausPrompt")
        }
    }, [activeDialog])

    return (
        <>
            {activeDialog === "alreadyHasClearhausPrompt" && (
                <AlreadyClearhausExistingUserPrompt
                    handleResponse={(hasClearhaus) => {
                        if (!hasClearhaus) {
                            onNotExistingUser()
                        }

                        setActiveDialog("onboardWithoutData")
                    }}
                    onGoBack={onGoBack}
                />
            )}
            {(activeDialog === "onboardWithoutData" || activeDialog === "onboardedMessage") && (
                <PageWrapper widest>
                    <PageContainer transparent>
                        <FormHeader
                            title={t("clearhausonboarding.headers.setupClearhaus")}
                            onClose={() => navigationService.navigate("/plugins")}
                        />
                        {activeDialog === "onboardWithoutData" && (
                            <ClearhausOnboardingMessage
                                checkmarkHidden
                                mainText={
                                    <>
                                        <p>
                                            <b>{t("clearhausOnboardingAsExistingUser.onboard.toMoveWriteUs")}</b>
                                        </p>
                                        <p>{t("clearhausOnboardingAsExistingUser.onboard.inTheEmailSpecify")}</p>
                                        <p>{t("clearhausOnboardingAsExistingUser.onboard.rememberTo")}</p>
                                    </>
                                }
                                mainButton={{
                                    text: t("swiipeservicesonboarding.continue"),
                                    onClick: async () => {
                                        const swMerchantContact = getMerchantContact(merchantDetails)
                                        await dispatch(
                                            onboardClearhausForPaymentTypeThunk(
                                                merchantDetails.swMerchant.swMerchantId,
                                                webshopIds,
                                                "CreditCard"
                                            )
                                        )
                                        await dispatch(
                                            clearhausOnboardingAsExistingUserThunk(
                                                merchantDetails.swMerchant.swMerchantId,
                                                swMerchantContact?.email || "",
                                                merchantDetails.swMerchant.name,
                                                swMerchantContact?.name || "",
                                                swMerchantContact?.phone || "",
                                                webshopIds
                                            )
                                        )
                                        setActiveDialog("onboardedMessage")
                                    },
                                }}
                                leftButton={{
                                    text: t("commononboardingtexts.goback"),
                                    onClick: () => setActiveDialog("alreadyHasClearhausPrompt"),
                                }}
                            />
                        )}
                        {activeDialog === "onboardedMessage" && (
                            <ClearhausOnboardingMessage
                                mainText={
                                    <>
                                        <p>
                                            <b>{t("clearhausOnboardingAsExistingUser.onboarded.awaitingApproval")}</b>
                                        </p>
                                        <p>{t("clearhausOnboardingAsExistingUser.onboarded.weWillGetBackToYou")}</p>
                                        <p>{t("clearhausOnboardingAsExistingUser.onboarded.rememberTo")}</p>
                                    </>
                                }
                                mainButton={{
                                    text: onboardingType === "cards" ? t("common.close") : t("common.continue"),
                                    onClick: onOnboarded,
                                }}
                            />
                        )}
                    </PageContainer>
                </PageWrapper>
            )}
        </>
    )
}
