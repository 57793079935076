import cn from "classnames"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer, TextButton, useSelector } from "swiipe.portal.shared"
import { navigationService } from "../../services/navigationService"
import { getPaymentMethodInfoString } from "../../services/paymentMethodService"
import { StoreState } from "../../store/StoreState"
import { paymentMethodSelectors } from "../../store/reducers/paymentMethodReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import {
    deletePaymentMethodRelationThunk,
    fetchRelatedPaymentMethodsThunk,
    setPrimaryPaymentMethodRelationThunk,
} from "../../store/thunks/paymentMethodThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import PaymentMethod from "../form/paymentmethods/PaymentMethod"
import InfoBoxAdder from "../infobox/InfoBoxAdder"
import { InfoBoxState } from "../infobox/InfoBoxState"
import TitleSection from "../sections/TitleSection"
import "./BillingPaymentMethods.scss"

interface IBillingPaymentMethodsProps {}

const BillingPaymentMethods = ({}: IBillingPaymentMethodsProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const paymentMethods = useSelector((state: StoreState) => paymentMethodSelectors.paymentMethodRelations(state, "Billing"))
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    useEffect(() => {
        dispatch(fetchRelatedPaymentMethodsThunk("Billing", false))
    }, [currentUserRelation])

    const pms = paymentMethods ?? []

    return (
        <div className="billing-payment-methods">
            <TitleSection isSmaller title={t("billingPaymentMethods.paymentMethodTitle")} />
            <SpinnerContainer showSpinner={!paymentMethods}>
                {pms.length === 0 && (
                    <div className="none-found">
                        <span>{t("billingPaymentMethods.noneAddedWarning")}</span>
                    </div>
                )}
                {pms.length > 0 && (
                    <div className="billing-payment-methods-inner-container">
                        {pms.map((pm) => (
                            <div key={pm.paymentId} className={cn("billing-payment-method", { primary: pm.isPrimary })}>
                                <PaymentMethod paymentMethod={pm} />
                                <div className="action-section">
                                    {!pm.isPrimary && (
                                        <TextButton
                                            textDecoration="underline"
                                            className="set-primary"
                                            text={t("billingPaymentMethods.setAsPrimary")}
                                            onClick={async () =>
                                                await dispatch(setPrimaryPaymentMethodRelationThunk("Billing", pm.paymentId))
                                            }
                                        />
                                    )}
                                    {!pm.isPrimary && <div className="billing-payment-methods__action-divider"></div>}
                                    {!pm.isPrimary && (
                                        <TextButton
                                            textDecoration="underline"
                                            className="delete"
                                            text={t("billingPaymentMethods.delete")}
                                            onClick={async () => {
                                                const resp = await dispatch(
                                                    addModalThunk({
                                                        type: "confirmDangerousAction",
                                                        title: t("billingPaymentMethods.deleteConfirm.title"),
                                                        promptText: t("billingPaymentMethods.deleteConfirm.text", {
                                                            paymentMethod: " (" + getPaymentMethodInfoString(pm) + ")",
                                                        }),
                                                        customSubmitText: t("billingPaymentMethods.deleteConfirm.delete"),
                                                        customCancelText: t("billingPaymentMethods.deleteConfirm.cancel"),
                                                    })
                                                )
                                                if (resp.type === "accepted") {
                                                    await dispatch(deletePaymentMethodRelationThunk("Billing", pm.paymentId))
                                                }
                                            }}
                                        />
                                    )}
                                    {pm.isPrimary && <span className="primary-title">{t("billingPaymentMethods.primary")}</span>}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="add-payment-method">
                    <InfoBoxAdder
                        state={InfoBoxState.DEFAULT}
                        title=""
                        adderLabel={
                            pms.length === 0
                                ? t("billingPaymentMethods.addPaymentMethod")
                                : t("billingPaymentMethods.addNewPaymentMethod")
                        }
                        isInEditMode={false}
                        onSwitchEditMode={() => {}}
                        isFirstItem={false}
                        renderFirstItemPreview={() => <></>}
                        onClickAdder={async () => navigationService.navigate("/add_payment_method?relationType=Billing")}
                    />
                </div>
            </SpinnerContainer>
        </div>
    )
}

export default BillingPaymentMethods
