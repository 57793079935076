import React from "react"
import SpinnerGif from "../../images/load/spinner.gif"
import "./Spinner.scss"

interface ISpinnerProps {
    height?: number
}

const Spinner = ({ height }: ISpinnerProps) => {
    return (
        <div className="swiipe-spinner" style={{ height: height || 50 }}>
            {<img className="spinner" src={SpinnerGif} />}
        </div>
    )
}

export default Spinner
