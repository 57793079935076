import React from "react"
import "./MerchantDashboardCellContainer.scss"
import cn from "classnames"

interface IMerchantDashboardCellContainerProps {
    className?: string
    valuesDisabled?: boolean
    html2CanvasIgnore?: boolean
    children: React.ReactNode
}

const MerchantDashboardCellContainer = ({
    className,
    valuesDisabled,
    html2CanvasIgnore,
    children,
}: IMerchantDashboardCellContainerProps) => {
    return (
        <div className={cn("merchant-dashboard-cell-container", className)} data-html2canvas-ignore={html2CanvasIgnore}>
            {valuesDisabled && <div className="disabled-overlay values"></div>}
            {children}
        </div>
    )
}

export default MerchantDashboardCellContainer
