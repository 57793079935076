import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-navi"
import { PageWrapper } from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"

interface IMerchantInstallationGuidePageProps {}

const MerchantInstallationGuidePage = ({}: IMerchantInstallationGuidePageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const { t, i18n } = useTranslation()

    return (
        <PageWrapper>
            <div id="plugin-guide" className="page">
                <div className="page-header"></div>
                <div id="guide-default" className="page-body guide">
                    <div className="page-top-container text-center">
                        <p>{t("installguide.header")}</p>
                    </div>
                    <div className="top-separator text-center h4 pt-4">
                        <h2>{t("installguide.videoheader")}</h2>
                    </div>
                    <div className="guide-section text-center">
                        <h3>{t("installguide.sec1title")}</h3>
                        <p className="text-center">
                            {t("installguide.sec1text")}
                            <a href="https://swiipeprodapidb.blob.core.windows.net/woocommerceplugin/swiipe-checkout.zip">
                                {t("installguide.sec1link")}
                            </a>
                        </p>
                    </div>
                    <div className="guide-section text-center">
                        <h3>{t("installguide.sec2title")}</h3>
                        <img src={require("../../images/guides/" + i18n.language + "_enter_api_key.png")} />
                        <p className="text-center">{t("installguide.sec2text")}</p>
                    </div>
                    <div className="guide-section text-center">
                        <h3>{t("installguide.sec3title")}</h3>
                        <img src={require("../../images/guides/" + i18n.language + "_enable_checkout.png")} />
                        <p className="text-center">{t("installguide.sec3text")}</p>
                        <p>{t("installguide.sec3text2")}</p>
                    </div>
                    <div className="guide-section text-center">
                        <h3>{t("installguide.sec4title")}</h3>
                        <p>{t("installguide.sec4text")}</p>
                    </div>
                    <div className="top-separator text-center h4 pt-4 ">
                        <p>
                            <Link href="/installation_guide_extended">{t("installguide.extendedguide")}</Link>
                        </p>
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}

export default MerchantInstallationGuidePage
