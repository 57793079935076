import React from "react"

interface IUnorderedListSectionProps {
    listItems: string[]
    listTitle?: string
    className?: string
}

const UnorderedListSection = ({ listItems, listTitle, className }: IUnorderedListSectionProps) => {
    return (
        <>
            {listTitle && <p className="mb-1">{listTitle}</p>}
            <ul className={className}>
                {listItems.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </>
    )
}

export default UnorderedListSection
