import React from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import { PageWrapper } from "swiipe.portal.shared"
import ResetPasswordForm from "../../components/form/password/ResetPasswordForm"
import MessageBox from "../../components/layouts/MessageBox"

interface IResetPasswordPageProps {}

const ResetPasswordPage = ({}: IResetPasswordPageProps) => {
    const currentRoute = useCurrentRoute()
    const token = currentRoute.url.query.token
    const email = currentRoute.url.query.email

    const { t } = useTranslation()

    if (!email || !token) {
        return null
    }

    return (
        <PageWrapper>
            <MessageBox title={t("forgotpw.resettitle")} contentClass="pb-3">
                <ResetPasswordForm email={email} token={token} />
            </MessageBox>
        </PageWrapper>
    )
}

export default ResetPasswordPage
