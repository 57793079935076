import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import { openContactUsWidget } from "../../services/contactUsService"
import { navigationService } from "../../services/navigationService"
import "./ModalCompanyAlreadyRegistered.scss"

export interface IModalCompanyAlreadyRegistered extends IModalBase {
    type: "modalCompanyAlreadyRegistered"
    subType: "default" | "deleted" | "ownerAndDeleted"
}

const ModalCompanyAlreadyRegistered = ({
    title,
    subType,
    callback,
    ...otherProps
}: IModalCompanyAlreadyRegistered & IModalControlProps) => {
    const { t } = useTranslation()

    return (
        <>
            <BaseModal
                {...otherProps}
                modalClassName="modal-company-already-registered"
                callback={callback}
                centered
                title={title}
                showCloseButton
                noBorderTop
            >
                <ModalBody>
                    <div className="content-container">
                        <p className="mb-0 text-center">
                            {subType === "ownerAndDeleted"
                                ? t("modalCompanyAlreadyExists.alreadyRegisteredOwner")
                                : t("modalCompanyAlreadyExists.alreadyRegistered")}
                            {subType === "default" && <br />}
                            {subType === "default" && (
                                <span
                                    onClick={() => {
                                        navigationService.navigate("/login")
                                        callback("accepted")
                                    }}
                                >
                                    {t("modalCompanyAlreadyExists.logIn")}
                                </span>
                            )}
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {t("modalCompanyAlreadyExists.needHelp")}
                    <span onClick={openContactUsWidget}>{t("modalCompanyAlreadyExists.contactUs")}</span>
                </ModalFooter>
            </BaseModal>
        </>
    )
}

export default ModalCompanyAlreadyRegistered
