import React from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import FormFooter from "../../components/form/footer/FormFooter"
import SalesSendOfferForm from "../../components/form/sales/SalesSendOfferForm"
import { useRequiredUserRelations } from "../../services/userRelationService"
import PageTitle from "./../../components/page/PageTitle"

interface ISalesSendOfferPageProps {}

const SalesSendOfferPage = ({}: ISalesSendOfferPageProps) => {
    const { t } = useTranslation()
    useRequiredUserRelations([{ requiredType: "CallCenter" }])
    return (
        <PageWrapper>
            <PageContainer roundPageLook centerContent topBottomMargin noHeader>
                <PageTitle title={t("sendoffer.title")} />
                <SalesSendOfferForm />
                <FormFooter />
            </PageContainer>
        </PageWrapper>
    )
}

export default SalesSendOfferPage
