import React from "react"
import "./MerchantDashboardCellTitle.scss"
import cn from "classnames"
import MerchantDashboardCompareDiff from "./MerchantDashboardCompareDiff"

interface IMerchantDashboardCellTitleProps {
    title: string
    icon?: string
    subtitle: string
    value?: number
    compareValue?: number
    diffValuePoints?: boolean
    invertPercentColors?: boolean
    isDisabled?: boolean
}

const MerchantDashboardCellTitle = ({
    title,
    icon,
    subtitle,
    value,
    compareValue,
    diffValuePoints,
    invertPercentColors,
    isDisabled,
}: IMerchantDashboardCellTitleProps) => {
    return (
        <div className={cn("merchant-dashboard-cell-title", { disabled: isDisabled })}>
            <div className="title">
                {title}
                {value !== undefined && compareValue !== undefined && (
                    <div className="data-compare">
                        <MerchantDashboardCompareDiff
                            isInverted={invertPercentColors}
                            diffValuePoints={diffValuePoints}
                            isLarge
                            value={value}
                            compareValue={compareValue}
                        />
                    </div>
                )}
                {icon && (
                    <div className="title-icon">
                        <img src={icon} alt={title} />
                    </div>
                )}
            </div>
            <div className="subtitle">{subtitle}</div>
        </div>
    )
}

export default MerchantDashboardCellTitle
