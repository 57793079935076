import React from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    DropDownList,
    FieldsetFormGroup,
    FormGroupWithCheckbox,
    TitleWithAbsoluteHint,
    getAvailablePlatforms,
    getDomainValidationPattern,
    sharedConfigurationSelectors,
    useSelector,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../../store/reducers/partnerReducer"
import { addWebshopThunk, deleteWebshopThunk, editWebshopAsMasterPartnerThunk } from "../../../store/thunks/merchantThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor, { TSetShowSpinnerFunc } from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import EditFormInput from "../input/EditFormInput"
import { removeSchemeFromHostName } from "./../../../services/merchantService"
import { AddWebshopFormModel } from "./../../../type/AddWebshopFormModel"
import InfoBoxAdder from "./../../infobox/InfoBoxAdder"
import "./MerchantWebshopsForm.scss"

interface IMerchantWebshopsFormProps {
    webshopIdInEditMode?: string
    onSwitchEditMode: (id: string, isInEditMode: boolean) => void
}

interface IWebshopEditForm {
    hostname: string
    mcc?: string
    isDisabled?: boolean
}

const MerchantWebshopsForm = ({ webshopIdInEditMode, onSwitchEditMode }: IMerchantWebshopsFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)
    if (!merchantDetails) {
        return null
    }
    const webshops = merchantDetails.webshops || []

    const mccHint = (
        <span>
            Click{" "}
            <a target="_blank" rel="noreferrer" href="https://www.web-payment-software.com/online-merchant-accounts/mcc-codes/">
                here
            </a>{" "}
            to see all the MCC codes
        </span>
    )

    return (
        <InfoBoxContainer isInEditMode={!!webshopIdInEditMode} iconClass="icon-webshop" title={t("personal.webshops")}>
            {webshops.map((webshop) => (
                <InfoBoxEditor<IWebshopEditForm>
                    key={webshop.webshopId}
                    state={
                        hasMasterPartner ? InfoBoxState.DEFAULT : webshop.isDisabled ? InfoBoxState.DISABLED : InfoBoxState.NOEDIT
                    }
                    iconClass="icon-webshop"
                    title={t("personal.editWebshop")}
                    isInEditMode={webshopIdInEditMode === webshop.webshopId}
                    editBtnTextType="editing"
                    renderPreview={() => (
                        <div>
                            {env === "Production" ? removeSchemeFromHostName(webshop.hostName) : webshop.hostName}
                            <br />
                            {webshop.platform}
                            {hasMasterPartner && webshop.mcc && (
                                <>
                                    <br />
                                    MCC: {webshop.mcc}
                                </>
                            )}
                            {hasMasterPartner && webshop.isDisabled && (
                                <>
                                    <br />
                                    <TitleWithAbsoluteHint
                                        className="merchant-webshops-form__is-disabled-preview"
                                        titleClassName="merchant-webshops-form__is-disabled-preview-title"
                                        hintBoxWhite
                                        hintIconRight
                                        title="Is disabled"
                                        hintText="Disabling the webshop will block new payments. Voids, captures and refunds will still be allowed. A disabled webshop will not be billed monthly. Billing for captures still applies."
                                    />
                                </>
                            )}
                        </div>
                    )}
                    renderFormFields={(register) => (
                        <div>
                            <Row>
                                <div className="col-12">
                                    <EditFormInput
                                        name="hostname"
                                        placeholder={t("common.hostname.label")}
                                        defaultValue={webshop.hostName}
                                        innerRef={register(
                                            valFuncRequiredAndPattern(
                                                getDomainValidationPattern(env),
                                                t("personal.validationerrors.domain")
                                            )
                                        )}
                                        autoCompleteOff
                                    />
                                </div>
                            </Row>
                            {hasMasterPartner && (
                                <Row>
                                    <div className="col-12">
                                        <EditFormInput
                                            name="mcc"
                                            placeholder={t("common.mcc.label")}
                                            defaultValue={webshop.mcc}
                                            innerRef={register(
                                                valFuncPattern(validationPatterns.mccNumber, t("common.mcc.errorMessage"))
                                            )}
                                            autoCompleteOff
                                            bottomHint={mccHint}
                                        />
                                    </div>
                                </Row>
                            )}
                            {hasMasterPartner && (
                                <Row>
                                    <div className="col-12">
                                        <FormGroupWithCheckbox
                                            name="isDisabled"
                                            title={t("Is disabled")}
                                            defaultChecked={webshop.isDisabled}
                                            innerRef={register()}
                                            hint="Disabling the webshop will block new payments. Voids, captures and refunds will still be allowed. A disabled webshop will not be billed monthly. Billing for captures still applies."
                                        />
                                    </div>
                                </Row>
                            )}
                        </div>
                    )}
                    onSave={async (model) => {
                        await dispatch(
                            editWebshopAsMasterPartnerThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                webshop.webshopId,
                                model.hostname,
                                model.mcc,
                                model.isDisabled
                            )
                        )
                    }}
                    allowDelete={false}
                    onDelete={async (setShowSpinner: TSetShowSpinnerFunc) => {
                        setShowSpinner(true)

                        const fallbackWebshopId =
                            merchantDetails.webshops.find((w) => w.webshopId !== webshop.webshopId)?.webshopId || ""
                        await dispatch(
                            deleteWebshopThunk(
                                webshop.webshopId,
                                webshop.clientId,
                                setShowSpinner,
                                merchantDetails.swMerchant.swMerchantId,
                                fallbackWebshopId
                            )
                        )

                        setShowSpinner(false)
                    }}
                    onSwitchEditMode={(isInEditMode) => onSwitchEditMode(webshop.webshopId, isInEditMode)}
                />
            ))}
            <InfoBoxAdder<AddWebshopFormModel>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-webshop"
                title={t("personal.addWebshop")}
                adderLabel={t("personal.addwebsite")}
                isInEditMode={webshopIdInEditMode === "-1"}
                onSwitchEditMode={(isInEditMode) => onSwitchEditMode("-1", isInEditMode)}
                isFirstItem={webshops.length === 0}
                renderFirstItemPreview={() => (
                    <div>
                        <strong>{t("personal.addWebshop")}</strong>
                    </div>
                )}
                renderFormFields={(register) => (
                    <div className="edit-form">
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    name="hostname"
                                    placeholder={t("common.hostname.label")}
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            getDomainValidationPattern(env),
                                            t("personal.validationerrors.domain")
                                        )
                                    )}
                                    bottomHint={t(
                                        env === "Production"
                                            ? "common.hostname.domainCheckHintProd"
                                            : "common.hostname.domainCheckHintNonProd"
                                    )}
                                    disableHintAfter={5}
                                    autoCompleteOff
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <FieldsetFormGroup field>
                                    <DropDownList
                                        innerRef={register({
                                            required: true,
                                        })}
                                        name="platform"
                                        options={getAvailablePlatforms()}
                                        defaultValue={merchantDetails?.webshops.find((w) => w.platform)?.platform}
                                    />
                                </FieldsetFormGroup>
                            </div>
                        </Row>
                        {hasMasterPartner && (
                            <Row>
                                <div className="col-12">
                                    <EditFormInput
                                        name="mcc"
                                        placeholder={t("common.mcc.label")}
                                        innerRef={register(
                                            valFuncPattern(validationPatterns.mccNumber, t("common.mcc.errorMessage"))
                                        )}
                                        bottomHint={mccHint}
                                        disableHintAfter={5}
                                        autoCompleteOff
                                    />
                                </div>
                            </Row>
                        )}
                    </div>
                )}
                onSave={async (model) => {
                    await dispatch(addWebshopThunk(model))
                }}
            />
        </InfoBoxContainer>
    )
}

export default MerchantWebshopsForm
