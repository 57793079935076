import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, StandardButtonWithSpinner } from "swiipe.portal.shared"
import SendEmailButtonImage from "../../images/buttons/send-email-en.png"
import { getPreferredLanguage } from "../../services/languageService"
import { userSignupThunk } from "../../store/thunks/userThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./ModalConfirmAccountFirst.scss"

export interface IModalConfirmAccountFirst extends IModalBase {
    type: "loginConfirmEmail"
    errorMessage: string
    sendToEmail: string
}

const ModalConfirmAccountFirst = ({
    title,
    errorMessage,
    sendToEmail,
    callback,
    ...otherProps
}: IModalConfirmAccountFirst & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    return (
        <BaseModal
            {...otherProps}
            callback={callback}
            centered
            title={title || t("confirmaccount.title")}
            noBordersInside
            showCloseButton
        >
            <ModalBody>
                <div>
                    <p className="mb-0 text-center errormessage-text mb-2">{errorMessage}</p>
                    <StandardButtonWithSpinner
                        className="swiipe-btn send-email"
                        onClick={async () => {
                            await dispatch(
                                userSignupThunk({
                                    email: sendToEmail,
                                    //Country and language will be ignored if user already exists and email confirmed
                                    countryCode: "DK",
                                    language: getPreferredLanguage(),
                                })
                            )
                            callback("ignored")
                        }}
                    >
                        <img className="send-email-img" src={SendEmailButtonImage} />
                    </StandardButtonWithSpinner>
                    <p className="footer-text mb-0 mt-5">{t("modalconfirmaccount.footer")}</p>
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalConfirmAccountFirst
