import cn from "classnames"
import React from "react"
import "./TitleSection.scss"

interface ITitleSectionProps {
    title: string
    isSmaller?: boolean
    center?: boolean
    className?: string
}

const TitleSection = ({ title, isSmaller, center, className }: ITitleSectionProps) => {
    return (
        <div className={cn("title-section", className, { smaller: isSmaller, center })}>
            <p className="">{title}</p>
        </div>
    )
}

export default TitleSection
