import React, { useEffect, useState } from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import { CompletedFieldsReview, FieldsetFormGroup, FloatingLabelInput, TSetError, delay } from "swiipe.portal.shared"
import { IClearhausApplicationData } from "../../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm } from "../../../../type/clearhaus/IClearhausApplicationForm"
import { TClearhausFormState } from "../../../../type/clearhaus/TClearhausFormState"
import { UploadedFilesOverviewText } from "../../../overview/UploadedFilesOverview"
import FileInput, { megabyteInBytes } from "../../input/FileInput"

interface IAdditionalFields {
    control: Control<IClearhausApplicationForm>
    clearhausFormState: TClearhausFormState
    applicationData?: IClearhausApplicationData
    setError: TSetError<any>
    clearErrors: (name: string) => void
    saveJustTriggered: boolean
}

const ClearhausAdditionalFields = ({
    control,
    clearhausFormState,
    applicationData,
    setError,
    clearErrors,
    saveJustTriggered,
}: IAdditionalFields) => {
    const { t } = useTranslation()
    const savedFields = applicationData?.applicationFields

    const [showFilesWarning, setShowFilesWarning] = useState(false)
    useEffect(() => {
        if (!saveJustTriggered) {
            return
        }
        if (!control.getValues().additionalInfo?.files.length) {
            return
        }
        setShowFilesWarning(true)
        delay(15 * 1000).then(() => setShowFilesWarning(false))
    }, [saveJustTriggered])

    return (
        <>
            {clearhausFormState === "submitted" && (
                <CompletedFieldsReview>
                    {savedFields?.additionalInfo?.comments}
                    <UploadedFilesOverviewText fileNames={savedFields?.additionalInfo?.uploadedFiles?.map((file) => file.name)} />
                </CompletedFieldsReview>
            )}

            {clearhausFormState !== "submitted" && (
                <>
                    <span className="text-normal">
                        {t("clearhausonboarding.messagetitles.additionalinfo.title")}
                        <ul>
                            <li>{t("clearhausonboarding.messagetitles.additionalinfo.1")}</li>
                            <li>{t("clearhausonboarding.messagetitles.additionalinfo.2")}</li>
                            <li>{t("clearhausonboarding.messagetitles.additionalinfo.3")}</li>
                            <li>{t("clearhausonboarding.messagetitles.additionalinfo.4")}</li>
                        </ul>
                    </span>
                    <FieldsetFormGroup field>
                        <FloatingLabelInput
                            name="additionalInfo.comments"
                            type="textarea"
                            placeholder={t("clearhausonboarding.inputfields.comments")}
                            innerRef={control.register}
                        ></FloatingLabelInput>
                    </FieldsetFormGroup>
                    <FileInput
                        name="additionalInfo.files"
                        maxFilesAmount={5}
                        maxFileSizeBytes={megabyteInBytes * 10}
                        triggerValidation={control.trigger}
                        clearError={clearErrors}
                        setError={setError}
                        setValue={control.setValue}
                        getValues={control.getValues}
                        accept="*"
                        multiple
                        register={control.register}
                    />
                    <UploadedFilesOverviewText fileNames={savedFields?.additionalInfo?.uploadedFiles?.map((file) => file.name)} />
                    {showFilesWarning && (
                        <Alert color="warning">{t("clearhausonboarding.warnings.documentsAreNotGettingSaved")}</Alert>
                    )}
                </>
            )}
        </>
    )
}

export default ClearhausAdditionalFields
