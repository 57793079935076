import React from "react"
import { Row } from "reactstrap"
import { FieldsetFormGroup } from "swiipe.portal.shared"

interface IContentRightButtonContainerProps {
    button: React.ReactElement
    children?: React.ReactNode
}

const ContentRightButtonContainer = ({ children, button }: IContentRightButtonContainerProps) => {
    return (
        <Row className="align-items-end">
            <div className="col-md-9 col-sm-12 pr-md-0">{children}</div>
            <div className="col-md-3">
                <FieldsetFormGroup>{button}</FieldsetFormGroup>
            </div>
        </Row>
    )
}

export default ContentRightButtonContainer
