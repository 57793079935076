import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import "./ModalContactUs.scss"

export interface IModalContactUs extends IModalBase {
    type: "contactUs"
    errorMessage: string
}

const ModalContactUs = ({ title, errorMessage, callback, ...otherProps }: IModalContactUs & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal
            {...otherProps}
            modalClassName="modal-contact-us"
            callback={callback}
            centered
            title={title || t("common.error_title")}
        >
            <ModalBody>
                <div>
                    <p className="mb-0 text-center errormessage-text">{errorMessage}</p>
                </div>
            </ModalBody>
            <ModalFooter
                leftButton={{ onClick: async () => callback("declined"), text: t("common.close"), isInvertedColor: true }}
                rightButton={{ onClick: async () => callback("accepted"), text: t("common.contactUs") }}
                wideButtons={true}
            />
        </BaseModal>
    )
}

export default ModalContactUs
