import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton } from "swiipe.portal.shared"
import { navigationService } from "../../../../services/navigationService"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import MerchantDashboardValueWithGraph from "../../tiles/MerchantDashboardTileValueWithGraph"

interface IDashboardRevenueDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
}

const DashboardRevenueDisplay = ({ dashboardData, paymentsEnabled }: IDashboardRevenueDisplayProps) => {
    const { t } = useTranslation()

    const revenue = {
        main: dashboardData.mainData.revenue,
        compare: dashboardData.compareData?.revenue,
    }

    return (
        <MerchantDashboardValueWithGraph
            title={t("merchantDashboard.revenueTitle")}
            subtitle={t("merchantDashboard.viaSwiipe")}
            value={{ amount: revenue.main.amountTotal, currency: dashboardData.currency }}
            valueFontSize="bigger"
            compareValue={revenue.compare && { amount: revenue.compare.amountTotal, currency: dashboardData.currency }}
            values={revenue.main.amountPerDay}
            notEnabled={!paymentsEnabled}
            notEnabledContent={
                <div className="active-payments">
                    <StandardButton
                        title={t("merchantDashboard.activateSwiipePayments")}
                        isSmall
                        onClick={() => navigationService.navigate("/swiipepayments_onboard")}
                    />
                </div>
            }
        />
    )
}

export default DashboardRevenueDisplay
