import cn from "classnames"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, FormGroup } from "reactstrap"
import { CountryInputComp, FieldsetFormGroup, ShowErrorMessages } from "swiipe.portal.shared"
import { getPreferredLanguage } from "../../../services/languageService"
import { userSignupThunk } from "../../../store/thunks/userThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { UserSignupFormModel } from "../../../type/userSignupFormModel"
import LinkButton from "../../buttons/LinkButton"
import EmailInputComp from "../input/EmailInputComp/EmailInputComp"
import SubmitButton from "./../../buttons/SubmitButton"
import GeneralTermsAndPrivacyLinks from "./../../legal/LegalTextWithLinks"
import "./UserSignupForm.scss"

interface IUserSignupFormProps {
    onSignedUp: (email: string, country: string) => void
    isTrimmed: boolean
}

const UserSignupForm = ({ onSignedUp, isTrimmed }: IUserSignupFormProps) => {
    const { handleSubmit, register, formState } = useForm<UserSignupFormModel>()
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<UserSignupFormModel> = async (data) => {
        try {
            data.language = getPreferredLanguage()

            await dispatch(userSignupThunk(data))
            onSignedUp(data.email, data.countryCode)
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldsetFormGroup field>
                <EmailInputComp register={register} inputName={"email"} />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <CountryInputComp defaultValue={"DK"} inputName="countryCode" register={register} />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            <FieldsetFormGroup>
                <SubmitButton formState={formState} id="signup-btn" isSignUp />
            </FieldsetFormGroup>
            <FieldsetFormGroup>
                <GeneralTermsAndPrivacyLinks inForm userRelationType="User" />
            </FieldsetFormGroup>
            <FormGroup className={cn("mt-4 text-center")}>
                <p className="mb-1">{t("signup.alreadyhaveaccount")}</p>
                <LinkButton href="/login" title={t("signup.login")} />
            </FormGroup>
            <FormGroup className={cn("text-center", { "d-none": isTrimmed })}>
                <LinkButton textOnly href="/merchant_signup" title={t("login.signupmerchant")} />
            </FormGroup>
        </Form>
    )
}

export default UserSignupForm
