import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { Form, Row } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    PhoneNumberInput,
    ShowErrorMessages,
    getCurrentCurrencyTitle,
    valFuncRequired,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { ISalesSendOfferFormModel } from "../../../type/sales/ISalesSendOfferFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import FullNameInputComp from "../input/FullNameInputComp/FullNameInputComp"
import FormSectionTitle from "../title/FormSectionTitle"
import { checkUserEmailDataThunk, sendOfferThunk } from "./../../../store/thunks/salesOfferThunks"
import { ISalesUserDataModel } from "./../../../type/sales/ISalesUserDataModel"
import "./SalesSendOfferForm.scss"

interface ISalesSendOfferFormProps {}

const useKeepSwiipeValuesAcrossForms = (
    showSwiipeBox: boolean,
    foundUser: ISalesUserDataModel | undefined,
    getFormValues: () => ISalesSendOfferFormModel,
    setFormValue: (key: keyof ISalesSendOfferFormModel, value: any) => void
) => {
    const [tempForm, setTempForm] = useState<ISalesSendOfferFormModel>({
        productName: "",
        amount: 0,
        email: "",
        phone: "",
        fullName: "",
        textMessage: "",
    })
    const tempFormRef = useRef<ISalesSendOfferFormModel>()
    tempFormRef.current = tempForm

    useEffect(() => {
        const tf = tempFormRef.current || ({} as any)
        if (showSwiipeBox) {
            setFormValue("swiipePhone", tf.phone)
            setFormValue("swiipeFullName", tf.fullName)
            setFormValue("swiipeTextMessage", tf.textMessage)
        } else {
            setFormValue("phone", tf.phone)
            setFormValue("fullName", tf.fullName)
            setFormValue("textMessage", tf.textMessage)
        }
    }, [showSwiipeBox])

    return {
        onWillShowSwiipeBox: () => {
            const values = getFormValues()
            tempFormRef.current = values
            setTempForm(values)
        },
        onWillHideSwiipeBox: () => {
            let values = getFormValues()
            values = {
                ...values,
                phone:
                    foundUser && !foundUser.isPhoneSet
                        ? values.swiipePhone
                        : tempFormRef.current
                        ? tempFormRef.current.phone
                        : "",
                fullName:
                    foundUser && !foundUser.isNameSet
                        ? values.swiipeFullName
                        : tempFormRef.current
                        ? tempFormRef.current.fullName
                        : "",
                textMessage: values.swiipeTextMessage,
            }
            tempFormRef.current = values
            setTempForm(values)
        },
        defaultValues: tempForm,
    }
}

const SalesSendOfferForm = ({}: ISalesSendOfferFormProps) => {
    const { handleSubmit, register, formState, getValues, setValue, trigger, reset, watch } = useForm<ISalesSendOfferFormModel>()
    const { t } = useTranslation()

    const [showSwiipeBox, setShowSwiipeBox] = useState(false)
    const [triedToSubmit, setTriedToSubmit] = useState(false)
    const [foundUser, setFoundUser] = useState<ISalesUserDataModel | undefined>()
    const [animateSwiipeBoxIn, setanimateSwiipeBoxIn] = useState(false)
    const { onWillHideSwiipeBox, onWillShowSwiipeBox, defaultValues } = useKeepSwiipeValuesAcrossForms(
        showSwiipeBox,
        foundUser,
        () => getValues(),
        (key, value) => setValue(key, value)
    )
    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<ISalesSendOfferFormModel> = async (data, e) => {
        try {
            await dispatch(sendOfferThunk(data, showSwiipeBox ? foundUser : undefined))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    const onUserExistsFromEmailChanged = (newFoundUser?: ISalesUserDataModel) => {
        if (foundUser && !newFoundUser) {
            onWillHideSwiipeBox()
            setFoundUser(undefined)
            setanimateSwiipeBoxIn(false)
            setTimeout(() => {
                setShowSwiipeBox(false)
            }, 500)
            return
        }
        // Found a new user
        if (
            (!foundUser && newFoundUser) ||
            (foundUser &&
                newFoundUser &&
                (foundUser.isNameSet !== newFoundUser.isNameSet || foundUser.isPhoneSet !== newFoundUser.isPhoneSet))
        ) {
            setFoundUser({ isNameSet: newFoundUser.isNameSet, isPhoneSet: newFoundUser.isPhoneSet })
            onWillShowSwiipeBox()
            setShowSwiipeBox(!showSwiipeBox)
        }
    }

    const emailValidation = valFuncRequiredAndPattern(validationPatterns.email, t("sendoffer.validationerrors.email"))
    const onEmailChanged = async (email: string) => {
        if (!!email && emailValidation.validate(email) === true) {
            try {
                const user = await dispatch(checkUserEmailDataThunk(email))
                onUserExistsFromEmailChanged(user)
            } catch (err) {
                // Not found
                onUserExistsFromEmailChanged()
            }
        } else {
            onUserExistsFromEmailChanged()
        }
    }

    useEffect(() => {
        if (showSwiipeBox) {
            setanimateSwiipeBoxIn(true)
        }
        setTriedToSubmit(false)
        reset()
    }, [showSwiipeBox])

    useEffect(() => {
        register(
            { name: "amount" },
            {
                min: { value: 0.01, message: t("sendoffer.validationerrors.amount") },
                ...valFuncRequired(t("sendoffer.validationerrors.amount")),
            }
        )
    }, [])

    const phone = (
        <FieldsetFormGroup
            className={cn("mt-3", !showSwiipeBox && "mt-sm-0")}
            field
            col2={!showSwiipeBox}
            second={!showSwiipeBox}
            narrowX
        >
            <PhoneNumberInput<ISalesSendOfferFormModel>
                inputName={showSwiipeBox ? "swiipePhone" : "phone"}
                defaultValue={defaultValues.phone}
                register={register}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
            />
        </FieldsetFormGroup>
    )

    const fullName = (
        <FieldsetFormGroup field>
            <FullNameInputComp
                inputName={showSwiipeBox ? "swiipeFullName" : "fullName"}
                defaultValue={defaultValues.fullName}
                register={register}
            />
        </FieldsetFormGroup>
    )
    const message = (
        <FieldsetFormGroup field>
            <FloatingLabelInput
                defaultValue={defaultValues.textMessage}
                name={showSwiipeBox ? "swiipeTextMessage" : "textMessage"}
                type="textarea"
                innerRef={register(valFuncRequired(t("sendoffer.validationerrors.message")))}
                placeholder={t("sendoffer.placeholders.message")}
            />
        </FieldsetFormGroup>
    )

    const submitButton = (
        <FieldsetFormGroup>
            <SubmitButton isLarge formState={formState} title={t("sendoffer.buttonText")} />
        </FieldsetFormGroup>
    )

    const createSwiipeBox = (foundUser: ISalesUserDataModel) => {
        return (
            <div className={cn("form-row justify-content-center pt-3 expandable px-1", animateSwiipeBoxIn && "expand-show")}>
                <div className="alert alert-info w-100 fade show arrow-box">
                    <small>{t("sendoffer.foundCustomer")}</small>
                    {!foundUser.isPhoneSet && phone}
                    {!foundUser.isNameSet && fullName}
                    {message}
                    {submitButton}
                    <div className="w-100 text-right">
                        <a
                            className="manual-option"
                            href=""
                            onClick={(e) => {
                                e.preventDefault()
                                onUserExistsFromEmailChanged()
                            }}
                        >
                            <small>
                                <u className="blue-logo">{t("sendoffer.typeManualLink")}</u>
                            </small>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    const submitFunc = handleSubmit(onSubmit)

    return (
        <Form
            id="sendoffer"
            onSubmit={(e) => {
                if (!triedToSubmit) {
                    setTriedToSubmit(true)
                }
                submitFunc(e)
            }}
        >
            <FormSectionTitle title={t("sendoffer.productSectionTitle")} />
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    defaultValue={defaultValues.productName}
                    name="productName"
                    innerRef={register(valFuncRequired(t("sendoffer.validationerrors.productName")))}
                    placeholder={t("sendoffer.placeholders.productName")}
                />
            </FieldsetFormGroup>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="amount"
                    tag={NumericFormat}
                    decimalScale={2}
                    thousandSeparator={" "}
                    fixedDecimalScale={true}
                    onValueChange={(e: any) => {
                        setValue("amount", e.floatValue)
                        if (triedToSubmit) {
                            trigger("amount")
                        }
                    }}
                    placeholder={t("sendoffer.placeholders.amount")}
                    extraLabel={getCurrentCurrencyTitle()}
                    className="amount-input"
                />
            </FieldsetFormGroup>
            <FormSectionTitle title={t("sendoffer.customerSectionTitle")} />
            <Row className={cn(showSwiipeBox && "d-block mx-0")}>
                <FieldsetFormGroup className="mb-0" field col2={!showSwiipeBox} narrowX first={!showSwiipeBox}>
                    <FloatingLabelInput
                        defaultValue={defaultValues.email}
                        name="email"
                        onChange={async (e) => {
                            onEmailChanged(e.target.value)
                        }}
                        innerRef={register(emailValidation)}
                        placeholder={t("sendoffer.placeholders.email")}
                    />
                </FieldsetFormGroup>
                {!showSwiipeBox && phone}
            </Row>
            {showSwiipeBox && foundUser && createSwiipeBox(foundUser)}
            {!showSwiipeBox && (
                <>
                    {fullName}
                    {message}
                </>
            )}
            <ShowErrorMessages formState={formState} />
            {!showSwiipeBox && submitButton}
        </Form>
    )
}

export default SalesSendOfferForm
