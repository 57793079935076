import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getDistinct, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { gatewayProvidersHandledByPaymentsApiConfig } from "../../type/IProvider"
import { runInQueueExecuteFirstOnly } from "../../util/queueUtils"
import { StoreState } from "../StoreState"
import { IGatewayDto, gatewayReducerActions, gatewaySelectors } from "../reducers/gatewayReducer"
import { merchantServicesReducerActions } from "../reducers/merchantServicesReducer"

export const getGatewaysForMerchantThunk =
    (
        merchantId: string,
        force: boolean,
        includeConfiguration?: boolean
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const gateways = gatewaySelectors.getGatewaysForMerchant(getState(), merchantId)

        const foundNoGateways = gateways && gateways.length === 0
        const hasConfigurations = foundNoGateways || (!!gateways?.find((g) => g.configuration) ?? false)
        const needsToGetGateways = !gateways || force
        const needsToGetConfiguration = includeConfiguration && (force || !hasConfigurations)

        if (!needsToGetGateways && !needsToGetConfiguration) {
            return
        }

        await runInQueueExecuteFirstOnly("getAllGateways_" + merchantId + "_" + (includeConfiguration ?? false), async () => {
            const response = await dispatch(
                requestThunk<{
                    gateways: (IGatewayDto & { configuration?: any })[]
                }>(endpoints.Payments.getAllGateways, {
                    params: {
                        swMerchantId: merchantId,
                        includeConfiguration: includeConfiguration ?? false,
                    },
                })
            )

            // Ensure we do not remove configurations, when updating other gateway data
            const oldGateways = gatewaySelectors.getGatewaysForMerchant(getState(), merchantId) ?? []
            response.gateways?.forEach((newG) => {
                newG.configuration = includeConfiguration
                    ? newG.configuration ?? {}
                    : oldGateways.find((g) => g.gatewayId === newG.gatewayId)?.configuration
            })

            dispatch(gatewayReducerActions.setGatewaysForMerchant(response.gateways ?? [], merchantId))

            await dispatch(updateGatewayProvidersForPaymentTypesHandledByPaymentsApi(merchantId, response.gateways ?? []))
        })
    }

const updateGatewayProvidersForPaymentTypesHandledByPaymentsApi =
    (merchantId: string, gateways: IGatewayDto[]): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const webshopIds = getDistinct(gateways, (g) => g.webshopId).map((g) => g.webshopId)
        const updateMerchantServicesTasks = webshopIds.map(async (wid) => {
            const gatewaysToUpdate = gateways.filter(
                (g) =>
                    g.webshopId === wid &&
                    gatewayProvidersHandledByPaymentsApiConfig.map((c) => c.paymentType).includes(g.paymentType)
            )
            const mappedGatewayProviders = gatewaysToUpdate.map((g) => ({
                paymentType: g.paymentType,
                providerName: gatewayProvidersHandledByPaymentsApiConfig.find((c) => c.paymentType === g.paymentType)!
                    .providerName,
                providerType: gatewayProvidersHandledByPaymentsApiConfig.find((c) => c.paymentType === g.paymentType)!
                    .providerType,
                enabled: g.enabled,
                status: g.onboardingStatus,
            }))
            await dispatch(merchantServicesReducerActions.setGatewayProviders(merchantId, wid, mappedGatewayProviders))
        })
        await Promise.all(updateMerchantServicesTasks)
    }
