import React from "react"
import { useTranslation } from "react-i18next"
import { StandardButton, roundNumberToMaxDecimals } from "swiipe.portal.shared"
import PlusSellIcon from "../../../../images/swFeatureServices/plussell-icon.png"
import { navigationService } from "../../../../services/navigationService"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import MerchantDashboardSimpleValues from "../../tiles/MerchantDashboardTileSimpleValues"

interface IDashboardPlusSellDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
    plusSellEnabled: boolean
}

const DashboardPlusSellDisplay = ({ dashboardData, paymentsEnabled, plusSellEnabled }: IDashboardPlusSellDisplayProps) => {
    const { t } = useTranslation()

    const plusSell = {
        main: dashboardData.mainData.plusSell,
        compare: dashboardData.compareData?.plusSell,
    }

    return (
        <MerchantDashboardSimpleValues
            title={t("merchantDashboard.plusSellTitle")}
            icon={PlusSellIcon}
            subtitle={t("merchantDashboard.viaSwiipe")}
            simpleValues={[
                { value: { amount: plusSell.main.count }, subtitle: t("merchantDashboard.plusSell.count") },
                {
                    value: {
                        amount:
                            plusSell.main.periodRootAmountTotal === 0
                                ? 0
                                : (plusSell.main.amountPlusSelledTotal / plusSell.main.periodRootAmountTotal) * 100,
                        isPercent: true,
                    },
                    subtitle: t("merchantDashboard.plusSell.avgOrderIncrease"),
                },
                {
                    value: {
                        amount: roundNumberToMaxDecimals(plusSell.main.amountPlusSelledTotal, 2),
                        currency: dashboardData.currency,
                    },
                    subtitle: t("merchantDashboard.plusSell.additionalSaleAmount"),
                },
            ]}
            compareValues={
                (plusSell.compare &&
                    plusSellEnabled && [
                        {
                            value: { amount: plusSell.compare.count },
                            subtitle: t("merchantDashboard.plusSell.count"),
                        },
                        {
                            value: {
                                amount:
                                    plusSell.compare.periodRootAmountTotal === 0
                                        ? 0
                                        : (plusSell.compare.amountPlusSelledTotal / plusSell.compare.periodRootAmountTotal) * 100,
                                isPercent: true,
                            },
                            subtitle: t("merchantDashboard.plusSell.avgOrderIncrease"),
                        },
                        {
                            value: {
                                amount: roundNumberToMaxDecimals(plusSell.compare.amountPlusSelledTotal, 2),
                                currency: dashboardData.currency,
                            },
                            subtitle: t("merchantDashboard.plusSell.additionalSaleAmount"),
                        },
                    ]) ||
                undefined
            }
            notEnabled={!paymentsEnabled}
            overlayContent={
                plusSellEnabled ? undefined : (
                    <div className="service-activation-container">
                        <StandardButton
                            isSmall
                            title={t("merchantDashboard.plusSell.activateBtn")}
                            onClick={() => navigationService.navigate("/plussell_onboard")}
                        />
                    </div>
                )
            }
        />
    )
}

export default DashboardPlusSellDisplay
