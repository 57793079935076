import cn from "classnames"
import React from "react"
import { getFormattedNumber } from "swiipe.portal.shared"
import { IMerchantDashboardValue } from "../../../type/merchantDashboard/IMerchantDashboardValue"
import MerchantDashboardCellContainer from "../MerchantDashboardCellContainer"
import MerchantDashboardCellTitle from "../MerchantDashboardCellTitle"
import MerchantDashboardSmallGraph from "../MerchantDashboardSmallGraph"
import MerchantDashboardNoData from "./MerchantDashboardTileNoData"
import "./MerchantDashboardTileValueWithGraph.scss"

interface IMerchantDashboardTileValueWithGraphProps {
    title: string
    subtitle: string
    value?: IMerchantDashboardValue
    valueFontSize: "bigger" | "smaller"
    compareValue?: IMerchantDashboardValue
    values?: number[]
    extraText?: string
    extraSubText?: string
    forceNegative?: boolean
    notEnabled?: boolean
    notEnabledContent?: React.ReactNode
}

const MerchantDashboardTileValueWithGraph = ({
    title,
    subtitle,
    value,
    valueFontSize,
    compareValue,
    values,
    extraText,
    extraSubText,
    forceNegative,
    notEnabled,
    notEnabledContent,
}: IMerchantDashboardTileValueWithGraphProps) => {
    if (notEnabled || (!value && (!values || values.length === 0))) {
        return (
            <MerchantDashboardNoData title={title} subtitle={subtitle} isSwiipePaymentsNotEnabled={notEnabled}>
                {notEnabled ? notEnabledContent : undefined}
            </MerchantDashboardNoData>
        )
    }
    const textColorClass = (value?.amount ?? 0) < 0 || forceNegative ? "negative" : "positive"
    return (
        <MerchantDashboardCellContainer className="merchant-dashboard-tile-value-graph" valuesDisabled={!value?.amount}>
            <MerchantDashboardCellTitle
                title={title}
                subtitle={subtitle}
                invertPercentColors={forceNegative}
                value={value?.amount}
                diffValuePoints={value?.isPercent}
                compareValue={compareValue?.amount}
            />
            {!!value && (
                <div className="valueRow">
                    <div className={cn("value", `value--${valueFontSize}`, textColorClass)}>
                        {getFormattedNumber(value.amount)}
                        {value.isPercent ? "%" : ""}
                    </div>
                    {value.currency && <div className={cn("currency", textColorClass)}>{value.currency}</div>}
                    {!!extraText && (
                        <div className="extra">
                            <div className="divider"></div>
                            <div>
                                <div
                                    className={cn(
                                        "extra-text",
                                        extraText.length >= 7 && "extra-text--smallest",
                                        forceNegative ? "negative" : "positive"
                                    )}
                                >
                                    {extraText}
                                </div>
                                {!!extraSubText && <div className="extra-subtext">{extraSubText}</div>}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {!!values && (
                <MerchantDashboardSmallGraph
                    className="merchant-dashboard-graph-container"
                    isNegative={forceNegative}
                    values={values}
                    sections={15}
                />
            )}
        </MerchantDashboardCellContainer>
    )
}

export default MerchantDashboardTileValueWithGraph
