import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DropDownList, hasValue } from "swiipe.portal.shared"
import { calculateComparePeriod } from "../../../../services/merchantDashboardService"
import { TMerchantDashboardComparePeriod } from "../../../../type/merchantDashboard/TMerchantDashboardComparePeriod"
import { addDays, addYears, getDateRangeStringified, getDaysBetween, getSameTimeLastMonth } from "../../../../util/dateUtil"
import DropDownWithChildren from "../../../dropdowns/DropDownWithChildren"
import DateRangePicker from "../../../form/input/date/DateRangePicker"
import FilterContainer from "./FilterContainer"
import "./MerchantDashboardDateFilters.scss"

export interface IMerchantDashboardSelectedDates {
    dates: { fromDateUTC: Date | undefined; toDateUTC: Date | undefined }
    comparePeriod: { fromDateUTC: Date | undefined; toDateUTC: Date | undefined }
}

interface IDashboardDateFiltersProps {
    onDatesUpdated: (selectedDates: IMerchantDashboardSelectedDates) => void
}

const MerchantDashboardDateFilters = ({ onDatesUpdated }: IDashboardDateFiltersProps) => {
    const { t } = useTranslation()

    const [showDatePicker, setShowDatePicker] = useState<"main" | "compare" | "none">("none")
    const [selectedDateRangeStr, setSelectedDateRangeStr] = useState<string | undefined>(undefined)
    const [compareWithType, setCompareWithType] = useState<TMerchantDashboardComparePeriod>("lastPeriod")

    const [fromDateUTC, setFromDateUTC] = useState<Date | undefined>(undefined)
    const [toDateUTC, setToDateUTC] = useState<Date | undefined>(undefined)

    const [fromDateCompareUTC, setFromDateCompareUTC] = useState<Date | undefined>(undefined)
    const [toDateCompareUTC, setToDateCompareUTC] = useState<Date | undefined>(undefined)

    const comparePeriod = calculateComparePeriod(compareWithType, fromDateUTC, toDateUTC, fromDateCompareUTC, toDateCompareUTC)
    const allowPeriodLastYear = fromDateUTC && toDateUTC && getDaysBetween(fromDateUTC, toDateUTC) <= 365

    useEffect(() => {
        onDatesUpdated({
            dates: {
                fromDateUTC,
                toDateUTC,
            },
            comparePeriod: {
                fromDateUTC: comparePeriod.compareFromUTC,
                toDateUTC: comparePeriod.compareToUTC,
            },
        })
    }, [
        fromDateUTC?.getTime(),
        toDateUTC?.getTime(),
        comparePeriod.compareFromUTC?.getTime(),
        comparePeriod.compareToUTC?.getTime(),
    ])

    const [showAllEnabled, setShowAllEnabled] = useState(false)

    return (
        <div className="merchant-dashboard-header-date-filters">
            <FilterContainer
                title={t("merchantDashboard.period")}
                body={
                    <DropDownWithChildren
                        placeholder={selectedDateRangeStr || ""}
                        active={showDatePicker === "main"}
                        setActiveState={(active) => setShowDatePicker(active ? "main" : "none")}
                        width={200}
                        boxWidth={264}
                        isSmall
                    >
                        <DateRangePicker
                            initialFromDate={getSameTimeLastMonth(addDays(new Date(), -1))}
                            initialToDate={addDays(new Date(), -1)}
                            minDate={addYears(new Date(), -10)}
                            maxDate={addDays(new Date(), -1)}
                            showSelectAllToggle
                            onDateRangeUpdated={(dateFromUTC, dateToUTC, rangeStringified, showAllEnabled) => {
                                setFromDateUTC(dateFromUTC!)
                                setToDateUTC(dateToUTC!)
                                setShowDatePicker("none")

                                setSelectedDateRangeStr(
                                    // if show all display only the second date
                                    showAllEnabled ? `- ${rangeStringified?.split("-")[1]}` : rangeStringified
                                )
                                setShowAllEnabled(!!showAllEnabled)
                                setCompareWithType(showAllEnabled ? "noPeriod" : "lastPeriod")
                            }}
                        />
                    </DropDownWithChildren>
                }
            />
            <FilterContainer
                html2CanvasIgnore
                title={t("merchantDashboard.compareWith")}
                value={getDateRangeStringified(comparePeriod.compareFrom, comparePeriod.compareTo)}
                body={
                    <DropDownWithChildren
                        placeholder=""
                        active={compareWithType === "customPeriod" && showDatePicker === "compare"}
                        setActiveState={() => {}}
                        dropDownList={
                            <DropDownList
                                disabled={showAllEnabled}
                                isSmall
                                className="compare-ddl"
                                options={[
                                    { text: t("merchantDashboard.compareTypes.previousPeriod"), value: "lastPeriod" },
                                    (allowPeriodLastYear && {
                                        text: t("merchantDashboard.compareTypes.samePeriodLastYear"),
                                        value: "periodLastYear",
                                    }) ||
                                        undefined,
                                    {
                                        text: t("merchantDashboard.compareTypes.customPeriod"),
                                        value: "customPeriod",
                                    },
                                    { text: t("merchantDashboard.compareTypes.none"), value: "noPeriod" },
                                ].filter(hasValue)}
                                value={
                                    !allowPeriodLastYear && compareWithType === "periodLastYear" ? "lastPeriod" : compareWithType
                                }
                                onChange={(e) => setCompareWithType(e.target.value as TMerchantDashboardComparePeriod)}
                                onClick={() => setShowDatePicker("compare")}
                            />
                        }
                        boxWidth={264}
                    >
                        <DateRangePicker
                            initialFromDate={
                                (fromDateUTC && toDateUTC && addDays(fromDateUTC, -getDaysBetween(fromDateUTC, toDateUTC))) ||
                                undefined
                            }
                            initialToDate={fromDateUTC}
                            minDate={addYears(new Date(), -10)}
                            maxDate={fromDateUTC}
                            onDateRangeUpdated={(dateFromUTC, dateToUTC) => {
                                setFromDateCompareUTC(dateFromUTC)
                                setToDateCompareUTC(dateToUTC)
                                setShowDatePicker("none")
                            }}
                        />
                    </DropDownWithChildren>
                }
            />
        </div>
    )
}

export default MerchantDashboardDateFilters
