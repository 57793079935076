import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert, Form, FormGroup, ModalBody } from "reactstrap"
import {
    BaseModal,
    DropDownList,
    FloatingLabelInput,
    IModalBase,
    IModalControlProps,
    PhoneNumberInput,
    ShowErrorMessages,
    TextButton,
    getExampleEmail,
    getExampleSms,
    scandinaviaOnlyCountryFilter,
    useSelector,
    valFuncPattern,
    valFuncRequired,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getLanguageByISO, getLanguagesList } from "../../services/languageService"
import { getPaymentLinkNotificationPreviewData, supportedLanguages } from "../../services/paymentLinkService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { sendPaymentLinkNotificationThunk } from "../../store/thunks/paymentLinkThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import SubmitButton from "../buttons/SubmitButton"
import InlineElementsWithDividers from "../layouts/InlineElementsWithDividers"
import "./ModalPaymentLinkSend.scss"

export interface IModalPaymentLinkSend extends IModalBase {
    type: "paymentLinkSend"
    merchantDetails: MixedMerchantDetails
    paymentLinkData: {
        webshopId: string
        orderId: string
        platformOrderId: string

        paymentLink: string

        customerLanguage: string
        customerEmail: string
        customerFirstName: string
        customerPhone?: string
    }
    enableSmsChannelByDefault?: boolean
    notificationSentCallback: () => void
}

interface IForm {
    language: string
    customerEmail: string
    customerFirstName: string
    customerPhone: string

    notificationType: string
}

const ModalPaymentLinkSend = ({
    merchantDetails,
    paymentLinkData,
    enableSmsChannelByDefault,
    notificationSentCallback,
    callback,
    ...otherProps
}: IModalPaymentLinkSend & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [editFields, setEditFields] = useState(false)
    const [phoneInputCorrect, setPhoneInputCorrect] = useState(false)
    const [channelSelected, setChannelSelected] = useState<"email" | "sms">("email")

    const webshopDetails = useSelector((state: StoreState) => merchantSelectors.webshopDetails(state, paymentLinkData.webshopId))

    const { handleSubmit, register, setValue, getValues, watch, formState } = useForm<IForm>()
    const onSubmit: SubmitHandler<IForm> = async (data) => {
        await dispatch(
            sendPaymentLinkNotificationThunk({
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                webshopId: paymentLinkData.webshopId,
                orderId: paymentLinkData.orderId,
                notificationType: channelSelected,
                language: data.language ?? paymentLinkData.customerLanguage,
                customerEmail: data.customerEmail ?? paymentLinkData.customerEmail,
                customerFirstName: data.customerFirstName ?? paymentLinkData.customerFirstName,
                customerPhone: data.customerPhone,
            })
        )
        notificationSentCallback()
    }

    useEffect(() => {
        if (!enableSmsChannelByDefault) {
            return
        }

        setChannelSelected("sms")
    }, [])

    useEffect(() => {
        if (!paymentLinkData.customerPhone || channelSelected === "email") {
            return
        }

        setValue("customerPhone", paymentLinkData.customerPhone)
    }, [channelSelected])

    useEffect(() => {
        const customerPhone = getValues()["customerPhone"]
        setPhoneInputCorrect(!customerPhone ? false : true)
    }, [watch("customerPhone")])

    return (
        <BaseModal
            {...otherProps}
            modalClassName="payment-link-send-modal"
            centered
            size="pl-custom"
            callback={callback}
            showCloseButton
            noBorderTop
        >
            <ModalBody>
                <p className="title">{t("paymentLinkSendModal.title")}</p>

                <div className="main-container">
                    {!editFields && (
                        <div className="customer-data-preview">
                            <div className="column">
                                <div className="left">
                                    {t("paymentLinkSendModal.language")}: {getLanguageByISO(paymentLinkData.customerLanguage)}
                                </div>
                                <div className="right"></div>
                            </div>
                            <div className="column">
                                <div className="left">
                                    <span>{paymentLinkData.customerEmail}</span> <br />
                                    <span>{paymentLinkData.customerFirstName}</span>
                                </div>
                                <div className="right">
                                    <TextButton
                                        text={t("paymentLinkSendModal.edit")}
                                        color="grey"
                                        textDecoration="underline"
                                        fontSize="smaller"
                                        onClick={async () => setEditFields(true)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {editFields && (
                            <div className="mb-5">
                                <FormGroup>
                                    <div className="field-section">
                                        <DropDownList
                                            name="language"
                                            options={getLanguagesList(supportedLanguages)}
                                            defaultValue={paymentLinkData.customerLanguage}
                                            innerRef={register(
                                                valFuncRequired(t("paymentLinkPage.validationErrors.customersLanguage"))
                                            )}
                                        />
                                    </div>
                                    <div className="hint-section"></div>
                                </FormGroup>
                                <FormGroup>
                                    <FloatingLabelInput
                                        name="customerEmail"
                                        innerRef={register(
                                            valFuncRequiredAndPattern(
                                                validationPatterns.email,
                                                t("paymentLinkPage.validationErrors.customerEmail")
                                            )
                                        )}
                                        defaultValue={paymentLinkData.customerEmail}
                                        placeholder={t("paymentLinkPage.placeholders.customerEmail")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FloatingLabelInput
                                        name="customerFirstName"
                                        innerRef={register(
                                            valFuncPattern(
                                                validationPatterns.firstName,
                                                t("paymentLinkPage.validationErrors.customerFirstName")
                                            )
                                        )}
                                        defaultValue={paymentLinkData.customerFirstName}
                                        placeholder={t("paymentLinkPage.placeholders.customerFirstName")}
                                    />
                                </FormGroup>
                            </div>
                        )}

                        <FormGroup>
                            <DropDownList
                                name="notificationType"
                                options={[
                                    { text: t("paymentLinkSendModal.send.viaEmail"), value: "email" },
                                    { text: t("paymentLinkSendModal.send.viaSms"), value: "sms" },
                                ]}
                                value={channelSelected}
                                onChange={(e) => setChannelSelected(e.target.value == "email" ? "email" : "sms")}
                                innerRef={register}
                            />
                        </FormGroup>
                        {channelSelected === "sms" && (
                            <FormGroup>
                                <PhoneNumberInput<IForm>
                                    inputName="customerPhone"
                                    placeholder={t("paymentLinkSendModal.placeholders.customerPhone")}
                                    customErrorMsg={t("paymentLinkSendModal.validationErrors.customerPhone")}
                                    preferredCountry={merchantDetails.swMerchant.countryCode}
                                    countryFilter={scandinaviaOnlyCountryFilter}
                                    register={register}
                                    getValues={getValues}
                                    setValue={setValue}
                                    watch={watch}
                                />
                            </FormGroup>
                        )}
                        <InlineElementsWithDividers
                            className="preview-buttons"
                            elements={[
                                <TextButton
                                    key={1}
                                    text={
                                        channelSelected === "email"
                                            ? t("paymentLinkSendModal.preview.email")
                                            : t("paymentLinkSendModal.preview.sms")
                                    }
                                    color="grey"
                                    textDecoration="underline"
                                    fontSize="smaller"
                                    onClick={async () => {
                                        const currentInputsValues = getValues()

                                        const customerName =
                                            currentInputsValues["customerFirstName"] ?? paymentLinkData.customerFirstName
                                        const selectedLang = currentInputsValues["language"] ?? paymentLinkData.customerLanguage
                                        const customizationsForSelectedLang =
                                            webshopDetails?.plNotificationCustomizations?.[selectedLang]

                                        //Show email preview
                                        if (channelSelected === "email") {
                                            const email = await dispatch(
                                                getExampleEmail(
                                                    merchantDetails.swMerchant.swMerchantId,
                                                    "PaymentLinkNotification",
                                                    selectedLang,
                                                    await getPaymentLinkNotificationPreviewData(
                                                        "email",
                                                        paymentLinkData.platformOrderId,
                                                        paymentLinkData.paymentLink,
                                                        paymentLinkData.webshopId,
                                                        merchantDetails,
                                                        webshopDetails,
                                                        customizationsForSelectedLang?.signedBy,
                                                        undefined,
                                                        customizationsForSelectedLang?.customHeader,
                                                        customizationsForSelectedLang?.customBody,
                                                        customerName
                                                    )
                                                )
                                            )

                                            await dispatch(
                                                addModalThunk({
                                                    type: "email",
                                                    title: email.subject,
                                                    body: email.body,
                                                })
                                            )

                                            return
                                        }

                                        //Show SMS preview
                                        const sms = await dispatch(
                                            getExampleSms(
                                                merchantDetails.swMerchant.swMerchantId,
                                                "PaymentLinkNotification",
                                                selectedLang,
                                                await getPaymentLinkNotificationPreviewData(
                                                    "sms",
                                                    paymentLinkData.platformOrderId,
                                                    paymentLinkData.paymentLink,
                                                    paymentLinkData.webshopId,
                                                    merchantDetails,
                                                    webshopDetails,
                                                    customizationsForSelectedLang?.signedBy
                                                )
                                            )
                                        )

                                        await dispatch(
                                            addModalThunk({
                                                type: "sms",
                                                body: sms.body,
                                            })
                                        )
                                    }}
                                />,
                                channelSelected === "email" ? (
                                    <TextButton
                                        key={2}
                                        text={t("paymentLinkSendModal.customize")}
                                        color="grey"
                                        textDecoration="underline"
                                        fontSize="smaller"
                                        onClick={async () => {
                                            callback("ignored")

                                            // Wait while customization happens
                                            await dispatch(
                                                addModalThunk({
                                                    type: "paymentLinkCustomize",
                                                    merchantDetails,
                                                    selectedWebshopId: paymentLinkData.webshopId,
                                                })
                                            )

                                            // Reopen this modal again
                                            // cause it was closed
                                            dispatch(
                                                addModalThunk({
                                                    type: "paymentLinkSend",
                                                    merchantDetails,
                                                    paymentLinkData,
                                                    notificationSentCallback,
                                                })
                                            )
                                        }}
                                    />
                                ) : (
                                    <></>
                                ),
                            ]}
                        />
                        <Alert color="warning" isOpen>
                            {t("paymentLinkSendModal.smsAlert.text")}{" "}
                            <TextButton
                                text={t("paymentLinkSendModal.smsAlert.readMore")}
                                display="inline-block"
                                textDecoration="underline"
                                disableSpinner
                                onClick={async () => {
                                    dispatch(
                                        addModalThunk({
                                            type: "terms",
                                            userRelationType: "Merchant",
                                            termsType: "TermsPSP",
                                        })
                                    )
                                }}
                            />
                        </Alert>

                        <ShowErrorMessages formState={formState} />
                        <SubmitButton
                            dark
                            noBorder
                            containerClass="submit-btn mt-5"
                            formState={formState}
                            disabled={channelSelected === "sms" && !phoneInputCorrect}
                        >
                            {t("paymentLinkSendModal.sendToCustomer")}
                        </SubmitButton>
                    </Form>

                    <TextButton
                        text={t("paymentLinkSendModal.goBack")}
                        className="mt-5 mb-4"
                        color="grey"
                        textDecoration="underline"
                        fontSize="smaller"
                        onClick={async () => callback("ignored")}
                    />
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalPaymentLinkSend
