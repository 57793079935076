import cn from "classnames"
import React, { useEffect, useState } from "react"
import { Control, FormState } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert } from "reactstrap"
import {
    CompletedFieldsReview,
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    TSetError,
    TextButton,
    TitleWithAbsoluteHint,
    delay,
    getCountryByISO,
    getKeys,
    valFuncRequired,
} from "swiipe.portal.shared"
import { getCompanyDocumentationFileUploadRequired } from "../../../../services/clearhausService"
import { IClearhausApplicationData } from "../../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm, IClearhausPersonFormFields } from "../../../../type/clearhaus/IClearhausApplicationForm"
import { TClearhausCompanyOwnershipType } from "../../../../type/clearhaus/TClearhausCompanyOwnershipType"
import { TClearhausCompanyType } from "../../../../type/clearhaus/TClearhausCompanyType"
import { TClearhausOnboardingFormFields } from "../../../../type/clearhaus/TClearhausFormFieldsType"
import { TClearhausFormState } from "../../../../type/clearhaus/TClearhausFormState"
import { TClearhausPersonType } from "../../../../type/clearhaus/TClearhausPersonType"
import { UploadedFilesOverviewText } from "../../../overview/UploadedFilesOverview"
import FileInput, { megabyteInBytes } from "../../input/FileInput"
import "./ClearhausDocumentationFields.scss"
import ClearhausPersonFields from "./ClearhausPersonFields"

interface IDocumentationFields extends IPeopleInputsProps {
    companyType?: TClearhausCompanyType
    clearhausFormState: TClearhausFormState
}

export const ClearhausDocumentationFields = ({
    companyType,
    ownershipType,
    ownersCountState,
    directorsCountState,
    control,
    clearhausFormState,
    formState,
    showErrors,
    applicationData,
    saveJustTriggered,
    clearErrors,
    setError,
}: IDocumentationFields) => {
    const { t } = useTranslation()
    const savedFields = applicationData?.applicationFields
    const showCompanyDocumentationFileUpload = getCompanyDocumentationFileUploadRequired(
        control.getValues().company?.country,
        companyType
    )

    const [showFilesWarning, setShowFilesWarning] = useState(false)
    useEffect(() => {
        if (!saveJustTriggered) {
            return
        }
        if (!control.getValues().documentation?.files.length) {
            return
        }
        setShowFilesWarning(true)
        delay(15 * 1000).then(() => setShowFilesWarning(false))
    }, [saveJustTriggered])

    return (
        <div className="clearhaus-documentation-fields">
            {clearhausFormState === "submitted" && (
                <>
                    {savedFields?.documentation?.ownershipStructure || savedFields?.documentation?.uploadedFiles?.length ? (
                        <div className="clearhaus-documentation-fields__submitted-review">
                            <CompletedFieldsReview>
                                {savedFields?.documentation.uploadedFiles && (
                                    <>
                                        {t("clearhausonboarding.sectiontitles.documentation")}:
                                        <UploadedFilesOverviewText
                                            fileNames={savedFields?.documentation.uploadedFiles.map((file) => file.name)}
                                        />
                                    </>
                                )}
                                {savedFields?.documentation.ownershipStructure}
                            </CompletedFieldsReview>
                        </div>
                    ) : (
                        <hr style={{ opacity: 0 }} />
                    )}

                    {getKeys(savedFields?.directors || {}).map((key, i) => {
                        return (
                            <PersonReview
                                key={key}
                                type="director"
                                isMain={i === 0}
                                person={savedFields?.directors[key]}
                                ownershipType={ownershipType}
                            />
                        )
                    })}
                    {getKeys(savedFields?.owners || {}).map((key, i) => {
                        return <PersonReview key={key} type="owner" person={savedFields?.owners?.[key]} />
                    })}
                </>
            )}

            {clearhausFormState === "second" && (
                <>
                    <TitleWithAbsoluteHint
                        className="clearhaus-documentation-fields__info-hint"
                        titleClassName="clearhaus-documentation-fields__hint-title"
                        title={t("acquiringOnboarding.common.whyDoWeNeedInfo")}
                        hintText={t("acquiringOnboarding.common.whyInfoDesc.documentation")}
                    />

                    {ownershipType === "Multiple directors" && (
                        <div className="clearhaus-documentation-fields__ownership-structure">
                            <FieldsetFormGroup field>
                                <FloatingLabelInput
                                    name="documentation.ownershipStructure"
                                    type="textarea"
                                    placeholder={t("clearhausonboarding.messagetitles.describecompanyownership")}
                                    innerRef={control.register(
                                        valFuncRequired(t("clearhausonboarding.messagetitles.explainstructure"))
                                    )}
                                />
                            </FieldsetFormGroup>
                        </div>
                    )}
                    {showCompanyDocumentationFileUpload && (
                        <>
                            <div className="clearhaus-documentation-fields__files">
                                <p
                                    className="text-normal"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            control.getValues().company?.country !== "DK"
                                                ? t("clearhausonboarding.messagetitles.describeDocumentationNonDenmark")
                                                : companyType == "IVS"
                                                ? t("clearhausonboarding.messagetitles.describesuretyship")
                                                : t("clearhausonboarding.messagetitles.describedocumentation"),
                                    }}
                                />
                                <FileInput
                                    name="documentation.files"
                                    accept="image/*"
                                    minFilesAmount={savedFields?.documentation?.uploadedFiles?.length ? 0 : 1}
                                    maxFilesAmount={5}
                                    maxFileSizeBytes={megabyteInBytes * 10}
                                    clearError={clearErrors}
                                    triggerValidation={control.trigger}
                                    setError={setError}
                                    setValue={control.setValue}
                                    getValues={control.getValues}
                                    register={control.register}
                                    requiredErrorMessage={t("fileupload.errormessages.documentation")}
                                    multiple
                                />
                                <UploadedFilesOverviewText
                                    fileNames={savedFields?.documentation?.uploadedFiles?.map((file) => file.name)}
                                />
                                {showFilesWarning && (
                                    <Alert color="warning">{t("clearhausonboarding.warnings.documentsAreNotGettingSaved")}</Alert>
                                )}
                            </div>
                            <ShowErrorMessages
                                formState={formState}
                                isHidden={!showErrors}
                                onlyForFieldNames={
                                    [
                                        "documentation.ownershipStructure",
                                        "documentation.files",
                                    ] satisfies TClearhausOnboardingFormFields[]
                                }
                            />
                            <hr />
                        </>
                    )}

                    <PeopleInputs
                        className={cn(
                            !showCompanyDocumentationFileUpload &&
                                "clearhaus-documentation-fields__people-inputs--with-margin-top"
                        )}
                        ownershipType={ownershipType}
                        ownersCountState={ownersCountState}
                        directorsCountState={directorsCountState}
                        applicationData={applicationData}
                        control={control}
                        clearErrors={clearErrors}
                        setError={setError}
                        formState={formState}
                        showErrors={showErrors}
                        saveJustTriggered={saveJustTriggered}
                    />
                </>
            )}
        </div>
    )
}

interface IPersonHeaderProps {
    type: TClearhausPersonType
    ownershipType?: TClearhausCompanyOwnershipType
    isMain?: boolean
    children?: React.ReactNode
}

const PersonHeader = ({ type, ownershipType, isMain, children }: IPersonHeaderProps) => {
    const { t } = useTranslation()
    const directorHeader = t("clearhausonboarding.sectiontitles.director")
    const ownerHeader = t("clearhausonboarding.sectiontitles.owner")

    const getPersonHeader = () => {
        if (ownershipType === "Director=Owner" && isMain) {
            return ownershipType === "Director=Owner" ? `${directorHeader} (${ownerHeader})` : directorHeader
        }

        switch (type) {
            case "director":
                return directorHeader
            case "owner":
                return ownerHeader
        }
    }

    return (
        <p className="person-header text-center mt-4 mb-3">
            {getPersonHeader()}
            {children}
        </p>
    )
}

interface IPersonReviewProps {
    person?: IClearhausPersonFormFields
    type: TClearhausPersonType
    ownershipType?: TClearhausCompanyOwnershipType
    isMain?: boolean
}
const PersonReview = ({ person, type, isMain, ownershipType }: IPersonReviewProps) => {
    if (!person) {
        return null
    }

    return (
        <div className="clearhaus-person-review">
            <div>
                <hr />
                <PersonHeader type={type} isMain={isMain} ownershipType={ownershipType} />
                <div className="clearhaus-person-review__fields">
                    <CompletedFieldsReview>
                        {person.name}
                        <br />
                        {person.personalNumber}
                        <br />
                        {getCountryByISO(person.country)}
                        <br />
                        {person.address}
                        <br />
                        {person.postCode}, {person.city}
                        <br />
                        <br />
                        <UploadedFilesOverviewText fileNames={person.uploadedFiles?.map((file) => file.name)} />
                    </CompletedFieldsReview>
                </div>
            </div>
        </div>
    )
}

interface IPeopleInputsProps {
    ownershipType?: TClearhausCompanyOwnershipType
    ownersCountState: [number, (ownersCount: number) => void]
    directorsCountState: [number, (directorsCount: number) => void]

    applicationData?: IClearhausApplicationData
    control: Control<IClearhausApplicationForm>
    clearErrors: (name: string) => void
    setError: TSetError<any>
    formState: FormState<IClearhausApplicationForm>
    showErrors: boolean
    className?: string
    saveJustTriggered: boolean
}
const PeopleInputs = ({
    ownershipType,
    ownersCountState,
    directorsCountState,
    applicationData,
    control,
    clearErrors,
    setError,
    formState,
    showErrors,
    className,
    saveJustTriggered,
}: IPeopleInputsProps) => {
    const { t } = useTranslation()

    const [ownersCount, setOwnersCount] = ownersCountState
    const [directorsCount, setDirectorsCount] = directorsCountState

    const savedFields = applicationData?.applicationFields

    useEffect(() => {
        if (!ownershipType) {
            return
        }

        if (ownershipType === "Director+Multiple owners" || ownershipType === "Director!=Owner") {
            if (ownersCount > 0) {
                return
            }
            setOwnersCount(1) //at least one owner
        }

        if (ownershipType === "Director=Owner" || ownershipType === "Multiple directors") {
            setOwnersCount(0) //no owners
        }
    }, [ownershipType])

    const addOrRemovePerson = (directorOrOwner: TClearhausPersonType, addOrRemove: "add" | "remove") => {
        const increment = addOrRemove === "add" ? 1 : -1

        if (directorOrOwner === "director") {
            setDirectorsCount(directorsCount + increment)
            return
        }
        setOwnersCount(ownersCount + increment)
    }

    const DeleteBtn = (props: { type: TClearhausPersonType }) => {
        return (
            <span
                className="person-delete-btn"
                onClick={() => {
                    addOrRemovePerson(props.type, "remove")
                }}
            >
                {" "}
                ×
            </span>
        )
    }

    const AddButton = (props: { type: TClearhausPersonType }) => {
        return (
            <div className="person-add-btn">
                <hr />
                <TextButton
                    text={`+ ${
                        props.type === "owner"
                            ? t("clearhausonboarding.buttons.addanotherowner")
                            : t("clearhausonboarding.buttons.addanotherdirector")
                    }`}
                    onClick={async () => addOrRemovePerson(props.type, "add")}
                    textDecoration="underline"
                    fontSize="big"
                    display="inline-block"
                    fontWeight="600"
                    color="dark-blue"
                />
            </div>
        )
    }

    const commonClearhausPersonFields = {
        control,
        register: control.register,
        clearError: clearErrors,
        setError,
        showErrors,
        formState,
        saveJustTriggered,
    }

    const findUploadedOwnerDirectorFiles = (type: TClearhausPersonType, label: string, index: number) => {
        const target = type === "director" ? savedFields?.directors?.[index] : savedFields?.owners?.[index]
        return target?.uploadedFiles?.filter((file) => file.label === label).map((file) => file.name) || []
    }

    return (
        <div className={cn("clearhaus-people-inputs", className)}>
            <PersonHeader type="director" ownershipType={ownershipType} isMain />
            <div className="clearhaus-people-inputs__person-fields">
                <ClearhausPersonFields
                    {...commonClearhausPersonFields}
                    index={0}
                    type="director"
                    alreadyUploadedFileNames={{
                        pictureLegitimation: findUploadedOwnerDirectorFiles("director", "picture_legitimation", 0),
                        addressLegitimation: findUploadedOwnerDirectorFiles("director", "address_legitimation", 0),
                    }}
                />
            </div>

            {ownershipType === "Multiple directors" && (
                <>
                    {Array.from({ length: directorsCount }).map((v, i) => {
                        if (i === 0) {
                            // As you can see above first director is always present
                            return null
                        }

                        return (
                            <div key={i}>
                                <div>
                                    <hr />
                                    <PersonHeader type="director">
                                        {i == directorsCount - 1 && <DeleteBtn type="director" />}
                                    </PersonHeader>
                                </div>
                                <div className="clearhaus-people-inputs__person-fields">
                                    <ClearhausPersonFields
                                        {...commonClearhausPersonFields}
                                        type="director"
                                        index={i}
                                        alreadyUploadedFileNames={{
                                            pictureLegitimation: findUploadedOwnerDirectorFiles(
                                                "director",
                                                "picture_legitimation",
                                                i
                                            ),
                                            addressLegitimation: findUploadedOwnerDirectorFiles(
                                                "director",
                                                "address_legitimation",
                                                i
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    <AddButton type="director" />
                </>
            )}
            {ownershipType === "Director!=Owner" && (
                <>
                    {Array.from({ length: ownersCount }).map((v, i) => {
                        return (
                            <div key={i}>
                                <div>
                                    <hr />
                                    <PersonHeader type="owner">
                                        {i == ownersCount - 1 && ownersCount > 1 && <DeleteBtn type="owner" />}
                                    </PersonHeader>
                                </div>
                                <div className="clearhaus-people-inputs__person-fields">
                                    <ClearhausPersonFields
                                        {...commonClearhausPersonFields}
                                        type="owner"
                                        index={i}
                                        alreadyUploadedFileNames={{
                                            pictureLegitimation: findUploadedOwnerDirectorFiles(
                                                "owner",
                                                "picture_legitimation",
                                                i
                                            ),
                                            addressLegitimation: findUploadedOwnerDirectorFiles(
                                                "owner",
                                                "address_legitimation",
                                                i
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
            {ownershipType === "Director+Multiple owners" && (
                <>
                    {Array.from({ length: ownersCount }).map((v, i) => {
                        return (
                            <div key={i}>
                                <div>
                                    <hr />
                                    <PersonHeader type="owner">
                                        {i !== 0 && i == ownersCount - 1 && <DeleteBtn type="owner" />}
                                    </PersonHeader>
                                </div>
                                <div className="clearhaus-people-inputs__person-fields">
                                    <ClearhausPersonFields
                                        {...commonClearhausPersonFields}
                                        type="owner"
                                        index={i}
                                        alreadyUploadedFileNames={{
                                            pictureLegitimation: findUploadedOwnerDirectorFiles(
                                                "owner",
                                                "picture_legitimation",
                                                i
                                            ),
                                            addressLegitimation: findUploadedOwnerDirectorFiles(
                                                "owner",
                                                "address_legitimation",
                                                i
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {ownersCount < 3 && <AddButton type="owner" />}
                </>
            )}
        </div>
    )
}
