import React from "react"
import { useTranslation } from "react-i18next"
import { StandardBox, TextButton } from "swiipe.portal.shared"
import "./SharedCancelContainer.scss"

interface ISharedCancelContainer {
    onCancel: () => void
    cancelText?: string
}
export const SharedCancelContainer = ({ onCancel, cancelText }: ISharedCancelContainer) => {
    const { t } = useTranslation()

    return (
        <StandardBox>
            <div className="shared-cancel-container">
                <TextButton
                    className="shared-cancel-container__cancel-button"
                    text={cancelText ?? t("commononboardingtexts.cancel")}
                    color="red"
                    textDecoration="underline-dotted"
                    onClick={async () => {
                        onCancel()
                    }}
                />
            </div>
        </StandardBox>
    )
}
