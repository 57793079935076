import React from "react"
import { IModalControlProps } from "swiipe.portal.shared"
import ModalA2aRefund, { IModalA2aRefund } from "../ModalA2aRefund"
import ModalAcceptTerms, { IModalAcceptTerms } from "../ModalAcceptTerms"
import ModalBulkActions, { IModalBulkActions } from "../ModalBulkActions"
import ModalCancelTransaction, { IModalCancelTransaction } from "../ModalCancelTransaction"
import ModalCaptureRefund, { IModalCaptureRefund } from "../ModalCaptureRefund"
import ModalCompanyAlreadyRegistered, { IModalCompanyAlreadyRegistered } from "../ModalCompanyAlreadyRegistered"
import ModalConfirmAccountFirst, { IModalConfirmAccountFirst } from "../ModalConfirmAccountFirst"
import ModalConfirmCaptureRefund, { IModalConfirmCaptureRefund } from "../ModalConfirmCaptureRefund"
import ModalConfirmDangerousAction, { IModalConfirmDangerousAction } from "../ModalConfirmDangerousAction"
import ModalContactUs, { IModalContactUs } from "../ModalContactUs"
import ModalDialog, { IModalDialog } from "../ModalDialog"
import ModalEmail, { IModalEmail } from "../ModalEmail"
import ModalGAOnboardingErrorDialog, { IModalGAOnboardingErrorDialog } from "../ModalGAOnboardingErrorDialog"
import ModalInfo, { IModalInfo } from "../ModalInfo"
import ModalLogOut, { IModalLogOut } from "../ModalLogOut"
import ModalLoggedOut, { IModalLoggedOut } from "../ModalLoggedOut"
import ModalMerchantOfferNavigateKyc, { IModalMerchantOfferNavigateKyc } from "../ModalMerchantOfferNavigateKyc"
import ModalPaymentLinkCustomize, { IModalPaymentLinkCustomize } from "../ModalPaymentLinkCustomize"
import ModalPaymentLinkError, { IModalPaymentLinkError } from "../ModalPaymentLinkError"
import ModalPaymentLinkSend, { IModalPaymentLinkSend } from "../ModalPaymentLinkSend"
import ModalReportFraud, { IModalReportFraud } from "../ModalReportFraud"
import ModalSalesHistoryCancel, { IModalSalesHistoryCancel } from "../ModalSalesHistoryCancel"
import ModalSalesHistoryInfo, { IModalSalesHistoryInfo } from "../ModalSalesHistoryInfo"
import ModalSms, { IModalSms } from "../ModalSms"
import ModalTerms, { IModalTerms } from "../ModalTerms"
import ModalTransactionActionError, { IModalTransactionActionError } from "../ModalTransactionActionError"
import ModalTransactionDetails, { IModalTransactionDetails } from "../ModalTransactionTechnicalDetails"

export type TAllModals =
    | IModalInfo
    | IModalContactUs
    | IModalConfirmAccountFirst
    | IModalLogOut
    | IModalTerms
    | IModalAcceptTerms
    | IModalConfirmDangerousAction
    | IModalSalesHistoryInfo
    | IModalSalesHistoryCancel
    | IModalCaptureRefund
    | IModalLoggedOut
    | IModalA2aRefund
    | IModalEmail
    | IModalSms
    | IModalCancelTransaction
    | IModalCompanyAlreadyRegistered
    | IModalTransactionActionError
    | IModalTransactionDetails
    | IModalReportFraud
    | IModalBulkActions
    | IModalConfirmCaptureRefund
    | IModalDialog
    | IModalPaymentLinkError
    | IModalPaymentLinkSend
    | IModalPaymentLinkCustomize
    | IModalGAOnboardingErrorDialog
    | IModalMerchantOfferNavigateKyc

export function RenderModal(modalWithoutCallback: TAllModals, controlProps: IModalControlProps) {
    switch (modalWithoutCallback.type) {
        case "info":
            return <ModalInfo {...modalWithoutCallback} {...controlProps} />
        case "logOut":
            return <ModalLogOut {...modalWithoutCallback} {...controlProps} />
        case "loginConfirmEmail":
            return <ModalConfirmAccountFirst {...modalWithoutCallback} {...controlProps} />
        case "terms":
            return <ModalTerms {...modalWithoutCallback} {...controlProps} />
        case "contactUs":
            return <ModalContactUs {...modalWithoutCallback} {...controlProps} />
        case "acceptTerms":
            return <ModalAcceptTerms {...modalWithoutCallback} {...controlProps} />
        case "confirmDangerousAction":
            return <ModalConfirmDangerousAction {...modalWithoutCallback} {...controlProps} />
        case "salesHistoryInfo":
            return <ModalSalesHistoryInfo {...modalWithoutCallback} {...controlProps} />
        case "salesHistoryCancel":
            return <ModalSalesHistoryCancel {...modalWithoutCallback} {...controlProps} />
        case "captureRefund":
            return <ModalCaptureRefund {...modalWithoutCallback} {...controlProps} />
        case "modalLoggedOut":
            return <ModalLoggedOut {...modalWithoutCallback} {...controlProps} />
        case "a2aRefund":
            return <ModalA2aRefund {...modalWithoutCallback} {...controlProps} />
        case "email":
            return <ModalEmail {...modalWithoutCallback} {...controlProps} />
        case "sms":
            return <ModalSms {...modalWithoutCallback} {...controlProps} />
        case "cancelTransaction":
            return <ModalCancelTransaction {...modalWithoutCallback} {...controlProps} />
        case "modalCompanyAlreadyRegistered":
            return <ModalCompanyAlreadyRegistered {...modalWithoutCallback} {...controlProps} />
        case "transactionActionError":
            return <ModalTransactionActionError {...modalWithoutCallback} {...controlProps} />
        case "transactionTechnicalDetails":
            return <ModalTransactionDetails {...modalWithoutCallback} {...controlProps} />
        case "reportFraud":
            return <ModalReportFraud {...modalWithoutCallback} {...controlProps} />
        case "bulkActions":
            return <ModalBulkActions {...modalWithoutCallback} {...controlProps} />
        case "confirmCaptureRefund":
            return <ModalConfirmCaptureRefund {...modalWithoutCallback} {...controlProps} />
        case "modalDialog":
            return <ModalDialog {...modalWithoutCallback} {...controlProps} />
        case "paymentLinkError":
            return <ModalPaymentLinkError {...modalWithoutCallback} {...controlProps} />
        case "paymentLinkSend":
            return <ModalPaymentLinkSend {...modalWithoutCallback} {...controlProps} />
        case "paymentLinkCustomize":
            return <ModalPaymentLinkCustomize {...modalWithoutCallback} {...controlProps} />
        case "gaOnboardingErrorDialog":
            return <ModalGAOnboardingErrorDialog {...modalWithoutCallback} {...controlProps} />
        case "merchantOfferNavigateKyc":
            return <ModalMerchantOfferNavigateKyc {...modalWithoutCallback} {...controlProps} />
        default:
            return null
    }
}
