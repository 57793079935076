import React, { useState } from "react"
import cn from "classnames"
import "./PageIconContainer.scss"

interface IPageIconContainerProps {
    iconClass: string
    containerClass?: string
    textTitle?: string
    textReduced?: string
    textExpanded?: string
    description?: string
    onClick?: (event: React.MouseEvent<any, MouseEvent>) => void
}

const PageIconContainer = ({
    iconClass,
    containerClass,
    textTitle,
    textExpanded,
    textReduced,
    description,
    onClick,
    ...otherProps
}: IPageIconContainerProps) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div id="page-icon-container-comp" className={cn("page-icon-container", containerClass, { "mt-4": isExpanded })}>
            <span
                className="readmore-container"
                onClick={(e) => {
                    if (!textExpanded) {
                        return
                    }
                    if (onClick) {
                        onClick(e)
                    }

                    setIsExpanded(!isExpanded)
                }}
                {...otherProps}
            >
                <span className="readmore-text">{isExpanded ? textExpanded : textReduced}</span>
                <span className={cn("icon page-icon", iconClass)}></span>
                {textTitle && <span className="title">{textTitle}</span>}
            </span>
            {!!description && <div className="description">{description}</div>}
        </div>
    )
}

export default PageIconContainer
