import cn from "classnames"
import React from "react"
import { CloseIcon } from "swiipe.portal.shared"
import "./ContainerWithCloseButton.scss"

interface IContainerWithCloseButtonProps {
    titleOpened: React.ReactNode
    titleClosed: React.ReactNode
    isOpened: boolean
    setIsOpened?: (isOpened: boolean) => void
    children?: React.ReactNode
    className?: string
}

const ContainerWithCloseButton = ({
    titleOpened,
    titleClosed,
    isOpened,
    children,
    setIsOpened,
    className,
}: IContainerWithCloseButtonProps) => {
    return (
        <div className={cn("container-with-close-button", { closed: !isOpened }, className)}>
            {isOpened && (
                <CloseIcon
                    className="close-cross"
                    onClick={() => {
                        if (!setIsOpened) {
                            return
                        }
                        setIsOpened(false)
                    }}
                />
            )}

            <div
                className={cn("header", { "cursor-pointer": !isOpened })}
                onClick={() => {
                    if (!setIsOpened) {
                        return
                    }
                    setIsOpened(true)
                }}
            >
                {isOpened ? titleOpened : titleClosed}
            </div>

            <div className={cn({ "d-none": !isOpened })}>{children}</div>
        </div>
    )
}

export default ContainerWithCloseButton
