import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    ShowErrorMessages,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { resetPasswordThunk } from "../../../store/thunks/loginThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { ResetPasswordFormModel } from "../../../type/ResetPasswordFormModel"
import SubmitButton from "../../buttons/SubmitButton"
import FormPasswordRequirements from "./../FormPasswordRequirements"

interface IResetPasswordFormProps {
    email: string
    token: string
}

const ResetPasswordForm = ({ email, token }: IResetPasswordFormProps) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<ResetPasswordFormModel>()
    const dispatch = useReduxDispatch()
    const [showRequirements, setShowRequirements] = useState(false)

    const onSubmit: SubmitHandler<ResetPasswordFormModel> = async (data, e) => {
        try {
            await dispatch(resetPasswordThunk({ ...data, email, token }))
        } catch (err) {
            // Catch to stop showing spinner
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FieldsetFormGroup field>
                <FloatingLabelInput
                    name="password"
                    innerRef={register(
                        valFuncRequiredAndPattern(validationPatterns.password, t("validation.resetpasswordform.wrongpassword"))
                    )}
                    onFocus={() => setShowRequirements(true)}
                    onBlur={() =>
                        setTimeout(() => {
                            setShowRequirements(false)
                        }, 100)
                    }
                    type="password"
                    placeholder={t("forgotpw.password")}
                />
            </FieldsetFormGroup>
            <ShowErrorMessages formState={formState} />
            {showRequirements && <FormPasswordRequirements isBlueBubble />}
            <FieldsetFormGroup className="pt-4">
                <div className="row">
                    <div className="offset-lg-6 col-lg-6 offset-md-7 col-md-5 offset-sm-6 col-sm-6 col-12">
                        <SubmitButton formState={formState} isResetPassword />
                    </div>
                </div>
            </FieldsetFormGroup>
        </Form>
    )
}

export default ResetPasswordForm
