import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    BillingAddressDto,
    DeliveryLocationDto,
    IPaymentMethods,
    UserDataDto,
    changeLanguage,
    prepareFormData,
    requestThunk,
    setCulture,
    userReducerActions,
    userSelectors,
} from "swiipe.portal.shared"
import { getCultureForCountry, getCultureForLanguage, setLocaleForDatepicker } from "../../services/languageService"
import { endpoints } from "./../../data/endpoints"
import { ChangeUserLanguageOrCountryForm } from "./../../type/personal/changeUserLanguageForm"
import { ChangePhonenumberForm } from "./../../type/personal/changephonenumberform"
import { ChangeUserFullNameForm } from "./../../type/personal/changeuserfullnameform"
import { StoreState } from "./../StoreState"
import { clearTermsThunk } from "./termsThunks"

export const fetchUserDataThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force && userSelectors.userData(getState())) {
            // User data already fetched
            return
        }

        const userData = await dispatch(requestThunk<UserDataDto>(endpoints.Core.getUserData))
        setCulture(getCultureForLanguage(userData.user.language) || getCultureForCountry(userData.user.countryCode))
        setLocaleForDatepicker({
            language: userData.user.language,
            countryCode: userData.user.countryCode,
        })
        dispatch(userReducerActions.setUserData(userData))

        changeLanguage(userData.user.language)
    }

export const updateUserDataThunk =
    (
        form: ChangeUserLanguageOrCountryForm | ChangeUserFullNameForm | ChangePhonenumberForm
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userData = userSelectors.userData(getState())
        const languageChanged = form.type === "languageCountryForm" && userData?.user.language !== form.language

        await dispatch(
            requestThunk<void>(endpoints.Identity.updateUserData, {
                data: prepareFormData(form, []),
            })
        )
        await dispatch(fetchUserDataThunk(true))
        if (languageChanged) {
            dispatch(clearTermsThunk())
        }
    }

export const deleteUserFullNameThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch) => {
    await dispatch(updateUserDataThunk({ type: "nameForm", firstName: "", lastName: "" }))
}

export const ensureDeliveryLocationThunk =
    (form: DeliveryLocationDto): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Core.ensureDeliveryLocation, {
                data: { ...form },
            })
        )
        await dispatch(fetchUserDataThunk(true))
    }
export const ensureBillingAddressThunk =
    (form: BillingAddressDto): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Core.ensureBillingAddress, {
                data: { ...form },
            })
        )
        await dispatch(fetchUserDataThunk(true))
    }

export const deleteDeliveryLocationThunk =
    (id: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(requestThunk<void>(endpoints.Core.deleteDeliveryLocation(id)))
        await dispatch(fetchUserDataThunk(true))
    }

export const getUserPaymentMethodsThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userData = userSelectors.userData(getState())
        const pm = userSelectors.userPaymentMethods(getState())

        if (!userData) {
            return
        }

        if (!force && pm) {
            return
        }

        const paymentMethods = await dispatch(requestThunk<IPaymentMethods>(endpoints.Payments.getUserPaymentMethods))

        dispatch(userReducerActions.setUserPaymentMethdos(paymentMethods))
    }

export const deleteUserPaymentMethodThunk =
    (paymentMethodId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<void>(endpoints.Payments.deleteUserPaymentMethod, {
                params: {
                    paymentMethodId: paymentMethodId,
                    deleteAsUser: true,
                },
            })
        )
        await dispatch(getUserPaymentMethodsThunk(true))
    }
