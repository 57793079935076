import React from "react"
import { IInvoicingPrice } from "swiipe.portal.shared"
import { TPspPriceType, getPspPriceHtml } from "../../../services/pspService"
import OnboardingPricingLine from "../../servicesonboarding/OnboardingPricingLine"

interface IPspPriceRow {
    priceType: TPspPriceType
    prices: IInvoicingPrice[] | undefined
}

const PspPriceRow = ({ priceType, prices }: IPspPriceRow) => {
    return <OnboardingPricingLine enlargeSpans priceHtml={getPspPriceHtml(priceType, prices)} />
}

export default PspPriceRow
