import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
    HintIconWithTextOnClick,
    SpinnerContainer,
    StandardButton,
    StandardButtonWithSpinner,
    TCreditCardType,
    TPlatformValue,
    TSwiipeService,
    TextButton,
    openUrl,
} from "swiipe.portal.shared"
import { tryGetA2aInsuranceLogo } from "../../services/a2aService"
import { getImage } from "../../services/imageLanguageService"
import { navigationService } from "../../services/navigationService"
import { getLogoByCardType } from "../../services/paymentService"
import { IGatewayDto } from "../../store/reducers/gatewayReducer"
import { ETermsType } from "../../store/reducers/legalReducer"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import LegalTextLinkPopup from "../legal/LegalTextLinkPopup"
import "./Plugin.scss"

export interface IPlugin {
    id: TSwiipeService | undefined
    platforms: TPlatformValue[]
    price: string
    priceSubText?: string
    editNode?: React.ReactNode
    isPlugin?: boolean
    logo: string
    title: string
    installationLink?: string
    pluginDescription: string
    pluginShortDescription?: string
    textUnderDescription?: string
    buttonLink: string
    buttonFunc?: () => Promise<void>
    logoWidth: number
    logoTopMargin?: number
    isOnboarded?: boolean | null
    isFetched: boolean
    onboardedText?: string
    onboardedTextItalic?: boolean
    onboardedSecondaryText?: string
    onboardedSecondaryTextItalic?: boolean
    priceHasLotsOfText?: boolean
    priceAlignCenter?: boolean
    legalTextType?: ETermsType[]
    legalTextLinkText?: string
    legalTextLinkOnboarded?: string[]
    showAiiaLogo?: boolean
    cardTypes?: TCreditCardType[]
    canEdit: boolean
    editBtnText?: string
    removeBtnMargin?: boolean
    disableSeparator?: boolean
    editBtnAsText?: boolean
    activateBtnText?: string
    hintBtnSection?: {
        title: string
        hint: string
    }
    actionButtonBlue?: boolean
}

interface IPluginsProps extends IPlugin {
    merchantDetails: MixedMerchantDetails
    gateways?: IGatewayDto[]
    isTermsAccepted: boolean | undefined
}

const Plugins = ({
    price,
    priceSubText,
    editNode,
    isPlugin,
    logo,
    title,
    installationLink,
    buttonLink,
    buttonFunc,
    pluginDescription,
    pluginShortDescription,
    textUnderDescription,
    logoWidth,
    logoTopMargin,
    isOnboarded,
    isFetched,
    onboardedText,
    onboardedTextItalic,
    onboardedSecondaryText,
    onboardedSecondaryTextItalic,
    priceHasLotsOfText,
    priceAlignCenter,
    legalTextType,
    legalTextLinkText,
    legalTextLinkOnboarded,
    showAiiaLogo,
    cardTypes,
    canEdit,
    editBtnText,
    editBtnAsText,
    activateBtnText,
    hintBtnSection,
    removeBtnMargin,
    disableSeparator,
    merchantDetails,
    gateways,
    actionButtonBlue,
    isTermsAccepted,
}: IPluginsProps) => {
    const [reduceDescription, setReduceDescription] = useState(true)

    const { t } = useTranslation()
    const divider = "-----"

    const merchantDeletionPending = !!merchantDetails?.deletionStatus

    return (
        <div className={cn({ "plugins-separator": !isPlugin && !disableSeparator })}>
            <div>
                <div className={"service-row-item"}>
                    <div className="plugin-container">
                        <div className="plugin-logo-box">
                            <div className="plugin-container__main-logos">
                                <img
                                    className="plugin-main-logo"
                                    src={logo}
                                    width={logoWidth}
                                    style={logoTopMargin ? { marginTop: logoTopMargin } : undefined}
                                />
                                <strong>{title}</strong>
                            </div>
                            {!!cardTypes && (
                                <div className="plugin-container__card-logos">
                                    {cardTypes.map((cardType) => (
                                        <img
                                            key={cardType.toString()}
                                            className="card-logos--image"
                                            src={getLogoByCardType(cardType)}
                                        />
                                    ))}
                                </div>
                            )}
                            {showAiiaLogo && (
                                <div className="plugin-container__additional-logos">
                                    <img className="plugin-additional-logos__logo-aiia" src={getImage("poweredByAiiaLogo")} />
                                    {isOnboarded && (
                                        <div className="aiia-insurance-container">
                                            {!tryGetA2aInsuranceLogo(
                                                gateways?.find((gateway) => gateway.paymentType == "AccountToAccount")
                                            ) ? (
                                                <span>{t("plugins.purchaseProtection")}</span>
                                            ) : (
                                                <img
                                                    className="plugin-additional-logos__logo-insurance"
                                                    src={tryGetA2aInsuranceLogo(
                                                        gateways?.find((gateway) => gateway.paymentType == "AccountToAccount")
                                                    )}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={cn("price-container", { "more-text": priceHasLotsOfText })}>
                            {!editNode && price ? (
                                <div
                                    className={cn("service-price", { "text-center": priceAlignCenter })}
                                    dangerouslySetInnerHTML={{ __html: price }}
                                ></div>
                            ) : null}
                            {!editNode && priceSubText ? (
                                <div
                                    className={cn("service-price sub-text", { "text-center": priceAlignCenter })}
                                    dangerouslySetInnerHTML={{ __html: priceSubText }}
                                ></div>
                            ) : null}
                            {!!editNode && <div className="text-center node-edit-plugin">{editNode}</div>}
                        </div>
                        <div className={cn("button-area-container", !installationLink && !removeBtnMargin ? "add-margin" : "")}>
                            {isPlugin && (
                                <StandardButton
                                    width={110}
                                    isSmall
                                    dark
                                    noBorder
                                    onClick={() => openUrl(buttonLink)}
                                    title={t("plugins.download")}
                                    className="smaller-text"
                                    disabled={merchantDeletionPending}
                                />
                            )}
                            {hintBtnSection && (
                                <span className="btn-section-hint">
                                    {hintBtnSection.title}
                                    <HintIconWithTextOnClick
                                        hintText={hintBtnSection.hint}
                                        showHintOnHover
                                        blue
                                        wider
                                        showOnTop
                                    />
                                </span>
                            )}
                            {!isPlugin && (
                                <SpinnerContainer showSpinner={!isFetched}>
                                    {isOnboarded && (
                                        <div className="onboard-status-approved">
                                            <p className={cn(onboardedTextItalic && "font-italic")}>{onboardedText}</p>
                                            {onboardedSecondaryText && (
                                                <p className={cn("mt-2", onboardedSecondaryTextItalic && "font-italic")}>
                                                    {onboardedSecondaryText}
                                                </p>
                                            )}
                                            {canEdit && !editBtnAsText && (
                                                <StandardButton
                                                    width={110}
                                                    invertedBlue={!actionButtonBlue}
                                                    dark={actionButtonBlue}
                                                    noBorder={actionButtonBlue}
                                                    isSmall
                                                    onClick={() => navigationService.navigate(buttonLink)}
                                                    title={editBtnText ? editBtnText : t("plugins.edit")}
                                                    className={cn("smaller-text", { "no-padding": editBtnText })}
                                                    disabled={merchantDeletionPending}
                                                />
                                            )}
                                            {canEdit && editBtnAsText && (
                                                <TextButton
                                                    className="text-btn-edit"
                                                    text={editBtnText ? editBtnText : t("plugins.edit")}
                                                    color="grey"
                                                    textDecoration="underline"
                                                    onClick={async () => navigationService.navigate(buttonLink)}
                                                />
                                            )}
                                        </div>
                                    )}
                                    {!isOnboarded && (
                                        <StandardButtonWithSpinner
                                            width={110}
                                            invertedBlue={!actionButtonBlue}
                                            dark={actionButtonBlue}
                                            noBorder={actionButtonBlue}
                                            isSmall
                                            onClick={async () => {
                                                if (buttonFunc) {
                                                    await buttonFunc()
                                                } else {
                                                    navigationService.navigate(buttonLink)
                                                }
                                            }}
                                            title={activateBtnText ? activateBtnText : t("plugins.activate")}
                                            className={cn("smaller-text", { "no-padding": activateBtnText })}
                                            disabled={merchantDeletionPending}
                                        />
                                    )}
                                    {!isOnboarded && isTermsAccepted && (
                                        <div className="plugin__terms-accepted">{t("plugins.termsAcceptedShort")}</div>
                                    )}
                                </SpinnerContainer>
                            )}
                            {installationLink && (
                                <a href={installationLink} target="_blank" rel="noopener noreferrer">
                                    {t("plugins.installationGuide")}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className="plugins-expand-section">
                    <div className={cn("plugin-desc-section", reduceDescription && "reduced")}>
                        {isOnboarded && legalTextLinkOnboarded && legalTextType && (
                            <>
                                <span className="description">
                                    {pluginShortDescription
                                        ? pluginShortDescription
                                        : t("plugins.onboardedDescription.swiipeTerms")}
                                </span>
                                {!pluginShortDescription &&
                                    legalTextType?.map((type, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <LegalTextLinkPopup
                                                    legalTextBeforeLink={
                                                        legalTextLinkOnboarded[i] + t("plugins.onboardedDescription.service")
                                                    }
                                                    linkText={t("plugins.onboardedDescription.here")}
                                                    termsType={type}
                                                    inPluginDesc
                                                ></LegalTextLinkPopup>
                                                {i !== legalTextType.length - 1 && t("plugins.onboardedDescription.and")}
                                            </React.Fragment>
                                        )
                                    })}
                            </>
                        )}
                        {(!isOnboarded || !legalTextLinkOnboarded || !legalTextType) && (
                            <>
                                <span className="description">
                                    {reduceDescription ? pluginDescription.split(".")[0] + ". " : ""}

                                    {!reduceDescription && (
                                        <>
                                            {!legalTextType && !legalTextLinkText && (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: pluginDescription.replace("[[https]]", "https://"),
                                                    }}
                                                ></span>
                                            )}

                                            {legalTextType &&
                                                legalTextLinkText &&
                                                pluginDescription
                                                    .replaceAll("{{legalTextLink}}", divider)
                                                    .split(divider)
                                                    .map((slice, i) => {
                                                        return (
                                                            <span key={i}>
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: slice.replace("[[https]]", "https://"),
                                                                    }}
                                                                ></span>
                                                                {legalTextType[i] && (
                                                                    <LegalTextLinkPopup
                                                                        legalTextBeforeLink=""
                                                                        linkText={legalTextLinkText}
                                                                        termsType={legalTextType[i]}
                                                                        inPluginDesc
                                                                    ></LegalTextLinkPopup>
                                                                )}
                                                            </span>
                                                        )
                                                    })}
                                        </>
                                    )}
                                </span>

                                <span
                                    className="toggle-desc"
                                    onClick={() => {
                                        setReduceDescription(!reduceDescription)
                                    }}
                                >
                                    {reduceDescription ? t("plugins.readmore") : t("plugins.readless")}
                                </span>
                            </>
                        )}
                    </div>
                    {textUnderDescription && (
                        <div className="plugin-desc-section mt-3">
                            <span className="description">{t(textUnderDescription)}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Plugins
