import cn from "classnames"
import React from "react"
import useDimensions from "react-cool-dimensions"
import { sortDescending } from "swiipe.portal.shared"
import { polarToCartesian } from "../../util/circleUtils"
import "./MerchantDashboardCircleDiagram.scss"

export interface IMerchantDashboardCircleDiagramValue {
    title?: string
    key: string
    value: number
    color: string
    isSelected: boolean
}

interface IMerchantDashboardCircleDiagramProps {
    className?: string
    values: IMerchantDashboardCircleDiagramValue[]
    onSelection?: (key?: string) => void
}

const scale = 1.1

const MerchantDashboardCircleDiagram = ({ values, className, onSelection }: IMerchantDashboardCircleDiagramProps) => {
    const { observe, width, height } = useDimensions<HTMLDivElement>({})

    const wh = Math.min(width, height, 180)
    const padding = wh * (scale - 1) * 0.5
    const radius = wh / 2 - padding
    const innerRadius = radius * 0.4
    const degrees: number[] = values.reduce<number[]>(
        (res, v, index) => {
            res.push(res[index] + (360 / 100) * v.value)
            return res
        },
        [0]
    )

    const data = sortDescending(
        values.map((v, i) => ({
            ...v,
            fromDegree: degrees[i],
            toDegree: degrees[i + 1],
        })),
        (d) => (d.isSelected ? 0 : 1000) + d.value
    )

    if (!values.find((v) => v.value !== 0)) {
        return null
    }

    return (
        <div ref={observe} className={cn("merchant-dashboard-circle-diagram", className)}>
            <div className="diagram" style={{ width: wh, height: wh, left: (width - wh) / 2, top: (height - wh) / 2 }}>
                {width > 0 && height > 0 && (
                    <svg viewBox={`0 0 ${wh} ${wh}`}>
                        {data.map((d, i) => {
                            const pointInner1 = polarToCartesian(padding + radius, padding + radius, innerRadius, d.fromDegree)
                            const pointInner2 = polarToCartesian(padding + radius, padding + radius, innerRadius, d.toDegree)
                            const pointOuter1 = polarToCartesian(padding + radius, padding + radius, radius, d.fromDegree)
                            const pointOuter2 = polarToCartesian(padding + radius, padding + radius, radius, d.toDegree)

                            const rangeInDegrees = (360 / 100) * d.value
                            return (
                                <path
                                    transform={d.isSelected ? `scale(${scale} ${scale})` : undefined}
                                    /* eslint react/no-unknown-property: 0 */
                                    transform-origin={`${padding + radius} ${padding + radius}`}
                                    key={i}
                                    fill={d.color}
                                    onMouseEnter={() => {
                                        onSelection?.(d.key)
                                    }}
                                    onMouseLeave={() => {
                                        onSelection?.(undefined)
                                    }}
                                    d={`M${pointInner1.x} ${pointInner1.y} L${pointOuter1.x} ${
                                        pointOuter1.y
                                    } A${radius} ${radius} 0 ${rangeInDegrees > 180 ? 1 : 0} 1 ${pointOuter2.x} ${
                                        pointOuter2.y
                                    } L${pointInner2.x} ${pointInner2.y} A${innerRadius} ${innerRadius} 0 ${
                                        rangeInDegrees > 180 ? 1 : 0
                                    } 0 ${pointInner1.x} ${pointInner1.y}`}
                                />
                            )
                        })}
                    </svg>
                )}
            </div>
        </div>
    )
}

export default MerchantDashboardCircleDiagram
