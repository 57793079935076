import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, FormGroup, Input } from "reactstrap"
import {
    FloatingLabelInput,
    TRegister,
    TSetValue,
    TextButton,
    TitleWithAbsoluteHint,
    getExampleEmail,
    getExampleSms,
    getKeys,
    getLanguage,
    isAnyOfKeysPressed,
    useSelector,
} from "swiipe.portal.shared"
import IconGearBlue from "../../../images/icons/icon-gear-blue.svg"
import { getLanguageByISO } from "../../../services/languageService"
import { getWebshopHostName } from "../../../services/merchantService"
import { getPaymentLinkNotificationPreviewData, supportedLanguages } from "../../../services/paymentLinkService"
import { StoreState } from "../../../store/StoreState"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { addModalThunk } from "../../../store/thunks/modalThunks"
import { getWebshopDetailsThunk, setWebshopDetailsThunk, uploadWebshopFileThunk } from "../../../store/thunks/webshopThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IPaymentLinkNotificationCustomizations } from "../../../type/IWebshopDetails"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import SubmitButton from "../../buttons/SubmitButton"
import FileInput, { megabyteInBytes } from "../../form/input/FileInput"
import ContainerWithCloseButton from "../../layouts/ContainerWithCloseButton"
import InlineElementsWithDividers from "../../layouts/InlineElementsWithDividers"
import { UploadedFilesOverviewText } from "../../overview/UploadedFilesOverview"
import TabsComponent from "../../sections/TabsComponent"
import "./CustomizePaymentLinkNotifications.scss"

interface ICustomizePaymentLinkNotifications {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId: string
    isOpened?: boolean
    setIsOpened?: (isOpened: boolean) => void

    insideModal?: boolean
}

const CustomizePaymentLinkNotifications = ({
    merchantDetails,
    selectedWebshopId,
    isOpened,
    setIsOpened,
    insideModal,
}: ICustomizePaymentLinkNotifications) => {
    const { t } = useTranslation()

    const [isConfigured, setIsConfigured] = useState(false)

    return insideModal ? (
        <div className="customize-pl-notifications-container">
            <div className="customize-notifications-title mb-4 opened">
                <p>{t("paymentLinkCustomize.title")}</p>
            </div>
            <CustomizePaymentLinkNotificationsInner
                merchantDetails={merchantDetails}
                selectedWebshopId={selectedWebshopId}
                setIsConfigured={setIsConfigured}
            />
        </div>
    ) : (
        <ContainerWithCloseButton
            className="customize-pl-notifications-container"
            titleOpened={
                <div className="customize-notifications-title mb-4 opened">
                    <p>{t("paymentLinkCustomize.title")}</p>
                </div>
            }
            titleClosed={
                <div className="customize-notifications-title closed">
                    <div className="customize">
                        <img src={IconGearBlue} />
                        <span>{t("paymentLinkCustomize.title")}</span>
                    </div>
                    <div className={cn({ not: !isConfigured }, "configured")}>
                        {isConfigured
                            ? t("paymentLinkCustomize.statuses.configured")
                            : t("paymentLinkCustomize.statuses.notConfigured")}
                    </div>
                </div>
            }
            isOpened={isOpened ?? true}
            setIsOpened={setIsOpened}
        >
            <CustomizePaymentLinkNotificationsInner
                merchantDetails={merchantDetails}
                selectedWebshopId={selectedWebshopId}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                setIsConfigured={(isConfigured) => setIsConfigured(isConfigured)}
            />
        </ContainerWithCloseButton>
    )
}

interface ICustomizePaymentLinkNotificationsInner extends ICustomizePaymentLinkNotifications {
    setIsConfigured: (isConfigured: boolean) => void
}

const CustomizePaymentLinkNotificationsInner = ({
    merchantDetails,
    selectedWebshopId,
    isOpened,
    setIsConfigured,
}: ICustomizePaymentLinkNotificationsInner) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [selectedLanguageTab, setSelectedLanguageTab] = useState(getLanguage())

    const webshopDetails = useSelector((state: StoreState) => merchantSelectors.webshopDetails(state, selectedWebshopId))

    const { handleSubmit, register, setValue, getValues, trigger, clearErrors, setError, formState } = useForm<any>()

    const onSubmit: SubmitHandler<any> = async (data) => {
        const webshopId = selectedWebshopId
        const swMerchantId = merchantDetails.swMerchant.swMerchantId

        if (data.webshopLogo.length) {
            //Update or upload webshop logo
            await dispatch(uploadWebshopFileThunk(swMerchantId, webshopId, "WebshopLogo", data.webshopLogo[0]))
        }

        //Update customizable fields
        const notificationCustomizations: IPaymentLinkNotificationCustomizations = {}

        supportedLanguages.forEach((lang) => {
            notificationCustomizations[lang] = {
                customHeader: data[`customHeader-${lang}`],
                customBody: data[`customBody-${lang}`],
                signedBy: data[`signedBy-${lang}`],
            }
        })

        await dispatch(
            setWebshopDetailsThunk(
                swMerchantId,
                [webshopId],
                [
                    {
                        plNotificationCustomizations: notificationCustomizations,
                    },
                ]
            )
        )
    }

    const setCustomizationInputs = (language: string, customHeader?: string, customBody?: string, signedBy?: string) => {
        setValue(`customHeader-${language}`, customHeader)
        setValue(`customBody-${language}`, customBody)
        setValue(`signedBy-${language}`, signedBy)
    }

    useEffect(() => {
        if (!selectedWebshopId) {
            return
        }

        dispatch(getWebshopDetailsThunk(selectedWebshopId))

        setValue("webshopName", getWebshopHostName(merchantDetails, selectedWebshopId))
    }, [selectedWebshopId, isOpened])

    useEffect(() => {
        supportedLanguages.forEach((lang) => {
            //Clear customization inputs
            setCustomizationInputs(lang)
        })

        const notificationCustomizations = webshopDetails?.plNotificationCustomizations
        if (!notificationCustomizations) {
            setIsConfigured(false)
            return
        }

        let hasAnyConfiguredFields = false

        const langs = getKeys(notificationCustomizations)
        langs.forEach((lang) => {
            const customHeader = notificationCustomizations[lang].customHeader
            const customBody = notificationCustomizations[lang].customBody
            const signedBy = notificationCustomizations[lang].signedBy

            if (customBody || customHeader || signedBy) {
                hasAnyConfiguredFields = true
            }

            setCustomizationInputs(lang, customHeader, customBody, signedBy)
        })

        setIsConfigured(hasAnyConfiguredFields)
    }, [webshopDetails, isOpened, selectedWebshopId])

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="customize-notifications-form">
            <FormGroup>
                <div className="field-section webshop-name-field">
                    <FloatingLabelInput
                        name="webshopName"
                        innerRef={register}
                        placeholder={t("paymentLinkCustomize.webshopName")}
                        readOnly
                    />
                </div>
                <div className="hint-section"></div>
            </FormGroup>
            <FormGroup>
                <div className="field-section">
                    <p className="input-legend mt-3">{t("paymentLinkCustomize.logoUpload.companyLogo")}</p>
                    {webshopDetails?.webshopLogoName && (
                        <div className="current-logo mb-2">
                            <span className="text">{t("paymentLinkCustomize.logoUpload.currentLogo")}:</span>
                            <UploadedFilesOverviewText fileNames={[webshopDetails.webshopLogoName]}></UploadedFilesOverviewText>
                        </div>
                    )}
                    <FileInput
                        name="webshopLogo"
                        slimButton
                        triggerValidation={trigger}
                        maxFileSizeBytes={megabyteInBytes * 1}
                        clearError={clearErrors}
                        setError={setError}
                        setValue={setValue}
                        getValues={getValues}
                        register={register}
                        maxFilesAmount={1}
                        accept="image/jpeg, image/png"
                        required={false}
                    />
                </div>
                <div className="hint-section"></div>
            </FormGroup>
            <p className="input-legend mt-5 bold">{t("paymentLinkCustomize.customEmailTexts.title")}</p>
            <TabsComponent
                tabs={supportedLanguages.map((lang) => {
                    return {
                        name: getLanguageByISO(lang, getLanguage()),
                        id: lang,
                        component: (
                            <CustomEmailFields register={register} getValues={getValues} setValue={setValue} lang={lang} />
                        ),
                    }
                })}
                tabWidth={110}
                withActiveTabId={getLanguage()}
                onSwitchActiveTabId={(lang: string) => setSelectedLanguageTab(lang)}
            />

            <div className="indented-box">
                <InlineElementsWithDividers
                    className="preview-buttons mt-2"
                    elements={[
                        <TextButton
                            key={1}
                            text={t("paymentLinkSendModal.preview.email")}
                            color="grey"
                            textDecoration="underline"
                            onClick={async () => {
                                const currentInputsValues = getValues()

                                const email = await dispatch(
                                    getExampleEmail(
                                        merchantDetails.swMerchant.swMerchantId,
                                        "PaymentLinkNotification",
                                        selectedLanguageTab,
                                        await getPaymentLinkNotificationPreviewData(
                                            "email",
                                            "PL104",
                                            "https://secure.swiipe.com/paymentlink/be020c2f-ee65-46c1-9fd4-696444e43b44",
                                            selectedWebshopId,
                                            merchantDetails,
                                            webshopDetails,
                                            currentInputsValues[`signedBy-${selectedLanguageTab}`],
                                            currentInputsValues["webshopLogo"][0],
                                            currentInputsValues[`customHeader-${selectedLanguageTab}`],
                                            currentInputsValues[`customBody-${selectedLanguageTab}`]
                                        )
                                    )
                                )

                                await dispatch(
                                    addModalThunk({
                                        type: "email",
                                        title: email.subject,
                                        body: email.body,
                                    })
                                )
                            }}
                        />,
                        <TextButton
                            key={2}
                            text={t("paymentLinkSendModal.preview.sms")}
                            color="grey"
                            textDecoration="underline"
                            onClick={async () => {
                                const sms = await dispatch(
                                    getExampleSms(
                                        merchantDetails.swMerchant.swMerchantId,
                                        "PaymentLinkNotification",
                                        selectedLanguageTab,
                                        await getPaymentLinkNotificationPreviewData(
                                            "sms",
                                            "PL104",
                                            "https://secure.swiipe.com/paymentlink/be020c2f-ee65-46c1-9fd4-696444e43b44",
                                            selectedWebshopId,
                                            merchantDetails,
                                            webshopDetails,
                                            getValues()[`signedBy-${selectedLanguageTab}`]
                                        )
                                    )
                                )

                                await dispatch(
                                    addModalThunk({
                                        type: "sms",
                                        body: sms.body,
                                    })
                                )
                            }}
                        />,
                    ]}
                />
                <SubmitButton dark noBorder containerClass="submit-btn mt-4" formState={formState}>
                    {t("paymentLinkCustomize.saveBtn")}
                </SubmitButton>
            </div>
        </Form>
    )
}

const CustomEmailFields = (props: { register: TRegister; getValues: () => any; setValue: TSetValue<any>; lang: string }) => {
    const { t } = useTranslation()
    const customHeaderInputName = `customHeader-${props.lang}`
    const customBodyInputName = `customBody-${props.lang}`
    const signedByInputName = `signedBy-${props.lang}`

    const onKeyUpDoNameReplacement = (e: React.KeyboardEvent<HTMLInputElement>, inputName: string) => {
        if (isAnyOfKeysPressed(e.key, ["Backspace", "Delete", "Alt", "Shift", "Control"])) {
            return
        }

        const currentText = props.getValues()[inputName]
        const curlyBracketLastIndex = currentText.lastIndexOf("{")

        if (curlyBracketLastIndex < 0) {
            return
        }

        if (curlyBracketLastIndex + 1 === currentText.length) {
            props.setValue(inputName, currentText + "customerName}")
        }
    }

    return (
        <>
            <span className="group-placeholder">{t("paymentLinkCustomize.customEmailTexts.headerPlaceholder")}:</span>
            <FormGroup>
                <div className="field-section">
                    <FloatingLabelInput
                        name={customHeaderInputName}
                        innerRef={props.register}
                        onKeyUp={(e) => onKeyUpDoNameReplacement(e, customHeaderInputName)}
                    />
                </div>
                <div className="hint-section">
                    <TitleWithAbsoluteHint hintTextWithHtml={t("paymentLinkCustomize.customEmailTexts.hints.email")} />
                </div>
            </FormGroup>
            <span className="group-placeholder">{t("paymentLinkCustomize.customEmailTexts.bodyPlaceholder")}:</span>
            <FormGroup>
                <div className="field-section">
                    <Input
                        name={customBodyInputName}
                        innerRef={props.register}
                        type="textarea"
                        rows={6}
                        onKeyUp={(e) => onKeyUpDoNameReplacement(e, customHeaderInputName)}
                    ></Input>
                </div>
                <div className="hint-section bind-top"></div>
            </FormGroup>
            <span className="group-placeholder">{t("paymentLinkCustomize.customEmailTexts.signedByPlaceholder")}:</span>
            <FormGroup>
                <div className="field-section">
                    <FloatingLabelInput name={signedByInputName} innerRef={props.register} />
                </div>
                <div className="hint-section">
                    <TitleWithAbsoluteHint hintTextWithHtml={t("paymentLinkCustomize.customEmailTexts.hints.signedBy")} />
                </div>
            </FormGroup>
        </>
    )
}

export default CustomizePaymentLinkNotifications
