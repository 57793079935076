import React from "react"
import { useTranslation } from "react-i18next"
import { IMerchantDashboardServerData } from "../../../../type/merchantDashboard/IMerchantDashboardServerData"
import MerchantDashboardSimpleValues from "../../tiles/MerchantDashboardTileSimpleValues"

interface IDashboardOrdersDisplayProps {
    dashboardData: IMerchantDashboardServerData
    paymentsEnabled: boolean
}

const DashboardOrdersDisplay = ({ dashboardData, paymentsEnabled }: IDashboardOrdersDisplayProps) => {
    const { t } = useTranslation()

    const orders = {
        main: dashboardData.mainData.orders,
        compare: dashboardData.compareData?.orders,
    }

    return (
        <MerchantDashboardSimpleValues
            title={t("merchantDashboard.ordersTitle")}
            subtitle={t("merchantDashboard.viaSwiipe")}
            simpleValues={[
                { value: { amount: orders.main.count }, subtitle: t("merchantDashboard.orderSize") },
                {
                    value: { amount: orders.main.avgSize, currency: dashboardData.currency },
                    subtitle: t("merchantDashboard.orderSizeAvg"),
                },
            ]}
            compareValues={
                orders.compare && [
                    { value: { amount: orders.compare.count }, subtitle: t("merchantDashboard.orderSize") },
                    {
                        value: { amount: orders.compare.avgSize, currency: dashboardData.currency },
                        subtitle: t("merchantDashboard.orderSizeAvg"),
                    },
                ]
            }
            notEnabled={!paymentsEnabled && orders.main.count === 0}
        />
    )
}

export default DashboardOrdersDisplay
