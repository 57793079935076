import cn from "classnames"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Input } from "reactstrap"
import { StandardButton, copyToClipboard } from "swiipe.portal.shared"
import "./SalesCheckoutScriptCopyField.scss"

interface ICheckoutScriptClipboardProps {
    title: string
    value: string
    className?: string
}
const CheckoutScriptClipboardField = ({ title, value, className }: ICheckoutScriptClipboardProps) => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>()
    return (
        <div className={cn("row item-container", className)}>
            <div className="col-md-9 col-sm-12 pr-md-0 clipboard-input">
                <span>{title}</span>
                <Input innerRef={(ref) => (inputRef.current = ref)} readOnly value={value} />
            </div>
            <div className="col-md-3">
                <StandardButton
                    onClick={() => {
                        if (inputRef.current) {
                            copyToClipboard(inputRef.current as HTMLInputElement)
                        }
                    }}
                    title={t("checkoutscript.copy")}
                />
            </div>
        </div>
    )
}

export default CheckoutScriptClipboardField
