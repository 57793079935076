import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody, Row } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter, ModalFooterButton } from "swiipe.portal.shared"
import ListOfMessagesSection from "../../components/sections/ListOfMessagesSection"
import { getPlatformOrderIdFormatted } from "../../services/orderService"
import { Order } from "../../type/Order"

export interface IModalCancelTransaction extends IModalBase {
    type: "cancelTransaction"
    selectedOrders?: Order[]
}

const ModalCancelTransaction = ({ callback, selectedOrders, ...otherProps }: IModalCancelTransaction & IModalControlProps) => {
    const { t } = useTranslation()

    const ordersWithCancelAvailable = selectedOrders?.filter((o) => o.actions.canCancel)

    const getModalMessage = () => {
        if (!selectedOrders || !ordersWithCancelAvailable) {
            return t("transactions.cancelModal.description")
        }

        if (ordersWithCancelAvailable.length === 0) {
            return ""
        }

        if (selectedOrders.length === ordersWithCancelAvailable.length) {
            return t("transactions.bulkActionModals.messages.cancel", {
                amountOfOrders: selectedOrders.length.toString(),
            })
        }

        return t("transactions.bulkActionModals.messages.cancelFiltered", {
            amountOfOrders: ordersWithCancelAvailable.length,
            outOfAmount: selectedOrders.length,
        })
    }

    const getFooterButtonsProps = (): { leftButton?: ModalFooterButton; rightButton?: ModalFooterButton } => {
        if (ordersWithCancelAvailable?.length === 0) {
            return {
                leftButton: undefined,
                rightButton: { onClick: async () => callback("declined"), text: t("common.close") },
            }
        }

        return {
            leftButton: { onClick: async () => callback("declined"), text: t("transactions.cancelModal.no") },
            rightButton: {
                onClick: async () => callback("accepted"),
                text: t("transactions.cancelModal.yes"),
                isDelete: true,
                isInvertedColor: true,
            },
        }
    }

    return (
        <BaseModal
            {...otherProps}
            callback={callback}
            title={t("transactions.action.cancel")}
            titleClassName="text-danger"
            centered
            showCloseButton
        >
            <ModalBody>
                <Row className="text-center">
                    <div className="col">
                        {(!selectedOrders || !!ordersWithCancelAvailable?.length) && (
                            <p className="dialog-text mb-4 mt-2">{getModalMessage()}</p>
                        )}
                        {selectedOrders && (
                            <ListOfMessagesSection
                                header={t("transactions.bulkActionModals.errors.actionNotAvailable")}
                                showMoreText={t("transactions.bulkActionModals.messages.actionFail.seeMore")}
                                messages={selectedOrders
                                    .filter((o) => !o.actions.canCancel)
                                    .map((o) => getPlatformOrderIdFormatted(o.platformOrderId))}
                                showBeforeExpanded={3}
                            />
                        )}
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter wideButtons {...getFooterButtonsProps()} />
        </BaseModal>
    )
}

export default ModalCancelTransaction
