import React from "react"
import { useSelector } from "swiipe.portal.shared"
import { billingSelectors } from "../../../store/reducers/billingReducer"
import PspPriceRow from "./PspPriceRow"

const PspPricingRows = () => {
    const prices = useSelector(billingSelectors.resolvedPrices)
    return (
        <>
            <PspPriceRow priceType="transaction" prices={prices} />
            <PspPriceRow priceType="monthly" prices={prices} />
        </>
    )
}

export default PspPricingRows
