import React from "react"
import { useTranslation } from "react-i18next"
import LinkButton from "../buttons/LinkButton"

interface TextTranslatedWithLinksProps {
    textTranslationKey: string
    linkKeyInText: string
    linkTranslationKey: string
    linkAddress: string
    openInNewTab?: boolean
}

const TextTranslatedWithLinks = ({
    textTranslationKey,
    linkKeyInText,
    linkTranslationKey,
    linkAddress,
    openInNewTab,
}: TextTranslatedWithLinksProps) => {
    const { t } = useTranslation()
    return (
        <>
            {t(textTranslationKey)
                .split(linkKeyInText)
                .map((text, i, chunks) => {
                    if (i !== chunks.length - 1) {
                        return (
                            <span key={`translated-link-${i}`}>
                                {text}
                                <LinkButton textOnly inline href={linkAddress} target={openInNewTab ? "_blank" : undefined}>
                                    {t(linkTranslationKey)}
                                </LinkButton>
                            </span>
                        )
                    } else {
                        return <span key={`translated-link-${i}`}>{text}</span>
                    }
                })}
        </>
    )
}

export default TextTranslatedWithLinks
