import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-navi"
import { sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { authService, useShowLoggedInMenus } from "../../services/authService"
import { openContactUsWidget } from "../../services/contactUsService"
import { getLinkForTerms, getTermsTypesForUserRelationType } from "../../services/legalService"
import { ETermsType } from "../../store/reducers/legalReducer"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TUserRelation } from "../../type/TUserRelation"
import ContactUsWidget from "../widget/ContactUsWidget"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"

const useShowHideFooter = () => {
    const [showFooter, setShowFooter] = useState(false)
    const showFooterRef = useRef<boolean>()
    showFooterRef.current = showFooter

    const footerHandler = () => {
        if (window.innerHeight + window.scrollY + 5 >= document.body.offsetHeight) {
            if (!showFooterRef.current) {
                setShowFooter(true)
            }
        } else {
            if (showFooterRef.current) {
                setShowFooter(false)
            }
        }
    }

    useEffect(() => {
        const touchEvent = "ontouchstart" in window ? "touchstart" : "click"
        window.addEventListener(touchEvent, footerHandler)
        window.onscroll = footerHandler
    }, [])

    return showFooter
}

const PageFooter = ({}) => {
    const showLoggedInMenus = useShowLoggedInMenus()

    const { t } = useTranslation()

    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)
    const currentUserRelationType = useSelector(userRelationSelectors.currentUserRelationType)
    const currentUserRelationId = useSelector(userRelationSelectors.currentUserRelation)?.id
    const termsTypes = getTermsTypesForUserRelationType(currentUserRelationType, env)
    const showFooter = useShowHideFooter()

    const isLoggedIn = authService.getIsLoggedIn()

    const LegalLink = ({
        termsType,
        text,
        relationType,
        relationId,
    }: {
        termsType: ETermsType
        relationType: TUserRelation
        relationId: string | undefined
        text: string
    }) => {
        return (
            <a
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(
                        addModalThunk({
                            type: "terms",
                            userRelationType: relationType,
                            relationId: relationId,
                            termsType: termsType,
                        })
                    )
                }}
                id="termsLink"
                href={getLinkForTerms(termsType, relationType, relationId)}
            >
                {text}
            </a>
        )
    }

    return (
        <>
            <div className="pseudo-footer"></div>
            <footer className={cn("footer", { show: showFooter })}>
                <ul className="nav">
                    {showLoggedInMenus && (
                        <li className="col-sm-4 order-2 order-sm-1">
                            {termsTypes.terms && (
                                <LegalLink
                                    termsType={termsTypes.terms}
                                    relationType={currentUserRelationType}
                                    relationId={currentUserRelationId}
                                    text={t("footer.terms")}
                                />
                            )}
                            <br />
                            {termsTypes.privacy && (
                                <LegalLink
                                    termsType={termsTypes.privacy}
                                    relationType={currentUserRelationType}
                                    relationId={currentUserRelationId}
                                    text={t("footer.datapolicy")}
                                />
                            )}
                        </li>
                    )}
                    {!showLoggedInMenus && (
                        <li className="col-sm-4 order-2 order-sm-1">
                            <Link
                                href=""
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    openContactUsWidget()
                                }}
                            >
                                {t("contactform.title")}
                            </Link>
                        </li>
                    )}
                    <li className="col-sm-4 order-3 order-sm-2">
                        <p>
                            {t("footer.text", { currentYear: new Date().getFullYear() })}
                            <br />
                            {t("footer.text2")}
                        </p>
                    </li>
                    <li className="col-sm-4 order-1 order-sm-3">
                        <a href="//www.swiipe.com" target="_blank" rel="noopener noreferrer" className="social-link-footer">
                            <span className="icon icon-footer-social icon-website"></span>
                        </a>
                        <a
                            href="//www.linkedin.com/company/swiipe"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link-footer"
                        >
                            <span className="icon icon-footer-social icon-linkedin"></span>
                        </a>
                        <a
                            href="https://www.facebook.com/Swiipe-325067421464696/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link-footer"
                        >
                            <span className="icon icon-footer-social icon-facebook"></span>
                        </a>
                    </li>
                </ul>
            </footer>
            {!isLoggedIn && <ContactUsWidget />}
        </>
    )
}

export default PageFooter
