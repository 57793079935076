import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps } from "swiipe.portal.shared"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./ModalA2aRefund.scss"

export interface IModalA2aRefund extends IModalBase {
    type: "a2aRefund"
    url: string
    onUpdate?: () => Promise<void>
}

const ModalA2aRefund = ({ url, callback, onUpdate, ...otherProps }: IModalA2aRefund & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    useEffect(() => {
        function handleIframeEvent(e: any) {
            if (e.origin && e.origin.endsWith(".swiipe.com")) {
                const status = (e.data.swiipe.status ?? "") + ""

                onUpdate && onUpdate()
                if (status.toLowerCase() === "success") {
                    dispatch(
                        addModalThunk({
                            type: "info",
                            title: t("transactions.refundModal.successTitle"),
                            text: t("transactions.refundModal.successText"),
                            showCheckmark: true,
                        })
                    )
                    callback("accepted")
                } else {
                    dispatch(
                        addModalThunk({
                            type: "error",
                            title: t("transactions.refundModal.failureTitle"),
                            errorMessage: t("transactions.refundModal.failureText"),
                        })
                    )
                    callback("declined")
                }
            }
        }
        window.addEventListener("message", handleIframeEvent)
        return () => window.removeEventListener("message", handleIframeEvent)
    }, [])

    return (
        <BaseModal
            {...otherProps}
            modalClassName="modal-a2a-refund"
            callback={(response, data) => {
                if (response === "ignored") {
                    return
                }
                onUpdate && onUpdate()
                callback(response, data)
            }}
            centered
        >
            <ModalBody>
                <div className="content-container">
                    <iframe src={url}></iframe>
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalA2aRefund
