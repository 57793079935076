import cn from "classnames"
import React from "react"
import { slide as Menu } from "react-burger-menu"
import { NavbarToggler } from "reactstrap"
import { sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import { layoutReducerActions, layoutSelectors } from "../../store/reducers/layoutReducer"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { useShowLoggedInMenus } from "./../../services/authService"

interface ISidebarProps {
    children?: React.ReactNode
}

const Sidebar = ({ children }: ISidebarProps) => {
    const isOpen = useSelector(layoutSelectors.navBarOpen)
    const dispatch = useReduxDispatch()
    const showLoggedInMenus = useShowLoggedInMenus()
    const env = useSelector(sharedConfigurationSelectors.environment)

    return (
        <>
            {showLoggedInMenus && (
                <nav id="sidebar" className={cn(env === "Sandbox" ? "sandbox-style" : "")}>
                    {children}
                </nav>
            )}
            <Menu
                burgerButtonClassName="d-none"
                isOpen={isOpen}
                onStateChange={(state) => dispatch(layoutReducerActions.setNavBarOpen(state.isOpen))}
            >
                <nav id="sidebar-mobile" className={cn(env === "Sandbox" ? "sandbox-style" : "")}>
                    <NavbarToggler
                        onClick={() => dispatch(layoutReducerActions.setNavBarOpen(false))}
                        target="sidebar-collapse"
                        id="close-sidebar"
                    ></NavbarToggler>
                    {children}
                </nav>
            </Menu>
        </>
    )
}

export default Sidebar
