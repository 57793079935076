import cn from "classnames"
import React from "react"
import { StandardBox } from "swiipe.portal.shared"
import "./ClearhausOnboardingFooter.scss"

interface IClearhausOnboardingFooterProps {
    className?: string
    left?: JSX.Element
    middle?: JSX.Element
    right?: JSX.Element
}

export const ClearhausOnboardingFooter = ({ className, left, middle, right }: IClearhausOnboardingFooterProps) => {
    return (
        <StandardBox
            className={cn("clearhaus-onboarding-footer", "mt-2", className)}
            padding="15-20"
            margin="0"
            borders="rounded"
        >
            <div className="clearhaus-onboarding-footer__left">{left}</div>
            <div className="clearhaus-onboarding-footer__middle">{middle}</div>
            <div className="clearhaus-onboarding-footer__right">{right}</div>
        </StandardBox>
    )
}
