import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    FormHeader,
    IFormNavigationControl,
    PageContainer,
    PageWrapper,
    companySearchSelectors,
    getCompanyInfo,
    getQueryValueFromCurrentUrl,
    useSelector,
} from "swiipe.portal.shared"
import ClearhausOnboardingMessage from "../../../components/clearhaus/ClearhausOnboardingMessage"
import { ClearhausUpdateInfoButton } from "../../../components/clearhaus/ClearhausUpdateInfoButton"
import ClearhausOnboardingForm from "../../../components/form/clearhaus/ClearhausOnboardingForm"
import { navigationService } from "../../../services/navigationService"
import { TOnboardingType } from "../../../services/onboardingService"
import { StoreState } from "../../../store/StoreState"
import { clearhausSelectors } from "../../../store/reducers/clearhausReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import {
    getClearhausApplicationDataThunk,
    registerOnboardingStartedThunk,
    updateOnboardingIsInstantStatusThunk,
} from "../../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IClearhausApplicationOnboardingState } from "../../../type/clearhaus/IClearhausApplicationOnboardingState"
import { TClearhausDialogType } from "../../../type/clearhaus/TClearhausDialogType"
import { TClearhausFormState } from "../../../type/clearhaus/TClearhausFormState"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import { scrollToTop } from "../../../util/animationUtil"

interface IClearHausOnboardingPageProps {
    withWebshopIds: string[]
    onboardingType?: TOnboardingType
    onLoadCallBack?: () => void
    onGoBack: () => void
    onOnboarded: () => void
    viewMode?: boolean
}
interface IClearHausOnboardingPageInnerProps extends IClearHausOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
}

const ClearhausOnboardingPage = (props: IClearHausOnboardingPageProps) => {
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails) {
        return null
    }

    return <ClearhausOnboardingPageInner merchantDetails={merchantDetails} {...props} />
}

const ClearhausOnboardingPageInner = ({
    merchantDetails,
    withWebshopIds,
    onboardingType,
    onLoadCallBack,
    onGoBack,
    onOnboarded,
    viewMode,
}: IClearHausOnboardingPageInnerProps) => {
    const swMerchantId = merchantDetails.swMerchant.swMerchantId
    const webshopIdFromQuery = getQueryValueFromCurrentUrl("webshopId")
    const webshopIds = viewMode && webshopIdFromQuery ? [webshopIdFromQuery] : withWebshopIds

    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [clearhausFormState, setClearhausFormState] = useState<TClearhausFormState>(viewMode ? "submitted" : "first")
    const [activeDialog, setActiveDialog] = useState<TClearhausDialogType | undefined>()

    const navigationControl = useMemo<IFormNavigationControl>(() => ({}), [])

    const [allowInstant, setAllowInstant] = useState(false)

    useEffect(() => {
        if (!onLoadCallBack) {
            return
        }

        onLoadCallBack()
    }, [])

    useEffect(() => {
        if (!viewMode) {
            dispatch(registerOnboardingStartedThunk(swMerchantId, webshopIds))
        }

        dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds))
    }, [swMerchantId, withWebshopIds])

    useEffect(() => {
        dispatch(getCompanyInfo(merchantDetails.swMerchant.countryCode, merchantDetails.swMerchant.registrationNumber))
    }, [merchantDetails])

    const companyDetails = useSelector(companySearchSelectors.userCompanyDetails)
    const applicationData = useSelector((state: StoreState) => clearhausSelectors.getOnboardingData(state, webshopIds[0]))
    const applicationDataFetched = useSelector((state: StoreState) =>
        clearhausSelectors.getOnboardingDataFetched(state, webshopIds[0])
    )
    const clearhausApplicationState = useSelector<StoreState, IClearhausApplicationOnboardingState | undefined>((state) =>
        clearhausSelectors.applicationState(state, webshopIds[0])
    )

    useEffect(() => {
        if (!applicationData || activeDialog) {
            return
        }

        if (applicationData.isSecondStageSubmitted) {
            setClearhausFormState("submitted")
            return
        }

        if (applicationData.isFirstStageSubmitted) {
            setClearhausFormState("second")
        }
    }, [applicationData, activeDialog])

    useEffect(() => {
        if (!viewMode) {
            return
        }

        if (applicationDataFetched && (!applicationData || clearhausApplicationState?.isWithoutData)) {
            setActiveDialog("noDataAtSwiipe")
        }
    }, [applicationData, applicationDataFetched])

    const getHeaderText = (formState: TClearhausFormState) => {
        if (formState === "submitted") {
            return t("clearhausonboarding.headers.yourInfoAtClearhaus")
        }

        if (!allowInstant) {
            return t("clearhausonboarding.headers.setupClearhaus")
        }

        switch (formState) {
            case "first":
                return t("clearhausonboarding.headers.instantClearhaus")
            case "second":
                return t("clearhausonboarding.headers.setupClearhaus")
        }
    }

    return (
        <PageWrapper widest>
            <PageContainer transparent>
                <FormHeader
                    title={getHeaderText(clearhausFormState)}
                    navigationControl={navigationControl}
                    onClose={() => navigationService.navigate("/plugins")}
                />
                {!activeDialog && (
                    <ClearhausOnboardingForm
                        webshopIds={webshopIds}
                        merchantDetails={merchantDetails}
                        companyDetails={companyDetails}
                        onGoBack={onGoBack}
                        navigationControl={navigationControl}
                        clearhausFormState={clearhausFormState}
                        formStateUpdatedCallback={(newState) => {
                            if (clearhausFormState === "first" && newState === "second" && allowInstant) {
                                setActiveDialog("limited")
                            }

                            if (clearhausFormState === "second" && newState === "submitted") {
                                setActiveDialog("submitted")
                            }

                            setClearhausFormState(newState)
                            scrollToTop()
                        }}
                        applicationData={applicationData}
                        allowInstantOnboarding={allowInstant}
                        setAllowInstantOnboarding={(allow) => {
                            if (allow === allowInstant) {
                                return
                            }

                            setAllowInstant(allow)
                            dispatch(
                                updateOnboardingIsInstantStatusThunk(
                                    merchantDetails.swMerchant.swMerchantId,
                                    allow,
                                    applicationData?.onboardingId
                                )
                            )
                        }}
                        viewMode={viewMode}
                    />
                )}
                {activeDialog === "limited" && (
                    <ClearhausOnboardingMessage
                        mainText={
                            <>
                                <p>
                                    <b>{t("clearhausonboarding.disclaimers.applicationSubmitted")}</b>
                                </p>
                                <p>{t("clearhausonboarding.disclaimers.limitedAccess.canTakeDays")}</p>
                                <p>{t("clearhausonboarding.disclaimers.limitedAccess.youCanAuthorizeUpTo")}</p>
                                <p>{t("clearhausonboarding.disclaimers.limitedAccess.completeApplication")}</p>
                            </>
                        }
                        mainButton={{
                            text: t("clearhausonboarding.buttons.continue"),
                            onClick: () => {
                                if (onboardingType === "cards") {
                                    setActiveDialog(undefined)
                                    return
                                }
                                onOnboarded()
                            },
                        }}
                    />
                )}
                {activeDialog === "submitted" && (
                    <ClearhausOnboardingMessage
                        mainText={
                            <>
                                <p>
                                    <b>{t("clearhausonboarding.disclaimers.applicationSubmitted")}</b>
                                </p>
                                <p>{t("clearhausonboarding.disclaimers.submitted.canTakeDays")}</p>
                                {allowInstant && <p>{t("clearhausonboarding.disclaimers.submitted.youCanAuthorizeUpTo")}</p>}
                            </>
                        }
                        secondaryText={<p>{t("clearhausonboarding.disclaimers.submitted.remark")}</p>}
                        mainButton={{
                            text: t("clearhausonboarding.buttons.close"),
                            onClick: onOnboarded,
                        }}
                    />
                )}
                {activeDialog === "noDataAtSwiipe" && (
                    <ClearhausOnboardingMessage
                        checkmarkHidden
                        mainText={
                            <>
                                <p>
                                    <b>{t("clearhausonboarding.disclaimers.noDataAtSwiipe.youAreUsingClearhaus")}</b>
                                </p>
                                <p>{t("clearhausonboarding.disclaimers.noDataAtSwiipe.needToUpdate")}</p>
                                <ClearhausUpdateInfoButton
                                    customUpdateInfoText={t("clearhausonboarding.disclaimers.noDataAtSwiipe.updateInfo")}
                                    applicationData={applicationData}
                                    isInsideForm={false}
                                />
                            </>
                        }
                        mainButton={{
                            text: t("clearhausonboarding.buttons.close"),
                            onClick: () => navigationService.navigate("/plugins"),
                        }}
                    />
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default ClearhausOnboardingPage
