import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, useSelector, userSelectors } from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"
import UserBillingAddressForm from "./../../components/form/user/UserBillingAddressForm"
import UserDeliveryAddressesForm from "./../../components/form/user/UserDeliveryLocationsForm"
import UserEmailForm from "./../../components/form/user/UserEmailForm"
import UserFullNameForm from "./../../components/form/user/UserFullNameForm"
import UserPaymentMethodsForm from "./../../components/form/user/UserPaymentMethodsForm"
import UserPhoneForm from "./../../components/form/user/UserPhoneForm"

interface IUserPersonalInfoPageProps {}

const UserPersonalInfoPage = ({}: IUserPersonalInfoPageProps) => {
    useRequiredUserRelations([{ requiredType: "User" }])
    const { t } = useTranslation()
    const [formInEditMode, setFormInEditMode] = useState("")
    const userData = useSelector(userSelectors.userData)

    if (!userData) {
        return null
    }
    return (
        <PageWrapper>
            <PageContainer transparent title={t("menu.personalinfo")}>
                <UserFullNameForm
                    onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "fullname" : "")}
                    isInEditMode={formInEditMode === "fullname"}
                />
                <UserEmailForm
                    onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "email" : "")}
                    isInEditMode={formInEditMode === "email"}
                />
                <UserPhoneForm
                    onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "phone" : "")}
                    isInEditMode={formInEditMode === "phone"}
                />
                <UserBillingAddressForm
                    onSwitchEditMode={(isInEditMode) => setFormInEditMode(isInEditMode ? "billingAddress" : "")}
                    isInEditMode={formInEditMode === "billingAddress"}
                />
                <UserPaymentMethodsForm
                    onSwitchEditMode={(inEditModeIdentifier, isInEditMode) =>
                        setFormInEditMode(isInEditMode ? "pm_" + inEditModeIdentifier : "")
                    }
                    inEditModeIdentifier={formInEditMode.startsWith("pm_") ? formInEditMode.replace("pm_", "") : undefined}
                ></UserPaymentMethodsForm>
                <UserDeliveryAddressesForm
                    onSwitchEditMode={(id, isInEditMode) => setFormInEditMode(isInEditMode ? "deliveryAddress_" + id : "")}
                    deliveryAddressIdInEditMode={
                        formInEditMode.startsWith("deliveryAddress_") ? formInEditMode.replace("deliveryAddress_", "") : undefined
                    }
                />
            </PageContainer>
        </PageWrapper>
    )
}

export default UserPersonalInfoPage
