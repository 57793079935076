import React from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    TitleWithAbsoluteHint,
    inputOnChangeForceCapitalLetters,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { IClearhausApplicationData } from "../../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausUpdateInfoForm } from "../../../../type/clearhaus/IClearhausUpdateInfoForm"
import "./ClearhausUpdateBankAccountFields.scss"

interface IClearhausUpdateBankAccountFieldsProps {
    control: Control<IClearhausUpdateInfoForm>
    applicationData?: IClearhausApplicationData
}

const ClearhausUpdateBankAccountFields = ({ control, applicationData }: IClearhausUpdateBankAccountFieldsProps) => {
    const { t } = useTranslation()
    const savedFields = applicationData?.applicationFields?.bank

    return (
        <div className="clearhaus-update-bank-fields">
            <div className="clearhaus-update-bank-fields__field-pair">
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t("clearhausUpdateInfo.inputs.bank.current.iban")}
                        name="bank.current.iban"
                        defaultValue={savedFields?.iban}
                        innerRef={control.register(
                            valFuncRequiredAndPattern(
                                validationPatterns.ibanNumber,
                                t("clearhausonboarding.inputfields.errormsg.iban")
                            )
                        )}
                        onChange={(e) => inputOnChangeForceCapitalLetters(e, "bank.current.iban", control.setValue)}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t("clearhausUpdateInfo.inputs.bank.new.iban")}
                        name="bank.new.iban"
                        innerRef={control.register(
                            valFuncPattern(validationPatterns.ibanNumber, t("clearhausonboarding.inputfields.errormsg.iban"))
                        )}
                        onChange={(e) => inputOnChangeForceCapitalLetters(e, "bank.new.iban", control.setValue)}
                    />
                </FieldsetFormGroup>
            </div>
            <hr />
            <div className="clearhaus-update-bank-fields__field-pair">
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t("clearhausUpdateInfo.inputs.bank.current.swiftCode")}
                        name="bank.current.swiftCode"
                        defaultValue={savedFields?.swiftCode}
                        innerRef={control.register(
                            valFuncRequiredAndPattern(
                                validationPatterns.swiftNumber,
                                t("clearhausonboarding.inputfields.errormsg.swiftcode")
                            )
                        )}
                        onChange={(e) => inputOnChangeForceCapitalLetters(e, "bank.current.swiftCode", control.setValue)}
                    />
                </FieldsetFormGroup>
                <FieldsetFormGroup>
                    <FloatingLabelInput
                        placeholder={t("clearhausUpdateInfo.inputs.bank.new.swiftCode")}
                        name="bank.new.swiftCode"
                        innerRef={control.register(
                            valFuncPattern(
                                validationPatterns.swiftNumber,
                                t("clearhausonboarding.inputfields.errormsg.swiftcode")
                            )
                        )}
                        onChange={(e) => inputOnChangeForceCapitalLetters(e, "bank.new.swiftCode", control.setValue)}
                    />
                </FieldsetFormGroup>
            </div>
            <div className="clearhaus-update-bank-fields__explanation-hint">
                <TitleWithAbsoluteHint
                    className="clearhaus-update-bank-fields__hint"
                    title={t("clearhausUpdateInfo.hints.noChanges.title")}
                    iconBold
                />
            </div>
        </div>
    )
}

export default ClearhausUpdateBankAccountFields
