import React from "react"
import cn from "classnames"
import "./RequestSentText.scss"

interface IRequestSentText {
    text: string
    show: boolean
}

const RequestSentText = ({ text, show }: IRequestSentText) => {
    return (
        <p className={cn("request-sent", { "d-none": !show })}>
            <span className="icon icon-tick-small-grey"></span>
            {text}
        </p>
    )
}

export default RequestSentText
