import React from "react"
import cn from "classnames"
import "./ToolTipContainer.scss"

interface IToolTipContainerProps {
    text: string
    minWidth?: number
    noWrap?: boolean
    centerY?: boolean
    children?: React.ReactNode
    disable?: boolean
}

const ToolTipContainer = ({ text, minWidth, disable, noWrap, centerY, children }: IToolTipContainerProps) => {
    if (disable) {
        return <>{children}</>
    }
    return (
        <div className="tool-tip-container">
            {children}
            <div className={cn("tool-tip", noWrap && "no-wrap", centerY && "center-y")} style={{ minWidth: minWidth }}>
                {text}
            </div>
        </div>
    )
}

export default ToolTipContainer
