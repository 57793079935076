import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"

interface IConnectToGoogleAnalyticsThunk {
    connected: boolean
    error: string
}

export const connectToGoogleAnalyticsThunk =
    (
        swMerchantId: string,
        webshopId: string,
        propertyId: string,
        streamId: string,
        allowNoData: boolean
    ): ThunkAction<Promise<IConnectToGoogleAnalyticsThunk>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<IConnectToGoogleAnalyticsThunk>(endpoints.GoogleAnalytics.addWebshopProperties, {
                data: {
                    swMerchantId,
                    webshopId,
                    allowNoData,
                    properties: {
                        propertyId,
                        streamId,
                    },
                },
            })
        )
    }

interface IGetWebshopProperties {
    propertyId: string
    streamId: string
}

export const getGAWebshopPropertiesThunk =
    (
        swMerchantId: string,
        webshopId: string
    ): ThunkAction<Promise<IGetWebshopProperties | undefined>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const response = await dispatch(
            requestThunk<{ properties: IGetWebshopProperties }>(endpoints.GoogleAnalytics.getWebshopProperties, {
                params: {
                    swMerchantId,
                    webshopId,
                },
            })
        )

        return response.properties
    }
