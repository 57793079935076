import { toBase64 } from "swiipe.portal.shared"
import { IWebshopDetails } from "../type/IWebshopDetails"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"
import { getWebshopHostName } from "./merchantService"

export const supportedLanguages = ["da", "en", "sv", "pl", "no"]

export async function getPaymentLinkNotificationPreviewData(
    type: "email" | "sms",
    platformOrderId: string,
    paymentLink: string,
    webshopId: string,
    merchantDetails: MixedMerchantDetails | undefined,
    webshopDetails: IWebshopDetails | undefined,
    signedBy?: string,
    webshopLogoFromInput?: File,
    customHeader?: string,
    customBody?: string,
    customerName?: string
) {
    const data = {
        platformOrderId,
        webshopName: getWebshopHostName(merchantDetails, webshopId),
        signedBy,
        paymentLink,
    }

    if (type == "sms") {
        return data
    }

    if (webshopLogoFromInput || webshopDetails?.webshopLogoUrl) {
        return {
            ...data,
            customizedTextHtml: getCustomizedEmailTextHtml(customHeader || "", customBody || "", customerName),
            webshopLogoUrl: webshopLogoFromInput ? await toBase64(webshopLogoFromInput) : webshopDetails!.webshopLogoUrl,
        }
    }

    return {
        ...data,
        customizedTextHtml: getCustomizedEmailTextHtml(customHeader || "", customBody || "", customerName),
    }
}

function getCustomizedEmailTextHtml(customHeader: string, customBody: string, customerName?: string) {
    if (customHeader.trim() === "" && customBody.trim() === "") {
        return ""
    }

    const header = replaceCustomerNameForPreview(customHeader, customerName)

    const body = replaceCustomerNameForPreview(customBody.split("\n").join("<br/>"), customerName)

    return header ? `<h2>${header}</h2><p>${body ?? ""}</p>` : `<p>${body ?? ""}</p>`
}

function replaceCustomerNameForPreview(replaceIn?: string, customerName?: string) {
    if (!replaceIn) {
        return undefined
    }

    return replaceIn.replaceAll("{customerName}", customerName ? customerName : "Hans")
}
