import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import { PageWrapper, useSelector, userSelectors } from "swiipe.portal.shared"
import FormFooter from "../../components/form/footer/FormFooter"
import UserSignupDataForm from "../../components/form/signup/UserSignupDataForm"
import FormHeader from "../../components/form/title/FormHeader"
import { authService } from "../../services/authService"
import UserSignupForm from "./../../components/form/signup/UserSignupForm"
import MessageBox from "./../../components/layouts/MessageBox"

interface IUserSignupPageProps {}

const UserSignupPage = ({}: IUserSignupPageProps) => {
    const [hasSignedUpWithEmail, setEmail] = useState("")
    const [selectedCountry, setSelectedCountry] = useState("")
    const [showWelcome, setShowWelcome] = useState(false)
    const { t } = useTranslation()
    const userRegisterData = useSelector(userSelectors.userRegisterData)

    const currentRoute = useCurrentRoute()
    if (currentRoute.url.query.otac) {
        // TODO Show redirect message
        authService.login("/", currentRoute.url.query.otac)
        return null
    }

    const trimmedForm = !!currentRoute.url.query.fromCheckout
    if (userRegisterData && userRegisterData.token && !showWelcome) {
        return (
            <PageWrapper>
                <div id="signup" className="center-form">
                    <FormHeader title={t("signup.addpersonaldata")} />
                    <div className="panel-body">
                        <UserSignupDataForm
                            onSubmitOrSkip={(showWelcome) => {
                                setShowWelcome(showWelcome)
                            }}
                            mail={hasSignedUpWithEmail}
                            country={selectedCountry}
                        />
                    </div>
                    <FormFooter className="form-footer" />
                </div>
            </PageWrapper>
        )
    }
    if (hasSignedUpWithEmail) {
        return (
            <PageWrapper>
                <MessageBox id="signup" centerContent title={t("signup.welcometitle")}>
                    <p className="mb-4">
                        <strong>{t("signup.welcomemsg")}</strong>
                        <br />
                        {t("signup.welcomemsg1", { email: hasSignedUpWithEmail })}
                        <br />
                        {t("signup.welcomemsg3")}
                    </p>
                    <p>{t("signup.welcomemsg2")}</p>
                    <p className="small-text mt-5 mb-0">{t("signup.welcomemsg4")}</p>
                </MessageBox>
            </PageWrapper>
        )
    }
    return (
        <PageWrapper>
            <div id="signup" className="center-form">
                <FormHeader title={t("signup.formheadermessage")} />
                <div className="panel-body">
                    <UserSignupForm
                        onSignedUp={(email, country) => {
                            setEmail(email)
                            setSelectedCountry(country)
                        }}
                        isTrimmed={trimmedForm}
                    />
                </div>
                <FormFooter />
            </div>
        </PageWrapper>
    )
}

export default UserSignupPage
