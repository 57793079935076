import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, ModalBody } from "reactstrap"
import {
    BaseModal,
    FieldsetFormGroup,
    FloatingLabelInput,
    IModalBase,
    IModalControlProps,
    ModalFooter,
    ShowErrorMessages,
    TPayment,
    getFormattedNumber,
    valFuncRequiredAndWithinRange,
} from "swiipe.portal.shared"
import { captureThunk, refundThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { IReportOrderActionData } from "../../type/IReportOrderActionData"
import SubmitButton from "../buttons/SubmitButton"
import "./ModalCaptureRefund.scss"

export interface IModalCaptureRefund extends IModalBase {
    type: "captureRefund"
    minAmount: number
    maxAmount: number
    currency: string
    webshopId: string
    orderId: string
    isRefund: boolean
    paymentType: TPayment
    reportData: IReportOrderActionData
    onUpdate?: () => Promise<void>
}

interface IAmountModel {
    amount: number
}

const ModalCaptureRefund = ({
    minAmount,
    maxAmount,
    currency,
    webshopId,
    orderId,
    isRefund,
    paymentType,
    reportData,
    onUpdate,
    callback,
    ...otherProps
}: IModalCaptureRefund & IModalControlProps) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<IAmountModel>()
    const dispatch = useReduxDispatch()

    const onSubmit: SubmitHandler<IAmountModel> = async (data) => {
        try {
            if (isRefund) {
                const success = await dispatch(refundThunk(webshopId, orderId, data.amount, reportData, onUpdate))
                callback(success ? "accepted" : "declined")
            } else {
                const success = await dispatch(captureThunk(webshopId, orderId, data.amount, reportData, onUpdate))
                callback(success ? "accepted" : "declined")
            }
        } catch (err) {
            callback("declined")
        }
    }

    const textPrefix = isRefund ? "transactions.refundModal." : "transactions.captureModal."

    const validationErrorType = minAmount === maxAmount ? "partialActionNotSupported" : "partialOutOfBoundaries"

    return (
        <BaseModal
            {...otherProps}
            autoFocus={false}
            modalClassName="modal-capture-refund"
            callback={callback}
            centered
            showCloseButton
            title={t(textPrefix + "title")}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <div className="content-container">
                        <div className="mb-3 text-center">
                            {t(textPrefix + "description1")}
                            <br />
                            {t(textPrefix + "description2", {
                                minAmount: getFormattedNumber(minAmount, { numberFormat: "forceTwoDecimals" }),
                                maxAmount: getFormattedNumber(maxAmount, { numberFormat: "forceTwoDecimals" }) + " " + currency,
                            })}
                        </div>
                        <FieldsetFormGroup field>
                            <div className="input-container">
                                <FloatingLabelInput
                                    name="amount"
                                    type="number"
                                    defaultValue={maxAmount}
                                    step={0.01}
                                    autoFocus
                                    inputMode="decimal"
                                    innerRef={register(
                                        valFuncRequiredAndWithinRange(
                                            minAmount,
                                            maxAmount,
                                            validationErrorType === "partialActionNotSupported"
                                                ? t(textPrefix + "validation.partialNotSupported", { paymentType })
                                                : t(textPrefix + "validation.outOfBoundaries", {
                                                      minAmount: getFormattedNumber(minAmount, {
                                                          numberFormat: "forceTwoDecimals",
                                                      }),
                                                      maxAmount:
                                                          getFormattedNumber(maxAmount, { numberFormat: "forceTwoDecimals" }) +
                                                          " " +
                                                          currency,
                                                  })
                                        )
                                    )}
                                    placeholder={t(textPrefix + "inputPlaceholder")}
                                />
                                <div className="currency">{currency}</div>
                            </div>
                        </FieldsetFormGroup>
                        <ShowErrorMessages formState={formState} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="buttons-container">
                        <SubmitButton formState={formState} title={t(textPrefix + "button")} />
                    </div>
                </ModalFooter>
            </Form>
        </BaseModal>
    )
}

export default ModalCaptureRefund
