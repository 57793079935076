import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "swiipe.portal.shared"
import { getMerchantContact } from "../../../services/merchantService"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { IResursNoStoresNotifyData, resursOnboardingNoStoresNotifyThunk } from "../../../store/thunks/resursThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import ExternalLinkButton from "../../buttons/ExternalLinkButton"
import "./ResursNotReadyStep.scss"

export const ResursNotReadyStep = () => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)
    const swMerchantContact = getMerchantContact(merchantDetails)

    useEffect(() => {
        if (!merchantDetails || !selectedWebshopId || !swMerchantContact) {
            return
        }

        const data: IResursNoStoresNotifyData = {
            webshopIds: [selectedWebshopId],
            swMerchantId: merchantDetails.swMerchant.swMerchantId,
            swMerchantEmail: swMerchantContact?.email || "",
            swMerchantName: merchantDetails.swMerchant.name,
            merchantContactName: swMerchantContact?.name || "",
            merchantContactPhone: swMerchantContact?.phone || "",
        }

        dispatch(resursOnboardingNoStoresNotifyThunk(data))
    }, [merchantDetails, selectedWebshopId])

    return (
        <div id="resurs-onboarding__not-ready-step">
            <div className="resurs-onboarding__apply-for-resurs-info">{t("resursOnboarding.applyForResursAccountInfo")}</div>
            <ExternalLinkButton
                className="resurs-onboarding__apply-account-btn"
                href="https://scrive.com/t/9222115557492036355/56141e2d5f2f4f0d"
                title={t("resursOnboarding.applyForResursAccount")}
                dark
            />
            <hr />
            <div className="resurs-onboarding__apply-for-resurs-notice">{t("resursOnboarding.applyForResursAccountNotice")}</div>
        </div>
    )
}

export default ResursNotReadyStep
