import React, { useEffect, useState } from "react"
import { TFeatureType, getCompanyInfo, getText, organizationSelectors, useSelector } from "swiipe.portal.shared"
import CvrNotFoundDialog from "../components/servicesonboarding/CvrNotFoundDialog"
import { StoreState } from "../store/StoreState"
import { ETermsType } from "../store/reducers/legalReducer"
import { IWebshopProviderConfig } from "../store/reducers/merchantServicesReducer"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { TOnboardingStatus } from "../type/IProvider"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"
import { navigationService } from "./navigationService"
import { getProviderStatus } from "./paymentService"

export type TOnboardingType =
    | "mpo"
    | "cards"
    | "dankort"
    | "forbrugsforeningen"
    | "winback"
    | "plusSell"
    | "anywhereMobile"
    | "returnSell"
    | "viabill"
    | "swish"
    | "klarna"
    | "vipps"
    | "applepay"
    | "accountToAccount"
    | "resurs"
    | "externalAnalytics"

export function isWebshopOnboardedForFlow(config: IWebshopProviderConfig | undefined, onboardingType: TOnboardingType) {
    if (!config) {
        return false
    }

    if (!config.gatewayProviders) {
        return false
    }

    switch (onboardingType) {
        case "cards": {
            const creditCardStatus = getProviderStatus(config.gatewayProviders, "CreditCard", "Clearhaus")
            if (creditCardStatus && creditCardStatus !== "ActionRequired") {
                return true
            }
            break
        }
        case "mpo": {
            const clearhausMpoStatus = getProviderStatus(config.gatewayProviders, "MobilePayOnline", "Clearhaus")
            const mpoStatus = getProviderStatus(config.gatewayProviders, "MobilePayOnline", "MobilePayOnline")
            if (clearhausMpoStatus && clearhausMpoStatus !== "ActionRequired" && mpoStatus && mpoStatus !== "ActionRequired") {
                return true
            }
            break
        }
        case "dankort": {
            const dankortStatus = getProviderStatus(config.gatewayProviders, "CreditCard", "DankortEpay")
            if (dankortStatus && dankortStatus !== "ActionRequired") {
                return true
            }
            break
        }
        case "swish": {
            const swishStatus = getProviderStatus(config.gatewayProviders, "Swish", "Swish")
            if (swishStatus && swishStatus !== "ActionRequired") {
                return true
            }
            break
        }
        case "forbrugsforeningen": {
            const forbrugsforeningenStatus = getProviderStatus(config.gatewayProviders, "CreditCard", "ForbrugsforeningenEpay")
            if (forbrugsforeningenStatus && forbrugsforeningenStatus !== "ActionRequired") {
                return true
            }
            break
        }
        case "viabill": {
            const viabillStatus = getProviderStatus(config.gatewayProviders, "Viabill", "Viabill")
            if (viabillStatus && viabillStatus !== "ActionRequired") {
                return true
            }
            break
        }
        case "klarna": {
            const klarnaPayLaterStatus = getProviderStatus(config.gatewayProviders, "KlarnaPayLater", "Klarna")
            const klarnaFinancingStatus = getProviderStatus(config.gatewayProviders, "KlarnaFinancing", "Klarna")
            const isNotActionRequired = (status?: TOnboardingStatus) => status && status !== "ActionRequired"
            if (isNotActionRequired(klarnaPayLaterStatus) && isNotActionRequired(klarnaFinancingStatus)) {
                return true
            }
            break
        }
        case "vipps": {
            const clearhausVippsStatus = getProviderStatus(config.gatewayProviders, "Vipps", "Clearhaus")
            const vippsStatus = getProviderStatus(config.gatewayProviders, "Vipps", "Vipps")
            if (
                clearhausVippsStatus &&
                clearhausVippsStatus !== "ActionRequired" &&
                vippsStatus &&
                vippsStatus !== "ActionRequired"
            ) {
                return true
            }
            break
        }
        case "applepay": {
            const clearhausApplePayStatus = getProviderStatus(config.gatewayProviders, "ApplePay", "Clearhaus")
            const applePayStatus = getProviderStatus(config.gatewayProviders, "ApplePay", "ApplePay")
            if (
                clearhausApplePayStatus &&
                clearhausApplePayStatus !== "ActionRequired" &&
                applePayStatus &&
                applePayStatus !== "ActionRequired"
            ) {
                return true
            }
            break
        }
        case "resurs": {
            const resusrInvoiceStatus = getProviderStatus(config.gatewayProviders, "ResursInvoice", "Resurs")
            const resusrPartPaymentStatus = getProviderStatus(config.gatewayProviders, "ResursPartPayment", "Resurs")
            const resusRevolvingCreditStatus = getProviderStatus(config.gatewayProviders, "ResursRevolvingCredit", "Resurs")
            const isNotActionRequired = (status?: TOnboardingStatus) => status && status !== "ActionRequired"
            if (
                isNotActionRequired(resusrInvoiceStatus) ||
                isNotActionRequired(resusrPartPaymentStatus) ||
                isNotActionRequired(resusRevolvingCreditStatus)
            ) {
                return true
            }
            break
        }
    }
    return false
}

export const getServiceNameFromOnboardingType = (onboardingType: TOnboardingType) => {
    switch (onboardingType) {
        case "mpo":
            return getText("serviceNames.mpo")
        case "dankort":
            return getText("serviceNames.dankort")
        case "forbrugsforeningen":
            return getText("serviceNames.forbrugsforeningen")
        case "winback":
            return getText("serviceNames.winback")
        case "plusSell":
            return getText("serviceNames.plusSell")
        case "anywhereMobile":
            return getText("serviceNames.anywhereMobile")
        case "returnSell":
            return getText("serviceNames.returnSell")
        case "viabill":
            return getText("serviceNames.viabill")
        case "swish":
            return getText("serviceNames.swish")
        case "klarna":
            return getText("serviceNames.klarna")
        case "vipps":
            return getText("serviceNames.vipps")
        case "applepay":
            return getText("serviceNames.applepay")
        case "accountToAccount":
            return getText("serviceNames.a2a")
        case "resurs":
            return "Resurs"
        case "cards":
            return "Clearhaus"
        case "externalAnalytics":
            return "Analytics"
    }
}

export const getFeatureTypeFromOnboardingType = (onboardingType: TOnboardingType): TFeatureType | undefined => {
    switch (onboardingType) {
        case "winback":
            return "Winback"
        case "plusSell":
            return "PlusSell"
        case "returnSell":
            return "ReturnSell"
        case "anywhereMobile":
            return "AnywhereMobile"
        case "externalAnalytics":
            return "ExternalAnalytics"
        default:
            return undefined
    }
}

export function onboardingTypeIsAnyOf(onboardingType: TOnboardingType, onboardingTypes: TOnboardingType[]) {
    return onboardingTypes.some((type) => type === onboardingType)
}

export interface IOnboardingComponentsConfigEntity {
    component: JSX.Element | null
    condition: boolean
    id: TOnboardingStepId
}
export type TOnFirstStepGoBack = "showWebshopSelectAgain" | "navigateToPluginsPage"
export type TOnboardingStepId =
    | "sharedNoteDialog"
    | "acquiringPricingDialog"
    | "pspOnboarding"
    | "clearhausWithoutDataOnboarding"
    | "clearhausOnboarding"
    | "onboardingPricingDialog"
    | "dankortOnboarding"
    | "forbrugsforeningenOnboarding"
    | "hasViabillAlready"
    | "viabillOnboarding"
    | "swishOnboarding"
    | "vippsOnboarding"
    | "applePayOnboarding"
    | "klarnaOnboarding"
    | "mpoOnboarding"
    | "a2aOnboarding"
    | "resursOnboarding"
    | "anywhereMobileOnboarding"
    | "plusSellDisclaimer"
    | "plusSellOnboarding"
    | "returnSellOnboarding"
    | "winbackOnboarding"
    | "submittedDialog"
    | "externalAnalyticsOnboarding"
export function useServicesOnboardingPage(
    configRef: React.MutableRefObject<IOnboardingComponentsConfigEntity[]>,
    merchantDetails: MixedMerchantDetails,
    onFirstStepGoBack: TOnFirstStepGoBack
) {
    const dispatch = useReduxDispatch()

    const isCvrNotFound = useSelector<StoreState, boolean | undefined>((state) =>
        organizationSelectors.isCvrNotFound(state, merchantDetails.swMerchant.registrationNumber)
    )

    const [shouldSkipStepIds, setShouldSkipStepIds] = useState<TOnboardingStepId[]>([])
    const [shouldPreventSkipStepIds, setShouldPreventSkipStepIds] = useState<TOnboardingStepId[]>([])

    const [activeStepId, setActiveStepId] = useState("")
    const [isWebshopsSelected, setIsWebshopsSelected] = useState(false)

    useEffect(() => {
        if (isWebshopsSelected) {
            incrementStep("nextStep")
        }
    }, [isWebshopsSelected])

    useEffect(() => {
        setIsWebshopsSelected(false)
        setActiveStepId("")

        dispatch(getCompanyInfo(merchantDetails.swMerchant.countryCode, merchantDetails.swMerchant.registrationNumber))
    }, [merchantDetails])

    useEffect(() => {
        if (!isCvrNotFound) {
            return
        }
        // if cvr not found return <CvrNotFoundDialog> as active component
        // and set isWebshopsSelected so <WebshopSelectDialog> is hidden
        setIsWebshopsSelected(true)
    }, [isCvrNotFound])

    const incrementStep = (increment: "nextStep" | "previousStep") => {
        const stepsConfig = increment === "nextStep" ? configRef.current : configRef.current.reverse()

        if (increment === "previousStep" && activeStepId === "") {
            setIsWebshopsSelected(false)
            return
        }

        let previouslySelectedStepReached = false
        let stepSelected = false

        for (let i = 0; i < stepsConfig.length; i++) {
            const stepConfig = stepsConfig[i]
            const stepId = stepConfig.id

            const shouldSkipStep = shouldSkipStepIds.includes(stepId)
            const preventSkipStep = shouldPreventSkipStepIds.includes(stepId)

            if (activeStepId == stepId) {
                previouslySelectedStepReached = true
                continue
            }

            if (
                (previouslySelectedStepReached || activeStepId === "") &&
                !shouldSkipStep &&
                (stepConfig.condition || preventSkipStep)
            ) {
                setActiveStepId(stepId)
                stepSelected = true
                break
            }
        }

        if (!stepSelected) {
            setActiveStepId("")

            if (increment === "nextStep") {
                return
            }

            if (onFirstStepGoBack === "navigateToPluginsPage") {
                navigationService.navigate("/plugins")
                return
            }

            if (onFirstStepGoBack === "showWebshopSelectAgain") {
                setIsWebshopsSelected(false)
                setActiveStepId("")
            }
        }
    }

    return {
        isWebshopsSelected,
        setIsWebshopsSelected: (isWebshopsSelected: boolean) => setIsWebshopsSelected(isWebshopsSelected),
        getStepToShow: () => {
            if (isCvrNotFound) {
                return <CvrNotFoundDialog merchantDetails={merchantDetails} />
            }

            if (!isWebshopsSelected) {
                return null
            }

            return configRef.current.find((c) => c.id === activeStepId)?.component || null
        },
        incrementStep,
        addStepShouldSkip: (stepId: TOnboardingStepId) => {
            if (shouldSkipStepIds.includes(stepId)) {
                return
            }
            setShouldSkipStepIds((shouldSkipStepIds) => [...shouldSkipStepIds, stepId])
        },
        addStepPreventSkip: (stepId: TOnboardingStepId) => {
            if (shouldPreventSkipStepIds.includes(stepId)) {
                return
            }
            setShouldPreventSkipStepIds((shouldPreventSkipStepIds) => [...shouldPreventSkipStepIds, stepId])
        },
    }
}

const onboardingTypeToTermsType: { [onboardingType in TOnboardingType]: ETermsType } = {
    viabill: "TermsViaBill",
    dankort: "TermsDankort",
    forbrugsforeningen: "TermsForbrugsforeningen",
    cards: "TermsAcquiringClearhaus",
    plusSell: "TermsPlusSell",
    returnSell: "TermsReturnSell",
    anywhereMobile: "TermsAnywhereMobile",
    winback: "TermsWinBack",
    mpo: "TermsMobilePayOnline",
    swish: "TermsSwish",
    klarna: "TermsKlarna",
    vipps: "TermsVipps",
    applepay: "TermsApplePay",
    accountToAccount: "TermsA2a",
    resurs: "TermsResurs",
    externalAnalytics: "TermsExternalAnalytics",
}

export function getTermsTypeForOnboardingType(onboardingType: TOnboardingType): ETermsType {
    return onboardingTypeToTermsType[onboardingType]
}
