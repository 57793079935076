import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageContainer, PageWrapper, SpinnerContainer, getTextWithInvoicingPrices, useSelector } from "swiipe.portal.shared"
import FormHeaderLogoContainer from "../../components/form/title/FormHeaderLogoContainer"
import LegalTextLinkPopup from "../../components/legal/LegalTextLinkPopup"
import CancelService from "../../components/merchant/CancelService"
import { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import PspPricingRows from "../../components/paymentsOnboarding/psp/PspPricingRows"
import OnboardingPricingLine from "../../components/servicesonboarding/OnboardingPricingLine"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import { getImage } from "../../services/imageLanguageService"
import { navigationService } from "../../services/navigationService"
import { getProvider } from "../../services/paymentService"
import { StoreState } from "../../store/StoreState"
import { billingSelectors } from "../../store/reducers/billingReducer"
import { clearhausSelectors } from "../../store/reducers/clearhausReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IWebshopProviderConfig, merchantServicesSelectors } from "../../store/reducers/merchantServicesReducer"
import { cancelOnboardingThunk, getClearhausApplicationDataThunk } from "../../store/thunks/clearhausThunks"
import {
    chooseSwiipePspPlanThunk,
    getOnboardingStatusThunk,
    setPiqOnboardingStatusThunk,
} from "../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TSwiipePlan } from "../../type/IProvider"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./ClearhausDetailsPage.scss"

const ClearhausDetailsPage = () => {
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails) {
        return null
    }

    return <ClearhausDetailsPageInner merchantDetails={merchantDetails} />
}

interface IClearhausDetailsPageInner {
    merchantDetails: MixedMerchantDetails
}

const ClearhausDetailsPageInner = ({ merchantDetails }: IClearhausDetailsPageInner) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const swMerchantId = merchantDetails.swMerchant.swMerchantId

    const [showCancelService, setShowCancelService] = useState<"psp" | "acquiring" | undefined>()

    const { initialWebshopId: selectedWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)
    const wProviderConf = useSelector<StoreState, IWebshopProviderConfig | undefined>((state) =>
        merchantServicesSelectors.statuses(state, swMerchantId, selectedWebshopId)
    )
    const applicationData = useSelector((state: StoreState) => clearhausSelectors.getOnboardingData(state, selectedWebshopId))

    const prices = useSelector(billingSelectors.resolvedPrices)

    useEffect(() => {
        dispatch(getOnboardingStatusThunk(swMerchantId, selectedWebshopId, false))
        dispatch(getClearhausApplicationDataThunk(swMerchantId, [selectedWebshopId]))
    }, [swMerchantId, selectedWebshopId])

    const PspPricing = () => {
        if (!swiipePlan) {
            return null
        }

        return (
            <div className="option">
                <div className="main-title">PSP</div>
                <div className="price">
                    <PspPricingRows />
                </div>
                <LegalTextLinkPopup
                    legalTextBeforeLink=""
                    linkText={t("psponboarding.texts.termsForPsp")}
                    termsType="TermsPSP"
                    centered
                />
            </div>
        )
    }

    const AcquiringPricing = () => {
        return (
            <div className="option">
                <div className="main-title">{t("serviceNames.clearhaus")}</div>
                <div className="price">
                    <OnboardingPricingLine
                        enlargeSpans
                        priceHtml={getTextWithInvoicingPrices("clearhausonboarding.intro.transactionPct", prices, {
                            transactionPct: { category: "AcquirerTransactionPct" },
                        })}
                    />
                    <OnboardingPricingLine
                        enlargeSpans
                        priceHtml={getTextWithInvoicingPrices("clearhausonboarding.intro.priceMinimumTx", prices, {
                            acquirerMinTx: { type: "AcquirerMinTransactionShared" },
                        })}
                    />
                </div>

                <LegalTextLinkPopup
                    legalTextBeforeLink=""
                    linkText={t("clearhausonboarding.intro.termsAndConditions")}
                    termsType={"TermsAcquiringClearhaus"}
                    centered
                />
            </div>
        )
    }

    return (
        <>
            {showCancelService === "psp" && (
                <CancelService
                    serviceName={t("psponboarding.texts.pspPlan")}
                    cancelText={t("cancelService.psp.cancelText")}
                    customCancelledText={t("cancelService.psp.cancelledText")}
                    hintText={t("cancelService.psp.hintText")}
                    onCancel={async () => await dispatch(chooseSwiipePspPlanThunk(swMerchantId, swiipePlan, TSwiipePlan.None))}
                    onGoBack={() => setShowCancelService(undefined)}
                />
            )}
            {showCancelService === "acquiring" && (
                <CancelService
                    serviceName={t("serviceNames.cardPayments")}
                    cancelText={t("cancelService.cardPayments.cancelText")}
                    customCancelledText={t("cancelService.cardPayments.cancelledText")}
                    onCancel={async () => {
                        await dispatch(cancelOnboardingThunk(swMerchantId, applicationData?.onboardingId))
                        await dispatch(
                            setPiqOnboardingStatusThunk(swMerchantId, applicationData?.webshopIds || [selectedWebshopId], {
                                paymentType: "CreditCard",
                                providerName: "Clearhaus",
                                providerType: "Aquirer",
                                enabled: false,
                            })
                        )
                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                swMerchantId,
                                "GeneralCardPaymentsCancellationEmail",
                                t("serviceNames.cardPayments")
                            )
                        )
                        await dispatch(
                            getClearhausApplicationDataThunk(
                                swMerchantId,
                                applicationData?.webshopIds || [selectedWebshopId],
                                true
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(undefined)}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                <div className="go-back-container-clearhaus">
                    <GoBackContainer
                        goBackText={t("commononboardingtexts.backToAllService")}
                        onGoBack={() => navigationService.navigate("/plugins")}
                    />
                </div>
                <PageContainer id="clearhaus-details-page" transparent noHeader disableDefaultPaddingBottom>
                    <div className="main-container">
                        <FormHeaderLogoContainer
                            mainLogo={{
                                src: getImage("cardPaymentsLogoWithSchemes"),
                                alt: "swiipe-acquiring-logo",
                                width: "235px",
                            }}
                        />
                        <SpinnerContainer showSpinner={!swiipePlan}>
                            <PspPricing />
                            {!!swiipePlan && (
                                <SharedCancelContainer
                                    cancelText={t("psponboarding.buttons.cancelPsp")}
                                    onCancel={() => setShowCancelService("psp")}
                                />
                            )}

                            <hr className="divider" />

                            <AcquiringPricing />
                            {getProvider("CreditCard", "Clearhaus", wProviderConf?.gatewayProviders)?.enabled && (
                                <SharedCancelContainer
                                    cancelText={t("clearhausonboarding.cancelPayments")}
                                    onCancel={() => setShowCancelService("acquiring")}
                                />
                            )}
                        </SpinnerContainer>
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default ClearhausDetailsPage
