import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { ServerError, prepareFormData, requestThunk, sharedConfigurationSelectors, userSelectors } from "swiipe.portal.shared"
import { authService } from "../../services/authService"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { ResetPasswordFormModel } from "../../type/ResetPasswordFormModel"
import { LoginFormModel } from "../../type/loginform"
import { StoreState } from "../StoreState"
import { endpoints } from "./../../data/endpoints"
import { navigationService } from "./../../services/navigationService"
import { ChangePasswordFormModel } from "./../../type/ChangePasswordFormModel"
import { ConfirmAccountFormModel } from "./../../type/ConfirmAccountFormModel"
import { ForgotPasswordFormModel } from "./../../type/ForgotPasswordFormModel"

export interface ICheckCookieResponse {
    status: boolean
    expUnixTime?: string
}

const latestExpirationTimeKey = "latestCookieExpiration"

const pushStateListeners: (() => void)[] = []

const pushState = window.history.pushState
window.history.pushState = function (...args) {
    pushState.apply(window.history, args)
    pushStateListeners.forEach((l) => l())
}

export const checkCookieThunk = (): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> => async (dispatch) => {
    const response = await dispatch(requestThunk<ICheckCookieResponse>(endpoints.Self.checkCookie))

    if (window.self !== window.top) {
        // Do not start new cookie handling inside iframe
        return response.status
    }

    dispatch(handleCookieTimeoutThunk(response))

    return response.status
}

const isLatestExpireTime = (cookieExpireTime: number) => {
    const storedExpirationStr = localStorage.getItem(latestExpirationTimeKey)
    const storedExpiration = storedExpirationStr ? parseInt(storedExpirationStr) : undefined
    const isCookieLatest = storedExpiration && cookieExpireTime >= storedExpiration
    return !storedExpiration || isCookieLatest
}

const handleCookieTimeoutThunk =
    (cookieResponse: ICheckCookieResponse): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!cookieResponse.expUnixTime) {
            return
        }

        const cookieValidUntilSeconds = parseInt(cookieResponse.expUnixTime) // unix time is in seconds
        const elapsedTimeInSeconds = Math.floor(Date.now() / 1000) // js time is in milliseconds - divide by 1000 to get seconds
        const timeoutSeconds = Math.max(cookieValidUntilSeconds - elapsedTimeInSeconds, 0)
        const renewBufferSeconds = 120 + Math.floor(Math.random() * 120) // Random time slack to avoid all renewing at the same time
        const renewTimeSeconds = Math.max(timeoutSeconds - renewBufferSeconds, 0)

        // If renew fails the normal logged out modal should trigger else reschedule
        const showModalTimeout = setTimeout(async () => {
            const cookieResponse = await dispatch(requestThunk<ICheckCookieResponse>(endpoints.Self.checkCookie))
            const cookieExpireTime = cookieResponse.expUnixTime ? parseInt(cookieResponse.expUnixTime) : undefined

            if (cookieExpireTime && isLatestExpireTime(cookieExpireTime)) {
                localStorage.setItem(latestExpirationTimeKey, cookieExpireTime.toString())
                dispatch(handleCookieTimeoutThunk(cookieResponse))
                return
            }
            await dispatch(addModalThunk({ type: "modalLoggedOut" }))
        }, timeoutSeconds * 1000)

        if (timeoutSeconds > renewBufferSeconds) {
            // Schedule auto cookie renewal
            // Only renew if the users is active in the session to avoid excessive calls to auth
            let isTheUserThere = false
            const handleNavigation = () => (isTheUserThere = true)
            const handleScroll = () => (isTheUserThere = true)

            pushStateListeners.push(handleNavigation)
            document.addEventListener("scroll", handleScroll)

            setTimeout(async () => {
                pushStateListeners.splice(pushStateListeners.indexOf(handleNavigation), 1)
                document.removeEventListener("scroll", handleScroll)

                const container = document.getElementById("cookie-expiration-extender")
                if (!container || !isTheUserThere || !isLatestExpireTime(cookieValidUntilSeconds)) {
                    // If already renewed from other tabs
                    return
                }
                localStorage.setItem(latestExpirationTimeKey, (cookieValidUntilSeconds + 1800).toString()) // Add 30 min to prevent other threads from trying to renew

                // Insert cookie into the cookie-expiration-extender div
                const iframe = document.createElement("iframe")
                iframe.src = document.location.origin + "?forceauth=1"
                iframe.style["height"] = "0px"
                iframe.style["display"] = "none"
                container.appendChild(iframe)

                // The until we expect the cookie renewal the succeed - so we remove the iframe after that
                const timeToFinishLogin = 20 * 1000
                setTimeout(async () => {
                    container.removeChild(iframe)
                }, timeToFinishLogin)
            }, renewTimeSeconds * 1000)
        }
    }

export const loginThunk =
    (form: LoginFormModel, returnUrl?: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const preparedForm = prepareFormData(form, [])
        const otac = await dispatch(
            requestThunk<string>(
                endpoints.Auth.login,
                {
                    data: preparedForm,
                },
                {
                    errorHandlers: [
                        {
                            errorType: "EmailNotConfirmedError",
                            handleError: async (error: ServerError) => {
                                await dispatch(
                                    addModalThunk({
                                        type: "loginConfirmEmail",
                                        errorMessage: error.errorToAlert,
                                        sendToEmail: preparedForm.email,
                                    })
                                )
                                return true
                            },
                        },
                    ],
                }
            )
        )
        await authService.login(returnUrl || "/", otac)
    }

export const logOutThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch) => {
    const response = await dispatch(addModalThunk({ type: "logOut" }))
    if (response.type === "accepted") {
        navigationService.navigate("forgotpassword")
    }
}

export const forgotPasswordThunk =
    (form: ForgotPasswordFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const serverConfiguration = sharedConfigurationSelectors.serverConfiguration(getState())
        await dispatch(
            requestThunk<string>(endpoints.Auth.forgotPassword, {
                data: { ...prepareFormData(form, []), returnUrl: serverConfiguration.clientUrl + "/forgotpassword" },
            })
        )
    }

export const resetPasswordThunk =
    (
        form: ResetPasswordFormModel | ConfirmAccountFormModel,
        returnUrl?: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<string>(endpoints.Auth.resetPassword, {
                data: {
                    ...prepareFormData(form, []),
                },
            })
        )
        // If password reset was successful, log in the user and redirect home
        await dispatch(loginThunk({ email: form.email, password: form.password }, returnUrl))
    }

export const changePasswordThunk =
    (form: ChangePasswordFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userData = userSelectors.userData(getState())
        const email = userData ? (userData.user ? userData.user.email : "") : ""
        const preparedForm = prepareFormData(form, [])
        await dispatch(
            requestThunk<string>(endpoints.Auth.changePassword, {
                data: {
                    currentPassword: preparedForm.existing_password,
                    newPassword: preparedForm.new_password,
                    email,
                    returnUrl: "/changepassword",
                },
            })
        )

        navigationService.navigate("/")
    }
