const allApiKeyScopes = ["CheckoutPluginBase", "PaymentPostAuthActions", "AccountingSystem", "Recommendations"] as const

export type TApiKeyScope = (typeof allApiKeyScopes)[number]

export function getAllApiKeyScopes() {
    return allApiKeyScopes
}

export interface IApiKey {
    apiKey: string
    scopeTypes: TApiKeyScope[]
    created: string
    integratorName: string
}

export const allowedIntegratorNames: { [key in TApiKeyScope]: string[] } = {
    CheckoutPluginBase: ["SwiipeEcommercePlugin"],
    PaymentPostAuthActions: ["Shipmondo"],
    AccountingSystem: ["Storebuddy"],
    Recommendations: ["Homerunner"],
}
