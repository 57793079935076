import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "swiipe.portal.shared"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../../store/reducers/userRelationReducer"
import {
    deleteMerchantContactThunk,
    getOwnersContactInfoThunk,
    updateSwMerchantContactDataThunk,
} from "../../../store/thunks/merchantThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { SwMerchantContactDto } from "../../../type/SwMerchantMainContactDto"
import { TUserRole } from "../../../type/TUserRole"
import InfoBoxAdder from "../../infobox/InfoBoxAdder"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import MerchantContactInfoFields from "../fieldsets/MerchantContactInfoFields"
import "./MerchantContactInfoForm.scss"

interface IMerchantContactInfoFormProps {
    contactIdInEditMode?: string
    onSwitchEditMode: (id: string, isInEditMode: boolean) => void
}

const MerchantContactInfoForm = ({ contactIdInEditMode, onSwitchEditMode }: IMerchantContactInfoFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!

    const [useOwnerInfoBtnActiveContactId, setUseOwnerInfoBtnActiveContactId] = useState<string | undefined>()

    if (!merchantDetails || !currentUserRelation) {
        return null
    }

    const contacts = merchantDetails.swMerchantContacts

    return (
        <InfoBoxContainer
            className="merchant-contact-info-box"
            isInEditMode={!!contactIdInEditMode}
            iconClass="icon-contact"
            title={t("merchantsettings.contactperson")}
        >
            {contacts.map((contact) => (
                <InfoBoxEditor<SwMerchantContactDto>
                    key={contact.id}
                    state={currentUserRelation.userRole >= TUserRole.Admin ? InfoBoxState.DEFAULT : InfoBoxState.NOEDIT}
                    iconClass="icon-contact"
                    title={t("personal.editcontactinfo")}
                    isInEditMode={contactIdInEditMode === contact.id}
                    editBtnTextType="editing"
                    renderPreview={() => (
                        <div>
                            {contact.name && <div className="merchant-contact-info-box__preview-row">{contact.name}</div>}
                            {contact.phone && <div className="merchant-contact-info-box__preview-row">{contact.phone}</div>}
                            {contact.email && <div className="merchant-contact-info-box__preview-row">{contact.email}</div>}
                            {contact.comment && (
                                <div className="merchant-contact-info-box__preview-row merchant-contact-info-box__preview-row--comment">
                                    {contact.comment}
                                </div>
                            )}
                        </div>
                    )}
                    onSwitchEditMode={(isInEditMode) => {
                        onSwitchEditMode(contact.id, isInEditMode)
                        setUseOwnerInfoBtnActiveContactId(undefined)
                    }}
                    renderFormFields={(register, setValue, getValues, watch) => (
                        <MerchantContactInfoFields
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                            watch={watch}
                            contact={contact}
                        />
                    )}
                    onSave={async (model) => {
                        model.id = contact.id
                        await dispatch(updateSwMerchantContactDataThunk(model, merchantDetails.swMerchant.swMerchantId))
                    }}
                    allowDelete
                    deleteButtonTitle={
                        useOwnerInfoBtnActiveContactId === contact.id ? t("personal.useOwnersInfo") : t("personal.delete")
                    }
                    deleteButtonTextSmaller={useOwnerInfoBtnActiveContactId === contact.id}
                    componentUnmountsAfterDelete
                    onDelete={async (setShowSpinner, setValue, setError, clearErrors) => {
                        setShowSpinner(true)

                        if (useOwnerInfoBtnActiveContactId == contact.id) {
                            // Fetch owner info
                            const ownerContact = await dispatch(
                                getOwnersContactInfoThunk(merchantDetails.swMerchant.swMerchantId)
                            )

                            // Set owner info
                            setValue("firstName", ownerContact.firstName)
                            setValue("lastName", ownerContact.lastName)
                            setValue("phone", ownerContact.phone)
                            setValue("email", ownerContact.email)
                            setValue("comment", "")

                            clearErrors("comment")
                            setShowSpinner(false)
                            return
                        }

                        if (contacts.length == 1) {
                            // Activate "useOwnerInfo" button if only one contact left
                            setError("comment", { message: t("personal.validationerrors.contactInfoCantBeEmpty") })
                            setUseOwnerInfoBtnActiveContactId(contact.id)
                            setShowSpinner(false)
                            return
                        }

                        //Perform deletion
                        await dispatch(deleteMerchantContactThunk(merchantDetails.swMerchant.swMerchantId, contact.id))
                        setShowSpinner(false)
                    }}
                />
            ))}
            <InfoBoxAdder<SwMerchantContactDto>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-contact"
                title={t("personal.addContactPerson")}
                adderLabel={t("personal.addNewContactPerson")}
                isInEditMode={contactIdInEditMode === "-1"}
                onSwitchEditMode={(isInEditMode) => onSwitchEditMode("-1", isInEditMode)}
                isFirstItem={contacts.length === 0}
                renderFirstItemPreview={() => (
                    <div>
                        <strong>{t("personal.addContactPerson")}</strong>
                    </div>
                )}
                renderFormFields={(register, setValue, getValues, watch) => (
                    <MerchantContactInfoFields register={register} setValue={setValue} getValues={getValues} watch={watch} />
                )}
                onSave={async (model) => {
                    await dispatch(updateSwMerchantContactDataThunk(model, merchantDetails.swMerchant.swMerchantId))
                }}
            />
        </InfoBoxContainer>
    )
}

export default MerchantContactInfoForm
