import cn from "classnames"
import React from "react"
import { Input, InputProps } from "reactstrap"
import { FieldsetFormGroup } from "swiipe.portal.shared"
import "./FixedLabelInput.scss"

export interface IFixedLabelInput extends InputProps {
    label: string
    labelBold?: boolean
    className?: string
    inputClassName?: string
    labelClassName?: string
}

const FixedLabelInput = ({ label, labelBold, className, inputClassName, labelClassName, ...otherProps }: IFixedLabelInput) => {
    return (
        <FieldsetFormGroup field className={className}>
            <span className={cn("fixed-label", "blue", labelClassName, { bold: labelBold })}>{label}</span>
            <Input {...otherProps} className={cn("fixed-label-input", inputClassName)} />
        </FieldsetFormGroup>
    )
}

export default FixedLabelInput
