import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert, Input, Label, ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter, StandardButton, TextButton } from "swiipe.portal.shared"
import { openContactUsWidget } from "../../services/contactUsService"
import "./ModalGAOnboardingErrorDialog.scss"

export type TGoogleAnalyticsModalError = "streamError" | "propertyError"
export interface IModalGAOnboardingErrorDialog extends IModalBase {
    type: "gaOnboardingErrorDialog"
    errorType: TGoogleAnalyticsModalError
}

const ModalGAOnboardingErrorDialog = ({
    errorType,
    callback,
    ...otherProps
}: IModalGAOnboardingErrorDialog & IModalControlProps) => {
    const { t } = useTranslation()
    const [proceedAnywayChecked, setProceedAnywayChecked] = useState(false)

    const showProceedAnywayDialog = errorType === "streamError"

    return (
        <BaseModal
            {...otherProps}
            callback={callback}
            title={t(`analyticsOnboarding.errorModal.${errorType}.title`)}
            centered
            modalClassName="modal-ga-onboarding-error"
            showCloseButton
        >
            <ModalBody>
                <div className="content-container">
                    <p className="mb-1 mt-3">{t("analyticsOnboarding.errorModal.pleaseEnsureThat")}</p>
                    <ul>
                        {showProceedAnywayDialog ? (
                            <>
                                <li>{t("analyticsOnboarding.errorModal.streamError.listItem1")}</li>
                                <li>{t("analyticsOnboarding.errorModal.streamError.listItem2")}</li>
                                <li>{t("analyticsOnboarding.errorModal.streamError.listItem3")}</li>
                            </>
                        ) : (
                            <>
                                <li>{t("analyticsOnboarding.errorModal.propertyError.listItem1")}</li>
                                <li>{t("analyticsOnboarding.errorModal.propertyError.listItem2")}</li>
                            </>
                        )}
                    </ul>

                    {showProceedAnywayDialog && (
                        <Alert isOpen={true} color="danger" className="mt-4">
                            <p>{t("analyticsOnboarding.errorModal.streamError.alertText")}</p>
                            <Label check>
                                <Input
                                    className="swiipe-checkbox"
                                    onChange={() => setProceedAnywayChecked(!proceedAnywayChecked)}
                                    type="checkbox"
                                />
                                {t("analyticsOnboarding.errorModal.streamError.checkboxText")}
                            </Label>
                        </Alert>
                    )}

                    <div className={cn("buttons mt-5 mb-3", { centered: !showProceedAnywayDialog })}>
                        <StandardButton dark noBorder onClick={() => callback("declined")}>
                            {showProceedAnywayDialog
                                ? t("common.close")
                                : t("analyticsOnboarding.errorModal.propertyError.buttonOk")}
                        </StandardButton>
                        {showProceedAnywayDialog && (
                            <StandardButton invertedRed onClick={() => callback("accepted")} disabled={!proceedAnywayChecked}>
                                {t("analyticsOnboarding.errorModal.streamError.confirmNoDataBtn")}
                            </StandardButton>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <TextButton
                    text={t("analyticsOnboarding.errorModal.needHelp")}
                    color="grey"
                    textDecoration="underline"
                    onClick={openContactUsWidget}
                />
            </ModalFooter>
        </BaseModal>
    )
}

export default ModalGAOnboardingErrorDialog
