import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { DropDownList, StandardButtonWithSpinner } from "swiipe.portal.shared"
import { addMerchantApiKey } from "../../store/thunks/merchantThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TApiKeyScope, allowedIntegratorNames, getAllApiKeyScopes } from "../../type/IApiKey"
import Adder from "../form/common/Adder"
import EditFormInput from "../form/input/EditFormInput"
import "./AddApiKeyForm.scss"

interface IAddApiKeyFormProps {}

const AddApiKeyForm = ({}: IAddApiKeyFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [showForm, setShowForm] = useState(false)
    const [currentScope, setCurrentScope] = useState<TApiKeyScope>(getAllApiKeyScopes()[0])
    const [integratorNameOption, setIntegratorNameOption] = useState<string>(allowedIntegratorNames[currentScope][0])
    const [customIntegratorName, setCustomIntegratorName] = useState<string>("")
    const [submitTried, setSubmitTried] = useState(false)

    if (!showForm) {
        return (
            <div className="add-apikey-form adder">
                <Adder onClick={() => setShowForm(true)} buttonText={t("apikey.add")} />
            </div>
        )
    }

    return (
        <div className="add-apikey-form form">
            <div className="add-apikey-form__title">{t("apikey.add")}</div>
            <div className="add-apikey-form__input-title">{t("apikey.scope")}</div>
            <DropDownList
                className="add-apikey-form__scope"
                options={getAllApiKeyScopes().map((sc) => ({ text: t("apikey.scopeTitle." + sc), value: sc }))}
                value={currentScope}
                onChange={(e) => {
                    const newScope = e.currentTarget.value as TApiKeyScope
                    setCurrentScope(newScope)
                    setIntegratorNameOption(allowedIntegratorNames[newScope][0])
                }}
            ></DropDownList>
            <div className="add-apikey-form__desc">{t("apikey.scopeDescription." + currentScope)}</div>
            <div className="add-apikey-form__input-title">{t("apikey.integratorName.inputTitle")}</div>
            <DropDownList
                className="add-apikey-form__integrator-name"
                options={[
                    ...allowedIntegratorNames[currentScope].map((sc) => ({ text: sc, value: sc })),
                    { text: t("apikey.integratorName.other"), value: "other" },
                ]}
                value={integratorNameOption}
                onChange={(e) => {
                    setIntegratorNameOption(e.currentTarget.value)
                }}
            ></DropDownList>
            {integratorNameOption === "other" && (
                <>
                    <EditFormInput
                        defaultValue={""}
                        groupClassName="add-apikey-form__custom-integrator-name"
                        name="customIntegratorName"
                        placeholder={t("apikey.integratorName.customTitle")}
                        value={customIntegratorName}
                        onChange={(e) => {
                            setCustomIntegratorName(e.target.value === "other" ? "" : e.target.value)
                        }}
                    />
                    {submitTried && !customIntegratorName.trim() && (
                        <div className="add-apikey-form__custom-integrator-name-error">
                            {t("apikey.integratorName.customError")}
                        </div>
                    )}
                </>
            )}
            <StandardButtonWithSpinner
                title={t("apikey.save")}
                className="add-apikey-form__save-button"
                onClick={async () => {
                    try {
                        if (integratorNameOption === "other" && !customIntegratorName.trim()) {
                            setSubmitTried(true)
                            return
                        }
                        setSubmitTried(false)
                        await dispatch(
                            addMerchantApiKey(
                                currentScope,
                                integratorNameOption === "other" ? customIntegratorName : integratorNameOption
                            )
                        )
                        setShowForm(false)
                    } catch (ex) {
                        // Ignore
                    }
                }}
            />
        </div>
    )
}

export default AddApiKeyForm
