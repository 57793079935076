import cn from "classnames"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Input } from "reactstrap"
import { StandardButton, copyToClipboard } from "swiipe.portal.shared"
import { IApiKey } from "../../type/IApiKey"
import ToolTipContainer from "../text/ToolTipContainer"
import PageSection from "./../page/PageSection"
import "./ApiKey.scss"

interface IApiKeysProps {
    apiKey: IApiKey
}

const ApiKey = ({ apiKey }: IApiKeysProps) => {
    const { t } = useTranslation()
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>()

    return (
        <PageSection>
            <div className={cn("row item-container api-key-container")}>
                <div className="col-md-9 col-sm-8 col-7">
                    <Input innerRef={(ref) => (inputRef.current = ref)} readOnly bsSize="lg" value={apiKey.apiKey} />
                    <div className="scope">
                        {apiKey.integratorName && (
                            <div>
                                <b>{t("apikey.integratorName.title")}</b>: {apiKey.integratorName}
                            </div>
                        )}
                        {/* Currently only supporting one scope */}
                        {apiKey.scopeTypes.length > 0 && (
                            <div>
                                <b>{t("apikey.scope")}</b>:{" "}
                                <ToolTipContainer text={t("apikey.scopeDescription." + apiKey.scopeTypes[0])}>
                                    {t("apikey.scopeTitle." + apiKey.scopeTypes[0])}
                                </ToolTipContainer>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-3  col-sm-4 col-5">
                    <StandardButton
                        onClick={() => {
                            if (inputRef.current) {
                                copyToClipboard(inputRef.current as HTMLInputElement)
                            }
                        }}
                        title={t("apikey.copy")}
                    />
                </div>
            </div>
        </PageSection>
    )
}

export default ApiKey
