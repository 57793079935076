import cn from "classnames"
import React from "react"
import { getFormattedNumber } from "swiipe.portal.shared"
import { IMerchantDashboardValue } from "../../../type/merchantDashboard/IMerchantDashboardValue"
import MerchantDashboardCellContainer from "../MerchantDashboardCellContainer"
import MerchantDashboardCellTitle from "../MerchantDashboardCellTitle"
import MerchantDashboardCompareDiff from "../MerchantDashboardCompareDiff"
import MerchantDashboardNoData from "./MerchantDashboardTileNoData"
import "./MerchantDashboardTileSimpleValues.scss"
import MerchantDashboardTileOverlayContent from "./overlay/MerchantDashboardTileOverlayContent"

export interface IMerchantDashboardSimpleValue {
    value: IMerchantDashboardValue
    subtitle: string
}

interface IMerchantDashboardSimpleValueWithIndex extends IMerchantDashboardSimpleValue {
    index: number
}

interface IMerchantDashboardTileSimpleValuesProps {
    title: string
    icon?: string
    subtitle: string
    simpleValues?: IMerchantDashboardSimpleValue[]
    compareValues?: IMerchantDashboardSimpleValue[]
    notEnabled?: boolean
    noDataText?: string
    overlayContent?: JSX.Element
}

const MerchantDashboardTileSimpleValues = ({
    title,
    icon,
    subtitle,
    simpleValues,
    compareValues,
    notEnabled,
    noDataText,
    overlayContent,
}: IMerchantDashboardTileSimpleValuesProps) => {
    if (notEnabled || !simpleValues || simpleValues.length === 0) {
        return (
            <MerchantDashboardNoData
                title={title}
                icon={icon}
                subtitle={subtitle}
                isSwiipePaymentsNotEnabled={notEnabled}
                noDataText={noDataText}
            >
                {!!overlayContent && <MerchantDashboardTileOverlayContent>{overlayContent}</MerchantDashboardTileOverlayContent>}
            </MerchantDashboardNoData>
        )
    }

    const data = (simpleValues ?? []).reduce<(IMerchantDashboardSimpleValueWithIndex | "divider")[]>((res, item, index) => {
        return index === 0 ? [...res, { ...item, index: index }] : [...res, "divider", { ...item, index: index }]
    }, [])

    return (
        <MerchantDashboardCellContainer
            className="merchant-dashboard-tile-simple-values"
            valuesDisabled={!simpleValues.find((v) => v.value.amount !== 0) && !overlayContent}
        >
            <MerchantDashboardCellTitle title={title} icon={icon} subtitle={subtitle} />
            <div className="simple-values">
                {data.map((sv, i) => {
                    if (sv === "divider") {
                        return <div key={i} className="divider"></div>
                    }
                    const textColorClass = sv.value.amount < 0 ? "negative" : "positive"
                    const formattedNumber = getFormattedNumber(sv.value.amount)
                    return (
                        <div key={i} className={cn("simple-value", simpleValues.length <= 1 && "single-value")}>
                            <div className="value-container">
                                <div className={cn("value", textColorClass)}>
                                    {getFormattedNumber(sv.value.amount)}
                                    {sv.value.isPercent ? "%" : ""}
                                </div>
                                {sv.value.currency && <div className={cn("currency", textColorClass)}>{sv.value.currency}</div>}
                                {compareValues && compareValues.length > sv.index && (
                                    <div className={cn("data-compare", { "absolute-transformed": formattedNumber.length <= 4 })}>
                                        <MerchantDashboardCompareDiff
                                            isLarge
                                            diffValuePoints={sv.value.isPercent}
                                            value={sv.value.amount}
                                            compareValue={compareValues[sv.index].value.amount}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={cn("subtitle", textColorClass)}>{sv.subtitle}</div>
                        </div>
                    )
                })}
            </div>
            {!!overlayContent && <MerchantDashboardTileOverlayContent>{overlayContent}</MerchantDashboardTileOverlayContent>}
        </MerchantDashboardCellContainer>
    )
}

export default MerchantDashboardTileSimpleValues
