import cn from "classnames"
import React from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    CompletedFieldsReview,
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    TitleWithAbsoluteHint,
    getCurrencyDropdownOptions,
    inputOnChangeForceCapitalLetters,
    valFuncRequired,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { IClearhausApplicationData } from "../../../../type/clearhaus/IClearhausApplicationData"
import { IClearhausApplicationForm } from "../../../../type/clearhaus/IClearhausApplicationForm"
import { TClearhausFormState } from "../../../../type/clearhaus/TClearhausFormState"
import { ClearhausUpdateInfoButton } from "../../../clearhaus/ClearhausUpdateInfoButton"
import "./ClearhausBankAccountFields.scss"

interface IBankAccountFieldsProps {
    control: Control<IClearhausApplicationForm>
    clearhausFormState: TClearhausFormState
    applicationData?: IClearhausApplicationData
}

const ClearhausBankAccountFields = ({ control, clearhausFormState, applicationData }: IBankAccountFieldsProps) => {
    const { t } = useTranslation()
    const savedFields = applicationData?.applicationFields?.bank
    const currentFields = control.getValues()?.bank

    return (
        <div
            className={cn(
                "clearhaus-bank-account-fields",
                clearhausFormState === "submitted" && "clearhaus-bank-account-fields--submitted"
            )}
        >
            <div className={cn("clearhaus-bank-account-fields__first-page-fields", { "d-none": clearhausFormState !== "first" })}>
                <FieldsetFormGroup field>
                    <DropDownList
                        name="bank.currency"
                        options={getCurrencyDropdownOptions()}
                        innerRef={control.register()}
                    ></DropDownList>
                </FieldsetFormGroup>
                <FieldsetFormGroup className="mb-0" field>
                    <FloatingLabelInput
                        placeholder={t("clearhausonboarding.inputfields.iban")}
                        hintInsideInput={t("clearhausonboarding.messagetitles.ibanHint")}
                        name="bank.iban"
                        type="text"
                        innerRef={control.register(
                            valFuncRequiredAndPattern(
                                validationPatterns.ibanNumber,
                                t("clearhausonboarding.inputfields.errormsg.iban")
                            )
                        )}
                        onChange={(e) => inputOnChangeForceCapitalLetters(e, "bank.iban", control.setValue)}
                    ></FloatingLabelInput>
                </FieldsetFormGroup>
            </div>

            {clearhausFormState === "second" && (
                <>
                    <div className="clearhaus-bank-account-fields__second-page-fields">
                        <CompletedFieldsReview>
                            {t("transactions.currency")}: {savedFields?.currency || currentFields?.currency}
                            <br />
                            {t("common.iban")}: {savedFields?.iban || currentFields?.iban}
                        </CompletedFieldsReview>
                        <FieldsetFormGroup field>
                            <FloatingLabelInput
                                placeholder={t("clearhausonboarding.inputfields.swiftcode")}
                                hintInsideInput={t("clearhausonboarding.messagetitles.swiftHint")}
                                name="bank.swiftCode"
                                type="text"
                                innerRef={control.register(
                                    valFuncRequiredAndPattern(
                                        validationPatterns.swiftNumber,
                                        t("clearhausonboarding.inputfields.errormsg.swiftcode")
                                    )
                                )}
                                onChange={(e) => inputOnChangeForceCapitalLetters(e, "bank.swiftCode", control.setValue)}
                            ></FloatingLabelInput>
                        </FieldsetFormGroup>
                        <FieldsetFormGroup className="mb-0" field>
                            <FloatingLabelInput
                                placeholder={t("clearhausonboarding.inputfields.bankname")}
                                name="bank.name"
                                type="text"
                                innerRef={control.register(
                                    valFuncRequired(t("clearhausonboarding.inputfields.errormsg.bankname"))
                                )}
                            ></FloatingLabelInput>
                        </FieldsetFormGroup>
                    </div>
                    <div>
                        <TitleWithAbsoluteHint
                            className="clearhaus-bank-account-fields__info-hint"
                            titleClassName="clearhaus-bank-account-fields__hint-title"
                            title={t("acquiringOnboarding.common.whyDoWeNeedInfo")}
                            hintText={t("acquiringOnboarding.clearhaus.whyInfoDesc.bank")}
                        />
                    </div>
                </>
            )}

            {clearhausFormState === "submitted" && (
                <>
                    <CompletedFieldsReview>
                        {t("transactions.currency")}: {savedFields?.currency}
                        <br />
                        {t("common.iban")}: {savedFields?.iban}
                        <br />
                        {savedFields?.name}
                        <br />
                        {t("common.swift")}: {savedFields?.swiftCode}
                    </CompletedFieldsReview>
                    <ClearhausUpdateInfoButton applicationData={applicationData} />
                </>
            )}
        </div>
    )
}

export default ClearhausBankAccountFields
