import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    FormHeader,
    IFormNavigationControl,
    PageContainer,
    PageWrapper,
    getCompanyInfo,
    getQueryValueFromCurrentUrl,
    useSelector,
} from "swiipe.portal.shared"
import { ClearhausApplicationComments } from "../../../components/clearhaus/ClearhausApplicationComments"
import ClearhausOnboardingMessage from "../../../components/clearhaus/ClearhausOnboardingMessage"
import ClearhausOnboardingForm from "../../../components/form/clearhaus/ClearhausOnboardingForm"
import { navigationService } from "../../../services/navigationService"
import { StoreState } from "../../../store/StoreState"
import { clearhausSelectors } from "../../../store/reducers/clearhausReducer"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { fetchClearhausApplicationCommentsThunk, getClearhausApplicationDataThunk } from "../../../store/thunks/clearhausThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { IClearhausCommentsData } from "../../../type/clearhaus/IClearhausCommentsData"
import { TClearhausDialogType } from "../../../type/clearhaus/TClearhausDialogType"
import { TClearhausFormState } from "../../../type/clearhaus/TClearhausFormState"
import { MixedMerchantDetails } from "../../../type/mixedmerchantdetails"
import { scrollToTop } from "../../../util/animationUtil"

interface IClearHausOnboardingPageInnerProps {
    merchantDetails: MixedMerchantDetails
    editingWebshopId: string
}

const ClearhausEditPage = () => {
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const webshopId = getQueryValueFromCurrentUrl("webshopId")

    if (!merchantDetails || !webshopId) {
        return null
    }

    return <ClearhausEditPageInner merchantDetails={merchantDetails} editingWebshopId={webshopId} />
}

const ClearhausEditPageInner = ({ merchantDetails, editingWebshopId }: IClearHausOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const swMerchantId = merchantDetails.swMerchant.swMerchantId

    //Keep in a state so no multiple applicationData sate updates triggered
    const [webshopIds] = useState([editingWebshopId])

    const [clearhausFormState, setClearhausFormState] = useState<TClearhausFormState>("first")
    const [activeDialog, setActiveDialog] = useState<TClearhausDialogType | undefined>()

    const navigationControl = useMemo<IFormNavigationControl>(() => ({}), [])

    useEffect(() => {
        dispatch(getClearhausApplicationDataThunk(swMerchantId, webshopIds))
        dispatch(fetchClearhausApplicationCommentsThunk(swMerchantId, webshopIds[0], false))
    }, [])

    useEffect(() => {
        dispatch(getCompanyInfo(merchantDetails.swMerchant.countryCode, merchantDetails.swMerchant.registrationNumber))
    }, [merchantDetails])

    const commentsData = useSelector<StoreState, IClearhausCommentsData | undefined>((state) =>
        clearhausSelectors.getApplicationComments(state, webshopIds[0])
    )

    const applicationData = useSelector((state: StoreState) => clearhausSelectors.getOnboardingData(state, webshopIds[0]))

    if (!applicationData) {
        return null
    }

    return (
        <PageWrapper widest>
            <PageContainer transparent>
                <FormHeader
                    title={t("editclearhaus.header")}
                    navigationControl={navigationControl}
                    onClose={() => navigationService.navigate("/plugins")}
                />
                {!activeDialog && (
                    <>
                        <ClearhausApplicationComments commentsData={commentsData} />
                        <ClearhausOnboardingForm
                            isEditing
                            webshopIds={webshopIds}
                            merchantDetails={merchantDetails}
                            onGoBack={() => navigationService.navigate("/plugins")}
                            navigationControl={navigationControl}
                            clearhausFormState={clearhausFormState}
                            formStateUpdatedCallback={(newState) => {
                                if (clearhausFormState === "second" && newState === "submitted") {
                                    setActiveDialog("submitted")
                                }

                                setClearhausFormState(newState)
                                scrollToTop()
                            }}
                            applicationData={applicationData}
                            allowInstantOnboarding={false}
                            setAllowInstantOnboarding={(allow) => {}}
                            commentsData={commentsData}
                        />
                    </>
                )}
                {activeDialog === "submitted" && (
                    <ClearhausOnboardingMessage
                        mainText={
                            <>
                                <p>
                                    <b>{t("clearhausonboarding.disclaimers.applicationSubmitted")}</b>
                                </p>
                                <p>{t("clearhausonboarding.disclaimers.editPage.canTakeDays")}</p>
                            </>
                        }
                        mainButton={{
                            text: t("common.ok"),
                            onClick: () => navigationService.navigate("/plugins"),
                        }}
                    />
                )}
            </PageContainer>
        </PageWrapper>
    )
}

export default ClearhausEditPage
