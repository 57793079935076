import React from "react"
import { useTranslation } from "react-i18next"
import { BillingAddressDto, DeliveryLocationDto, getCountryByISO, useSelector, userSelectors } from "swiipe.portal.shared"
import bringIcon from "../../../images/delivery/bring-logo.png"
import daoIcon from "../../../images/delivery/dao.svg"
import glsIcon from "../../../images/delivery/gls.png"
import postnordIcon from "../../../images/delivery/postnord.png"
import { createKey, groupDeliveryLocations } from "../../../services/deliveryLocationsService"
import { deleteDeliveryLocationThunk, ensureDeliveryLocationThunk } from "../../../store/thunks/userPersonalInfoThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import InfoBoxAdder from "../../infobox/InfoBoxAdder"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import AddressFormFields from "./AddressFormFields"
import "./UserDeliveryLocationsForm.scss"

interface IUserDeliveryLocationsFormFormProps {
    deliveryAddressIdInEditMode?: string
    onSwitchEditMode: (id: string, isInEditMode: boolean) => void
}
const getDeliveryIcon = (provider: string) => {
    const p = provider.toLowerCase()
    if (p === "gls") {
        return glsIcon
    }
    if (p === "postnord") {
        return postnordIcon
    }
    if (p === "dao") {
        return daoIcon
    }
    if (p == "bring") {
        return bringIcon
    }
    return ""
}

const removeDublicatesFromProviders = (providers: DeliveryLocationDto[]) => {
    return providers.filter((item, index) => {
        const p = providers.find((p) => p.deliveryProvider === item.deliveryProvider && p.deliveryType === item.deliveryType)

        return providers.indexOf(p!) === index
    })
}

const UserDeliveryLocationsForm = ({ deliveryAddressIdInEditMode, onSwitchEditMode }: IUserDeliveryLocationsFormFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const userData = useSelector(userSelectors.userData)
    if (!userData) {
        return null
    }

    const deliveryAddresses = userData.deliveryLocations || []
    const groupEntries = Array.from(groupDeliveryLocations(userData.deliveryLocations).entries())
    return (
        <InfoBoxContainer
            isInEditMode={!!deliveryAddressIdInEditMode}
            iconClass="icon-shipping-address"
            title={t("personal.shipping_address")}
        >
            {groupEntries.map((ge) => {
                const key = ge[0]
                const providers = ge[1] as DeliveryLocationDto[]
                const deliveryAddress = userData.deliveryLocations.filter((x) => key === createKey(x))[0]
                const businessProviders = removeDublicatesFromProviders(
                    providers.filter((x) => x.deliveryType && x.deliveryType.toLowerCase() === "business")
                )
                const privateProviders = removeDublicatesFromProviders(
                    providers.filter((x) => x.deliveryType && x.deliveryType.toLowerCase() === "private")
                )
                const pickupProviders = removeDublicatesFromProviders(
                    providers.filter((x) => x.deliveryType && x.deliveryType.toLowerCase() === "pickup point")
                )
                const isPickupPoint = (deliveryAddress.deliveryType || "").toLowerCase() === "pickup point"

                const renderMainInfo = () => (
                    <>
                        {deliveryAddress.fullName}
                        <br />
                        {deliveryAddress.companyName}
                        <br />
                        {deliveryAddress.address}
                        <br />
                        {deliveryAddress.postCode}, {deliveryAddress.city}
                        <br />
                        {deliveryAddress.region && (
                            <span>
                                {deliveryAddress.region}
                                <br />
                            </span>
                        )}
                        {getCountryByISO(deliveryAddress.country || "")}
                    </>
                )

                const renderProviders = (providers: DeliveryLocationDto[], title: string) => {
                    if (providers.length === 0) {
                        return null
                    }
                    return (
                        <>
                            <br />
                            <span>{title}</span>
                            {providers.map((dl: DeliveryLocationDto, index) => (
                                <img key={index} className="delivery-icon" src={getDeliveryIcon(dl.deliveryProvider!)} />
                            ))}
                        </>
                    )
                }

                return (
                    <InfoBoxEditor<DeliveryLocationDto>
                        key={deliveryAddress.id}
                        noSave={isPickupPoint}
                        state={InfoBoxState.NEUTRAL}
                        iconClass="icon-shipping-address"
                        title={t("personal.editdeliveryaddress")}
                        isInEditMode={deliveryAddressIdInEditMode === deliveryAddress.id}
                        editBtnTextType="editing"
                        renderPreview={() => (
                            <div>
                                {renderMainInfo()}
                                {renderProviders(businessProviders, t("personal.business"))}
                                {renderProviders(privateProviders, t("personal.private"))}
                                {renderProviders(pickupProviders, t("personal.pickuppoint"))}
                            </div>
                        )}
                        renderFormFields={(register, setValue, getValues) => {
                            if (isPickupPoint) {
                                return (
                                    <div className="mt-2">
                                        {renderMainInfo()}
                                        {renderProviders(pickupProviders, t("personal.pickuppoint"))}
                                    </div>
                                )
                            } else {
                                return (
                                    <AddressFormFields
                                        noSave={isPickupPoint}
                                        getValues={getValues}
                                        setValue={setValue}
                                        register={register}
                                        address={deliveryAddress}
                                    />
                                )
                            }
                        }}
                        onSave={async (model) => {
                            const provider = providers.find((a) => a.id === deliveryAddressIdInEditMode)
                            model.id = provider!.id
                            await dispatch(ensureDeliveryLocationThunk(model))
                        }}
                        allowDelete
                        onDelete={async () => {
                            const provider = providers.find((a) => a.id === deliveryAddressIdInEditMode)
                            await dispatch(deleteDeliveryLocationThunk(provider!.id!))
                        }}
                        onSwitchEditMode={(isInEditMode) => onSwitchEditMode(deliveryAddress.id!, isInEditMode)}
                    />
                )
            })}
            <InfoBoxAdder<BillingAddressDto>
                state={deliveryAddresses.length === 0 ? InfoBoxState.DEFAULT : InfoBoxState.NONE}
                iconClass="icon-shipping-address"
                title={t("personal.adddeliveryadress")}
                adderLabel={t("personal.addyourdeliveryadress")}
                isInEditMode={deliveryAddressIdInEditMode === "-1"}
                onSwitchEditMode={(isInEditMode) => onSwitchEditMode("-1", isInEditMode)}
                isFirstItem={deliveryAddresses.length === 0}
                renderFirstItemPreview={() => (
                    <div>
                        <strong>{t("personal.adddeliveryadress")}</strong>
                    </div>
                )}
                renderFormFields={(register, setValue, getValues) => (
                    <AddressFormFields getValues={getValues} setValue={setValue} register={register} address={undefined} />
                )}
                onSave={async (model) => {
                    await dispatch(ensureDeliveryLocationThunk({ ...model, id: undefined }))
                }}
            />
        </InfoBoxContainer>
    )
}

export default UserDeliveryLocationsForm
