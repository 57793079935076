import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { SpinnerContainer } from "swiipe.portal.shared"
import FixedLabelInput from "../../../../components/form/input/FixedLabelInput"
import CancelService from "../../../../components/merchant/CancelService"
import GoBackContainer from "../../../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../../../components/servicesonboarding/SharedCancelContainer"
import { getImage } from "../../../../services/imageLanguageService"
import { getApplePayOnboardingDataThunk } from "../../../../store/thunks/applePayThunks"
import { setPiqOnboardingStatusThunk } from "../../../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../../../type/mixedmerchantdetails"
import "./ApplePayDetailsStep.scss"

interface IApplePayDetailsStepProps {
    webshopId: string
    merchantDetails: MixedMerchantDetails
    onBackStep: () => void
}
const ApplePayDetailsStep = ({ webshopId, merchantDetails, onBackStep }: IApplePayDetailsStepProps) => {
    const { t } = useTranslation()
    const [showCancelService, setShowCancelService] = useState(false)
    const [applePayId, setApplePayId] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useReduxDispatch()

    useEffect(() => {
        if (webshopId) {
            ;(async () => {
                const applePayData = await dispatch(
                    getApplePayOnboardingDataThunk(webshopId, merchantDetails.swMerchant.swMerchantId)
                )
                setApplePayId(applePayData.applePayId)
                setIsLoading(false)
            })()
        }
    }, [webshopId])

    return (
        <SpinnerContainer showSpinner={isLoading}>
            <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onBackStep} />

            {showCancelService ? (
                <CancelService
                    serviceName={t("applepayonboarding.applepay")}
                    cancelText={t("cancelService.applepay.cancelText")}
                    customCancelledText={t("cancelService.applepay.cancelledText")}
                    hintText={t("cancelService.applepay.hintText")}
                    onCancel={async () => {
                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [webshopId!], {
                                paymentType: "ApplePay",
                                providerName: "ApplePay",
                                providerType: "Other",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [webshopId!], {
                                paymentType: "ApplePay",
                                providerName: "Clearhaus",
                                providerType: "Aquirer",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationEopEmail",
                                t("serviceNames.applepay"),
                                true
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            ) : (
                <div className="applepay-details">
                    <div className="applepay-form-head">
                        <img className="applepay-logo" src={getImage("applePayLogo")} />
                    </div>

                    {!applePayId ? (
                        <p>{t("applepayonboarding.noCertsOnboarding.onboarded")}</p>
                    ) : (
                        <FixedLabelInput
                            className="applepay-id-input"
                            label={t("applepayonboarding.applepayid")}
                            name="applePayId"
                            value={applePayId}
                            disabled={true}
                        />
                    )}
                    <SharedCancelContainer
                        cancelText={t("applepayonboarding.cancel")}
                        onCancel={() => setShowCancelService(true)}
                    />
                </div>
            )}
        </SpinnerContainer>
    )
}

export default ApplePayDetailsStep
