import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-navi"
import { PageWrapper } from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"

interface IMerchantInstallationGuideExtendedPageProps {}

const MerchantInstallationGuideExtendedPage = ({}: IMerchantInstallationGuideExtendedPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const { t, i18n } = useTranslation()

    return (
        <PageWrapper>
            <div id="plugin-guide" className="page">
                <div className="page-header"></div>
                <div id="guide-extended" className="page-body guide">
                    <div className="page-top-container text-center">
                        <p>{t("installguide.header")}</p>
                    </div>
                    <div className="top-separator text-center h4 pt-4">
                        <h2>{t("installguide.videoheader")}</h2>
                    </div>
                    <div className="guide-section text-center pt-4">
                        <p>
                            <Link href="/installation_guide">{t("installguide.extended.quicklink")}</Link>
                        </p>
                    </div>
                    <div className="guide-section text-center top-separator pt-4">
                        <h3>{t("installguide.extended.header")}</h3>
                    </div>
                    <div className="guide-section">
                        <h3>{t("installguide.extended.sec1title")}</h3>
                        <img src={require("../../images/guides/merchant_signup.png")} />
                        <p>
                            {t("installguide.extended.sec1text")}
                            <a href="https://portal.swiipe.com/merchant_signup">{t("installguide.extended.sec1link")}</a>
                            {t("installguide.extended.sec1text2")}
                        </p>
                    </div>
                    <div className="guide-section">
                        <h3>{t("installguide.extended.sec2title")}</h3>
                        <p>{t("installguide.extended.sec2text")}</p>
                        <img src={require("../../images/guides/login.png")} />
                    </div>
                    <div className="guide-section">
                        <h3>{t("installguide.extended.sec3title")}</h3>
                        <p>{t("installguide.extended.sec3text")}</p>
                        <p>{t("installguide.extended.sec3text2")}</p>
                        <p>{t("installguide.extended.sec3text3")}</p>
                        <img src={require("../../images/guides/" + i18n.language + "_download_plugin.png")} />
                        <p>{t("installguide.extended.sec3text4")}</p>
                        <img src={require("../../images/guides/" + i18n.language + "_copy_api_key.png")} />
                    </div>
                    <div className="guide-section">
                        <h3>{t("installguide.extended.sec4title")}</h3>
                        <p>{t("installguide.extended.sec4text")}</p>
                        <p>{t("installguide.extended.sec4text2")}</p>
                        <p>{t("installguide.extended.sec4text3")}</p>
                        <img src={require("../../images/guides/" + i18n.language + "_add_plugin.png")} />
                        <p>{t("installguide.extended.sec4text4")}</p>
                    </div>
                    <div className="guide-section">
                        <h3>{t("installguide.extended.sec5title")}</h3>
                        <p>{t("installguide.extended.sec5text")}</p>
                        <p>{t("installguide.extended.sec5text2")}</p>
                        <p>{t("installguide.extended.sec5text3")}</p>
                        <img src={require("../../images/guides/" + i18n.language + "_settings.png")} />
                        <p>{t("installguide.extended.sec5text4")}</p>
                        <p>{t("installguide.extended.sec5text5")}</p>
                        <img src={require("../../images/guides/" + i18n.language + "_enter_api_key.png")} />
                        <p>{t("installguide.extended.sec5text6")}</p>
                        <img src={require("../../images/guides/" + i18n.language + "_enable_checkout.png")} />
                        <p>{t("installguide.extended.sec5text7")}</p>
                    </div>
                    <div className="guide-section top-separator pt-4 text-center">
                        <h3>{t("installguide.extended.sec6title")}</h3>
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}

export default MerchantInstallationGuideExtendedPage
