import React from "react"
import { Link } from "react-navi"
import { Navbar, NavbarBrand, NavbarToggler } from "reactstrap"
import { NavigateToOtherPortal, sharedConfigurationSelectors, useSelector } from "swiipe.portal.shared"
import logo from "../../images/swiipe-logo.png"
import { useShowLoggedInMenus } from "../../services/authService"
import { layoutReducerActions, layoutSelectors } from "../../store/reducers/layoutReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import "./NavBar.scss"
import SwitchUserMenu from "./SwitchUserMenu"

const NavBar = () => {
    const showLoggedInMenus = useShowLoggedInMenus()
    const sidebarIsOpen = useSelector(layoutSelectors.navBarOpen)
    const env = useSelector(sharedConfigurationSelectors.environment)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)
    const dispatch = useReduxDispatch()

    return (
        <Navbar light id="top-navbar" toggleable="">
            {showLoggedInMenus && (
                <NavbarToggler
                    className="d-sm-none"
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch(layoutReducerActions.setNavBarOpen(!sidebarIsOpen))
                    }}
                    target="sidebar-collapse"
                    id="navbar-toggle-trigger"
                ></NavbarToggler>
            )}
            <NavbarBrand tag="div">
                <Link href="/" exact>
                    <img src={logo} />
                </Link>
                {env === "Sandbox" && <span className="sandbox-label">SANDBOX</span>}
            </NavbarBrand>
            <div className="top-navbar__spacer"></div>
            <div className="top-navbar__navigation-container">
                {hasMasterPartner && <NavigateToOtherPortal relationId={currentUserRelation?.id ?? ""} type="NavToAdmin" />}
            </div>
            <SwitchUserMenu />
        </Navbar>
    )
}

export default NavBar
