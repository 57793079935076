import cn from "classnames"
import React from "react"
import { FieldsetFormGroup } from "swiipe.portal.shared"
import "./FormSectionTitle.scss"

interface IFormSectionTitleProps {
    title: string
    className?: string
}

const FormSectionTitle = ({ title, className }: IFormSectionTitleProps) => {
    return (
        <FieldsetFormGroup className={cn(className ? className : "")}>
            <p className="swiipe-form-title text-center">{title}</p>
        </FieldsetFormGroup>
    )
}

export default FormSectionTitle
