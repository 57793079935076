import React from "react"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FieldsetFormGroup, FloatingLabelInput, valFuncRequired } from "swiipe.portal.shared"
import { IClearhausUpdateInfoForm } from "../../../../type/clearhaus/IClearhausUpdateInfoForm"
import "./ClearhausUpdateReasonFields.scss"

interface IClearhausUpdateReasonFieldsProps {
    control: Control<IClearhausUpdateInfoForm>
}

const ClearhausUpdateReasonFields = ({ control }: IClearhausUpdateReasonFieldsProps) => {
    const { t } = useTranslation()
    return (
        <div className="clearhaus-update-reason-fields">
            <div className="clearhaus-update-reason-fields__description">
                <p>{t("clearhausUpdateInfo.texts.reasonForChange.question")}</p>
                <p className="font-italic">{t("clearhausUpdateInfo.texts.reasonForChange.forExample")}</p>
            </div>
            <FieldsetFormGroup>
                <FieldsetFormGroup field>
                    <FloatingLabelInput
                        name="reasonForChange"
                        type="textarea"
                        innerRef={control.register(valFuncRequired(t("clearhausUpdateInfo.errors.reasonForChange")))}
                    />
                </FieldsetFormGroup>
            </FieldsetFormGroup>
        </div>
    )
}

export default ClearhausUpdateReasonFields
