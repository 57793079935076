import { TEnv, getLanguage } from "swiipe.portal.shared"

export function getGoogleAnalyticsGuideLink() {
    const currentLang = getLanguage()
    const guides = {
        en: "https://swiipe-support.zendesk.com/hc/da/articles/20592814392977-How-to-connect-Google-Analytics-to-Swiipe-",
        da: "https://swiipe-support.zendesk.com/hc/da/articles/8313556867601-Forbind-Google-Analytics-til-Swiipe",
    }

    return guides[currentLang] || guides["da"]
}

export function getGoogleAnalyticsApiClientEmail(env: TEnv) {
    const testServiceAccountEmail = "starting-account-7lqxikkeijw5@swiipe-test-1695117804298.iam.gserviceaccount.com"
    return {
        Development: testServiceAccountEmail,
        Test: testServiceAccountEmail,
        Test2: testServiceAccountEmail,
        QA: testServiceAccountEmail,
        Betatest: testServiceAccountEmail,

        Sandbox: "webshop-analytics@swiipe-sandbox.iam.gserviceaccount.com",
        Production: "webshop-analytics@swiipe-415808.iam.gserviceaccount.com",
    }[env]
}
