import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import checkmarkImg from "../../images/icons/ok-green.png"
import "./ModalInfo.scss"

export interface IModalInfo extends IModalBase {
    type: "info"
    text: string
    customButtonText?: string
    showCheckmark?: boolean
    noBordersInside?: boolean
    hideCloseButton?: boolean
}

const ModalInfo = ({
    title,
    text,
    customButtonText,
    showCheckmark,
    noBordersInside,
    hideCloseButton,
    callback,
    ...otherProps
}: IModalInfo & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal
            {...otherProps}
            modalClassName="modal-info"
            callback={callback}
            centered
            title={title}
            showCloseButton
            noBordersInside={noBordersInside}
        >
            <ModalBody>
                <div className="content-container">
                    <p className="mb-0 text-center">{text}</p>
                    {showCheckmark && <img src={checkmarkImg} />}
                </div>
            </ModalBody>
            <ModalFooter
                rightButton={
                    hideCloseButton
                        ? undefined
                        : { onClick: async () => callback("accepted"), text: customButtonText ?? t("common.close") }
                }
            />
        </BaseModal>
    )
}

export default ModalInfo
