import React, { Children } from "react"
import "./PageBoxSection.scss"
import c from "classnames"

interface IPageBoxSectionProps {
    title?: string
    children?: React.ReactNode
}

const PageBoxSection = ({ children, title }: IPageBoxSectionProps) => {
    return (
        <div className={c("swiipe-page-box-section col-lg-12 bg-white mx-auto my-3 py-3 px-md-5")}>
            <div className="row">
                <div className="col-md-12">
                    {!!title && <p className="font-weight-bold mt-4 mb-4">{title}</p>}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageBoxSection
