import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    PageContainer,
    PageWrapper,
    SpinnerContainer,
    getLanguage,
    getQueryValueFromCurrentUrl,
    getTranslatedCountryRelationByISO,
    sharedConfigurationSelectors,
    useSelector,
    userSelectors,
} from "swiipe.portal.shared"
import FormHeaderLogoContainer from "../../components/form/title/FormHeaderLogoContainer"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import TextBoxWithStatesContainer from "../../components/text/TextBoxWithStatesContainer"
import { getA2aAccountsModel, tryGetResolvedCountryCode } from "../../services/a2aService"
import { getImage } from "../../services/imageLanguageService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { a2aSelectors } from "../../store/reducers/a2aReducer"
import { IGatewayDto, gatewaySelectors } from "../../store/reducers/gatewayReducer"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { a2aDisableOnboardingThunk, initA2aAccountsOverviewThunk } from "../../store/thunks/a2aThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TUserRole } from "../../type/TUserRole"
import { IA2aUserAccount } from "../../type/a2a/IA2aUserAccount"
import "./A2aAccountOverview.scss"
import A2aAccounts from "./A2aAccounts"

interface A2aAccountOverviewProps {
    onContinue: () => void
    onGoBack: () => void
    selectedWebshopId: string
    selectedCountryCode?: string
    isEdit?: boolean
}
const A2aAccountOverview = ({
    onContinue,
    onGoBack,
    isEdit,
    selectedWebshopId,
    selectedCountryCode,
}: A2aAccountOverviewProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [accountInEditMode, setAccountInEditMode] = useState("")
    const [showCancelService, setShowCancelService] = useState(false)

    useRequiredUserRelations([{ requiredType: "Merchant", requiredRole: TUserRole.Admin }])

    const userData = useSelector(userSelectors.userData)

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const merchantId = currentUserRelation.id
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    const gateway = useSelector<StoreState, IGatewayDto | undefined>((state) =>
        gatewaySelectors.getGateway(state, merchantId, "AccountToAccount", selectedWebshopId)
    )

    const resolvedCountryCode = tryGetResolvedCountryCode(gateway, selectedCountryCode) ?? ""
    const countryRegionsConfig = useSelector(sharedConfigurationSelectors.countryRegionConfiguration)

    const resolvedCountryName = countryRegionsConfig
        ? countryRegionsConfig[resolvedCountryCode]?.langToText[getLanguage()]
        : undefined

    const accounts = useSelector((state: StoreState) => a2aSelectors.accounts(state, merchantId))
    const isAccountsFetched = !!accounts && !!merchantDetails && !!resolvedCountryName

    useEffect(() => {
        if (!merchantId || !selectedWebshopId) {
            return
        }
        dispatch(initA2aAccountsOverviewThunk(merchantId, selectedWebshopId, selectedCountryCode))
    }, [merchantId, selectedWebshopId, selectedCountryCode])

    const addAccountsStatusParam = getQueryValueFromCurrentUrl("addAccountsStatus")
    useEffect(() => {
        if ((addAccountsStatusParam ?? "").toLowerCase() === "failure") {
            dispatch(
                addModalThunk({
                    type: "error",
                    errorMessage: t("a2aonboarding.errorAddingAccounts"),
                })
            )
        }
    }, [])

    if (!userData || !userData.user.id || !accounts || !merchantDetails || !countryRegionsConfig) {
        return null
    }

    const { mainAccounts, otherAccounts, unsupportedAccounts, selectedAccountForWebshop } = getA2aAccountsModel({
        userId: userData.user.id,
        webshopId: selectedWebshopId,
        expectedCountryCode: resolvedCountryCode,
        accounts,
    })

    return (
        <>
            {showCancelService && gateway ? (
                <CancelService
                    serviceName={t("serviceNames.a2a")}
                    cancelText={t("cancelService.a2a.cancelText")}
                    customCancelledText={t("cancelService.a2a.cancelledText")}
                    hintText={t("cancelService.a2a.hintText")}
                    onCancel={async () => {
                        await dispatch(a2aDisableOnboardingThunk(merchantId, selectedWebshopId, gateway.gatewayId))

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationEopEmail",
                                t("serviceNames.a2a")
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            ) : (
                <>
                    <PageWrapper disableMinHeight>
                        <GoBackContainer
                            goBackText={isEdit ? t("commononboardingtexts.backToAllService") : undefined}
                            onGoBack={onGoBack}
                        />

                        <PageContainer disableDefaultPaddingBottom noHeader>
                            <div className="mt-4">
                                <FormHeaderLogoContainer
                                    mainLogo={{
                                        src: getImage("a2aLogo"),
                                        alt: "swiipe-a2a-logo",
                                        width: "200px",
                                    }}
                                    additionalLogos={[
                                        {
                                            src: getImage("poweredByAiiaLogo"),
                                            alt: "powered-by-aiia-logo",
                                            width: "110px",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="a2aonboarding__currency-warning">{t("a2aonboarding.constraints")}</div>
                        </PageContainer>
                    </PageWrapper>
                    <SpinnerContainer showSpinner={!isAccountsFetched || isLoading}>
                        <PageWrapper disableMinHeight>
                            <PageContainer disableDefaultPaddingBottom>
                                <AccountNotifications
                                    accounts={[...mainAccounts, ...otherAccounts]}
                                    selectedWebshopId={selectedWebshopId}
                                    countryCode={resolvedCountryCode}
                                />
                                <A2aAccounts
                                    title={t("a2aonboarding.yourAccounts", { country: resolvedCountryName })}
                                    adderLabel={t("a2aonboarding.addAccounts")}
                                    accounts={mainAccounts}
                                    merchantDetails={merchantDetails}
                                    selectedWebshopId={selectedWebshopId}
                                    isOnboarding={!selectedAccountForWebshop}
                                    onOnboarding={() => setIsLoading(true)}
                                    selectedCountryCode={selectedCountryCode}
                                    accountIdInEditMode={
                                        accountInEditMode.startsWith("userAcc_")
                                            ? accountInEditMode.replace("userAcc_", "")
                                            : undefined
                                    }
                                    onSwitchEditMode={(id, isInEditMode) =>
                                        setAccountInEditMode(isInEditMode ? "userAcc_" + id : "")
                                    }
                                />
                            </PageContainer>
                        </PageWrapper>

                        {otherAccounts.length !== 0 && (
                            <PageWrapper disableMinHeight>
                                <PageContainer disableDefaultPaddingBottom>
                                    <A2aAccounts
                                        title={t("a2aonboarding.otherAccounts", { country: resolvedCountryName })}
                                        accounts={otherAccounts}
                                        merchantDetails={merchantDetails}
                                        selectedWebshopId={selectedWebshopId}
                                        isOnboarding={!selectedAccountForWebshop}
                                        onOnboarding={() => setIsLoading(true)}
                                        selectedCountryCode={selectedCountryCode}
                                        disableAdder
                                        accountIdInEditMode={
                                            accountInEditMode.startsWith("otherAcc_")
                                                ? accountInEditMode.replace("otherAcc_", "")
                                                : undefined
                                        }
                                        onSwitchEditMode={(id, isInEditMode) =>
                                            setAccountInEditMode(isInEditMode ? "otherAcc_" + id : "")
                                        }
                                    />
                                </PageContainer>
                            </PageWrapper>
                        )}

                        {unsupportedAccounts.length !== 0 && (
                            <PageWrapper disableMinHeight>
                                <PageContainer disableDefaultPaddingBottom>
                                    <A2aAccounts
                                        title={t("a2aonboarding.unsupportedAccounts", { country: resolvedCountryName })}
                                        accounts={unsupportedAccounts}
                                        merchantDetails={merchantDetails}
                                        selectedWebshopId={selectedWebshopId}
                                        isOnboarding={false}
                                        disableAdder={true}
                                        isUnsupported={true}
                                        accountIdInEditMode={accountInEditMode.replace("userAcc_", "").replace("otherAcc_", "")}
                                        onSwitchEditMode={(id, isInEditMode) =>
                                            setAccountInEditMode(isInEditMode ? "otherAcc_" + id : "")
                                        }
                                    />
                                </PageContainer>
                            </PageWrapper>
                        )}
                        {isEdit && (
                            <PageWrapper disableMinHeight>
                                <SharedCancelContainer
                                    cancelText={t("a2aonboarding.cancel")}
                                    onCancel={() => setShowCancelService(true)}
                                />
                            </PageWrapper>
                        )}
                    </SpinnerContainer>
                </>
            )}
        </>
    )
}

interface IAccountNotifications {
    selectedWebshopId: string
    accounts: IA2aUserAccount[]
    countryCode: string
}
const AccountNotifications = ({ selectedWebshopId, accounts, countryCode }: IAccountNotifications) => {
    const { t } = useTranslation()

    const resolvedCountryRelation = getTranslatedCountryRelationByISO(countryCode, t)

    const hasAccounts = accounts.length >= 1
    const isAnyUnverifiedVerified = accounts.find((account) => !account.isVerifiedForPayments) != undefined
    const isAnyAccountSelected =
        accounts.find((account) => account.selectedForWebshopIds.includes(selectedWebshopId)) != undefined

    return (
        <>
            {!hasAccounts && (
                <TextBoxWithStatesContainer
                    error
                    text={t("a2aonboarding.noSelectedAccountNoneAdded", { countryRelation: resolvedCountryRelation })}
                />
            )}
            {!isAnyAccountSelected && hasAccounts && (
                <TextBoxWithStatesContainer error text={t("a2aonboarding.noSelectedAccount")} />
            )}
            {isAnyUnverifiedVerified && hasAccounts && (
                <TextBoxWithStatesContainer warning text={t("a2aonboarding.unverifiedAccounts")} />
            )}
        </>
    )
}

export default A2aAccountOverview
