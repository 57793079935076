import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import {
    BaseModal,
    FieldsetFormGroup,
    IModalBase,
    IModalControlProps,
    StandardButtonWithSpinner,
    useSelector,
} from "swiipe.portal.shared"
import { ETermsType } from "../../store/reducers/legalReducer"
import { markTermsAsAcceptedThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import GeneralTermsAndPrivacyLinks from "../legal/LegalTextWithLinks"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"

export interface IModalAcceptTerms extends IModalBase {
    type: "acceptTerms"
    isVersionUpgrade: boolean
    termsTypes: ETermsType[]
}

const ModalAcceptTerms = ({ termsTypes, isVersionUpgrade, callback, ...otherProps }: IModalAcceptTerms & IModalControlProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    return (
        <BaseModal {...otherProps} callback={callback} size="md" centered>
            <ModalBody>
                <div className="legal-warning-component">
                    <div className="page-icon-container d-flex justify-content-center">
                        <span className="icon icon-terms-conditions page-icon"></span>
                    </div>
                    <h3 className="text-center mt-3 mb-3">
                        {isVersionUpgrade ? t("merchantsignup.termsUpdated") : t("merchantsignup.accepttermstitle")}
                    </h3>
                    <FieldsetFormGroup>
                        <GeneralTermsAndPrivacyLinks
                            userRelationType={currentUserRelation.relationType}
                            userRelationId={currentUserRelation.id}
                        />
                    </FieldsetFormGroup>
                    <div>
                        <StandardButtonWithSpinner
                            title={t("common.accept")}
                            onClick={async () => {
                                try {
                                    await dispatch(markTermsAsAcceptedThunk(termsTypes))
                                    callback("accepted")
                                } catch (err) {
                                    callback("declined")
                                }
                            }}
                        />
                    </div>
                </div>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalAcceptTerms
