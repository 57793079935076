import React from "react"
import Zendesk, { ZendeskAPI } from "react-zendesk"
import { getLanguage, useSelector, userSelectors } from "swiipe.portal.shared"
import { openContactUsWidget } from "../../services/contactUsService"
import "./ContactUsWidget.scss"

interface IContactUsWidgetProps {
    triggerOnLoad?: boolean
}

export const ContactUsWidget = ({ triggerOnLoad }: IContactUsWidgetProps) => {
    const userData = useSelector(userSelectors.userData)

    const settings = {
        contactForm: {
            fields: userData
                ? [
                      { id: "name", prefill: { "*": userData.user.fullName } },
                      { id: "email", prefill: { "*": userData.user.email } },
                  ]
                : [],
        },
    }

    return (
        <Zendesk
            defer
            zendeskKey="e888c98f-6b99-42a4-8c12-2335ff72b71e"
            {...settings}
            onLoaded={() => {
                ZendeskAPI("messenger:set", "locale", getLanguage())

                if (triggerOnLoad) {
                    openContactUsWidget()
                }
            }}
        />
    )
}

export default ContactUsWidget
