import { map, mount, redirect, route } from "navi"
import React from "react"
import { getQueryValueFromUrl, waitForever } from "swiipe.portal.shared"
import Home from "../pages/Home"
import InvoicesPage from "../pages/billing/InvoicesPage"
import ForgotPasswordPage from "../pages/login/ForgotPasswordPage"
import LoginPage from "../pages/login/LoginPage"
import MerchantDashboardPage from "../pages/merchant/MerchantDashboardPage"
import MerchantOfferPage from "../pages/merchant/MerchantOfferPage"
import MerchantSettingsPage from "../pages/merchant/MerchantSettingsPage"
import PaymentLinkHistoryPage from "../pages/merchant/PaymentLinkHistoryPage"
import SalesSendOfferPage from "../pages/sales/SalesSendOfferPage"
import MerchantSignupPage from "../pages/signup/MerchantSignupPage"
import UserSignupPage from "../pages/signup/UserSignupPage"
import { authService } from "../services/authService"
import { disableForEnvSandbox, getRouteIncludingEnv } from "../services/routesService"
import { getRootUrlForRelation } from "../services/userRelationService"
import ConfirmAccountPage from "./../pages/login/ConfirmAccountPage"
import ResetPasswordPage from "./../pages/login/ResetPasswordPage"
import BookkeepingPage from "./../pages/merchant/BookkeepingPage"
import MerchantAccountPage from "./../pages/merchant/MerchantAccountPage"
import MerchantApiKeyPage from "./../pages/merchant/MerchantApiKeyPage"
import MerchantInstallationGuideExtendedPage from "./../pages/merchant/MerchantInstallationGuideExtendedPage"
import MerchantInstallationGuidePage from "./../pages/merchant/MerchantInstallationGuidePage"
import MerchantPluginsPage from "./../pages/merchant/MerchantPluginsPage"
import OrdersPage from "./../pages/merchant/OrdersPage"
import ManageUsersPage from "./../pages/organization/ManageUsersPage"
import PartnerMerchantRelationsPage from "./../pages/partner/PartnerMerchantRelationsPage"
import PartnerSettingsPage from "./../pages/partner/PartnerSettingsPage"
import ReleaseNotesPage from "./../pages/releasenotes/ReleaseNotesPage"
import SalesOfferHistoryPage from "./../pages/sales/SalesOfferHistoryPage"
import SalesOfferSentPage from "./../pages/sales/SalesOfferSentPage"
import SalesOfferSettingsPage from "./../pages/sales/SalesOfferSettingsPage"
import PartnerSignupPage from "./../pages/signup/PartnerSignupPage"
import ManageUserRelationsPage from "./../pages/user/ManageUserRelationsPage"
import SettingsPage from "./../pages/user/SettingsPage"
import UserPersonalInfoPage from "./../pages/user/UserPersonalInfoPage"
import { INavigationContext } from "./INavigationContext"
import { legalRoutes } from "./legalRoutes"
import { mobileCheckoutRoutes } from "./mobileCheckoutRoutes"
import mobilepayRoutes from "./mobilepayRoutes"
import swiipePaymentsRoutes from "./swiipePaymentsRoutes"

export const loggedInRoutes = mount<INavigationContext>({
    "/": map<INavigationContext>(async (request, context) => {
        const returnUrl = request.query.returnUrl
        if (returnUrl) {
            return redirect(returnUrl, { exact: false })
        }
        if (context.currentUserRelation) {
            const rootUrl = getRootUrlForRelation(context.currentUserRelation, context.env)
            if (rootUrl !== "/") {
                return redirect(rootUrl, { exact: false })
            }
        }
        return route({
            view: <Home />,
        })
    }),
    "/manageuserrelations": route({
        view: <ManageUserRelationsPage />,
    }),
    "/merchantdashboard": getRouteIncludingEnv(<MerchantDashboardPage />, disableForEnvSandbox),
    "/partnersettings": route({
        view: <PartnerSettingsPage />,
    }),
    "/settings": route({
        view: <SettingsPage />,
    }),
    "/merchantsettings": route({
        view: <MerchantSettingsPage />,
    }),
    "/account": route({
        view: <MerchantAccountPage />,
    }),
    "/manageusers": route({
        view: <ManageUsersPage />,
    }),
    "/bookkeeping": getRouteIncludingEnv(<BookkeepingPage />, disableForEnvSandbox),
    "/api_key": route({
        view: <MerchantApiKeyPage />,
    }),
    "/orders": route({
        view: <OrdersPage />,
    }),
    "/payment_link_history": route({
        view: <PaymentLinkHistoryPage />,
    }),
    "/invoices": route({
        view: <InvoicesPage />,
    }),
    "/plugins": route({
        view: <MerchantPluginsPage />,
    }),
    "/installation_guide": route({
        view: <MerchantInstallationGuidePage />,
    }),
    "/installation_guide_extended": route({
        view: <MerchantInstallationGuideExtendedPage />,
    }),
    "/partnermerchantrelations": route({ view: <PartnerMerchantRelationsPage /> }),
    "/personalinfo": route({
        view: <UserPersonalInfoPage />,
    }),
    "/offersettings": route({
        view: <SalesOfferSettingsPage />,
    }),
    "/merchantOffer": route({
        view: <MerchantOfferPage />,
    }),
    "/sendoffer": route({
        view: <SalesSendOfferPage />,
    }),
    "/offersent": route({
        view: <SalesOfferSentPage />,
    }),
    "/offerhistory": route({
        view: <SalesOfferHistoryPage />,
    }),
    ...mobilepayRoutes,
    ...swiipePaymentsRoutes,
})

export const mainRoutes = mount<INavigationContext>({
    "*": map<INavigationContext>(async (request, context) => {
        if (request.params.forceauth === "1") {
            const url = request.originalUrl.split("#")[0].replace("forceauth=1", "forceauth=0")
            await authService.login(url)
            return loggedInRoutes
        }
        if (!authService.getIsLoggedIn()) {
            if (authService.getIsWaitingForRedirectToAuth()) {
                await waitForever()
            }
            if (request.url === "/") {
                return redirect("/login")
            }
            if (authService.isAuthChecked() || authService.getIsWaitingForLinkToLoginPage()) {
                authService.setAuthChecked(false)
                authService.setIsWaitingForLinkToLoginPage(true)
                const flowType = getQueryValueFromUrl(request.originalUrl, "flowType")
                const flowTypePart = flowType ? "&flowType=" + flowType : ""
                const email = getQueryValueFromUrl(request.originalUrl, "email")
                const emailPart = email ? "&email=" + email : ""
                return redirect("/login?returnUrl=" + encodeURIComponent(request.originalUrl) + flowTypePart + emailPart, {
                    exact: false,
                })
            } else {
                authService.setAuthChecked(true)
                authService.setIsWaitingForRedirectToAuth(true)
                await authService.login(request.originalUrl.split("#")[0])
            }
        }
        return loggedInRoutes
    }),
    "/login": map<INavigationContext>(async (request, context) => {
        if (authService.getIsLoggedIn()) {
            return redirect(
                // Redirect to the value of the URL's `redirectTo` parameter. If no
                // redirectTo is specified, default to `/resource/favorite-foods/`.
                request.params.redirectTo ? decodeURIComponent(request.params.redirectTo) : "/",
                { exact: false }
            )
        }
        return route({
            title: "Swiipe Portal",
            getView: async (req, context) => {
                return <LoginPage />
            },
        })
    }),
    "/signup": map<INavigationContext>(async (request, context) => {
        if (authService.getIsLoggedIn()) {
            return redirect("/")
        }
        if (context.env === "Sandbox") {
            return redirect("/merchant_signup")
        }
        return route({
            view: <UserSignupPage />,
        })
    }),
    "/merchant_signup": map<INavigationContext>(async (request, context) => {
        if (authService.getIsLoggedIn()) {
            return redirect("/")
        }
        return route({
            view: <MerchantSignupPage />,
        })
    }),
    "/partner_signup": map<INavigationContext>(async (request, context) => {
        if (authService.getIsLoggedIn()) {
            return redirect("/")
        }
        return route({
            view: <PartnerSignupPage />,
        })
    }),
    "/forgotpassword": route({
        view: <ForgotPasswordPage />,
    }),
    "/resetpassword": route({
        view: <ResetPasswordPage />,
    }),
    "/confirmaccount": route({
        view: <ConfirmAccountPage />,
    }),
    "/releasenotes": route({ view: <ReleaseNotesPage /> }),
    "/mobilecheckout": mobileCheckoutRoutes,
    ...legalRoutes,
})
