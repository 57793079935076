import cn from "classnames"
import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from "react-i18next"
import {
    addDays,
    addYears,
    getDateRangeStringified,
    getDateUTCFilteringRange,
    getStringLocaleDateFormat,
} from "../../../../util/dateUtil"
import ApplyResetButtonsBlock from "../../../buttons/ApplyResetButtonsBlock"
import ToggleButtonCheckbox from "../../../buttons/ToggleButtonCheckbox"
import "./DateRangePicker.scss"

interface IDateRangePickerProps {
    initialFromDate?: Date
    initialToDate?: Date
    minDate?: Date
    maxDate?: Date
    allowEmptyDates?: boolean
    showSelectAllToggle?: boolean
    onDateRangeUpdated: (dateFromUTC?: Date, dateToUTC?: Date, rangeStringified?: string, showAllEnabled?: boolean) => void
}

const DateRangePicker = ({
    initialFromDate,
    initialToDate,
    minDate,
    maxDate,
    allowEmptyDates,
    showSelectAllToggle,
    onDateRangeUpdated,
}: IDateRangePickerProps) => {
    const { t } = useTranslation()

    const [fromDateSelected, setFromDateSelected] = useState<Date | undefined>(undefined)
    const [toDateSelected, setToDateSelected] = useState<Date | undefined>(undefined)

    const [showRangePicker, setShowRangePicker] = useState(true)
    const { selectAllEnabled, setSelectAllEnabled } = useShowAllSwitcher(
        {
            fromDateSelected,
            toDateSelected,
        },
        (updatedValues) => {
            setFromDateSelected(updatedValues.fromDateSelected)
            setToDateSelected(updatedValues.toDateSelected)
        }
    )

    useEffect(() => {
        setFromDateSelected(initialFromDate)
        setToDateSelected(initialToDate)
        onUpdated(selectAllEnabled, initialFromDate, initialToDate)
    }, [])

    const onUpdated = (selectAllEnabled: boolean, fromDateSelected?: Date, toDateSelected?: Date) => {
        if ((!fromDateSelected || !toDateSelected) && !allowEmptyDates) {
            return
        }
        const { dateFromUTC, dateToUTC } = getDateUTCFilteringRange(fromDateSelected, toDateSelected)
        const dateRangeStringified = getDateRangeStringified(fromDateSelected, toDateSelected)
        onDateRangeUpdated(dateFromUTC, dateToUTC, dateRangeStringified, selectAllEnabled)
    }

    const onReset = async () => {
        const selectAllEnabled = false
        setSelectAllEnabled(selectAllEnabled)
        setFromDateSelected(initialFromDate)
        setToDateSelected(initialToDate)
        onUpdated(selectAllEnabled, initialFromDate, initialToDate)
    }

    const datePickersCommonProps = {
        className: "sw__date-range-picker__from-to-input from form-control",
        dateFormat: getStringLocaleDateFormat(),
        showYearDropdown: true,
        showMonthDropdown: true,
        onCalendarOpen: () => setShowRangePicker(false),
        onCalendarClose: () => setShowRangePicker(true),
        dayClassName: (date: Date) => {
            const dateToCheck = date.toDateString()
            if (dateToCheck === fromDateSelected?.toDateString() || dateToCheck === toDateSelected?.toDateString()) {
                return "date-range-edge"
            }
            return null
        },
        disabled: selectAllEnabled,
    }

    return (
        <div className="sw__date-range-picker">
            <div className="sw__date-range-picker__inputs-row">
                <ReactDatePicker
                    selectsStart
                    selected={fromDateSelected}
                    onChange={(date) => {
                        if (!date) {
                            return
                        }
                        setFromDateSelected(date)
                    }}
                    startDate={fromDateSelected}
                    endDate={toDateSelected}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...datePickersCommonProps}
                />
                <div className="sw__date-range-picker__input-title">{t("fromToDatePicker.toDate")}</div>
                <ReactDatePicker
                    selectsEnd
                    selected={toDateSelected}
                    onChange={(date) => {
                        if (!date) {
                            return
                        }
                        setToDateSelected(date)
                    }}
                    startDate={fromDateSelected}
                    endDate={toDateSelected}
                    minDate={fromDateSelected}
                    maxDate={maxDate}
                    popperPlacement="bottom-end"
                    {...datePickersCommonProps}
                />
            </div>
            <ReactDatePicker
                selectsRange
                selected={fromDateSelected}
                inline
                calendarClassName={cn({ "react-datepicker--hidden": !showRangePicker })}
                onChange={(dates) => {
                    if (selectAllEnabled) {
                        return
                    }
                    const [start, end] = dates
                    setFromDateSelected(start || undefined)
                    setToDateSelected(end || undefined)
                }}
                startDate={fromDateSelected}
                endDate={toDateSelected}
                minDate={minDate}
                maxDate={maxDate}
                {...datePickersCommonProps}
            />
            {showSelectAllToggle && (
                <div className="sw__date-range-picker__select-all-toggle">
                    {t("fromToDatePicker.selectAll")}
                    <ToggleButtonCheckbox
                        className="ml-2"
                        id="select-all-toggle"
                        onClick={() => setSelectAllEnabled(!selectAllEnabled)}
                        checked={selectAllEnabled}
                    />
                </div>
            )}
            <ApplyResetButtonsBlock
                onApply={() => onUpdated(selectAllEnabled, fromDateSelected, toDateSelected)}
                onReset={onReset}
                applyText={t("fromToDatePicker.show")}
                resetText={t("fromToDatePicker.reset")}
            />
        </div>
    )
}

interface IDateRangeValues {
    fromDateSelected: Date | undefined
    toDateSelected: Date | undefined
}
const useShowAllSwitcher = (values: IDateRangeValues, onUpdateValues: (values: IDateRangeValues) => void) => {
    const [selectAllEnabled, setSelectAllEnabled] = useState(false)
    const [memorizedValues, setMemorizedValues] = useState<IDateRangeValues | undefined>(undefined)

    useEffect(() => {
        if (!selectAllEnabled) {
            if (memorizedValues) {
                onUpdateValues(memorizedValues) //restore state
            }
            return
        }

        // remember state
        setMemorizedValues({
            fromDateSelected: values.fromDateSelected,
            toDateSelected: values.toDateSelected,
        })

        // set "show all" values
        onUpdateValues({
            fromDateSelected: addYears(new Date(), -10),
            toDateSelected: addDays(new Date(), -1),
        })
    }, [selectAllEnabled])

    return { selectAllEnabled, setSelectAllEnabled }
}

export default DateRangePicker
