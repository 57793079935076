import { useEffect, useState } from "react"
import {
    IDropdownOption,
    TFeatureType,
    WebshopDetails,
    getKeys,
    getQueryValueFromCurrentUrl,
    hasValue,
    useSelector,
} from "swiipe.portal.shared"
import { useInitialWebshopValuesForWebshopSelect } from "../components/merchant/WebshopSelect"
import { StoreState } from "../store/StoreState"
import { merchantSelectors } from "../store/reducers/merchantReducer"
import { IMerchantProviderConfig, merchantServicesSelectors } from "../store/reducers/merchantServicesReducer"
import { fetchWebshopFeaturesDetailsThunk } from "../store/thunks/merchantThunks"
import { getOnboardingStatusThunk } from "../store/thunks/swiipePaymentsThunks"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { TSwiipePlan } from "../type/IProvider"
import { IWebshopFeaturesDetails } from "../type/feature/IFeatureDetails"
import { MixedMerchantDetails } from "../type/mixedmerchantdetails"
import { removeSchemeFromHostName } from "./merchantService"
import { navigationService } from "./navigationService"
import { TOnboardingType, getFeatureTypeFromOnboardingType, isWebshopOnboardedForFlow } from "./onboardingService"

export function useStatusesForWebshopsToOnboardSelect(
    merchantDetails: MixedMerchantDetails,
    onboardingType: TOnboardingType
): {
    isStatusesLoaded: boolean
    featureStatuses?: {
        [webshopId: string]: IWebshopFeaturesDetails
    }
    onboardingStatuses?: IMerchantProviderConfig
} {
    const dispatch = useReduxDispatch()

    const webshops = merchantDetails.webshops
    const swMerchantId = merchantDetails.swMerchant.swMerchantId
    const onboardingForFeatureType = getFeatureTypeFromOnboardingType(onboardingType)

    const onboardingStatuses = useSelector<StoreState, IMerchantProviderConfig | undefined>((state) =>
        merchantServicesSelectors.merchantStatuses(state, swMerchantId)
    )
    const featureStatuses = useSelector(merchantSelectors.featureDetails)

    useEffect(() => {
        if (onboardingForFeatureType) {
            // onboarding for feature, load feature statuses
            webshops.forEach((w) => dispatch(fetchWebshopFeaturesDetailsThunk(w.webshopId, false)))
            return
        }

        // onboarding for service, load onboarding statuses
        webshops.forEach((w) => dispatch(getOnboardingStatusThunk(swMerchantId, w.webshopId, false)))
    }, [webshops, swMerchantId])

    if (onboardingForFeatureType) {
        if (!featureStatuses || !allRequiredFeaturesFetched(onboardingForFeatureType, featureStatuses, webshops)) {
            // not all features loaded yet
            return { isStatusesLoaded: false }
        }

        return { isStatusesLoaded: true, featureStatuses }
    }

    if (!onboardingStatuses || !allRequiredOnboardingStatusesFetched(onboardingStatuses, webshops)) {
        // not all onboarding statuses loaded yet
        return { isStatusesLoaded: false }
    }

    return { isStatusesLoaded: true, onboardingStatuses }
}

export function useWebshopToOnboardSelect(
    isStatusesLoaded: boolean,
    merchantDetails: MixedMerchantDetails,
    onboardingType: TOnboardingType,
    navigateToOnEmptyWebshops: string,
    swiipePlan?: TSwiipePlan,
    onboardingStatuses?: IMerchantProviderConfig,
    featureStatuses?: {
        [webshopId: string]: IWebshopFeaturesDetails
    }
) {
    const webshops = merchantDetails.webshops
    const webshopIdsAvailableForSelect = [
        ...webshops
            .filter((webshop) =>
                canWebshopBeSelected(
                    webshop.webshopId,
                    isStatusesLoaded,
                    onboardingType,
                    swiipePlan,
                    onboardingStatuses,
                    featureStatuses
                )
            )
            .map((webshop) => webshop.webshopId),
        getQueryValueFromCurrentUrl("forceWebshopId"),
    ].filter(hasValue)

    const [initialized, setInitialized] = useState(false)

    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopIds, setSelectedWebshopIds] = useState<string[]>([])

    useEffect(() => {
        if (initialized || !isStatusesLoaded) {
            return
        }

        if (webshopIdsAvailableForSelect.length === 0) {
            navigationService.navigate(navigateToOnEmptyWebshops)
            return
        }

        if (!webshopIdsAvailableForSelect.includes(initialWebshopId)) {
            //if initialWebshopId can't be selected
            setSelectedWebshopIds([webshopIdsAvailableForSelect[0]])
            setInitialized(true)
            return
        }

        setSelectedWebshopIds([initialWebshopId])
        setInitialized(true)
    }, [webshopIdsAvailableForSelect])

    useEffect(() => {
        if (!initialized) {
            return
        }
        setSelectedWebshopIds([])
        setInitialized(false)
    }, [merchantDetails])

    if (!isStatusesLoaded) {
        return {
            initialized: initialized,
            selectedWebshopIds,
            getDropdownOptions: (webshopId: string): IDropdownOption<string>[] => {
                return []
            },
            addWebshop: () => {},
            selectWebshop: (webshopId: string) => {},
            deselectWebshop: (webshopId: string) => {},
        }
    }

    const selectWebshop = (webshopId: string, replaceWebshopId?: string, force?: boolean) => {
        if (!webshopIdsAvailableForSelect.includes(webshopId) && !force) {
            return
        }

        setSelectedWebshopIds((selectedWebshopIds) => {
            if (!replaceWebshopId) {
                return [...selectedWebshopIds, webshopId]
            }

            return selectedWebshopIds.map((selectedWebshopId) => {
                if (selectedWebshopId === replaceWebshopId) {
                    return webshopId
                }

                return selectedWebshopId
            })
        })
    }

    return {
        initialized: initialized,
        selectedWebshopIds,
        getDropdownOptions: (webshopId: string): IDropdownOption<string>[] => {
            return [webshopId, ...webshopIdsAvailableForSelect.filter((id) => !selectedWebshopIds.includes(id))]
                .map((id) => {
                    const webshop = webshops.find((webshop) => webshop.webshopId === id)
                    if (!webshop) {
                        return
                    }
                    return { text: removeSchemeFromHostName(webshop.hostName), value: webshop.webshopId }
                })
                .filter(hasValue)
        },
        selectWebshop,
        deselectWebshop: (webshopId: string) => {
            if (!selectedWebshopIds.includes(webshopId)) {
                return
            }
            setSelectedWebshopIds((selectedWebshopIds) => [...selectedWebshopIds.filter((id) => id !== webshopId)])
        },
        addWebshop:
            webshopIdsAvailableForSelect.length !== selectedWebshopIds.length
                ? () => {
                      const webshopIdToSelect = webshopIdsAvailableForSelect.find((id) => !selectedWebshopIds.includes(id))
                      if (!webshopIdToSelect) {
                          return
                      }
                      selectWebshop(webshopIdToSelect)
                  }
                : undefined,
    }
}

function canWebshopBeSelected(
    webshopId: string,
    isStatusesLoaded: boolean,
    onboardingType: TOnboardingType,
    swiipePlan?: TSwiipePlan,
    onboardingStatuses?: IMerchantProviderConfig,
    featureStatuses?: {
        [webshopId: string]: IWebshopFeaturesDetails
    }
) {
    if (!isStatusesLoaded) {
        return false
    }

    if (swiipePlan === TSwiipePlan.None) {
        return true
    }

    // calculate for feature onboarding
    const featureType = getFeatureTypeFromOnboardingType(onboardingType)
    if (featureType && featureStatuses) {
        return !featureStatuses[webshopId][featureType].enabled
    }

    // calculate for service onboarding
    return !isWebshopOnboardedForFlow(onboardingStatuses![webshopId], onboardingType)
}

function allRequiredFeaturesFetched(
    onboardingForFeatureType: TFeatureType,
    featureStatuses: {
        [webshopId: string]: IWebshopFeaturesDetails
    },
    webshops: WebshopDetails[]
) {
    const featureStatusesWebshopIds = getKeys(featureStatuses)

    return webshops.every(
        (webshop) =>
            featureStatusesWebshopIds.includes(webshop.webshopId) &&
            getKeys(featureStatuses[webshop.webshopId]).includes(onboardingForFeatureType)
    )
}

export function allRequiredOnboardingStatusesFetched(onboardingStatuses: IMerchantProviderConfig, webshops: WebshopDetails[]) {
    const onboardingStatusesWebshopIds = getKeys(onboardingStatuses)
    return webshops.every((webshop) => onboardingStatusesWebshopIds.includes(webshop.webshopId))
}
