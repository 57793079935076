import { Matcher, Navigation, Route, createBrowserNavigation } from "navi"
import { addQueryParamsToUrl } from "swiipe.portal.shared"
import { INavigationContext } from "./../routes/INavigationContext"

export type TOnNavigate = (value: Route) => void
export type TShouldForceHideMenu = (forceShouldHide: boolean) => void

interface INavigationService {
    currentRoute?: string
    lastRoute?: string
    nav?: Navigation
    listeners: TOnNavigate[]
    forceHideMenuListeners: TShouldForceHideMenu[]
    forceHideMenus: boolean
    setup: (context: INavigationContext, routes: Matcher<INavigationContext, INavigationContext>) => Navigation
    navigate: (path: string) => void
    navigatePortalBack: (navigateToIfLeavingPortal: string) => void
    navigateBack: () => void
    navigateSamePageChangeParams: (
        params: { [key: string]: string | number | boolean | undefined },
        clearType: "keepExistingParams" | "clearExistingParams"
    ) => void
    addNavigationListener: (listener: TOnNavigate) => () => void
    // If any components wants to know if logged in menus should be hidden
    addForceHideMenuListener: (listener: TShouldForceHideMenu) => () => void
}

const forceHideMenuRoutes: string[] = ["/mobilecheckout", "/merchantOffer"]

function onRouteChange(href: string) {
    if (navigationService.currentRoute === href) {
        return
    }

    navigationService.lastRoute = navigationService.currentRoute
    navigationService.currentRoute = href
}

export const navigationService: INavigationService = {
    listeners: [],
    forceHideMenuListeners: [],
    forceHideMenus: false,

    setup(context: INavigationContext, routes: Matcher<INavigationContext, INavigationContext>) {
        if (!this.nav) {
            this.nav = createBrowserNavigation<INavigationContext>({ routes, context })
            this.nav.subscribe((value: Route) => {
                onRouteChange(value.url.href)
                this.forceHideMenus = forceHideMenuRoutes.indexOf(value.url.pathname) >= 0
                this.forceHideMenuListeners.forEach((l) => l(this.forceHideMenus))
                this.listeners.forEach((l) => l(value))
            })
        }

        return this.nav
    },
    async navigate(path: string) {
        if (!!this.currentRoute && path === this.currentRoute) {
            // Already there or is already navigating
            return
        }
        if (!this.nav) {
            throw new Error("Navigation is not setup")
        }
        onRouteChange(path)
        return await this.nav.navigate(path)
    },
    async navigateSamePageChangeParams(
        params: { [key: string]: string | number | boolean | undefined },
        clearType: "keepExistingParams" | "clearExistingParams"
    ) {
        return await this.navigate(addQueryParamsToUrl(this.currentRoute ?? "", params, clearType === "clearExistingParams"))
    },
    async navigateBack() {
        return await this.nav?.goBack()
    },
    async navigatePortalBack(navigateToIfLeavingPortal: string) {
        return this.lastRoute ? await this.nav?.goBack() : await this.navigate(navigateToIfLeavingPortal)
    },
    addNavigationListener(listener: TOnNavigate) {
        this.listeners.push(listener)
        return () => {
            const index = this.listeners.indexOf(listener)
            this.listeners.splice(index, 1)
        }
    },
    addForceHideMenuListener(listener: TShouldForceHideMenu) {
        this.forceHideMenuListeners.push(listener)
        return () => {
            const index = this.forceHideMenuListeners.indexOf(listener)
            this.forceHideMenuListeners.splice(index, 1)
        }
    },
}
