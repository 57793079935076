import { useEffect } from "react"
import {
    IMerchantOnboardingServicesSubForm,
    getDistinct,
    getKeys,
    getMerchantPreOnboardingSwiipeServices,
    getQueryValueFromUrl,
    hasValue,
    useSelector,
} from "swiipe.portal.shared"
import { ETermsType } from "../store/reducers/legalReducer"
import { merchantPreOnboardingSelectors } from "../store/reducers/merchantPreOnboardingSlice"
import { userRelationSelectors } from "../store/reducers/userRelationReducer"
import { getMerchantPreOnboardingDetailsThunk } from "../store/thunks/merchantPreOnboardingThunks"
import { useReduxDispatch } from "../store/useReduxDispatch"
import { navigationService } from "./navigationService"
import { getTermsForSwiipeServices } from "./swiipeServicesService"

export function getRequiredTermsForMerchantPreOnboardingServices(services?: IMerchantOnboardingServicesSubForm): ETermsType[] {
    const selectedInputKeys = getKeys(services ?? {}).filter((s) => !!services?.[s])
    const serviceTypesFromInput = selectedInputKeys
        .map((s) => getMerchantPreOnboardingSwiipeServices(s))
        .filter(hasValue)
        .flat()

    const termsFromServices = getTermsForSwiipeServices(serviceTypesFromInput)

    return getDistinct(["TermsMerchant", "PrivacyPolicyShared", "TermsPSP", ...termsFromServices])
}

export function useMerchantPreOnboardingDetails() {
    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const preOnboardingDetails = useSelector(merchantPreOnboardingSelectors.details)
    useEffect(() => {
        if (currentUserRelation?.id && currentUserRelation.relationType === "Merchant") {
            dispatch(getMerchantPreOnboardingDetailsThunk())
        }
    }, [currentUserRelation])

    return preOnboardingDetails
}

const merchantOfferClearhausOnboardingFlowPaths = ["clearhaus_edit", "swiipepayments_onboard"]
export function isCurrentPageInMerchantOfferAcquiringOnboardingFlow() {
    const path = navigationService.currentRoute?.split("?")[0].split("#")[0] ?? document.location.pathname
    const firstPath = path.split("/").filter((p) => !!p)?.[0] ?? ""
    return merchantOfferClearhausOnboardingFlowPaths.includes(firstPath)
}

export function getMerchantOfferCurrentStep() {
    if (isCurrentPageInMerchantOfferAcquiringOnboardingFlow()) {
        return "kyc"
    }
    const path = navigationService.currentRoute?.split("?")[0].split("#")[0] ?? document.location.pathname
    const firstPath = path.split("/").filter((p) => !!p)?.[0] ?? ""
    if (firstPath === "merchantOffer") {
        const step = getQueryValueFromUrl(navigationService.currentRoute ?? document.location.href, "step")
        return step === "terms" ? "terms" : "info"
    }
    return undefined
}
