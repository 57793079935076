import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getQueryValueFromCurrentUrl, prepareFormData, removeQueryValueFromCurrentUrl, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import {
    deleteJustConfirmedRelationFromLocalStorage,
    getCurrentUserRelationFromLocalStorage,
    getJustConfirmedRelationFromLocalStorage,
    getLastUsedRelationsFromLocalStorage,
    returnActiveUserRelations,
    setLastUsedRelationsToLocalStorage,
} from "../../services/userRelationService"
import { IRelationSearchData } from "../../type/IRelationSearchData"
import { IRelationsForOrganization } from "../../type/IRelationsForOrganization"
import { IUserRelationFlattened } from "../../type/IUserRelation"
import { StoreState } from "../StoreState"
import { partnerSelectors } from "../reducers/partnerReducer"
import { userRelationReducerActions, userRelationSelectors } from "../reducers/userRelationReducer"
import { switchUserThunk } from "./../../store/thunks/startupThunks"
import { IUserRelations } from "./../../type/IUserRelations"
import { IAddUserRelation } from "./../../type/sales/IUpdateSalesEmployee"
import { ensureAllPartnerMerchantRelationsThunk, ensureMasterPartnerThunk } from "./partnerThunks"
import { fetchUserDataThunk } from "./userPersonalInfoThunks"

export const ensureUserRelationsThunk =
    (force?: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentRelation = userRelationSelectors.currentUserRelation(getState())
        if (currentRelation && !force) {
            // Already set up
            return
        }

        const userRelations = await dispatch(requestThunk<IUserRelations>(endpoints.Core.getUserRelations))

        dispatch(userRelationReducerActions.setUserRelations(userRelations.relations))
        const relations = userRelationSelectors.userRelationsFlattened(getState())?.filter((r) => !r.markedDeletion) || []

        let newCurrentRelation: IUserRelationFlattened | undefined = undefined
        const justConfirmedRelation = getJustConfirmedRelationFromLocalStorage()
        if (justConfirmedRelation) {
            newCurrentRelation = relations.find((r) => justConfirmedRelation.relatesToId === r.id)
        }
        if (!newCurrentRelation) {
            newCurrentRelation = getCurrentUserRelationFromLocalStorage(relations)
        }
        if (!newCurrentRelation) {
            newCurrentRelation = relations.find((r) => r.relationType === "Merchant")!
        }
        if (!newCurrentRelation) {
            newCurrentRelation = relations.find((r) => r.relationType === "User")!
        }
        dispatch(userRelationReducerActions.setCurrentRelation(newCurrentRelation))
        deleteJustConfirmedRelationFromLocalStorage()
        dispatch(ensureMasterPartnerThunk(false))
        dispatch(ensureLastUsedRelationsThunk())
    }

export const ensureSwitchUserRelationsFromUrl =
    (): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const id = getQueryValueFromCurrentUrl("relationId")
        const relations = userRelationSelectors.userRelationsFlattened(getState()) || []
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())

        if (!currentUserRelation || !id) {
            return false
        }

        if (currentUserRelation.id === id) {
            return false
        }

        const newUserRelation = relations.find((r) => r.id === id && !r.markedDeletion)

        if (!newUserRelation) {
            return false
        }
        removeQueryValueFromCurrentUrl("relationId")
        dispatch(switchUserThunk(newUserRelation))
        return true
    }

export const ensureLastUsedRelationsThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        await dispatch(fetchUserDataThunk(false))
        const lastUsedRelations = userRelationSelectors.lastUsedRelations(getState())

        if (lastUsedRelations.length === 0) {
            await dispatch(ensureAllPartnerMerchantRelationsThunk(false))

            const userRelationsFlattened = userRelationSelectors.userRelationsFlattened(getState()) || []
            const currentUserData = userRelationsFlattened.find((r) => r.relationType === "User")!
            const partnerMerchantRelationsAll = partnerSelectors.partnerMerchantRelationsAll(getState())
            const lastUsedRelationsFromStorage = getLastUsedRelationsFromLocalStorage(currentUserData.id, userRelationsFlattened)
            const partnersContainMasterPartner = partnerSelectors.partnersContainMasterPartner(getState())

            const userRelationsFlattenedActive = returnActiveUserRelations(
                userRelationsFlattened,
                partnerMerchantRelationsAll,
                partnersContainMasterPartner
            )

            if (lastUsedRelationsFromStorage) {
                //if any of id's in localstorage is non existent
                if (lastUsedRelationsFromStorage.length < 5) {
                    dispatch(userRelationReducerActions.initLastUsedRelations(userRelationsFlattenedActive))
                } else {
                    dispatch(userRelationReducerActions.initLastUsedRelations(lastUsedRelationsFromStorage))
                }
            } else {
                setLastUsedRelationsToLocalStorage(currentUserData.id, userRelationsFlattenedActive.map((r) => r.id).slice(0, 5))
                dispatch(userRelationReducerActions.initLastUsedRelations(userRelationsFlattenedActive))
            }
        }
    }

export const addLastUsedRelationThunk =
    (relation: IUserRelationFlattened): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const userRelationsFlattened = userRelationSelectors.userRelationsFlattened(getState()) || []
        const currentUserData = userRelationsFlattened.find((r) => r.relationType === "User")!

        await dispatch(userRelationReducerActions.addLastUsedRelation(relation.id))
        setLastUsedRelationsToLocalStorage(currentUserData.id, userRelationSelectors.lastUsedRelations(getState()))
    }

export const ensureUsersRelatedForOrganizationThunk =
    (force: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation) {
            return
        }
        if (currentUserRelation.relationType === "User") {
            // Type User is not an organization
            return
        }
        const userRelations = userRelationSelectors.relationsForOrganization(getState(), currentUserRelation.id)
        if (!force && userRelations) {
            return
        }

        const relations = await dispatch(
            requestThunk<IRelationsForOrganization>(
                endpoints.Core.getUsersForOrganization(currentUserRelation.relationType, currentUserRelation.id)
            )
        )
        dispatch(userRelationReducerActions.setRelationsForOrganization(currentUserRelation.id, relations.users))
    }

export const changeActiveStateForOrganizationThunk =
    (userId: string, isActive: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        await dispatch(
            requestThunk<void>(endpoints.Core.changeActiveStatusUserRelation, {
                data: {
                    isActive,
                    userId,
                    organizationId: currentUserRelation.id,
                    organizationType: currentUserRelation.relationType,
                },
            })
        )
        await dispatch(ensureUsersRelatedForOrganizationThunk(true))
    }

export const changeAdminStateForOrganizationThunk =
    (userId: string, isAdmin: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        await dispatch(
            requestThunk<void>(endpoints.Core.changeIsAdminStatusUserRelation, {
                data: {
                    isAdmin,
                    userId,
                    organizationId: currentUserRelation.id,
                    organizationType: currentUserRelation.relationType,
                },
            })
        )
        await dispatch(ensureUsersRelatedForOrganizationThunk(true))
    }

export const changeOwnerForOrganizationThunk =
    (ownerId: string, newOwnerId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!

        await dispatch(
            requestThunk<void>(endpoints.Identity.changeOrganizationOwner, {
                data: {
                    ownerId: ownerId,
                    newOwnerId: newOwnerId,
                    organizationId: currentUserRelation.id,
                    organizationType:
                        currentUserRelation.relationType === "Merchant"
                            ? `sw${currentUserRelation.relationType}`
                            : currentUserRelation.relationType,
                },
            })
        )

        await dispatch(ensureUsersRelatedForOrganizationThunk(true))
    }

export const changeUserDataAsMasterPartner =
    (form: IAddUserRelation, userId: string, initialEmail: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const preparedForm = prepareFormData(form, ["isAdmin"])

        await dispatch(
            requestThunk<void>(endpoints.Identity.updateUserDataAsMasterPartner, {
                data: {
                    ...preparedForm,
                    userId: userId,
                    isEmailToBeChanged: initialEmail != preparedForm.email,
                },
            })
        )

        await dispatch(ensureUsersRelatedForOrganizationThunk(true))
    }

export const addUserRelationThunk =
    (form: IAddUserRelation): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const preparedForm = prepareFormData(form, ["isAdmin"])

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!

        await dispatch(
            requestThunk<void>(endpoints.Core.addUserRelation, {
                data: {
                    ...preparedForm,
                    organizationId: currentUserRelation.id,
                    organizationType: currentUserRelation.relationType,
                },
            })
        )
        await dispatch(ensureUsersRelatedForOrganizationThunk(true))
    }

export const deleteUserRelationThunk =
    (userId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        await dispatch(
            requestThunk<void>(endpoints.Core.deleteUserRelation, {
                params: {
                    userId,
                    organizationId: currentUserRelation.id,
                    organizationType: currentUserRelation.relationType,
                },
            })
        )
        await dispatch(ensureUsersRelatedForOrganizationThunk(true))
    }

export const fetchRelationSearchData =
    (force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!force) {
            const relationSearchData = userRelationSelectors.getRelationSearchData(getState())
            if (relationSearchData) {
                return
            }
        }

        const result = await dispatch(requestThunk<IRelationSearchData>(endpoints.Identity.getUserRelationsSearchData))

        await dispatch(userRelationReducerActions.setRelationSearchData(result))
    }
