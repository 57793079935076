import cn from "classnames"
import React from "react"
import { getFormattedNumber, roundNumberToMaxDecimals } from "swiipe.portal.shared"
import "./MerchantDashboardCompareDiff.scss"

interface IMerchantDashboardCompareDiffProps {
    className?: string
    value: number
    compareValue: number
    // Do not calc difference in pct but in points
    diffValuePoints?: boolean
    isInverted?: boolean
    isLarge?: boolean
    alignRight?: boolean
    showDashIfSame?: boolean
}

const MerchantDashboardCompareDiff = ({
    value,
    compareValue,
    diffValuePoints,
    isInverted,
    isLarge,
    className,
    alignRight,
    showDashIfSame,
}: IMerchantDashboardCompareDiffProps) => {
    const percent = diffValuePoints ? value - compareValue : ((value - compareValue) / compareValue) * 100
    const rounded = getRoundedPercent(percent)
    const hideValue =
        (showDashIfSame && rounded === 0) ||
        (diffValuePoints && value === 0 && compareValue === 0) ||
        (!diffValuePoints && (value === 0 || compareValue === 0))
    return (
        <div
            className={cn(
                "merchant-dashboard-compare-diff",
                getColorClass(rounded, isInverted ?? false),
                { large: isLarge },
                alignRight && "align-right",
                className
            )}
        >
            {!hideValue && (
                <div className="compare-pct">
                    {getFormattedNumber(rounded, {
                        numberFormat: "forceTwoDecimals",
                    })}
                    %
                </div>
            )}
            <div className="indicator">
                <div className={cn(...getArrowClasses(rounded, showDashIfSame))}></div>
            </div>
        </div>
    )
}

function getArrowClasses(percent: number, showDashIfSame?: boolean) {
    if (percent > 0) {
        return ["compare-arrow", "compare-arrow-up"]
    }
    if (percent < 0) {
        return ["compare-arrow", "compare-arrow-down"]
    }
    if (showDashIfSame) {
        return ["compare-same"]
    }
    return [""]
}

function getColorClass(percent: number, isInverted: boolean) {
    if (percent > 0) {
        return isInverted ? "color-negative" : "color-positive"
    }
    if (percent < 0) {
        return isInverted ? "color-positive" : "color-negative"
    }
    return "color-neutral"
}

function getRoundedPercent(percent: number) {
    return roundNumberToMaxDecimals(percent, 2)
}

export default MerchantDashboardCompareDiff
