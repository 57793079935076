export function toUpperAndTrim(text: string): string {
    if (!text) {
        return text
    }

    return text.toUpperCase().trim()
}

export function createGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function removeCharInString(str: string, index: number) {
    return str.length - 1 < index ? str : str.substring(0, index) + str.substring(index + 1, str.length)
}

export function swapCharsInString(str: string, index1: number, index2: number) {
    const lowIndex = Math.min(index1, index2)
    const highIndex = Math.max(index1, index2)
    return str.length - 1 < highIndex
        ? str
        : str.substring(0, lowIndex) +
              str.substring(highIndex, highIndex + 1) +
              str.substring(lowIndex + 1, highIndex) +
              str.substring(lowIndex, lowIndex + 1) +
              str.substring(highIndex + 1, str.length)
}

export function splitStringAndKeepDelimiter(str: string, delimiter: string) {
    const splittedStr: string[] = str
        .split(delimiter)
        .reduce((result: string[], value, index) => {
            return index !== 0 ? result.concat([delimiter, value]) : result.concat(value)
        }, [])
        .filter((str) => str)

    return splittedStr
}
