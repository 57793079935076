import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageWrapper } from "swiipe.portal.shared"
import FormFooter from "../../components/form/footer/FormFooter"
import FormHeaderPartner from "../../components/form/title/FormHeaderPartner"
import MessageBox from "../../components/layouts/MessageBox"
import PartnerSignupForm from "./../../components/form/signup/PartnerSignupForm"

interface IPartnerSignupPageProps {}

const PartnerSignupPage = ({}: IPartnerSignupPageProps) => {
    const [hasSignedUpWithEmail, setEmail] = useState("")
    const { t } = useTranslation()

    if (hasSignedUpWithEmail) {
        return (
            <PageWrapper>
                <MessageBox id="signup" title={t("merchantsignup.welcometitle")} centerContent>
                    <p className="mb-4">
                        <strong>{t("merchantsignup.welcomemsg")}</strong>
                        <br />
                        {t("partnersignup.welcomemsg", { email: hasSignedUpWithEmail })}
                        <br />
                        {t("merchantsignup.welcomemsg3")}
                    </p>
                    <p>{t("merchantsignup.welcomemsg2")}</p>
                    <p className="small-text mt-5">{t("merchantsignup.welcomemsg4")}</p>
                </MessageBox>
            </PageWrapper>
        )
    }
    return (
        <PageWrapper>
            <div id="partner-signup" className="center-form">
                <div className="panel">
                    <FormHeaderPartner />
                    <div className="panel-body">
                        <PartnerSignupForm onSignedUp={(email) => setEmail(email)} />
                    </div>
                    <FormFooter />
                </div>
            </div>
        </PageWrapper>
    )
}

export default PartnerSignupPage
