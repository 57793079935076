import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody, ModalHeader, Row } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import "./ModalDialog.scss"

export interface IModalDialog extends IModalBase {
    type: "modalDialog"
    subtitle?: string
    mainTextHtml: string
    cancelBtnText: string
    acceptBtnText: string
    isDelete?: boolean
}

const ModalDialog = ({
    callback,
    title,
    subtitle,
    mainTextHtml,
    cancelBtnText,
    acceptBtnText,
    isDelete,
    ...otherProps
}: IModalDialog & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal modalClassName="modal-dialog" {...otherProps} callback={callback} centered>
            <ModalHeader>
                <span className="title">{title}</span>
                <br />
                <span className="subtitle">{subtitle}</span>
            </ModalHeader>
            <ModalBody>
                <Row className="text-center">
                    <div className="col">
                        <p className="dialog-text" dangerouslySetInnerHTML={{ __html: mainTextHtml }} />
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter
                wideButtons
                leftButton={{
                    onClick: async () => callback("declined"),
                    text: cancelBtnText,
                    isInvertedColor: true,
                }}
                rightButton={{
                    onClick: async () => callback("accepted"),
                    text: acceptBtnText,
                    isDelete,
                }}
            />
        </BaseModal>
    )
}

export default ModalDialog
