import { ActionsUnion, createAction, removeElementAtIndex } from "swiipe.portal.shared"
import { IMerchantDashboardSelectedDates } from "../../components/merchantDashboard/header/dateFilters/MerchantDashboardDateFilters"
import { findIndexDashboardDataWithSameDates } from "../../services/merchantDashboardService"
import { IMerchantDashboardServerData } from "../../type/merchantDashboard/IMerchantDashboardServerData"
import { StoreState } from "../StoreState"

export interface IMerchantDashboardSearchData {
    serverData: IMerchantDashboardServerData
    selectedDates: IMerchantDashboardSelectedDates
}

export type MerchantDashboardState = Readonly<{
    dashboardData: { [webshopId: string]: IMerchantDashboardSearchData[] }
    latestReportDates: { [webshopId: string]: Date | null }
}>

const initialState: MerchantDashboardState = {
    dashboardData: {},
    latestReportDates: {},
}

export const merchantDashboardReducerActions = {
    setMerchantDashboardData: (
        serverData: IMerchantDashboardServerData,
        webshopId: string,
        selectedDates: IMerchantDashboardSelectedDates
    ) => createAction("APP/SET_MERCHANT_DASHBOARD_DATA", { serverData, webshopId, selectedDates }),
    setWebshopDashboardLatestReportDate: (webshopId: string, latestReportDate?: Date) =>
        createAction("APP/SET_WEBSHOP_LATEST_REPORT_DATE", { webshopId, latestReportDate }),
}

export type MerchantDashboardActions = ActionsUnion<typeof merchantDashboardReducerActions>

export const merchantDashboardReducer = (state: MerchantDashboardState = initialState, action: MerchantDashboardActions) => {
    switch (action.type) {
        case "APP/SET_MERCHANT_DASHBOARD_DATA": {
            const all = state.dashboardData[action.webshopId] ?? []
            const indexWithSameDates = findIndexDashboardDataWithSameDates(all, action.selectedDates)
            const otherDataForWebshop = (indexWithSameDates && removeElementAtIndex(all, indexWithSameDates)) || all
            return {
                ...state,
                dashboardData: {
                    ...state.dashboardData,
                    [action.webshopId]: [
                        ...otherDataForWebshop,
                        {
                            serverData: action.serverData,
                            selectedDates: action.selectedDates,
                        },
                    ],
                },
            }
        }
        case "APP/SET_WEBSHOP_LATEST_REPORT_DATE": {
            return {
                ...state,
                latestReportDates: {
                    ...state.latestReportDates,
                    [action.webshopId]: action.latestReportDate ?? null,
                },
            }
        }
    }

    return state
}

export const merchantDashboardSelectors = {
    dashboardData: (state: StoreState, webshopId: string, selectedDates: IMerchantDashboardSelectedDates) => {
        const index = findIndexDashboardDataWithSameDates(state.merchantDashboard.dashboardData[webshopId] ?? [], selectedDates)
        if (index === -1) {
            return
        }
        return state.merchantDashboard.dashboardData[webshopId][index]
    },
    latestReportDate: (state: StoreState, webshopId?: string) =>
        webshopId && state.merchantDashboard.latestReportDates[webshopId]
            ? state.merchantDashboard.latestReportDates[webshopId]
            : undefined,
}
