import React from "react"
import cn from "classnames"
import "./InlineElementsWithDividers.scss"

interface IInlineElementsWithDividersProps {
    elements: JSX.Element[]
    className?: string
}

const InlineElementsWithDividers = ({ elements, className }: IInlineElementsWithDividersProps) => {
    return <div className={cn("inline-elements-with-dividers", className)}>{elements.map((e) => e)}</div>
}

export default InlineElementsWithDividers
