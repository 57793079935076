import React, { useState } from "react"
import { InfoBoxState } from "./InfoBoxState"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import EditIcon from "../../images/icons/edit.png"

export type TEditButtonTextType = "editing" | "adding"
interface IInfoBoxStateContainerProps {
    state: InfoBoxState
    showEditBtn: boolean
    editBtnTextType: TEditButtonTextType
    useShortEditText?: boolean
    onClick?: () => void
    children?: React.ReactNode
}

const InfoBoxStateContainer = ({
    state,
    useShortEditText,
    showEditBtn,
    editBtnTextType,
    onClick,
    children,
}: IInfoBoxStateContainerProps) => {
    const { t } = useTranslation()
    const [hover, setHover] = useState(false)

    const editText =
        editBtnTextType === "adding"
            ? useShortEditText
                ? t("common.clickToAddShort")
                : t("common.clickToAdd")
            : useShortEditText
            ? t("common.clickToEditShort")
            : t("common.clickToEdit")

    return (
        <div
            className={cn({ pointer: state !== InfoBoxState.NOEDIT && state !== InfoBoxState.DISABLED })}
            onClick={() => {
                if (IsClickable(state) && onClick) {
                    onClick()
                }
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className={cn("border-container", state)}>
                {children}

                {isEditable(state) && showEditBtn && (
                    <div
                        className="edit"
                        onClick={() => {
                            if (onClick) {
                                onClick()
                            }
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <span>
                            {hover && editText} <img src={EditIcon} />
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

function isEditable(state: InfoBoxState) {
    return state !== InfoBoxState.DISABLED && state !== InfoBoxState.NOEDIT && state !== InfoBoxState.DELETEONLY
}

function IsClickable(state: InfoBoxState) {
    return state !== InfoBoxState.DISABLED && state !== InfoBoxState.NOEDIT && state !== InfoBoxState.NONE
}

export default InfoBoxStateContainer
