import React from "react"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps } from "swiipe.portal.shared"

export interface IModalSalesHistoryInfo extends IModalBase {
    type: "salesHistoryInfo"
    reason: string
    fullName: string
}

const ModalSalesHistoryInfo = ({
    title,
    reason,
    fullName,
    callback,
    ...otherProps
}: IModalSalesHistoryInfo & IModalControlProps) => {
    return (
        <BaseModal {...otherProps} showCloseButton callback={callback} centered title={title}>
            <ModalBody>
                <span className="text font-italic">&quot;{reason}&quot;</span>
                <span className="name mt-3 d-block text-right">{fullName}</span>
            </ModalBody>
        </BaseModal>
    )
}

export default ModalSalesHistoryInfo
