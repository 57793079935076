import React from "react"
import cn from "classnames"
import "./ExternalLinkButton.scss"

interface IExternalLinkButtonProps {
    href: string
    className?: string
    id?: string
    textOnly?: boolean
    title?: string
    children?: React.ReactNode
    dark?: boolean
}

const ExternalLinkButton = ({ textOnly, title, dark, href, id, className }: IExternalLinkButtonProps) => {
    return (
        <div
            id={id}
            className={cn("external-link-button", "swiipe-standard-button", "swiipe-btn", className, {
                "swiipe-btn-dark": dark && !textOnly,
                "swiipe-btn-white": !dark && !textOnly,
                "external-link-button--text-only": textOnly,
            })}
        >
            <a href={href} target="_blank" rel="noopener noreferrer">
                <span className="external-link-button__title">{title}</span>
            </a>
        </div>
    )
}

export default ExternalLinkButton
