import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    PageContainer,
    PageWrapper,
    StandardBox,
    StandardButtonWithSpinner,
    sharedConfigurationSelectors,
    useSelector,
} from "swiipe.portal.shared"
import ResendConfirmationEmail from "../../components/partner/ResendConfirmationEmail"
import RequestSentText from "../../components/textFormat/RequestSentText"
import { getMerchantContact } from "../../services/merchantService"
import { filterPartnerMerchantRelationsASC } from "../../services/partnerService"
import { getRootUrlForRelation, useRequiredUserRelations } from "../../services/userRelationService"
import { getMerchantDataThunk } from "../../store/thunks/merchantThunks"
import { ensurePartnerMerchantRelationsThunk } from "../../store/thunks/partnerThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TUserRole } from "../../type/TUserRole"
import Adder from "./../../components/form/common/Adder"
import PartnerAddMerchantRelationForm from "./../../components/form/partner/PartnerAddMerchantRelationForm"
import CloseBox from "./../../components/layouts/CloseBox"
import { navigationService } from "./../../services/navigationService"
import { partnerSelectors } from "./../../store/reducers/partnerReducer"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"
import { switchUserThunk } from "./../../store/thunks/startupThunks"
import "./PartnerMerchantRelationsPage.scss"

interface IPartnerMerchantRelationsPageProps {}

const PartnerMerchantRelationsPage = ({}: IPartnerMerchantRelationsPageProps) => {
    const { t } = useTranslation()
    useRequiredUserRelations([{ requiredType: "Partner", requiredRole: TUserRole.Admin }])
    const [formInEditMode, setFormInEditMode] = useState(false)
    const [isAdded, setIsAdded] = useState(false)
    const [activeButtonId, setActiveButtonId] = useState("")

    const env = useSelector(sharedConfigurationSelectors.environment)

    const [activeFormForMerchantId, setActiveFormForMerchantId] = useState("")
    const [activeFormMerchantEmail, setActiveFormMerchantEmail] = useState("")

    const dispatch = useReduxDispatch()
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)!
    const userRelations = useSelector(userRelationSelectors.userRelationsFlattened)!
    const partnerMerchantRelations = useSelector(partnerSelectors.partnerMerchantRelations) || []

    const partnerMerchantRelationsFiltered = filterPartnerMerchantRelationsASC(partnerMerchantRelations)

    useEffect(() => {
        dispatch(ensurePartnerMerchantRelationsThunk(false))
    }, [currentUserRelation])

    const toggleActiveButton = (merchantId: string) => {
        if (activeButtonId === merchantId) {
            setActiveButtonId("")
        } else {
            setActiveButtonId(merchantId)
        }
    }

    return (
        <PageWrapper>
            <PageContainer id="partner-relations-page" transparent title={t("menu.partnerMerchantRelations")}>
                {formInEditMode && (
                    <CloseBox
                        width={(!isAdded && "narrow") || undefined}
                        onClose={() => {
                            setFormInEditMode(false)
                            if (isAdded) {
                                setIsAdded(false)
                            }
                        }}
                    >
                        {!isAdded && (
                            <PartnerAddMerchantRelationForm
                                onAdded={() => {
                                    setIsAdded(true)
                                }}
                            />
                        )}
                        {isAdded && (
                            <div>
                                <span className="icon icon-ok"></span>
                                <p className="text-center mb-4 text-uppercase">
                                    <strong>{t("partnermerchantrelations.accountcreated")}</strong>
                                </p>
                                <p>{t("partnermerchantrelations.createdmsg1")}</p>
                                <p>{t("partnermerchantrelations.createdmsg2")}</p>
                            </div>
                        )}
                    </CloseBox>
                )}
                {!formInEditMode && (
                    <Adder onClick={() => setFormInEditMode(true)} buttonText={t("partnermerchantrelations.createaccount")} />
                )}
                {partnerMerchantRelationsFiltered.map((rel) => {
                    const currentRelation = userRelations.find((r) => r.id === rel.merchantId && !r.markedDeletion)

                    if (!currentRelation) {
                        return null
                    }

                    return (
                        <StandardBox key={rel.merchantId}>
                            <div className="d-flex align-items-center ecommerce-account-box">
                                <div className="icon-field">
                                    <span className="icon icon-ecommerce-account"></span>
                                </div>
                                <div
                                    className={cn("flex-fill", "separator-left", "mx-2", {
                                        active: rel.termsAcceptedByMerchantOwner,
                                    })}
                                    onClick={() => toggleActiveButton(rel.merchantId)}
                                >
                                    <div className="merchant-name">{rel.merchantName}</div>
                                </div>
                                <div className="action-button-box">
                                    <div className="action-btn-text-container">
                                        {!rel.termsAcceptedByMerchantOwner && (
                                            <div className="mb-1 text-above-button">
                                                {t("partnermerchantrelations.awaitingconfirm")}
                                            </div>
                                        )}
                                        <StandardButtonWithSpinner
                                            isDelete={!rel.termsAcceptedByMerchantOwner}
                                            inverted={!rel.termsAcceptedByMerchantOwner}
                                            className={cn("action-button", {
                                                "d-sm-block":
                                                    !rel.confirmEmailResent && rel.merchantId !== activeFormForMerchantId,
                                                "d-sm-none": rel.confirmEmailResent,
                                                "request-btn": !rel.termsAcceptedByMerchantOwner,
                                                "d-none":
                                                    rel.merchantId !== activeButtonId ||
                                                    rel.confirmEmailResent ||
                                                    rel.merchantId === activeFormForMerchantId,
                                            })}
                                            title={
                                                rel.termsAcceptedByMerchantOwner
                                                    ? t("partnermerchantrelations.manage")
                                                    : t("partnermerchantrelations.request")
                                            }
                                            onClick={async () => {
                                                const userRel = userRelations.find(
                                                    (r) =>
                                                        !!r.parentId &&
                                                        r.parentId === currentUserRelation.id &&
                                                        r.id === rel.merchantId
                                                )
                                                if (userRel && rel.termsAcceptedByMerchantOwner) {
                                                    await dispatch(switchUserThunk(userRel))
                                                    navigationService.navigate(getRootUrlForRelation(userRel, env))
                                                } else if (userRel && userRel.parentId) {
                                                    const merchantDetails = await dispatch(getMerchantDataThunk(rel.merchantId))
                                                    const swMerchantContact = getMerchantContact(merchantDetails)
                                                    setActiveFormMerchantEmail(swMerchantContact?.email || "")
                                                    setActiveFormForMerchantId(rel.merchantId)
                                                }
                                            }}
                                        />
                                        <RequestSentText
                                            show={rel.confirmEmailResent ? true : false}
                                            text={t("partnermerchantrelations.requestsent")}
                                        />
                                    </div>
                                </div>
                            </div>
                            {activeFormForMerchantId === rel.merchantId && (
                                <ResendConfirmationEmail
                                    merchantEmail={activeFormMerchantEmail}
                                    merchantId={rel.merchantId}
                                    partnerId={currentUserRelation.id}
                                    toggleCallback={(relId: string) => {
                                        toggleActiveButton(relId)
                                        setActiveFormForMerchantId("")
                                        setActiveFormMerchantEmail("")
                                    }}
                                />
                            )}
                        </StandardBox>
                    )
                })}
            </PageContainer>
        </PageWrapper>
    )
}

export default PartnerMerchantRelationsPage
