import { ActionsUnion, createAction } from "swiipe.portal.shared"
import { IPartnerDetails } from "../../type/IPartnerDetails"
import { StoreState } from "../StoreState"
import { IPartnerMerchantRelation } from "./../../type/IPartnerMerchantRelation"

export type PartnerState = Readonly<{
    partnerDetails: { [partnerId: string]: IPartnerDetails | undefined }
    merchantRelations: { [partnerId: string]: IPartnerMerchantRelation[] | undefined }
    hasMasterPartner?: boolean
    masterPartnerId?: string
}>
const initialState: PartnerState = { partnerDetails: {}, merchantRelations: {} }

export const partnerReducerActions = {
    setPartnerDetails: (partnerId: string, partnerDetails: IPartnerDetails) =>
        createAction("APP/SET_PARTNER_DETAILS", { partnerId, partnerDetails }),
    setMerchantRelations: (partnerId: string, merchantRelations: IPartnerMerchantRelation[]) =>
        createAction("APP/SET_PARTNER_MERCHANT_RELATIONS", { partnerId, merchantRelations }),
    setHasMasterPartner: (hasMasterPartner: boolean, masterPartnerId: string) =>
        createAction("APP/SET_HAS_MASTER_PARNTER", { hasMasterPartner, masterPartnerId }),
}

export type PartnerActions = ActionsUnion<typeof partnerReducerActions>

export const partnerReducer = (state: PartnerState = initialState, action: PartnerActions) => {
    switch (action.type) {
        case "APP/SET_PARTNER_DETAILS": {
            return {
                ...state,
                partnerDetails: {
                    ...state.partnerDetails,
                    [action.partnerId]: action.partnerDetails,
                },
            }
        }
        case "APP/SET_PARTNER_MERCHANT_RELATIONS": {
            return {
                ...state,
                merchantRelations: {
                    ...state.merchantRelations,
                    [action.partnerId]: action.merchantRelations,
                },
            }
        }
        case "APP/SET_HAS_MASTER_PARNTER": {
            return {
                ...state,
                hasMasterPartner: action.hasMasterPartner,
                masterPartnerId: action.masterPartnerId,
            }
        }
    }

    return state
}

export const partnerSelectors = {
    partnerDetails: (state: StoreState) =>
        state.userRelation.currentRelation ? state.partner.partnerDetails[state.userRelation.currentRelation.id] : undefined,
    partnerDetailsById: (state: StoreState, partnerId: string) => state.partner.partnerDetails[partnerId],
    partnerMerchantRelations: (state: StoreState) =>
        state.userRelation.currentRelation ? state.partner.merchantRelations[state.userRelation.currentRelation.id] : undefined,
    partnerMerchantRelationsForId: (state: StoreState, partnerId: string) => state.partner.merchantRelations[partnerId],
    partnerMerchantRelationsAll: (state: StoreState) => state.partner.merchantRelations,
    partnersContainMasterPartner: (state: StoreState) => state.partner.hasMasterPartner,
    partnersMasterPartnerId: (state: StoreState) => state.partner.masterPartnerId,
}
