import cn from "classnames"
import React from "react"
import { Input, InputProps } from "reactstrap"
import { FieldsetFormGroup } from "swiipe.portal.shared"
import "./FixedLabelTextAreaInput.scss"

interface IFixedLabelTextAreaInput extends InputProps {
    label: string
    className?: string
    inputClassName?: string
    labelClassName?: string
}

const FixedLabelTextAreaInput = (props: IFixedLabelTextAreaInput) => {
    return (
        <FieldsetFormGroup field className={props.className}>
            <span className={cn("fixed-label", "blue", props.labelClassName)}>{props.label}</span>
            <Input {...props} type="textarea" rows={props.rows ?? 9} className={props.inputClassName}></Input>
        </FieldsetFormGroup>
    )
}
export default FixedLabelTextAreaInput
