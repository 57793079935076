import React from "react"
import { useTranslation } from "react-i18next"
import {
    FloatingLabelInput,
    IFloatingLabelsInputProps,
    TRegister,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"

interface IEmailInputCompProps extends IFloatingLabelsInputProps {
    inputName: string
    defaultValue?: string
    isOptional?: boolean
    register: TRegister
}

const EmailInputComp = ({ inputName = "email", defaultValue, register, isOptional, ...otherProps }: IEmailInputCompProps) => {
    const { t } = useTranslation()
    return (
        <FloatingLabelInput
            placeholder={t("common.email.label")}
            name={inputName}
            type="email"
            innerRef={
                isOptional
                    ? register(valFuncPattern(validationPatterns.email, t("common.email.errorMessage")))
                    : register(valFuncRequiredAndPattern(validationPatterns.email, t("common.email.errorMessage")))
            }
            defaultValue={defaultValue || ""}
            {...otherProps}
        />
    )
}

export default EmailInputComp
