import cn from "classnames"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    DataType,
    ShowErrorMessages,
    SpinnerContainer,
    StandardButton,
    TClearError,
    TRegister,
    TSetError,
    TSetValue,
    TWatch,
} from "swiipe.portal.shared"
import { InfoBoxDeleteDialogModel } from "../../../src/type/InfoBoxDeleteDialogModel"
import { IEditFormBase } from "../../type/form/IEditFormBase"
import SubmitButton from "./../buttons/SubmitButton"
import InfoBoxDeleteDialog from "./InfoBoxDeleteDialog"
import "./InfoBoxEditor.scss"
import { InfoBoxState } from "./InfoBoxState"
import InfoBoxStateContainer, { TEditButtonTextType } from "./InfoBoxStateContainer"

export type TSetShowSpinnerFunc = (showSpinner: boolean) => void

interface IInfoBoxEditorFormProps<T> extends IEditFormBase {
    iconClass?: string
    renderFormFields?: (
        register: TRegister,
        setValue: TSetValue<T>,
        getValues: () => any,
        watch: TWatch<any>
    ) => React.ReactElement
    renderExtraFields?: (register: TRegister) => React.ReactElement
    title: string
    noSave?: boolean
    saveButtonTitle?: string
    onSave?: (model: T, setShowSpinner: TSetShowSpinnerFunc) => Promise<void>
    allowDelete?: boolean
    confirmDeleteDialog?: InfoBoxDeleteDialogModel
    componentUnmountsAfterDelete?: boolean
    deleteButtonTitle?: string
    deleteButtonTextSmaller?: boolean
    onDelete?: (
        setShowSpinner: TSetShowSpinnerFunc,
        setValue: TSetValue<T>,
        setError: TSetError<T>,
        clearErrors: TClearError<T>
    ) => Promise<void>
    state: InfoBoxState
    shortEditText?: boolean
}

const InfoBoxEditorForm = <T extends DataType>({
    iconClass,
    renderFormFields,
    renderExtraFields,
    title,
    noSave,
    saveButtonTitle,
    onSave,
    allowDelete,
    confirmDeleteDialog,
    componentUnmountsAfterDelete,
    deleteButtonTitle,
    deleteButtonTextSmaller,
    onDelete,
    onSwitchEditMode,
    shortEditText,
    state,
}: IInfoBoxEditorFormProps<T>) => {
    const { register, handleSubmit, formState, setValue, getValues, watch, setError, clearErrors } = useForm<any>()
    const { t } = useTranslation()
    const [isDeleting, setIsDeleting] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [confirmIsVisible, setConfirmIsVisible] = useState(false)

    const onSubmit: SubmitHandler<any> = async (data, e) => {
        try {
            if (onSave) {
                setIsSaving(true)
                await onSave(data, (showSpinner) => setIsSaving(showSpinner))
                setIsSaving(false)
                onSwitchEditMode(false)
            }
        } catch (err) {
            // Catch to stop showing spinner
            setIsSaving(false)
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="infoboxeditor-form">
            <div>
                <InfoBoxStateContainer
                    useShortEditText={shortEditText}
                    state={state}
                    showEditBtn={false}
                    editBtnTextType="adding"
                >
                    <div className="d-flex pr-4">
                        {!!iconClass && (
                            <div className={cn("icon-container adaptive-icon float-left mr-2 d-block d-sm-none")}>
                                <span className={cn("icon", iconClass)}></span>
                            </div>
                        )}
                        <strong>{title}</strong>
                    </div>
                    {renderFormFields && renderFormFields(register, setValue, getValues, watch)}
                    <div
                        onClick={() => {
                            onSwitchEditMode(false)
                        }}
                        className="close-form"
                    ></div>
                </InfoBoxStateContainer>
            </div>
            <div>
                {renderExtraFields && renderExtraFields(register)}
                <div className="mt-2 mb-0 ml-4">
                    <ShowErrorMessages<T> formState={formState} />
                </div>
            </div>
            <div className="button-container d-sm-flex flex-row-reverse">
                {!noSave && !confirmIsVisible && (
                    <div className="col-sm-5 px-3 px-sm-0">
                        <SubmitButton
                            isSmall
                            dark
                            noBorder
                            disabled={isDeleting || state === InfoBoxState.DELETEONLY || state === InfoBoxState.DISABLED}
                            className="btn btn-secondary mt-2 mt-sm-0 edit-form-submit"
                            formState={formState}
                            title={saveButtonTitle || t("personal.save")}
                        />
                    </div>
                )}
                {!noSave && !confirmIsVisible && (
                    <span className={cn("d-block d-sm-inline-block", allowDelete ? "col-sm-2" : "col-sm-7")} />
                )}
                {allowDelete && !confirmDeleteDialog && (
                    <div className="col-sm-5 px-3 px-sm-0">
                        <SpinnerContainer showSpinner={isDeleting}>
                            <StandardButton
                                isSmall
                                disabled={isSaving}
                                className={cn(
                                    "btn btn-secondary mt-2 mt-sm-0 edit-form-delete btn-infobox-delete",
                                    deleteButtonTextSmaller && "btn-infobox-delete--text-smaller"
                                )}
                                onClick={async (e) => {
                                    e.preventDefault()
                                    if (onDelete) {
                                        setIsDeleting(true)
                                        try {
                                            await onDelete(
                                                (showSpinner) => setIsDeleting(showSpinner),
                                                setValue,
                                                setError,
                                                clearErrors
                                            )
                                            if (!componentUnmountsAfterDelete) {
                                                setIsDeleting(false)
                                                onSwitchEditMode(false)
                                            }
                                        } catch (err) {
                                            setIsDeleting(false)
                                        }
                                    }
                                }}
                                title={deleteButtonTitle || t("personal.delete")}
                            />
                        </SpinnerContainer>
                    </div>
                )}
                {allowDelete && confirmDeleteDialog && !confirmIsVisible && (
                    <div className="col-sm-5 px-3 px-sm-0">
                        <SpinnerContainer showSpinner={isDeleting}>
                            <StandardButton
                                isSmall
                                disabled={isSaving}
                                className="btn btn-secondary mt-2 mt-sm-0 edit-form-delete btn-infobox-delete"
                                onClick={() => setConfirmIsVisible(true)}
                                title={deleteButtonTitle || t("personal.delete")}
                            />
                        </SpinnerContainer>
                    </div>
                )}
                {noSave && !confirmIsVisible && (
                    <span className={cn("d-block d-sm-inline-block", allowDelete ? "col-sm-7" : "col-sm-12")} />
                )}
                {allowDelete && confirmIsVisible && confirmDeleteDialog && (
                    <div className="col-12 pl-0 pr-0">
                        <InfoBoxDeleteDialog
                            confirmDeleteDialog={confirmDeleteDialog}
                            onCancelDelete={async () => {
                                setConfirmIsVisible(false)
                            }}
                            onConfirmDelete={async () => {
                                if (onDelete) {
                                    setIsDeleting(true)
                                    try {
                                        await onDelete(
                                            (showSpinner) => setIsDeleting(showSpinner),
                                            setValue,
                                            setError,
                                            clearErrors
                                        )
                                        if (!componentUnmountsAfterDelete) {
                                            setIsDeleting(false)
                                            onSwitchEditMode(false)
                                        }
                                    } catch (err) {
                                        setIsDeleting(false)
                                    }
                                }
                            }}
                            isDeleting={isDeleting}
                            isSaving={isSaving}
                        />
                    </div>
                )}
            </div>
        </Form>
    )
}

interface IInfoBoxEditorProps<T> extends IInfoBoxEditorFormProps<T> {
    renderPreview: () => React.ReactElement
    renderAdditionalTop?: () => React.ReactElement
    editBtnTextType: TEditButtonTextType
    editBtnBindTop?: boolean
    renderPreviewIncludesContainer?: boolean
}

const InfoBoxEditor = <T extends DataType>({
    renderPreview,
    renderPreviewIncludesContainer,
    renderAdditionalTop,
    isInEditMode,
    editBtnTextType,
    editBtnBindTop,
    onSwitchEditMode,
    state,
    shortEditText,
    ...otherProps
}: IInfoBoxEditorProps<T>) => {
    return (
        <div
            className={cn(
                "infobox-editor-container",
                "inner-info-container",
                { "edit-btn-bind-top": editBtnBindTop },
                { isineditmode: isInEditMode }
            )}
        >
            <div className="separator"></div>
            {renderAdditionalTop && renderAdditionalTop()}
            {!isInEditMode && !renderPreviewIncludesContainer && (
                <InfoBoxStateContainer
                    useShortEditText={shortEditText}
                    showEditBtn={!isInEditMode}
                    onClick={() => onSwitchEditMode(true)}
                    state={state}
                    editBtnTextType={editBtnTextType}
                >
                    {renderPreview()}
                </InfoBoxStateContainer>
            )}
            {!isInEditMode && renderPreviewIncludesContainer && renderPreview()}
            {isInEditMode && (
                <InfoBoxEditorForm
                    onSwitchEditMode={onSwitchEditMode}
                    isInEditMode={isInEditMode}
                    state={state}
                    {...otherProps}
                />
            )}
        </div>
    )
}

export default InfoBoxEditor
