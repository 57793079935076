import { EInvoicingRelationType } from "../store/reducers/billingReducer"
import { TUserRelation } from "../type/TUserRelation"
import { IInvoiceSimple } from "../type/billing/IInvoice"

export function getPaymentStatusConfig(invoice: IInvoiceSimple): {
    statusTextId: string
    descTextId: string
    alarmLevel: "default" | "green" | "red"
} {
    if (!invoice.hasPaymentStatus) {
        return { statusTextId: "invoices.statuses.legacy", descTextId: "invoices.statuses.legacyDesc", alarmLevel: "default" }
    }
    if (invoice.isManual) {
        if (invoice.isPaid) {
            return {
                statusTextId: "invoices.statuses.paidManual",
                descTextId: "invoices.statuses.paidManualDesc",
                alarmLevel: "green",
            }
        }
        if (invoice.isDue) {
            return {
                statusTextId: "invoices.statuses.dueManual",
                descTextId: "invoices.statuses.dueManualDesc",
                alarmLevel: "red",
            }
        }
        return {
            statusTextId: "invoices.statuses.waitingManual",
            descTextId: "invoices.statuses.waitingManualDesc",
            alarmLevel: "default",
        }
    }

    if (invoice.isPaid) {
        return {
            statusTextId: "invoices.statuses.paidAutomatic",
            descTextId: "invoices.statuses.paidAutomaticDesc",
            alarmLevel: "green",
        }
    }
    if (invoice.isDue) {
        return {
            statusTextId: "invoices.statuses.dueAutomatic",
            descTextId: "invoices.statuses.dueAutomaticDesc",
            alarmLevel: "red",
        }
    }
    if (invoice.isFailed) {
        return {
            statusTextId: "invoices.statuses.failedAutomatic",
            descTextId: "invoices.statuses.failedAutomaticDesc",
            alarmLevel: "red",
        }
    }
    return {
        statusTextId: "invoices.statuses.waitingAutomatic",
        descTextId: "invoices.statuses.waitingAutomaticDesc",
        alarmLevel: "default",
    }
}

export function mapUserRelationTypeToInvoicingRelationType(userRelationType: TUserRelation): EInvoicingRelationType {
    switch (userRelationType) {
        case "Merchant":
            return "Merchant"
        case "Partner":
            return "Partner"
        case "User":
            return "User"
    }

    throw "User relation type not supported for invoicing"
}
